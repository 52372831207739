/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Redirect, RouteComponentProps, useNavigate } from '@reach/router';
import React, { FC } from 'react';
import { MessageIcon } from '../../components/icons/MessageIcon';
import AuthedPageLayout from '../../components/pagelayout/AuthedPageLayout';
import { useT } from '../../i18n/useT';
import { useUserContext } from '../../lib/UserContext';
import useSelectedPatientId from '../../lib/useSelectedPatientId';
import DownloadSleepObservationReportButton from './DownloadSleepObservationReportButton';
import IconLink from './IconLink';
import { MissingInfoAlert } from './MissingInfoAlert';

const icons = {
  appointments: require('./assets/appointments_icon.png'),
  clients: require('./assets/clients_icon.png'),
  food: require('./assets/food_icon.png'),
  friends: require('./assets/friends_icon.png'),
  medication: require('./assets/medication_icon.png'),
  messages: require('./assets/messageIcon.svg'),
  notifications: require('./assets/notifications_icon.png'),
  sharing: require('./assets/sharing_icon.png'),
};

const PageContent = () => {
  const t = useT();
  const navigate = useNavigate();
  const { loggedInUser, hasRole } = useUserContext();
  const selectedPatientId = useSelectedPatientId();
  const selectedPatientIsLoggedInUser = selectedPatientId === loggedInUser!.user.patientId;
  const showAdminStuff = hasRole('SystemAdmin') && selectedPatientIsLoggedInUser;
  const showPhysicianStuff = hasRole('Physician') && selectedPatientIsLoggedInUser;

  React.useEffect(() => {
    if (!selectedPatientId) {
      navigate(`/${loggedInUser!.user.patientId}/hjem`);
    }
  }, [selectedPatientId, loggedInUser, navigate]);

  const content = (
    <>
      <IconLink
        to={`/${selectedPatientId}/medisiner`}
        title={t('Medications')}
        icon={icons.medication}
        description={t('Overview of your medications')}
      />
      <IconLink
        to={`/${selectedPatientId}/medications`}
        title={t('Medications') + ' 2'}
        icon={icons.medication}
        description={t('Overview of your medications')}
      />

      {(showAdminStuff || process.env.NODE_ENV === 'development') && (
        <IconLink to={`/${selectedPatientId}/kalender`} title={t('Calendar')} icon={icons.appointments} disabled />
      )}
      {(showAdminStuff || process.env.NODE_ENV === 'development') && (
        <IconLink to="/mat" title={t('Nutrition')} icon={icons.food} disabled />
      )}
      <IconLink
        to={`/${selectedPatientId}/meldinger`}
        title={t('Conversations')}
        iconElement={<MessageIcon size={35} />}
      />
      {showAdminStuff && <IconLink to="/klienter" title={t('Clients')} icon={icons.clients} disabled />}
      {showAdminStuff && <IconLink to="/varsler" title={t('Notifications')} icon={icons.notifications} disabled />}
      <IconLink
        to={`/${selectedPatientId}/sovn-oversikt`}
        title={t('Sleep diary')}
        icon={icons.notifications}
        description={t('Register and analyse your sleep')}
      />
      {(showAdminStuff || showPhysicianStuff) && (
        <IconLink to="/brukere" title={t('Users')} icon={icons.clients} description={t('List of all users')} />
      )}
      {selectedPatientIsLoggedInUser && (
        <IconLink
          to="/access-control"
          title={t('Access control')}
          icon={icons.sharing}
          description={t('Manage who has access to your data')}
        />
      )}
      <IconLink
        to={`/${selectedPatientId}/medical-conditions`}
        title={t('Medical conditions')}
        icon={icons.medication}
        description={t('Manage medical conditions')}
      />
      <IconLink
        to={`/${selectedPatientId}/allergies-and-intolerances`}
        title={t('Allergies and intolerances')}
        icon={icons.medication}
        description={t('Manage allergies and intolerances')}
      />
    </>
  );

  return (
    <div>
      <MissingInfoAlert />
      <div
        css={css`
          width: 100%;
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
          grid-gap: 20px;
        `}
      >
        {content}
      </div>
      {showAdminStuff && (
        <div
          css={css`
            margin-top: 40px;
          `}
        >
          <DownloadSleepObservationReportButton />
        </div>
      )}
    </div>
  );
};

export const HomePage: FC<RouteComponentProps> = () => {
  const t = useT();
  const user = useUserContext();
  const selectedPatientId = useSelectedPatientId();

  if (user.hasRole('Covid19User') && selectedPatientId) {
    return <Redirect to={`/${selectedPatientId}/covid19`} noThrow />;
  }

  return (
    <AuthedPageLayout documentTitle={t('Capable - Home')}>
      <PageContent />
    </AuthedPageLayout>
  );
};
