/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { Column } from './Flex';
import Text, { getTextStyle } from './Text';

export const Datalist = ({
  label,
  options,
  value,
  onValue,
}: {
  label: string;
  options: string[];
  value: string;
  onValue: (value: string) => void;
}) => {
  const [listId] = useState(String(Math.random()));

  return (
    <label
      css={css`
        margin-top: 16px;
      `}
    >
      <Column>
        <Text marginBottom={4}>{label}</Text>

        <input
          type="text"
          list={listId}
          value={value}
          onChange={(event) => {
            onValue(event.target.value);
          }}
          css={[
            getTextStyle('normal'),
            css`
              height: 32px;
              padding-left: 8px;
              padding-right: 8px;
              border-radius: 4px;
              border: 1px solid gray;
              color: black;
            `,
          ]}
        />
        <datalist id={listId}>
          {options.map((option, key) => (
            <option key={key} value={option} />
          ))}
        </datalist>
      </Column>
    </label>
  );
};
