/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Redirect, RouteComponentProps } from '@reach/router';
import { startOfWeek } from 'date-fns';
import React, { FC } from 'react';
import ErrorPageContent from '../../components/ErrorPageContent';
import { Column, Row } from '../../components/Flex';
import Heading from '../../components/Heading';
import Loader from '../../components/loader/Loader';
import AuthedPageLayout from '../../components/pagelayout/AuthedPageLayout';
import Text from '../../components/Text';
import { useT } from '../../i18n/useT';
import useSelectedPatientId from '../../lib/useSelectedPatientId';
import formatDate from '../../utils/formatDate';
import SleepWeekReport from './SleepWeekReport';
import WeekAccounts, { useWeekAccounts } from './WeekAccounts';

function SleepOverview({ selectedPatientId, weekDate }: { selectedPatientId: string; weekDate: string }) {
  const weekAccounts = useWeekAccounts(selectedPatientId!);
  const t = useT();
  if (weekAccounts.loading) {
    return <Loader />;
  } else if (weekAccounts.error) {
    return <ErrorPageContent />;
  }

  return (
    <Column
      css={css`
        /* max-width: 820px; */
        /* margin: 0 auto; */
        margin-bottom: 20px;
      `}
    >
      <Row horizontal="center">
        <WeekAccounts
          patientId={selectedPatientId!}
          weekAccounts={weekAccounts.data ?? []}
          selectedWeekDate={weekDate}
        />
      </Row>

      <SleepWeekReport patientId={selectedPatientId!} weekDate={weekDate} />

      <Column>
        <Heading level={5}>{t('Scientific background')}</Heading>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://pubmed.ncbi.nlm.nih.gov/22294820/?from_term=consensus+sleep+diary"
        >
          <Text color="blue">The Consensus Sleep Diary: Standardizing Prospective Sleep Self-Monitoring</Text>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://pubmed.ncbi.nlm.nih.gov/26194727/?from_term=denominator+sleep"
        >
          <Text color="blue">Measuring Sleep Efficiency: What Should the Denominator Be?</Text>
        </a>
      </Column>
    </Column>
  );
}

export const SleepOverviewPage: FC<RouteComponentProps<{ date?: string }>> = ({ date }) => {
  const t = useT();
  const selectedPatientId = useSelectedPatientId();

  if (!selectedPatientId) {
    return <Redirect to="/hjem" noThrow />;
  }

  if (!date) {
    const thisWeek = formatDate(startOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd');
    return <Redirect to={`/${selectedPatientId}/sovn-oversikt/${thisWeek}T00:00:00`} noThrow />;
  }

  return (
    <AuthedPageLayout documentTitle={t('Capable - Sleep')}>
      <SleepOverview selectedPatientId={selectedPatientId} weekDate={date} />
    </AuthedPageLayout>
  );
};
