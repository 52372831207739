import { RouteComponentProps, useNavigate } from '@reach/router';
import React, { FC } from 'react';
import { FilledButton } from '../../components/Button2';
import { Column } from '../../components/Flex';
import Loader from '../../components/loader/Loader';
import OpenPageLayout from '../../components/pagelayout/OpenPageLayout';
import Text from '../../components/Text';
import { useT } from '../../i18n/useT';
import { useUserContext } from '../../lib/UserContext';
import AuthClient from '../../network/authClient';
import ignoreException from '../../utils/ignoreException';

const PageContent = () => {
  const t = useT();
  const [logoutFailed, setLogoutFailed] = React.useState(false);
  const navigate = useNavigate();
  const { clearLoggedIn } = useUserContext();

  const logout = React.useCallback(async () => {
    ignoreException(() => {
      window.sessionStorage.clear();
    }, undefined);

    const result = await AuthClient.logOut();

    if (result.success) {
      clearLoggedIn();
      navigate('/');
    } else {
      setLogoutFailed(true);
    }
  }, [clearLoggedIn, navigate]);

  React.useEffect(() => {
    logout();
  }, [logout]);

  return logoutFailed ? (
    <Column horizontal="center">
      <Text>{t('Unable to log out')}</Text>
      <FilledButton onClick={logout} marginTop={20}>
        {t('Try again')}
      </FilledButton>
    </Column>
  ) : (
    <Loader />
  );
};

export const LogoutPage: FC<RouteComponentProps> = () => {
  return (
    <OpenPageLayout>
      <PageContent />
    </OpenPageLayout>
  );
};
