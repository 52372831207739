/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { FC, useState } from 'react';
import Button from '../../components/Button2';
import { Input } from '../../components/Input';
import Loader from '../../components/loader/Loader';
import { useT } from '../../i18n/useT';
import { hasRole } from '../../lib/userRoles';
import UserClient from '../../network/UserClient';
import { User } from '../../types/User';
import { EditProfileField } from '../profile/EditProfileField';
import { minutesFromMidnightToDate, timeToMinutesFromMidnight } from '../profile/Profile';

export const EditUser: FC<{ user: User }> = ({ user }) => {
  const t = useT();
  const existingSmsAlertOffset = minutesFromMidnightToDate(
    user.userAttributes.filter((item) => item.key === 'SmsAlertOffset').map((item) => item.value)[0]
  );

  const [isPhysician, setIsPhysician] = useState<boolean>(hasRole(user.userAttributes, 'Physician'));
  const [isTestUser, setIsTestUser] = useState<boolean>(hasRole(user.userAttributes, 'TestUser'));
  const [smsOffset, setSmsOffset] = useState<string>(existingSmsAlertOffset);
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <EditProfileField
      modalTitle={t('Edit user type')}
      label={t('User type')}
      inputType=""
      defaultValue={''}
      onSave={async () => {
        setLoading(true);
        let userAttributes = [
          {
            key: 'Physician',
            value: isPhysician ? 'true' : 'false',
          },
          {
            key: 'TestUser',
            value: isTestUser ? 'true' : 'false',
          },
          {
            key: 'SmsAlertOffset',
            value: String(timeToMinutesFromMidnight(smsOffset) ?? -1),
          },
        ];

        const result = await UserClient.setUserAttributes(user.id, userAttributes);

        if (result.success) {
          window.location.reload();
        } else {
          alert(t('Something went wrong, please try again.'));
        }
        setLoading(false);
      }}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin: 16px 0;
        `}
      >
        <label>
          <input type="checkbox" checked={isPhysician} onChange={() => setIsPhysician((cs) => !cs)} value="Physician" />{' '}
          {t('Doctor')}
        </label>
        <div style={{ height: 16 }} />
        <label>
          <input type="checkbox" checked={isTestUser} onChange={() => setIsTestUser((cs) => !cs)} value="TestUser" />{' '}
          {t('Test user')}
        </label>
        <div style={{ height: 16 }} />
        <Input
          value={smsOffset}
          onChange={(event) => setSmsOffset(event.target.value)}
          label={t('SMS reminder for sleep diary registration')}
          type="time"
          placeholder={t('Format: 12:00')}
        />
        <Button onClick={() => setSmsOffset('')}>{t('Reset SMS notification')}</Button>
        {loading && <Loader />}
      </div>
    </EditProfileField>
  );
};
