/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import readShortProps, { ShortProps } from './helpers/readShortProps';
import theme from '../utils/theme';

export type BlockProps = {
  shadow?: 'block' | 'none';
  backgroundColor?: string;
  as?: 'div' | 'section' | React.ElementType;
} & JSX.IntrinsicElements['div'] &
  ShortProps;

/* Block with shadow  */
const Block = ({
  shadow = 'block',
  backgroundColor = 'white',
  className,
  as: Component = 'div',
  ...restBeforeShortProps
}: BlockProps) => {
  const [shortPropClass, rest] = readShortProps(restBeforeShortProps);

  return (
    <Component
      css={[
        css`
          background-color: ${backgroundColor};
          border-radius: 8px;
          padding: 16px 20px 20px 20px;

          @media (max-width: 599px) {
            border-radius: 4px;
            padding: 12px 16px 16px 16px;
          }
        `,
        shadow === 'block' &&
          css`
            box-shadow: ${theme.shadows.block};
          `,
        shortPropClass,
      ]}
      className={className}
      {...rest}
    />
  );
};

export default Block;
