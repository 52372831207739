/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { Button } from '../../components/Button';
import { Column } from '../../components/Flex';
import { Modal } from '../../components/Modal';
import TextInput from '../../components/TextInput';
import { useT } from '../../i18n/useT';
import { useAddNotification } from '../../lib/notifications/NotificationContext';
import Fetch from '../../network/fetch';
import settings from '../../settings';

function EditPasswordModal({ onClose }: { onClose: () => void }): JSX.Element {
  const t = useT();
  const { addInfo } = useAddNotification();
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const sendData = async () => {
    if (password !== repeatPassword) {
      setError(t('Passwords must match'));
    } else {
      setLoading(true);

      const res = await Fetch.put({ url: `${settings.apiUrl}/User/set-password`, body: { password } });

      setLoading(false);

      if (res.success) {
        addInfo({ message: t('Password is changed') });
        onClose();
      } else {
        setError(res.error ?? t('Something went wrong, please try again.'));
      }
    }
  };

  return (
    <Modal title={t('Change password')} closeModal={onClose}>
      <Column>
        <TextInput type="password" placeholder={t('New password')} value={password} onValue={setPassword} />
        <div style={{ height: 16 }} />
        <TextInput
          type="password"
          placeholder={t('Repeat new password')}
          value={repeatPassword}
          onValue={setRepeatPassword}
        />
      </Column>
      <div
        css={css`
          padding-top: 20px;
        `}
      >
        {error && (
          <p
            css={css`
              padding-bottom: 20px;
              color: #ff0000;
            `}
          >
            {error}
          </p>
        )}
        {loading && (
          <p
            css={css`
              padding-bottom: 20px;
            `}
          >
            {t('Loading...')}
          </p>
        )}
        <div>
          <Button disabled={loading} onClick={sendData}>
            {t('Change')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default EditPasswordModal;
