/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link, RouteComponentProps } from '@reach/router';
import { isSameDay, isSameYear } from 'date-fns';
import { last, uniqBy } from 'lodash';
import React, { FC } from 'react';
import Block from '../../components/Block';
import Button, { FilledButton } from '../../components/Button2';
import ErrorPageContent from '../../components/ErrorPageContent';
import { Column, Row } from '../../components/Flex';
import Loader from '../../components/loader/Loader';
import { Modal, ModalTrigger } from '../../components/Modal';
import { default as MainPageContent } from '../../components/PageContent';
import AuthedPageLayout from '../../components/pagelayout/AuthedPageLayout';
import Text from '../../components/Text';
import Textarea from '../../components/Textarea';
import { useT } from '../../i18n/useT';
import MessagesClient from '../../network/MessagesClient';
import useClientFetch from '../../network/useClientFetch';
import { MailMessage, MailMessageAttachment, Thread } from '../../types/Messages';
import formatDate from '../../utils/formatDate';
import { fromServerDate } from '../../utils/serverDateConverter';
import { buttonReset, lineClamp } from '../../utils/styles';
import theme from '../../utils/theme';
import { useIsSmallScreen } from '../../utils/useMediaQuery';
import { createAttachmentUrl } from './createAttachmentUrl';

const Message = ({ message }: { message: MailMessage }) => {
  const t = useT();
  const sentByLoggedInUser = message.senderMailMessageAddress.isSessionUserMailMessageAddress;
  const attachments = message.mailMessageAttachments ?? [];

  const published = fromServerDate(message.publishTime);
  let format = '';
  if (!isSameYear(new Date(), published)) {
    format = "d. MMM yyyy, 'kl.'\u00a0HH.mm";
  } else if (!isSameDay(new Date(), published)) {
    format = "d. MMM 'kl.'\u00a0HH.mm";
  } else {
    format = "'kl.'\u00a0HH.mm";
  }
  const formattedDate = formatDate(published, format);

  return (
    <div
      css={css`
        &:after {
          content: '';
          clear: both;
          display: table;
        }
      `}
    >
      <div
        css={css`
          float: ${sentByLoggedInUser ? 'right' : 'left'};
          width: auto;
          min-width: 200px;
          max-width: 500px;
          margin-bottom: 16px;
        `}
      >
        <Block
          css={css`
            background-color: ${sentByLoggedInUser ? theme.colors.blue : 'white'};
          `}
        >
          <Text color={sentByLoggedInUser ? 'white' : theme.colors.gray}>
            {message.senderMailMessageAddress.description}
          </Text>

          <Column
            css={css`
              margin-top: 8px;
            `}
          >
            <Text color={sentByLoggedInUser ? 'white' : theme.colors.text} size="large">
              {message.contents}
            </Text>
            {attachments.length > 0 && (
              <>
                <div style={{ height: 20 }} />
                <Text strong size="small" color={sentByLoggedInUser ? 'white' : theme.colors.text}>
                  {t('Attachments')}
                </Text>
                <div style={{ height: 4 }} />
                {attachments.map((messageAttachment) => (
                  <Attachment
                    key={messageAttachment.resourceReference.ref_Id}
                    attachment={messageAttachment}
                    sentByLoggedInUser={sentByLoggedInUser}
                  />
                ))}
              </>
            )}
          </Column>
        </Block>

        <Row
          horizontal={sentByLoggedInUser ? 'flex-end' : 'flex-start'}
          css={css`
            margin-top: 2px;
            margin-left: ${sentByLoggedInUser ? 0 : 8}px;
            margin-right: ${sentByLoggedInUser ? 8 : 0}px;
          `}
        >
          <Text size="small" dimmed>
            {formattedDate}
          </Text>
        </Row>
      </div>
    </div>
  );
};

const Attachment = ({
  attachment,
  sentByLoggedInUser,
}: {
  attachment: MailMessageAttachment;
  sentByLoggedInUser?: boolean;
}) => {
  return (
    <div>
      <Link
        to={createAttachmentUrl(attachment)}
        css={css`
          color: ${sentByLoggedInUser ? 'white' : theme.colors.gray};
          font-weight: 400;
          font-size: 16px;
        `}
      >
        {attachment.description}
      </Link>
    </div>
  );
};

const MessageEditor = ({
  title,
  onSubmit,
}: {
  title?: string;
  onSubmit: (title: string, contents: string) => Promise<boolean>;
}) => {
  const t = useT();
  const [loading, setLoading] = React.useState(false);

  const [content, setContent] = React.useState('');

  const submit = async (event: React.MouseEvent | React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const success = await onSubmit(title ?? '', content);

      if (success) {
        setContent('');
      } else {
        alert(t('Something went wrong'));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Block
      marginTop="auto"
      marginBottom={20}
      css={css`
        padding: 20px;
        @media (max-width: 599px) {
          padding: 16px;
        }
      `}
    >
      <form onSubmit={submit}>
        <Column grow>
          <Textarea
            value={content}
            onValue={(value) => setContent(value)}
            placeholder={t('Write a message...')}
            disabled={loading}
            rows={3}
            css={css`
              flex: 1;
            `}
          />
        </Column>

        <Row
          horizontal="flex-end"
          css={css`
            margin-top: 16px;
          `}
        >
          <FilledButton type="submit" onClick={submit} disabled={loading}>
            {t('Send message')}
          </FilledButton>
        </Row>
      </form>
    </Block>
  );
};

const ParticipantsModal = ({ thread, closeModal }: { thread: Thread; closeModal: () => void }) => {
  const t = useT();
  const patient = thread.threadMembers.find((member) => member.threadRelation === 'Patient');
  const otherParticipants = uniqBy(thread.threadMembers, (member) => member.mailMessageAddress_Id)
    .filter((member) => member.mailMessageAddress_Id !== patient?.mailMessageAddress_Id)
    .filter((member) => Boolean(member.mailMessageAddress));

  return (
    <Modal title={t('Participants')} closeModal={closeModal}>
      <Column
        css={css`
          margin-top: 20px;
        `}
      >
        {patient && (
          <Column
            css={css`
              margin-bottom: 8px;
            `}
          >
            <Text dimmed marginBottom={4}>
              {t('Patient')}
            </Text>
            <Text>{patient.mailMessageAddress?.description}</Text>
            <Text>{patient.mailMessageAddress?.title}</Text>
          </Column>
        )}

        {otherParticipants.length > 0 && (
          <Text dimmed marginBottom={4}>
            {t('Participants')}
          </Text>
        )}
        {otherParticipants.map((member) => {
          return (
            <Column
              key={member.mailMessageAddress_Id}
              css={css`
                margin-bottom: 8px;
              `}
            >
              <Text>{member.mailMessageAddress?.description}</Text>
              <Text>{member.mailMessageAddress?.title}</Text>
            </Column>
          );
        })}
      </Column>
    </Modal>
  );
};

const AttachmentsModal = ({
  attachments,
  closeModal,
}: {
  attachments: MailMessageAttachment[];
  closeModal: () => void;
}) => {
  return (
    <Modal title="Attachment" closeModal={closeModal}>
      {attachments.map((attachment) => {
        return (
          <Column key={attachment.resourceReference.ref_Id}>
            <Attachment key={attachment.resourceReference.ref_Id} attachment={attachment} />
          </Column>
        );
      })}
    </Modal>
  );
};

const PageContent = ({ thread, initialMessages }: { thread: Thread; initialMessages: MailMessage[] }) => {
  const t = useT();
  const [messages, setMessages] = React.useState(initialMessages);

  const isSmallScreen = useIsSmallScreen();
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  let attachments: MailMessageAttachment[] = [];
  messages.forEach((message) => {
    (message.mailMessageAttachments ?? []).forEach((attachment) => {
      if (attachment) {
        attachments.push(attachment);
      }
    });
  });

  const messagesLength = messages.length;

  // DESKTOP: Scroll to bottom of thread
  React.useLayoutEffect(() => {
    if (containerRef.current && messagesLength) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [containerRef, messagesLength, isSmallScreen]);

  // MOBILE: Scroll to bottom of thread
  React.useEffect(() => {
    if (messagesLength) {
      window.scrollTo({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }, [isSmallScreen, messagesLength]);

  const lastMessage = last(messages)!;

  const onSubmit = async (title: string, contents: string) => {
    const newMessage = {
      title,
      contents,
    };

    const result = await MessagesClient.createMessageInThread(lastMessage.mailMessageThreadId, newMessage);

    if (result.success) {
      setMessages((messages) => [...messages, ...result.data!.mailMessages!]);
      return true;
    } else {
      return false;
    }
  };

  return (
    <Column grow>
      <Row
        height={60}
        vertical="center"
        css={css`
          background-color: white;
        `}
      >
        <MainPageContent>
          <Row horizontal="space-between">
            <Row vertical="center">
              <Button
                outline
                onClick={() => {
                  window.history.back();
                }}
                marginRight={16}
              >
                &lt;
              </Button>

              <Column>
                <Text size="large" css={lineClamp(1)}>
                  {thread.initialMessageTitle}
                </Text>
                <ModalTrigger
                  button={({ openModal }) => (
                    <button css={[buttonReset]} onClick={openModal}>
                      <Text
                        dimmed
                        css={css`
                          text-decoration: underline;
                        `}
                      >
                        {t('{{count}} participants in conversation', {
                          count: uniqBy(thread.threadMembers, (member) => member.mailMessageAddress_Id).length,
                        })}
                      </Text>
                    </button>
                  )}
                  modal={({ closeModal }) => <ParticipantsModal thread={thread} closeModal={closeModal} />}
                />
              </Column>
            </Row>
            {attachments.length > 0 && (
              <ModalTrigger
                button={({ openModal }) => (
                  <button css={[buttonReset]} onClick={openModal}>
                    <Text
                      dimmed
                      css={css`
                        text-decoration: underline;
                      `}
                    >
                      {t('{{count}} attachments', { count: attachments.length })}
                    </Text>
                  </button>
                )}
                modal={({ closeModal }) => <AttachmentsModal attachments={attachments} closeModal={closeModal} />}
              />
            )}
          </Row>
        </MainPageContent>
      </Row>

      <Column
        ref={containerRef}
        vertical="space-between"
        css={[
          css`
            padding-top: 20px;
            padding-bottom: 20px;
          `,
          isSmallScreen
            ? css`
                min-height: calc(100vh - 120px);
                padding-top: 20px;
                padding-bottom: 20px;
              `
            : css`
                height: calc(100vh - 120px);
                overflow-y: auto;
                padding-top: 40px;
                padding-bottom: 20px;
              `,
        ]}
      >
        <MainPageContent grow>
          <div
            css={css`
              margin-bottom: 20px;
            `}
          >
            {messages.map((message) => (
              <Message key={message.id} message={message} />
            ))}
          </div>

          <MessageEditor title={thread.initialMessageTitle} onSubmit={onSubmit} />
        </MainPageContent>
      </Column>
    </Column>
  );
};

const PageLoader = ({ threadId }: { threadId?: string }) => {
  const {
    data: messagesData,
    loading: messagesLoading,
    error: messagesError,
    errorMessage: messagesErrorMessage,
  } = useClientFetch(MessagesClient.fetchMessages, {
    args: [threadId],
  });

  const {
    data: threadData,
    loading: threadLoading,
    error: threadError,
    errorMessage: threadErrorMessage,
  } = useClientFetch(MessagesClient.fetchThread, {
    args: [threadId],
  });

  if (messagesLoading || threadLoading) {
    return <Loader />;
  } else if (messagesError || threadError) {
    return <ErrorPageContent reason={messagesErrorMessage || threadErrorMessage} />;
  } else if (!messagesData?.mailMessages || !threadData) {
    return (
      <Row
        css={css`
          margin: 20px;
        `}
      >
        Missing data
      </Row>
    );
  }

  return <PageContent thread={threadData} initialMessages={messagesData.mailMessages} />;
};

export const ThreadPage: FC<RouteComponentProps<{ threadId?: string }>> = ({ threadId }) => {
  return (
    <AuthedPageLayout ignorePageContent showFooter={false}>
      <PageLoader threadId={threadId} />
    </AuthedPageLayout>
  );
};
