/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link, RouteComponentProps, useNavigate } from '@reach/router';
import { lighten } from 'polished';
import React, { FC } from 'react';
import { useQuery } from 'react-query';
import Button from '../../components/Button2';
import { Column, Row } from '../../components/Flex';
import Loader from '../../components/loader/Loader';
import { ModalTrigger } from '../../components/Modal';
import AuthedPageLayout from '../../components/pagelayout/AuthedPageLayout';
import Text from '../../components/Text';
import { useT } from '../../i18n/useT';
import useSelectedPatientId from '../../lib/useSelectedPatientId';
import MedicationClient from '../../network/MedicationClient';
import { Cpl_CourseOfTherapy } from '../../types/Cpl_FEST';
import { Fhir_Medication } from '../../types/Fhir_Medication';
import { Fhir_MedicationRequest } from '../../types/Fhir_MedicationRequest';
import { buttonReset } from '../../utils/styles';
import theme from '../../utils/theme';
import { getCourseOfTherapyType, translateCourseOfTherapyType } from '../medication/utils';
import { PackageLeafletModal } from './PackageLeafletModal';

function medicationListMapper(medicationRequests: Fhir_MedicationRequest[]): MedicationType[] {
  return medicationRequests.map((medicationRequest: Fhir_MedicationRequest) => {
    const therapyType = getCourseOfTherapyType(medicationRequest);
    const active = medicationRequest?.status === 'active';
    const medicationName = medicationRequest?.medication?.code.coding?.[0].display;
    // old method after ??
    const dosageInstruction =
      medicationRequest.dosageInstruction?.[0]?.text ??
      medicationRequest?.dosageInstruction?.[0]?.timing?.code?.coding?.[0]?.display;
    // old method after ??
    const reason =
      medicationRequest.reasonCode?.[0]?.translatedText ?? medicationRequest.reasonCode?.[0]?.translatedText;
    const patientInstruction = medicationRequest?.dosageInstruction?.[0]?.additionalInstruction?.[0]?.translatedText;

    const identifier = medicationRequest?.medication?.identifier?.[0]?.value;

    return {
      id: medicationRequest.id,
      therapyType,
      active,
      medicationName,
      dosageInstruction,
      reason,
      patientInstruction,
      identifier,
      fhir_medication: medicationRequest.medication,
    };
  });
}

function sortMedicationList(medications: MedicationType[]) {
  // TODO: sort
  return medications;
}

const Medications = () => {
  const t = useT();
  const navigate = useNavigate();
  const selectedPatientId = useSelectedPatientId()!;
  const { data, isLoading, error } = useQuery(['medications', selectedPatientId], () =>
    MedicationClient.fetchMedications(selectedPatientId)
  );

  if (isLoading) {
    return <Loader />;
  } else if (error) {
    return <div>{t('Something went wrong')}</div>;
  }

  const mappedMedications = sortMedicationList(medicationListMapper(data?.data ?? []));

  const fixed = mappedMedications.filter((m) => m.therapyType === 'Faste medisiner');
  const byNeed = mappedMedications.filter((m) => m.therapyType === 'Ved behov');
  const discontinued = mappedMedications.filter((m) => m.therapyType === 'Seponert');

  return (
    <div>
      <Row horizontal="space-between" vertical="center">
        <h1>{t('Medications')}</h1>
        <Button onClick={() => navigate(`/${selectedPatientId}/medisiner/new`)}>{t('Add medication')}</Button>
      </Row>

      {fixed.length > 0 && (
        <MedicationTable
          medications={fixed}
          title={translateCourseOfTherapyType(t, 'Faste medisiner')}
          selectedPatientId={selectedPatientId}
        />
      )}
      {byNeed.length > 0 && (
        <MedicationTable
          medications={byNeed}
          title={translateCourseOfTherapyType(t, 'Ved behov')}
          selectedPatientId={selectedPatientId}
        />
      )}
      {discontinued.length > 0 && (
        <MedicationTable
          medications={discontinued}
          title={translateCourseOfTherapyType(t, 'Seponert')}
          selectedPatientId={selectedPatientId}
        />
      )}

      {mappedMedications.length === 0 && <div>{t('No medications')}</div>}
    </div>
  );
};

type MedicationType = {
  id?: string;
  therapyType: Cpl_CourseOfTherapy['courseOfTherapyType'];
  active?: boolean;
  medicationName?: string;
  dosageInstruction?: string;
  reason?: string;
  patientInstruction?: string;
  identifier?: string;
  fhir_medication?: Fhir_Medication;
};

const MedicationTable: FC<{ title: string; medications: MedicationType[]; selectedPatientId?: string }> = ({
  title,
  medications,
  selectedPatientId,
}) => {
  const t = useT();

  return (
    <div>
      <h2
        css={css`
          margin-top: 32px;
          margin-bottom: 16px;
        `}
      >
        {title}
      </h2>
      {medications.map((medication) => (
        <section
          key={medication.id}
          css={css`
            background-color: #f5f7fb;
            box-shadow: ${theme.shadows.block};
            margin-bottom: 8px;
            border-radius: 8px;
            &:hover {
              background-color: ${theme.colors.grayBackground};
            }
          `}
        >
          <Row
            horizontal="space-between"
            css={css`
              @media (max-width: 599px) {
                flex-direction: column;
              }
            `}
          >
            <Column
              css={css`
                padding: 16px;
              `}
            >
              <Row vertical="center">
                <Text>{t('Name of medication')}:&nbsp;</Text>
                {medication.medicationName && (
                  <>
                    {medication.identifier ? (
                      <ModalTrigger
                        button={({ openModal }) => (
                          <button
                            onClick={openModal}
                            css={[
                              buttonReset,
                              css`
                                background-color: ${theme.colors.brightBlue};
                                border-radius: 4px;
                                padding: 2px 8px;

                                &:hover {
                                  background-color: ${theme.colors.blue};
                                  span {
                                    color: white;
                                  }
                                }
                              `,
                            ]}
                          >
                            <Row vertical="center">
                              <Text>{medication.medicationName}</Text>
                            </Row>
                          </button>
                        )}
                        modal={({ closeModal }) => (
                          <PackageLeafletModal
                            closeModal={closeModal}
                            medicationName={medication.medicationName}
                            itemNumber={medication.identifier!}
                            patientId={selectedPatientId}
                          />
                        )}
                      />
                    ) : (
                      <Text>{medication.medicationName}</Text>
                    )}
                  </>
                )}
              </Row>
              {medication.reason && <Text dimmed>{medication.reason}</Text>}
              {(medication.dosageInstruction || medication.patientInstruction) && (
                <Column>
                  <Text>{t('Medication instructions')}:&nbsp;</Text>
                  {medication.dosageInstruction && <Text>{medication.dosageInstruction}</Text>}
                  {medication.patientInstruction && <Text>{medication.patientInstruction}</Text>}
                </Column>
              )}
            </Column>

            <Link
              to={`/${selectedPatientId}/medisiner/${medication.id}`}
              css={css`
                background-color: ${lighten(0.05, theme.colors.blue)};
                color: #ffffff;
                padding: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                text-decoration: none;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;

                @media (max-width: 599px) {
                  border-top-right-radius: 0px;
                  border-bottom-right-radius: 8px;
                  border-bottom-left-radius: 8px;
                }

                &:hover {
                  background-color: ${lighten(0.4, theme.colors.lightBlue)};
                  color: #000000;
                }
              `}
            >
              {t('To medication')}
            </Link>
          </Row>
        </section>
      ))}
    </div>
  );
};

export const MedicationsPage: FC<RouteComponentProps> = () => {
  const t = useT();

  return (
    <AuthedPageLayout documentTitle={t('Capable - Medications')}>
      <Medications />
    </AuthedPageLayout>
  );
};
