/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { PureComponent } from 'react';

class Table extends PureComponent {
  render() {
    return (
      <table
        css={css`
          background-color: #ffffff;
          width: 100%;
          box-shadow: 0 6px 12px rgba(0, 25, 94, 0.1);
          border-radius: 8px;
          tr {
            td {
              padding: 16px;
            }
          }

          thead {
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;

            tr:first-of-type {
              background-color: rgba(53, 90, 183, 0.2);
              td {
                &:first-of-type {
                  border-top-left-radius: 8px;
                }
                &:last-of-type {
                  border-top-right-radius: 8px;
                }
              }
            }

            tr:not(:first-of-type) {
              background-color: rgba(53, 90, 183, 0.1);

              td {
                padding: 8px 16px;
              }
            }
          }

          tbody {
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;

            tr {
              &:last-of-type {
                td {
                  &:first-of-type {
                    border-bottom-left-radius: 8px;
                  }
                  &:last-of-type {
                    border-bottom-right-radius: 8px;
                  }
                }
              }
              &:hover {
                background-color: rgba(53, 90, 183, 0.05);
              }
            }
          }
        `}
        {...this.props}
      >
        {this.props.children}
      </table>
    );
  }
}

export default Table;
