/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { setLanguage } from '../i18n/i18n';
import { Language } from '../i18n/languageStorage';
import { useT } from '../i18n/useT';
import { useSetUserAttributes } from '../network/UserClient';
import settings from '../settings';
import theme from '../utils/theme';
import { Column, Row } from './Flex';
import PageContent from './PageContent';
import Text from './Text';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const setUserAttributes = useSetUserAttributes();
  const t = useT();

  const onLanguageChange = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const language = event.target.value as Language;
      setLanguage(language);
      setUserAttributes
        .mutateAsync([
          {
            key: 'userLanguage',
            value: language,
          },
        ])
        .then(() => {
          window.location.reload();
        });
    },
    [setUserAttributes]
  );

  return (
    <Row>
      <Text marginRight={8} color="white">
        {t('Language')}:
      </Text>
      <select value={i18n.language} onChange={onLanguageChange}>
        <option value="en">English</option>
        <option value="no">Norsk</option>
        <option value="fr">Français</option>
      </select>
    </Row>
  );
};

const Footer = () => {
  const t = useT();

  return (
    <footer
      css={css`
        background-color: ${theme.colors.lightBlue};
        position: relative;
      `}
    >
      <PageContent>
        <Row vertical="center" horizontal="space-between" height={80}>
          <Text color="white">Capable.healthcare</Text>

          <Column horizontal="flex-end">
            <LanguageSelector />

            {settings.environment === 'test' && (
              <Link to="/tilbakemelding" style={{ marginTop: 8 }}>
                <Text invert>{t('Feedback')}</Text>
              </Link>
            )}
          </Column>
        </Row>
      </PageContent>
    </footer>
  );
};

export default Footer;
