export const TTL = {
  TEN_MINUTES: 600,
  THIRTY_SECONDS: 30,
};

export const createSessionCache = <T>(storageKey: string, ttlSeconds: number) => {
  const store = (key: string, value: T) => {
    try {
      window.sessionStorage.setItem(
        `${storageKey}:${key}`,
        JSON.stringify({
          expiresAt: Date.now() + ttlSeconds * 1000,
          value,
        })
      );
    } catch (error) {
      console.warn(error);
    }
  };

  const get = (key: string) => {
    try {
      const item = window.sessionStorage.getItem(`${storageKey}:${key}`);
      if (item) {
        const parsedItem = JSON.parse(item);
        if (parsedItem && parsedItem.expiresAt && Date.now() < parsedItem.expiresAt) {
          return parsedItem.value as T;
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const clear = (key: string) => {
    try {
      window.sessionStorage.removeItem(`${storageKey}:${key}`);
    } catch (error) {
      console.warn(error);
    }
  };

  return {
    store,
    get,
    clear,
  };
};
