import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { GH_Legemiddeldose } from '../../../types/gh-types';
import { sortStyrke } from './medutility';

const infoSymbol = () => {
  const myStyle = {
    fontSize: '1rem',
    verticalAlign: 'bottom',
    lineHeight: '1.25rem',
  };
  return (
    <span style={myStyle} className="material-symbols-outlined h-5 w-5 text-sm ">
      info
    </span>
  );
};

type AutofillProps = {
  onSelection: (drugName: string, drugProducer: string, searchString: string) => void;
  prefill: string;
};
type DrugPres = {
  drugName: string;
  drugForms: string;
  drugStyrke: string;
};

type DrugProd = {
  drugName: string;
  drugProducer: string;
  drugATC: string;
};

const AutofillDrugDose = ({ onSelection, prefill }: AutofillProps) => {
  const [searchText, setSearchText] = useState<string>(prefill);
  const [medList, setMedList] = useState<GH_Legemiddeldose[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [medPresList, setMedPresList] = useState<DrugPres[]>([]);
  const [, setMednavnListe] = useState<string[]>([]);
  const [active, setActive] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedMerkevarenavn, setSelectedMerkevarenavn] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const medlistref = useRef<HTMLDivElement>(null);
  const [infoPressed, setInfoPressed] = useState<boolean>(false);

  const merkvarenavn_Get = (meds: GH_Legemiddeldose[]): string[] => {
    return meds ? [...new Set(meds.map((x) => x.merkevarenavn))] : [];
  };

  const merkevarenavn_Get_Ext = (meds: GH_Legemiddeldose[]): DrugProd[] => {
    // const drugs: DrugProd[] = [...new Set<DrugProd>(meds.map(x => { return {drugName:x.merkevarenavn, drugProducer:x.produsent}}))]; Will not work, objects are checked by reference
    const allVariants: DrugProd[] = meds.map((x) => {
      return {
        drugName: x.merkevarenavn,
        drugProducer: x.produsent,
        drugATC: x.atc_DN,
      };
    });
    const uniqueDrugs: DrugProd[] = [...new Set(allVariants.map((i) => JSON.stringify(i)))].map((i) => JSON.parse(i));
    return uniqueDrugs;
  };

  const legemiddelforms_Get = (meds: GH_Legemiddeldose[], legemiddelnavn: string): string => {
    return meds
      ? [
          ...new Set(
            meds.filter((x) => x.merkevarenavn === legemiddelnavn).map((x) => x.legemiddelformKort_Beskrivelse)
          ),
        ].join(', ')
      : '';
  };

  const legemiddelforms_Get_Ext = (meds: GH_Legemiddeldose[], legemiddelnavn: string, produsent: string): string => {
    return meds
      ? [
          ...new Set(
            meds
              .filter((x) => x.merkevarenavn === legemiddelnavn && x.produsent === produsent)
              .map((x) => x.legemiddelformKort_Beskrivelse)
          ),
        ].join('/ ')
      : '';
  };

  const legemiddelstyrke_Get = (meds: GH_Legemiddeldose[], legemiddelnavn: string): string => {
    if (!meds) return '';
    const sortedstyrke: GH_Legemiddeldose[] = meds
      ? meds.filter((x) => x.merkevarenavn === legemiddelnavn).sort(sortStyrke)
      : [];
    return [...new Set(sortedstyrke.map((x) => x.styrke + x.styrkeEnhet))].join(', ');
  };

  const legemiddelstyrke_Get_Ext = (meds: GH_Legemiddeldose[], legemiddelnavn: string, produsent: string): string => {
    if (!meds) return '';
    const sortedstyrke: GH_Legemiddeldose[] = meds
      ? meds.filter((x) => x.merkevarenavn === legemiddelnavn && x.produsent === produsent).sort(sortStyrke)
      : [];
    return [...new Set(sortedstyrke.map((x) => x.styrke + x.styrkeEnhet))].join(', ');
  };

  // alternatively:
  const convertToPresentation = (meds: GH_Legemiddeldose[]): DrugPres[] => {
    if (!meds) return [];
    const drugs: string[] = [...new Set(meds.map((x) => x.merkevarenavn))];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const ret = drugs.forEach((item) => meds.filter((x) => x.merkevarenavn === item));
    const drugsarr: DrugPres[] = drugs.map((x) => {
      return { drugName: x, drugForms: 'form', drugStyrke: 'styrke' };
    });
    return drugsarr;
  };

  const makeSearchTextSafe = (txt: string): string => {
    // there is probably a finished method
    return txt.replace('/', '');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSearchChange = (e: any) => {
    setSearchText(makeSearchTextSafe(e.target.value)); // Strip of unfortunate characters, like "/"
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  const onKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      // enter key
      if (medList.length > 0) onSelection(merkvarenavn_Get(medList)[active], '', searchText);
      setMedList([]);
    } else if (e.keyCode === 38) {
      // up arrow
      return active === 0 ? null : setAndScrollActive(active - 1);
    } else if (e.keyCode === 40) {
      // down arrow
      return active === merkvarenavn_Get(medList).length - 1 ? null : setAndScrollActive(active + 1);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onKeyDown_Ext = (e: any) => {
    if (e.keyCode === 13) {
      // enter key
      if (medList.length === 0) return;
      const { drugName, drugProducer } = merkevarenavn_Get_Ext(medList)[active];
      onSelection(drugName, drugProducer, searchText);
      setMedList([]);
    } else if (e.keyCode === 38) {
      // up arrow
      return active === 0 ? null : setAndScrollActive(active - 1);
    } else if (e.keyCode === 40) {
      // down arrow
      return active === merkevarenavn_Get_Ext(medList).length - 1 ? null : setAndScrollActive(active + 1);
    }
  };

  const handleClick = (varenavn: string, produsent: string, index: number) => {
    setActive(index);
    onSelection(varenavn, produsent, searchText);
    setMedList([]);
    if (inputRef.current !== null) inputRef.current.focus();
  };

  const setAndScrollActive = (nextActive: number) => {
    let thisChild = medlistref.current?.firstElementChild;
    for (let i = 0; i <= nextActive; i++) {
      thisChild = thisChild?.nextElementSibling ?? null;
    }
    //thisChild?.scrollIntoView(false);
    thisChild?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    setActive(nextActive);
  };

  // fetch data
  useEffect(() => {
    if (searchText && searchText.length >= 3)
      fetch('https://capablemedapi.azurewebsites.net/api/LegemiddelDose/FetchByName/' + searchText)
        //fetch('https://localhost:44322/api/LegemiddelDose/FetchByName/'+ searchText)
        //fetch('api/LegemiddelDose/FetchByName/'+ searchText)
        .then((res) => res.json())
        .then((data: GH_Legemiddeldose[]) => {
          setMedList(
            data.sort((a: GH_Legemiddeldose, b: GH_Legemiddeldose) => a.navnFormStyrke.localeCompare(b.navnFormStyrke))
          );
          setMednavnListe(merkvarenavn_Get(data)); // brukes ikke
          setMedPresList(convertToPresentation(data)); // brukes ikke
          if (inputRef.current !== null) inputRef.current.focus();
          setActive(0);
        });
    else setMedList([]);
  }, [searchText]);

  useEffect(() => {
    if (inputRef.current !== null) inputRef.current.focus();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderAutocomplete = (thisMedList: GH_Legemiddeldose[]) => {
    const drugs: string[] = [...new Set(thisMedList.map((x) => x.merkevarenavn))];
    return (
      <div
        ref={medlistref}
        className="min-w-fill-available absolute top-10 max-h-96 min-w-96 overflow-auto bg-gray-100 drop-shadow-md"
      >
        {drugs.map((x, index) => {
          const className = index === active ? 'hover:bg-fuchsia-600/30 font-bold' : 'hover:bg-fuchsia-600/10';
          return (
            <div key={x} className={className}>
              <div className="-mb-1 pt-1 text-base" onClick={() => handleClick(x, '', index)}>
                {x}
              </div>
              <div className="text-xs text-gray-400" onClick={() => handleClick(x, '', index)}>
                {legemiddelforms_Get(thisMedList, x)}
              </div>
              <div className="text-xs text-gray-400 " onClick={() => handleClick(x, '', index)}>
                {legemiddelstyrke_Get(thisMedList, x)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  // Improved(?) variant that discern between producers:
  const renderAutocomplete_Ext = (thisMedList: GH_Legemiddeldose[], moreInfo: boolean) => {
    const drugs: DrugProd[] = merkevarenavn_Get_Ext(thisMedList);
    return (
      <div
        ref={medlistref}
        className="min-w-fill-available absolute top-10 max-h-96 min-w-96 overflow-auto bg-gray-100 drop-shadow-md"
      >
        {drugs.map((x, index) => {
          const className =
            index === active
              ? 'hover:bg-fuchsia-600/30 font-bold border-b border-gray-300'
              : 'hover:bg-fuchsia-600/10 border-b border-gray-300';
          return (
            <div key={index} className={className}>
              <div>
                <span className="-mb-1 pt-1 text-base" onClick={() => handleClick(x.drugName, x.drugProducer, index)}>
                  {x.drugName}
                </span>
                {moreInfo && (
                  <span
                    className="pl-3 text-xs text-gray-400"
                    onClick={() => handleClick(x.drugName, x.drugProducer, index)}
                  >
                    {x.drugATC}
                  </span>
                )}
                <span
                  className="pl-3 text-xs text-gray-400"
                  onClick={() => handleClick(x.drugName, x.drugProducer, index)}
                >
                  {x.drugProducer?.toUpperCase()}
                </span>
              </div>
              <div className="text-xs text-gray-400" onClick={() => handleClick(x.drugName, x.drugProducer, index)}>
                {legemiddelforms_Get_Ext(thisMedList, x.drugName, x.drugProducer)}
              </div>
              <div className="text-xs text-gray-400 " onClick={() => handleClick(x.drugName, x.drugProducer, index)}>
                {legemiddelstyrke_Get_Ext(thisMedList, x.drugName, x.drugProducer)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className="relative">
      <div className="flex flex-row">
        <input
          className="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          type="text"
          value={searchText}
          onChange={handleSearchChange}
          onKeyDown={onKeyDown_Ext}
          id="legemiddelsøk"
          ref={inputRef}
        />{' '}
        <button
          className={'-py-1.5 mx-2 px-1 ' + (infoPressed ? 'text-blue-800' : 'text-blue-300')}
          onClick={() => setInfoPressed(!infoPressed)}
        >
          {infoSymbol()}
        </button>
      </div>

      {/*             <div className="flex flex-row rounded-md border-0 pr-2 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 ">
                <input className='block w-full py-1.5 pl-2 text-gray-900 bg-transparent placeholder:text-gray-400 sm:text-sm sm:leading-6'
                type="text" value={searchText} onChange={handleSearchChange} onKeyDown={onKeyDown_Ext} id="legemiddelsøk" ref={inputRef}/>{' '}
                <button className={"mx-2 px-1 -py-1.5 " + ((infoPressed)? "text-blue-800": "text-blue-300")}
                    onClick={() => setInfoPressed(!infoPressed)}>{infoSymbol()}</button>
            </div>  */}
      {renderAutocomplete_Ext(medList, infoPressed)}
    </div>
  );
};

export default AutofillDrugDose;
