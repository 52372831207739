import settings from '../settings';
import { Fhir_AllergyIntolerance } from '../types/Fhir_AllergyIntolerance';
import { Fhir_Condition } from '../types/Fhir_Condition';
import { Fhir_Coding } from '../types/Fhir_Element';
import { RecursivePartial } from '../types/utils';
import { Cpl_DictionaryContents } from './../types/Cpl_DictionaryContents';
import Fetch from './fetch';

function fetchAvailableAllergyIntolerances() {
  return Fetch.get<Cpl_DictionaryContents>({
    url: `${settings.apiUrl}/AllergyIntolerance/AvailableAllergyIntolerances`,
  });
}

function fetchAvailableVolvenAllergies() {
  return Fetch.get<Cpl_DictionaryContents>({
    url: `${settings.apiUrl}/AllergyIntolerance/VolvenAllergies`,
  });
}

export type Cpl_DictEntry = Pick<Fhir_Coding, 'system' | 'code' | 'display'>;

type CreateAllergyIntoleranceParams = {
  patientId: string;
  type: Cpl_DictEntry;
  isIntolerance: boolean; 
};
function _buildCreateAllergyIntoleranceBody(
  params: CreateAllergyIntoleranceParams
): RecursivePartial<Fhir_AllergyIntolerance> {
  return {
    patient: {
      reference: `Patient/${params.patientId}`,
    },
    clinicalStatus: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/condition-clinical',
          code: 'active',
        },
      ],
    },
    code: {
      coding: [params.type],
      text: params.type.display,
    },
    type: (params.isIntolerance)? 'intolerance' : 'allergy',
  };
}

type CreateMedicalIntoleranceParams = {
  patientId: string;
  type: Cpl_DictEntry;
  decisionId: string;
  onsetDate: string;
  nameFormStrength: string;
  activeIngredient: string;
  company: string;
  severity: string; // mild|moderate|severe
};
function _buildCreateMedicalIntoleranceIntoleranceBody(
  params: CreateMedicalIntoleranceParams
): RecursivePartial<Fhir_AllergyIntolerance> {
  return {
    patient: {
      reference: `Patient/${params.patientId}`,
    },
    clinicalStatus: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/condition-clinical',
          code: 'active',
        },
      ],
    },
    code: {
      coding: [params.type],
      text: params.type.display,
    },
    type: 'intolerance',
    onSetDateTime: params.onsetDate,
    reaction: [{
      substance: {
        coding: [{
          system: 'http://ehelse/Decisions',
          code: params.decisionId,
        },
        {
          system: 'http://ehelse/NavnFormStyrke',
          code: params.nameFormStrength,
        },
        {
          system: 'http://ehelse/Substances',
          code: params.activeIngredient,
        },
        {
          system: 'http://ehelse/Companies',
          code: params.company,
        }
      ]
      },
      manifestationList: [],
      description: "",
      severity: params.severity,
      onset: params.onsetDate,
    }]
  };
}

async function createAllergyIntolerance(params: CreateAllergyIntoleranceParams) {
  const response = await Fetch.post<Fhir_Condition>({
    url: `${settings.apiUrl}/AllergyIntolerance`,
    body: _buildCreateAllergyIntoleranceBody(params),
  });

  return response;
}
async function createMedicalIntolerance(params: CreateMedicalIntoleranceParams) {
  const response = await Fetch.post<Fhir_Condition>({
    url: `${settings.apiUrl}/AllergyIntolerance`,
    body: _buildCreateMedicalIntoleranceIntoleranceBody(params),
  });

  return response;
}
async function deleteAllergyIntolerance(conditionId: string) {
  const response = await Fetch.delete({
    url: `${settings.apiUrl}/AllergyIntolerance/${conditionId}`,
  });

  return response;
}

const AllergyIntoleranceClient = {
  fetchAvailableAllergyIntolerances,
  fetchAvailableVolvenAllergies,
  createAllergyIntolerance,
  deleteAllergyIntolerance,
  createMedicalIntolerance,
};

export default AllergyIntoleranceClient;
