export const timeOptions = [
  { time: '00:00' },
  { time: '00:15' },
  { time: '00:30' },
  { time: '00:45' },
  { time: '01:00' },
  { time: '01:15' },
  { time: '01:30' },
  { time: '01:45' },
  { time: '02:00' },
  { time: '02:15' },
  { time: '02:30' },
  { time: '02:45' },
  { time: '03:00' },
  { time: '03:15' },
  { time: '03:30' },
  { time: '03:45' },
  { time: '04:00' },
  { time: '04:15' },
  { time: '04:30' },
  { time: '04:45' },
  { time: '05:00' },
  { time: '05:15' },
  { time: '05:30' },
  { time: '05:45' },
  { time: '06:00' },
  { time: '06:15' },
  { time: '06:30' },
  { time: '06:45' },
  { time: '07:00' },
  { time: '07:15' },
  { time: '07:30' },
  { time: '07:45' },
  { time: '08:00' },
  { time: '08:15' },
  { time: '08:30' },
  { time: '08:45' },
  { time: '09:00' },
  { time: '09:15' },
  { time: '09:30' },
  { time: '09:45' },
  { time: '10:00' },
  { time: '10:15' },
  { time: '10:30' },
  { time: '10:45' },
  { time: '11:00' },
  { time: '11:15' },
  { time: '11:30' },
  { time: '11:45' },
  { time: '12:00' },
  { time: '12:15' },
  { time: '12:30' },
  { time: '12:45' },
  { time: '13:00' },
  { time: '13:15' },
  { time: '13:30' },
  { time: '13:45' },
  { time: '14:00' },
  { time: '14:15' },
  { time: '14:30' },
  { time: '14:45' },
  { time: '15:00' },
  { time: '15:15' },
  { time: '15:30' },
  { time: '15:45' },
  { time: '17:00' },
  { time: '17:15' },
  { time: '17:30' },
  { time: '17:45' },
  { time: '18:00' },
  { time: '18:15' },
  { time: '18:30' },
  { time: '18:45' },
  { time: '19:00' },
  { time: '19:15' },
  { time: '19:30' },
  { time: '19:45' },
  { time: '20:00' },
  { time: '20:15' },
  { time: '20:30' },
  { time: '20:45' },
  { time: '21:00' },
  { time: '21:15' },
  { time: '21:30' },
  { time: '21:45' },
  { time: '22:00' },
  { time: '22:15' },
  { time: '22:30' },
  { time: '22:45' },
  { time: '23:00' },
  { time: '23:15' },
  { time: '23:30' },
  { time: '23:45' },
];

// export const nightTimeOptions = [
//   { label: 'Før 20:30', time: '20:00', offset: -240 },
//   { label: '20:30', time: '20:30', offset: -210 },
//   { label: '21:00', time: '21:00', offset: -180 },
//   { label: '21:30', time: '21:30', offset: -150 },
//   { label: '22:00', time: '22:00', offset: -120 },
//   { label: '22:30', time: '22:30', offset: -90 },
//   { label: '23:00', time: '23:00', offset: -60 },
//   { label: '23:30', time: '23:30', offset: -30 },
//   { label: '00:00', time: '00:00', offset: 0 },
//   { label: '00:30', time: '00:30', offset: 30 },
//   { label: '01:00', time: '01:00', offset: 60 },
//   { label: '01:30', time: '01:30', offset: 90 },
//   { label: '02:00', time: '02:00', offset: 120 },
//   { label: '02:30', time: '02:30', offset: 150 },
//   { label: '03:00', time: '03:00', offset: 180 },
//   { label: 'Etter 03:00', time: '03:30', offset: 210 },
// ];

export const defaultTimeOptions = [
  { label: 'Before 04:30', time: '04:00', offset: 240 },
  { label: '04:15', time: '04:15', offset: 255 },
  { label: '04:30', time: '04:30', offset: 270 },
  { label: '04:45', time: '04:45', offset: 285 },
  { label: '05:00', time: '05:00', offset: 300 },
  { label: '05:15', time: '05:15', offset: 315 },
  { label: '05:30', time: '05:30', offset: 330 },
  { label: '05:45', time: '05:45', offset: 345 },
  { label: '06:00', time: '06:00', offset: 360 },
  { label: '06:15', time: '06:15', offset: 375 },
  { label: '06:30', time: '06:30', offset: 390 },
  { label: '06:45', time: '06:45', offset: 405 },
  { label: '07:00', time: '07:00', offset: 420 },
  { label: '07:15', time: '07:15', offset: 435 },
  { label: '07:30', time: '07:30', offset: 450 },
  { label: '07:45', time: '07:45', offset: 465 },
  { label: '08:00', time: '08:00', offset: 480 },
  { label: '08:15', time: '08:15', offset: 495 },
  { label: '08:30', time: '08:30', offset: 510 },
  { label: '08:45', time: '08:45', offset: 525 },
  { label: '09:00', time: '09:00', offset: 540 },
  { label: '09:15', time: '09:15', offset: 555 },
  { label: '09:30', time: '09:30', offset: 670 },
  { label: '09:45', time: '09:45', offset: 685 },
  { label: '10:00', time: '10:00', offset: 600 },
  { label: '10:15', time: '10:15', offset: 615 },
  { label: '10:30', time: '10:30', offset: 630 },
  { label: '10:45', time: '10:45', offset: 645 },
  { label: '11:00', time: '11:00', offset: 660 },
  { label: '11:15', time: '11:15', offset: 675 },
  { label: 'After 11:00', time: '11:30', offset: 690 },
];

export const nightTimeOptions = [
  { optionGroup: 'before', label: '00:00 (yesterday)', time: '00:00', offset: -1440 },
  { optionGroup: 'before', label: '00:15 (yesterday)', time: '00:15', offset: -1425 },
  { optionGroup: 'before', label: '00:30 (yesterday)', time: '00:30', offset: -1410 },
  { optionGroup: 'before', label: '00:45 (yesterday)', time: '00:45', offset: -1395 },
  { optionGroup: 'before', label: '01:00 (yesterday)', time: '01:00', offset: -1380 },
  { optionGroup: 'before', label: '01:15 (yesterday)', time: '01:15', offset: -1365 },
  { optionGroup: 'before', label: '01:30 (yesterday)', time: '01:30', offset: -1350 },
  { optionGroup: 'before', label: '01:45 (yesterday)', time: '01:45', offset: -1335 },
  { optionGroup: 'before', label: '02:00 (yesterday)', time: '02:00', offset: -1320 },
  { optionGroup: 'before', label: '02:15 (yesterday)', time: '02:15', offset: -1305 },
  { optionGroup: 'before', label: '02:30 (yesterday)', time: '02:30', offset: -1290 },
  { optionGroup: 'before', label: '02:45 (yesterday)', time: '02:45', offset: -1275 },
  { optionGroup: 'before', label: '03:00 (yesterday)', time: '03:00', offset: -1260 },
  { optionGroup: 'before', label: '03:15 (yesterday)', time: '03:15', offset: -1245 },
  { optionGroup: 'before', label: '03:30 (yesterday)', time: '03:30', offset: -1230 },
  { optionGroup: 'before', label: '03:45 (yesterday)', time: '03:45', offset: -1215 },
  { optionGroup: 'before', label: '04:00 (yesterday)', time: '04:00', offset: -1200 },
  { optionGroup: 'before', label: '04:15 (yesterday)', time: '04:15', offset: -1185 },
  { optionGroup: 'before', label: '04:30 (yesterday)', time: '04:30', offset: -1170 },
  { optionGroup: 'before', label: '04:45 (yesterday)', time: '04:45', offset: -1155 },
  { optionGroup: 'before', label: '05:00 (yesterday)', time: '05:00', offset: -1140 },
  { optionGroup: 'before', label: '05:15 (yesterday)', time: '05:15', offset: -1125 },
  { optionGroup: 'before', label: '05:30 (yesterday)', time: '05:30', offset: -1110 },
  { optionGroup: 'before', label: '05:45 (yesterday)', time: '05:45', offset: -1095 },
  { optionGroup: 'before', label: '06:00 (yesterday)', time: '06:00', offset: -1080 },
  { optionGroup: 'before', label: '06:15 (yesterday)', time: '06:15', offset: -1065 },
  { optionGroup: 'before', label: '06:30 (yesterday)', time: '06:30', offset: -1050 },
  { optionGroup: 'before', label: '06:45 (yesterday)', time: '06:45', offset: -1035 },
  { optionGroup: 'before', label: '07:00 (yesterday)', time: '07:00', offset: -1020 },
  { optionGroup: 'before', label: '07:15 (yesterday)', time: '07:15', offset: -1005 },
  { optionGroup: 'before', label: '07:30 (yesterday)', time: '07:30', offset: -990 },
  { optionGroup: 'before', label: '07:45 (yesterday)', time: '07:45', offset: -975 },
  { optionGroup: 'before', label: '08:00 (yesterday)', time: '08:00', offset: -960 },
  { optionGroup: 'before', label: '08:15 (yesterday)', time: '08:15', offset: -945 },
  { optionGroup: 'before', label: '08:30 (yesterday)', time: '08:30', offset: -930 },
  { optionGroup: 'before', label: '08:45 (yesterday)', time: '08:45', offset: -915 },
  { optionGroup: 'before', label: '09:00 (yesterday)', time: '09:00', offset: -900 },
  { optionGroup: 'before', label: '09:15 (yesterday)', time: '09:15', offset: -885 },
  { optionGroup: 'before', label: '09:30 (yesterday)', time: '09:30', offset: -870 },
  { optionGroup: 'before', label: '09:45 (yesterday)', time: '09:45', offset: -855 },
  { optionGroup: 'before', label: '10:00 (yesterday)', time: '10:00', offset: -840 },
  { optionGroup: 'before', label: '10:15 (yesterday)', time: '10:15', offset: -825 },
  { optionGroup: 'before', label: '10:30 (yesterday)', time: '10:30', offset: -810 },
  { optionGroup: 'before', label: '10:45 (yesterday)', time: '10:45', offset: -795 },
  { optionGroup: 'before', label: '11:00 (yesterday)', time: '11:00', offset: -780 },
  { optionGroup: 'before', label: '11:15 (yesterday)', time: '11:15', offset: -765 },
  { optionGroup: 'before', label: '11:30 (yesterday)', time: '11:30', offset: -750 },
  { optionGroup: 'before', label: '11:45 (yesterday)', time: '11:45', offset: -735 },
  { optionGroup: 'before', label: '12:00 (yesterday)', time: '12:00', offset: -720 },
  { optionGroup: 'before', label: '12:15 (yesterday)', time: '12:15', offset: -705 },
  { optionGroup: 'before', label: '12:30 (yesterday)', time: '12:30', offset: -690 },
  { optionGroup: 'before', label: '12:45 (yesterday)', time: '12:45', offset: -675 },
  { optionGroup: 'before', label: '13:00 (yesterday)', time: '13:00', offset: -660 },
  { optionGroup: 'before', label: '13:15 (yesterday)', time: '13:15', offset: -645 },
  { optionGroup: 'before', label: '13:30 (yesterday)', time: '13:30', offset: -630 },
  { optionGroup: 'before', label: '13:45 (yesterday)', time: '13:45', offset: -615 },
  { optionGroup: 'before', label: '14:00 (yesterday)', time: '14:00', offset: -600 },
  { optionGroup: 'before', label: '14:15 (yesterday)', time: '14:15', offset: -585 },
  { optionGroup: 'before', label: '14:30 (yesterday)', time: '14:30', offset: -570 },
  { optionGroup: 'before', label: '14:45 (yesterday)', time: '14:45', offset: -555 },
  { optionGroup: 'before', label: '15:00 (yesterday)', time: '15:00', offset: -540 },
  { optionGroup: 'before', label: '15:15 (yesterday)', time: '15:15', offset: -525 },
  { optionGroup: 'before', label: '15:30 (yesterday)', time: '15:30', offset: -510 },
  { optionGroup: 'before', label: '15:45 (yesterday)', time: '15:45', offset: -495 },
  { optionGroup: 'before', label: '16:00 (yesterday)', time: '16:00', offset: -480 },
  { optionGroup: 'before', label: '16:15 (yesterday)', time: '16:15', offset: -465 },
  { optionGroup: 'before', label: '16:30 (yesterday)', time: '16:30', offset: -450 },
  { optionGroup: 'before', label: '16:45 (yesterday)', time: '16:45', offset: -435 },
  { optionGroup: 'before', label: '17:00 (yesterday)', time: '17:00', offset: -420 },
  { optionGroup: 'before', label: '17:15 (yesterday)', time: '17:15', offset: -405 },
  { optionGroup: 'before', label: '17:30 (yesterday)', time: '17:30', offset: -390 },
  { optionGroup: 'before', label: '17:45 (yesterday)', time: '17:45', offset: -375 },
  { optionGroup: 'before', label: '18:00 (yesterday)', time: '18:00', offset: -360 },
  { optionGroup: 'before', label: '18:15 (yesterday)', time: '18:15', offset: -345 },
  { optionGroup: 'before', label: '18:30 (yesterday)', time: '18:30', offset: -330 },
  { optionGroup: 'before', label: '18:45 (yesterday)', time: '18:45', offset: -315 },
  { optionGroup: 'before', label: '19:00 (yesterday)', time: '19:00', offset: -300 },
  { optionGroup: 'before', label: '19:15 (yesterday)', time: '19:15', offset: -285 },
  { optionGroup: 'before', label: '19:30 (yesterday)', time: '19:30', offset: -270 },
  { optionGroup: 'before', label: '19:45 (yesterday)', time: '19:45', offset: -255 },
  { optionGroup: 'before', label: '20:00 (yesterday)', time: '20:00', offset: -240 },
  { optionGroup: 'before', label: '20:15 (yesterday)', time: '20:15', offset: -225 },
  { optionGroup: 'default', label: 'Before 20:30 (yesterday)', time: '20:15', offset: -225 },
  { optionGroup: 'default', label: '20:30', time: '20:30', offset: -210 },
  { optionGroup: 'default', label: '20:45', time: '20:45', offset: -195 },
  { optionGroup: 'default', label: '21:00', time: '21:00', offset: -180 },
  { optionGroup: 'default', label: '21:15', time: '21:15', offset: -165 },
  { optionGroup: 'default', label: '21:30', time: '21:30', offset: -150 },
  { optionGroup: 'default', label: '21:45', time: '21:45', offset: -135 },
  { optionGroup: 'default', label: '22:00', time: '22:00', offset: -120 },
  { optionGroup: 'default', label: '22:15', time: '22:15', offset: -105 },
  { optionGroup: 'default', label: '22:30', time: '22:30', offset: -90 },
  { optionGroup: 'default', label: '22:45', time: '22:45', offset: -75 },
  { optionGroup: 'default', label: '23:00', time: '23:00', offset: -60 },
  { optionGroup: 'default', label: '23:15', time: '23:15', offset: -45 },
  { optionGroup: 'default', label: '23:30', time: '23:30', offset: -30 },
  { optionGroup: 'default', label: '23:45', time: '23:45', offset: -15 },
  { optionGroup: 'default', label: '00:00', time: '00:00', offset: 0 },
  { optionGroup: 'default', label: '00:15', time: '00:15', offset: 15 },
  { optionGroup: 'default', label: '00:30', time: '00:30', offset: 30 },
  { optionGroup: 'default', label: '00:45', time: '00:45', offset: 45 },
  { optionGroup: 'default', label: '01:00', time: '01:00', offset: 60 },
  { optionGroup: 'default', label: '01:15', time: '01:15', offset: 75 },
  { optionGroup: 'default', label: '01:30', time: '01:30', offset: 90 },
  { optionGroup: 'default', label: '01:45', time: '01:45', offset: 105 },
  { optionGroup: 'default', label: '02:00', time: '02:00', offset: 120 },
  { optionGroup: 'default', label: '02:15', time: '02:15', offset: 135 },
  { optionGroup: 'default', label: '02:30', time: '02:30', offset: 150 },
  { optionGroup: 'default', label: '02:45', time: '02:45', offset: 165 },
  { optionGroup: 'default', label: '03:00', time: '03:00', offset: 180 },
  { optionGroup: 'default', label: '03:15', time: '03:15', offset: 195 },
  { optionGroup: 'default', label: 'After 03:00', time: '03:15', offset: 195 },
  { optionGroup: 'after', label: '03:30', time: '03:30', offset: 210 },
  { optionGroup: 'after', label: '03:45', time: '03:45', offset: 225 },
  { optionGroup: 'after', label: '04:00', time: '04:00', offset: 240 },
  { optionGroup: 'after', label: '04:15', time: '04:15', offset: 255 },
  { optionGroup: 'after', label: '04:30', time: '04:30', offset: 270 },
  { optionGroup: 'after', label: '04:45', time: '04:45', offset: 285 },
  { optionGroup: 'after', label: '05:00', time: '05:00', offset: 300 },
  { optionGroup: 'after', label: '05:15', time: '05:15', offset: 315 },
  { optionGroup: 'after', label: '05:30', time: '05:30', offset: 330 },
  { optionGroup: 'after', label: '05:45', time: '05:45', offset: 345 },
  { optionGroup: 'after', label: '06:00', time: '06:00', offset: 360 },
  { optionGroup: 'after', label: '06:15', time: '06:15', offset: 375 },
  { optionGroup: 'after', label: '06:30', time: '06:30', offset: 390 },
  { optionGroup: 'after', label: '06:45', time: '06:45', offset: 405 },
  { optionGroup: 'after', label: '07:00', time: '07:00', offset: 420 },
  { optionGroup: 'after', label: '07:15', time: '07:15', offset: 435 },
  { optionGroup: 'after', label: '07:30', time: '07:30', offset: 450 },
  { optionGroup: 'after', label: '07:45', time: '07:45', offset: 465 },
  { optionGroup: 'after', label: '08:00', time: '08:00', offset: 480 },
  { optionGroup: 'after', label: '08:15', time: '08:15', offset: 495 },
  { optionGroup: 'after', label: '08:30', time: '08:30', offset: 510 },
  { optionGroup: 'after', label: '08:45', time: '08:45', offset: 525 },
  { optionGroup: 'after', label: '09:00', time: '09:00', offset: 540 },
  { optionGroup: 'after', label: '09:15', time: '09:15', offset: 555 },
  { optionGroup: 'after', label: '09:30', time: '09:30', offset: 570 },
  { optionGroup: 'after', label: '09:45', time: '09:45', offset: 585 },
  { optionGroup: 'after', label: '10:00', time: '10:00', offset: 600 },
  { optionGroup: 'after', label: '10:15', time: '10:15', offset: 615 },
  { optionGroup: 'after', label: '10:30', time: '10:30', offset: 630 },
  { optionGroup: 'after', label: '10:45', time: '10:45', offset: 645 },
  { optionGroup: 'after', label: '11:00', time: '11:00', offset: 660 },
  { optionGroup: 'after', label: '11:15', time: '11:15', offset: 675 },
  { optionGroup: 'after', label: '11:30', time: '11:30', offset: 690 },
  { optionGroup: 'after', label: '11:45', time: '11:45', offset: 705 },
  { optionGroup: 'after', label: '12:00', time: '12:00', offset: 720 },
  { optionGroup: 'after', label: '12:15', time: '12:15', offset: 735 },
  { optionGroup: 'after', label: '12:30', time: '12:30', offset: 750 },
  { optionGroup: 'after', label: '12:45', time: '12:45', offset: 765 },
  { optionGroup: 'after', label: '13:00', time: '13:00', offset: 780 },
  { optionGroup: 'after', label: '13:15', time: '13:15', offset: 795 },
  { optionGroup: 'after', label: '13:30', time: '13:30', offset: 810 },
  { optionGroup: 'after', label: '13:45', time: '13:45', offset: 825 },
  { optionGroup: 'after', label: '14:00', time: '14:00', offset: 840 },
  { optionGroup: 'after', label: '14:15', time: '14:15', offset: 855 },
  { optionGroup: 'after', label: '14:30', time: '14:30', offset: 870 },
  { optionGroup: 'after', label: '14:45', time: '14:45', offset: 885 },
  { optionGroup: 'after', label: '15:00', time: '15:00', offset: 900 },
  { optionGroup: 'after', label: '15:15', time: '15:15', offset: 915 },
  { optionGroup: 'after', label: '15:30', time: '15:30', offset: 930 },
  { optionGroup: 'after', label: '15:45', time: '15:45', offset: 945 },
  { optionGroup: 'after', label: '16:00', time: '16:00', offset: 960 },
  { optionGroup: 'after', label: '16:15', time: '16:15', offset: 975 },
  { optionGroup: 'after', label: '16:30', time: '16:30', offset: 990 },
  { optionGroup: 'after', label: '16:45', time: '16:45', offset: 1005 },
  { optionGroup: 'after', label: '17:00', time: '17:00', offset: 1020 },
  { optionGroup: 'after', label: '17:15', time: '17:15', offset: 1035 },
  { optionGroup: 'after', label: '17:30', time: '17:30', offset: 1050 },
  { optionGroup: 'after', label: '17:45', time: '17:45', offset: 1065 },
  { optionGroup: 'after', label: '18:00', time: '18:00', offset: 1080 },
  { optionGroup: 'after', label: '18:15', time: '18:15', offset: 1095 },
  { optionGroup: 'after', label: '18:30', time: '18:30', offset: 1110 },
  { optionGroup: 'after', label: '18:45', time: '18:45', offset: 1125 },
  { optionGroup: 'after', label: '19:00', time: '19:00', offset: 1140 },
  { optionGroup: 'after', label: '19:15', time: '19:15', offset: 1155 },
  { optionGroup: 'after', label: '19:30', time: '19:30', offset: 1170 },
  { optionGroup: 'after', label: '19:45', time: '19:45', offset: 1185 },
  { optionGroup: 'after', label: '20:00', time: '20:00', offset: 1200 },
  { optionGroup: 'after', label: '20:15', time: '20:15', offset: 1215 },
  { optionGroup: 'after', label: '20:30', time: '20:30', offset: 1230 },
  { optionGroup: 'after', label: '20:45', time: '20:45', offset: 1245 },
  { optionGroup: 'after', label: '21:00', time: '21:00', offset: 1260 },
  { optionGroup: 'after', label: '21:15', time: '21:15', offset: 1275 },
  { optionGroup: 'after', label: '21:30', time: '21:30', offset: 1290 },
  { optionGroup: 'after', label: '21:45', time: '21:45', offset: 1305 },
  { optionGroup: 'after', label: '22:00', time: '22:00', offset: 1320 },
  { optionGroup: 'after', label: '22:15', time: '22:15', offset: 1335 },
  { optionGroup: 'after', label: '22:30', time: '22:30', offset: 1350 },
  { optionGroup: 'after', label: '22:45', time: '22:45', offset: 1365 },
  { optionGroup: 'after', label: '23:00', time: '23:00', offset: 1380 },
  { optionGroup: 'after', label: '23:15', time: '23:15', offset: 1395 },
  { optionGroup: 'after', label: '23:30', time: '23:30', offset: 1410 },
  { optionGroup: 'after', label: '23:45', time: '23:45', offset: 1425 },
];

export const morningTimeOptions = [
  { optionGroup: 'before', label: '00:00 (yesterday)', time: '00:00', offset: -1440 },
  { optionGroup: 'before', label: '00:15 (yesterday)', time: '00:15', offset: -1425 },
  { optionGroup: 'before', label: '00:30 (yesterday)', time: '00:30', offset: -1410 },
  { optionGroup: 'before', label: '00:45 (yesterday)', time: '00:45', offset: -1395 },
  { optionGroup: 'before', label: '01:00 (yesterday)', time: '01:00', offset: -1380 },
  { optionGroup: 'before', label: '01:15 (yesterday)', time: '01:15', offset: -1365 },
  { optionGroup: 'before', label: '01:30 (yesterday)', time: '01:30', offset: -1350 },
  { optionGroup: 'before', label: '01:45 (yesterday)', time: '01:45', offset: -1335 },
  { optionGroup: 'before', label: '02:00 (yesterday)', time: '02:00', offset: -1320 },
  { optionGroup: 'before', label: '02:15 (yesterday)', time: '02:15', offset: -1305 },
  { optionGroup: 'before', label: '02:30 (yesterday)', time: '02:30', offset: -1290 },
  { optionGroup: 'before', label: '02:45 (yesterday)', time: '02:45', offset: -1275 },
  { optionGroup: 'before', label: '03:00 (yesterday)', time: '03:00', offset: -1260 },
  { optionGroup: 'before', label: '03:15 (yesterday)', time: '03:15', offset: -1245 },
  { optionGroup: 'before', label: '03:30 (yesterday)', time: '03:30', offset: -1230 },
  { optionGroup: 'before', label: '03:45 (yesterday)', time: '03:45', offset: -1215 },
  { optionGroup: 'before', label: '04:00 (yesterday)', time: '04:00', offset: -1200 },
  { optionGroup: 'before', label: '04:15 (yesterday)', time: '04:15', offset: -1185 },
  { optionGroup: 'before', label: '04:30 (yesterday)', time: '04:30', offset: -1170 },
  { optionGroup: 'before', label: '04:45 (yesterday)', time: '04:45', offset: -1155 },
  { optionGroup: 'before', label: '05:00 (yesterday)', time: '05:00', offset: -1140 },
  { optionGroup: 'before', label: '05:15 (yesterday)', time: '05:15', offset: -1125 },
  { optionGroup: 'before', label: '05:30 (yesterday)', time: '05:30', offset: -1110 },
  { optionGroup: 'before', label: '05:45 (yesterday)', time: '05:45', offset: -1095 },
  { optionGroup: 'before', label: '06:00 (yesterday)', time: '06:00', offset: -1080 },
  { optionGroup: 'before', label: '06:15 (yesterday)', time: '06:15', offset: -1065 },
  { optionGroup: 'before', label: '06:30 (yesterday)', time: '06:30', offset: -1050 },
  { optionGroup: 'before', label: '06:45 (yesterday)', time: '06:45', offset: -1035 },
  { optionGroup: 'before', label: '07:00 (yesterday)', time: '07:00', offset: -1020 },
  { optionGroup: 'before', label: '07:15 (yesterday)', time: '07:15', offset: -1005 },
  { optionGroup: 'before', label: '07:30 (yesterday)', time: '07:30', offset: -990 },
  { optionGroup: 'before', label: '07:45 (yesterday)', time: '07:45', offset: -975 },
  { optionGroup: 'before', label: '08:00 (yesterday)', time: '08:00', offset: -960 },
  { optionGroup: 'before', label: '08:15 (yesterday)', time: '08:15', offset: -945 },
  { optionGroup: 'before', label: '08:30 (yesterday)', time: '08:30', offset: -930 },
  { optionGroup: 'before', label: '08:45 (yesterday)', time: '08:45', offset: -915 },
  { optionGroup: 'before', label: '09:00 (yesterday)', time: '09:00', offset: -900 },
  { optionGroup: 'before', label: '09:15 (yesterday)', time: '09:15', offset: -885 },
  { optionGroup: 'before', label: '09:30 (yesterday)', time: '09:30', offset: -870 },
  { optionGroup: 'before', label: '09:45 (yesterday)', time: '09:45', offset: -855 },
  { optionGroup: 'before', label: '10:00 (yesterday)', time: '10:00', offset: -840 },
  { optionGroup: 'before', label: '10:15 (yesterday)', time: '10:15', offset: -825 },
  { optionGroup: 'before', label: '10:30 (yesterday)', time: '10:30', offset: -810 },
  { optionGroup: 'before', label: '10:45 (yesterday)', time: '10:45', offset: -795 },
  { optionGroup: 'before', label: '11:00 (yesterday)', time: '11:00', offset: -780 },
  { optionGroup: 'before', label: '11:15 (yesterday)', time: '11:15', offset: -765 },
  { optionGroup: 'before', label: '11:30 (yesterday)', time: '11:30', offset: -750 },
  { optionGroup: 'before', label: '11:45 (yesterday)', time: '11:45', offset: -735 },
  { optionGroup: 'before', label: '12:00 (yesterday)', time: '12:00', offset: -720 },
  { optionGroup: 'before', label: '12:15 (yesterday)', time: '12:15', offset: -705 },
  { optionGroup: 'before', label: '12:30 (yesterday)', time: '12:30', offset: -690 },
  { optionGroup: 'before', label: '12:45 (yesterday)', time: '12:45', offset: -675 },
  { optionGroup: 'before', label: '13:00 (yesterday)', time: '13:00', offset: -660 },
  { optionGroup: 'before', label: '13:15 (yesterday)', time: '13:15', offset: -645 },
  { optionGroup: 'before', label: '13:30 (yesterday)', time: '13:30', offset: -630 },
  { optionGroup: 'before', label: '13:45 (yesterday)', time: '13:45', offset: -615 },
  { optionGroup: 'before', label: '14:00 (yesterday)', time: '14:00', offset: -600 },
  { optionGroup: 'before', label: '14:15 (yesterday)', time: '14:15', offset: -585 },
  { optionGroup: 'before', label: '14:30 (yesterday)', time: '14:30', offset: -570 },
  { optionGroup: 'before', label: '14:45 (yesterday)', time: '14:45', offset: -555 },
  { optionGroup: 'before', label: '15:00 (yesterday)', time: '15:00', offset: -540 },
  { optionGroup: 'before', label: '15:15 (yesterday)', time: '15:15', offset: -525 },
  { optionGroup: 'before', label: '15:30 (yesterday)', time: '15:30', offset: -510 },
  { optionGroup: 'before', label: '15:45 (yesterday)', time: '15:45', offset: -495 },
  { optionGroup: 'before', label: '16:00 (yesterday)', time: '16:00', offset: -480 },
  { optionGroup: 'before', label: '16:15 (yesterday)', time: '16:15', offset: -465 },
  { optionGroup: 'before', label: '16:30 (yesterday)', time: '16:30', offset: -450 },
  { optionGroup: 'before', label: '16:45 (yesterday)', time: '16:45', offset: -435 },
  { optionGroup: 'before', label: '17:00 (yesterday)', time: '17:00', offset: -420 },
  { optionGroup: 'before', label: '17:15 (yesterday)', time: '17:15', offset: -405 },
  { optionGroup: 'before', label: '17:30 (yesterday)', time: '17:30', offset: -390 },
  { optionGroup: 'before', label: '17:45 (yesterday)', time: '17:45', offset: -375 },
  { optionGroup: 'before', label: '18:00 (yesterday)', time: '18:00', offset: -360 },
  { optionGroup: 'before', label: '18:15 (yesterday)', time: '18:15', offset: -345 },
  { optionGroup: 'before', label: '18:30 (yesterday)', time: '18:30', offset: -330 },
  { optionGroup: 'before', label: '18:45 (yesterday)', time: '18:45', offset: -315 },
  { optionGroup: 'before', label: '19:00 (yesterday)', time: '19:00', offset: -300 },
  { optionGroup: 'before', label: '19:15 (yesterday)', time: '19:15', offset: -285 },
  { optionGroup: 'before', label: '19:30 (yesterday)', time: '19:30', offset: -270 },
  { optionGroup: 'before', label: '19:45 (yesterday)', time: '19:45', offset: -255 },
  { optionGroup: 'before', label: '20:00 (yesterday)', time: '20:00', offset: -240 },
  { optionGroup: 'before', label: '20:15 (yesterday)', time: '20:15', offset: -225 },
  { optionGroup: 'before', label: '20:30 (yesterday)', time: '20:30', offset: -210 },
  { optionGroup: 'before', label: '20:45 (yesterday)', time: '20:45', offset: -195 },
  { optionGroup: 'before', label: '21:00 (yesterday)', time: '21:00', offset: -180 },
  { optionGroup: 'before', label: '21:15 (yesterday)', time: '21:15', offset: -165 },
  { optionGroup: 'before', label: '21:30 (yesterday)', time: '21:30', offset: -150 },
  { optionGroup: 'before', label: '21:45 (yesterday)', time: '21:45', offset: -135 },
  { optionGroup: 'before', label: '22:00 (yesterday)', time: '22:00', offset: -120 },
  { optionGroup: 'before', label: '22:15 (yesterday)', time: '22:15', offset: -105 },
  { optionGroup: 'before', label: '22:30 (yesterday)', time: '22:30', offset: -90 },
  { optionGroup: 'before', label: '22:45 (yesterday)', time: '22:45', offset: -75 },
  { optionGroup: 'before', label: '23:00 (yesterday)', time: '23:00', offset: -60 },
  { optionGroup: 'before', label: '23:15 (yesterday)', time: '23:15', offset: -45 },
  { optionGroup: 'before', label: '23:30 (yesterday)', time: '23:30', offset: -30 },
  { optionGroup: 'before', label: '23:45 (yesterday)', time: '23:45', offset: -15 },
  { optionGroup: 'before', label: '00:00', time: '00:00', offset: 0 },
  { optionGroup: 'before', label: '00:15', time: '00:15', offset: 15 },
  { optionGroup: 'before', label: '00:30', time: '00:30', offset: 30 },
  { optionGroup: 'before', label: '00:45', time: '00:45', offset: 45 },
  { optionGroup: 'before', label: '01:00', time: '01:00', offset: 60 },
  { optionGroup: 'before', label: '01:15', time: '01:15', offset: 75 },
  { optionGroup: 'before', label: '01:30', time: '01:30', offset: 90 },
  { optionGroup: 'before', label: '01:45', time: '01:45', offset: 105 },
  { optionGroup: 'before', label: '02:00', time: '02:00', offset: 120 },
  { optionGroup: 'before', label: '02:15', time: '02:15', offset: 135 },
  { optionGroup: 'before', label: '02:30', time: '02:30', offset: 150 },
  { optionGroup: 'before', label: '02:45', time: '02:45', offset: 165 },
  { optionGroup: 'before', label: '03:00', time: '03:00', offset: 180 },
  { optionGroup: 'before', label: '03:15', time: '03:15', offset: 195 },
  { optionGroup: 'before', label: '03:30', time: '03:30', offset: 210 },
  { optionGroup: 'before', label: '03:45', time: '03:45', offset: 225 },
  { optionGroup: 'before', label: '04:00', time: '04:00', offset: 240 },
  { optionGroup: 'before', label: '04:15', time: '04:15', offset: 255 },
  { optionGroup: 'default', label: 'Before 04:30', time: '04:15', offset: 255 },
  { optionGroup: 'default', label: '04:30', time: '04:30', offset: 270 },
  { optionGroup: 'default', label: '04:45', time: '04:45', offset: 285 },
  { optionGroup: 'default', label: '05:00', time: '05:00', offset: 300 },
  { optionGroup: 'default', label: '05:15', time: '05:15', offset: 315 },
  { optionGroup: 'default', label: '05:30', time: '05:30', offset: 330 },
  { optionGroup: 'default', label: '05:45', time: '05:45', offset: 345 },
  { optionGroup: 'default', label: '06:00', time: '06:00', offset: 360 },
  { optionGroup: 'default', label: '06:15', time: '06:15', offset: 375 },
  { optionGroup: 'default', label: '06:30', time: '06:30', offset: 390 },
  { optionGroup: 'default', label: '06:45', time: '06:45', offset: 405 },
  { optionGroup: 'default', label: '07:00', time: '07:00', offset: 420 },
  { optionGroup: 'default', label: '07:15', time: '07:15', offset: 435 },
  { optionGroup: 'default', label: '07:30', time: '07:30', offset: 450 },
  { optionGroup: 'default', label: '07:45', time: '07:45', offset: 465 },
  { optionGroup: 'default', label: '08:00', time: '08:00', offset: 480 },
  { optionGroup: 'default', label: '08:15', time: '08:15', offset: 495 },
  { optionGroup: 'default', label: '08:30', time: '08:30', offset: 510 },
  { optionGroup: 'default', label: '08:45', time: '08:45', offset: 525 },
  { optionGroup: 'default', label: '09:00', time: '09:00', offset: 540 },
  { optionGroup: 'default', label: '09:15', time: '09:15', offset: 555 },
  { optionGroup: 'default', label: '09:30', time: '09:30', offset: 570 },
  { optionGroup: 'default', label: '09:45', time: '09:45', offset: 585 },
  { optionGroup: 'default', label: '10:00', time: '10:00', offset: 600 },
  { optionGroup: 'default', label: '10:15', time: '10:15', offset: 615 },
  { optionGroup: 'default', label: '10:30', time: '10:30', offset: 630 },
  { optionGroup: 'default', label: '10:45', time: '10:45', offset: 645 },
  { optionGroup: 'default', label: '11:00', time: '11:00', offset: 660 },
  { optionGroup: 'default', label: '11:15', time: '11:15', offset: 675 },
  { optionGroup: 'default', label: 'After 11:00', time: '11:15', offset: 675 },
  { optionGroup: 'after', label: '11:30', time: '11:30', offset: 690 },
  { optionGroup: 'after', label: '11:45', time: '11:45', offset: 705 },
  { optionGroup: 'after', label: '12:00', time: '12:00', offset: 720 },
  { optionGroup: 'after', label: '12:15', time: '12:15', offset: 735 },
  { optionGroup: 'after', label: '12:30', time: '12:30', offset: 750 },
  { optionGroup: 'after', label: '12:45', time: '12:45', offset: 765 },
  { optionGroup: 'after', label: '13:00', time: '13:00', offset: 780 },
  { optionGroup: 'after', label: '13:15', time: '13:15', offset: 795 },
  { optionGroup: 'after', label: '13:30', time: '13:30', offset: 810 },
  { optionGroup: 'after', label: '13:45', time: '13:45', offset: 825 },
  { optionGroup: 'after', label: '14:00', time: '14:00', offset: 840 },
  { optionGroup: 'after', label: '14:15', time: '14:15', offset: 855 },
  { optionGroup: 'after', label: '14:30', time: '14:30', offset: 870 },
  { optionGroup: 'after', label: '14:45', time: '14:45', offset: 885 },
  { optionGroup: 'after', label: '15:00', time: '15:00', offset: 900 },
  { optionGroup: 'after', label: '15:15', time: '15:15', offset: 915 },
  { optionGroup: 'after', label: '15:30', time: '15:30', offset: 930 },
  { optionGroup: 'after', label: '15:45', time: '15:45', offset: 945 },
  { optionGroup: 'after', label: '16:00', time: '16:00', offset: 960 },
  { optionGroup: 'after', label: '16:15', time: '16:15', offset: 975 },
  { optionGroup: 'after', label: '16:30', time: '16:30', offset: 990 },
  { optionGroup: 'after', label: '16:45', time: '16:45', offset: 1005 },
  { optionGroup: 'after', label: '17:00', time: '17:00', offset: 1020 },
  { optionGroup: 'after', label: '17:15', time: '17:15', offset: 1035 },
  { optionGroup: 'after', label: '17:30', time: '17:30', offset: 1050 },
  { optionGroup: 'after', label: '17:45', time: '17:45', offset: 1065 },
  { optionGroup: 'after', label: '18:00', time: '18:00', offset: 1080 },
  { optionGroup: 'after', label: '18:15', time: '18:15', offset: 1095 },
  { optionGroup: 'after', label: '18:30', time: '18:30', offset: 1110 },
  { optionGroup: 'after', label: '18:45', time: '18:45', offset: 1125 },
  { optionGroup: 'after', label: '19:00', time: '19:00', offset: 1140 },
  { optionGroup: 'after', label: '19:15', time: '19:15', offset: 1155 },
  { optionGroup: 'after', label: '19:30', time: '19:30', offset: 1170 },
  { optionGroup: 'after', label: '19:45', time: '19:45', offset: 1185 },
  { optionGroup: 'after', label: '20:00', time: '20:00', offset: 1200 },
  { optionGroup: 'after', label: '20:15', time: '20:15', offset: 1215 },
  { optionGroup: 'after', label: '20:30', time: '20:30', offset: 1230 },
  { optionGroup: 'after', label: '20:45', time: '20:45', offset: 1245 },
  { optionGroup: 'after', label: '21:00', time: '21:00', offset: 1260 },
  { optionGroup: 'after', label: '21:15', time: '21:15', offset: 1275 },
  { optionGroup: 'after', label: '21:30', time: '21:30', offset: 1290 },
  { optionGroup: 'after', label: '21:45', time: '21:45', offset: 1305 },
  { optionGroup: 'after', label: '22:00', time: '22:00', offset: 1320 },
  { optionGroup: 'after', label: '22:15', time: '22:15', offset: 1335 },
  { optionGroup: 'after', label: '22:30', time: '22:30', offset: 1350 },
  { optionGroup: 'after', label: '22:45', time: '22:45', offset: 1365 },
  { optionGroup: 'after', label: '23:00', time: '23:00', offset: 1380 },
  { optionGroup: 'after', label: '23:15', time: '23:15', offset: 1395 },
  { optionGroup: 'after', label: '23:30', time: '23:30', offset: 1410 },
  { optionGroup: 'after', label: '23:45', time: '23:45', offset: 1425 },
];
