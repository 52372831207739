/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const rawTextStyle = {
  fontFamily: 'Helvetica, Arial, sans-serif',
  textRendering: 'optimizeLegibility',
  WebkitFontSmoothing: 'antialiased',
};

export const textStyle = css(rawTextStyle as any);

export const screenReaderOnlyStyle = css`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;

export const buttonReset = [
  css`
    background: none;
    color: inherit;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    cursor: pointer;
    text-align: left;
  `,
];

export const lineClampMixin = (lines: number) => `
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines};
  line-clamp: ${lines};
`;

export const lineClamp = (lines: number) => css`
  ${lineClampMixin(lines)}
`;
