import { TFunction } from '../../../i18n/useT';

export const required = (t: TFunction) => (text: string, fieldName: string) => {
  if (text) {
    return '';
  }
  return t('{{fieldName}} can not be empty', { fieldName });
};

export const matchOtherField =
  (t: TFunction, matchText: string, matchName: string) => (text: string, fieldName: string) => {
    if (text === matchText) {
      return '';
    }
    return t('{{fieldName}} need to match {{matchName}}', { fieldName });
  };

export const requiredInput = (t: TFunction) => (value?: unknown) => {
  if (value !== undefined) {
    return '';
  }
  return t('The field can not be empty');
};

export const requiredSelect = (t: TFunction, defaultValue: string) => (value?: string) => {
  if (value === defaultValue) {
    return t('Not a valid choice');
  }
  if (value !== undefined) {
    return '';
  }

  return t('Not a valid choice');
};

export const requiredRadio = (t: TFunction) => (value?: string) => {
  if (typeof value === 'undefined') {
    return t('Can not be empty');
  }

  return '';
};

export const number = (t: TFunction) => (value: string) => {
  if (/^[0-9]*$/.test(value)) {
    return '';
  }
  return t('Needs to be a number');
};

export const minLength = (t: TFunction, length: number) => (text?: string, fieldName?: string) =>
  text && text.length >= length
    ? ''
    : t('{{fieldName}} needs to be minimum {{length}} characters', { fieldName, length });

export const validEmail = (t: TFunction) => (value: string) => {
  if (/^\S+@\S+$/.test(value)) {
    return '';
  }
  return t('{{value}} is not a valid e-mail', value);
};

export const validEmailOrEmpty = (t: TFunction) => (value: string) => {
  if (value === '') {
    return '';
  }

  if (/^\S+@\S+$/.test(value)) {
    return '';
  }

  return t('{{value}} is not a valid e-mail', value);
};
