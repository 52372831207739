/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link } from '@reach/router';
import React, { FC, useEffect, useState } from 'react';
import { Alert } from '../../components/Alert';
import { useUserContext } from '../../lib/UserContext';

export const MissingInfoAlert: FC = () => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [missingValues, setMissingValues] = useState<string[]>([]);
  const { patient } = useUserContext().loggedInUser!;

  useEffect(() => {
    const name = patient && patient.name && patient.name[0];
    const given = name && name.given && name.given[0];
    const family = name?.family;

    if ((!given || given === '<GivenName>') && !missingValues.includes('Fornavn')) {
      setMissingValues([...missingValues, 'Fornavn']);
      setShowAlert(true);
    }
    if ((!family || family === '<FamilyName>') && !missingValues.includes('Etternavn')) {
      setMissingValues([...missingValues, 'Etternavn']);
      setShowAlert(true);
    }
  }, [patient, missingValues, setShowAlert, setMissingValues]);

  if (showAlert) {
    return (
      <Alert
        level="INFO"
        css={css`
          margin-bottom: 24px;
          position: relative;
        `}
      >
        <button
          css={css`
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            background-color: transparent;
            border: none;
            border-radius: 3px;
          `}
          onClick={() => setShowAlert(false)}
        >
          x
        </button>
        Profilen din er ikke helt ferdig enda, vi mangler
        <ul
          css={css`
            list-style-type: circle;
            margin-top: 8px;
            margin-bottom: 8px;

            li {
              margin-left: 16px;
            }
          `}
        >
          {missingValues.map((value) => (
            <li key={value}>{value}</li>
          ))}
        </ul>
        <Link to="/profil">Trykk her for å oppdatere profilen din</Link>
      </Alert>
    );
  }
  return null;
};
