import { Link, RouteComponentProps, useParams } from '@reach/router';
import React, { FC } from 'react';
import AuthedPageLayout from '../../../components/pagelayout/AuthedPageLayout';
import Loader from '../../../components/loader/Loader';
import useSelectedPatientId from '../../../lib/useSelectedPatientId';
import { useT } from '../../../i18n/useT';
import { getMedicationInfoLink } from '../../medications/medicationInfo';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Cpl_Decision } from '../../../types/Cpl_Decision';
import { useQuery } from 'react-query';
import { toastAndThrowError } from '../../../network/api';
import prescriptionClient from '../../../network/prescriptionClient';

const Prescription = ({
  medicationRequest: curDecision,
  refetchMedications,
}: {
  medicationRequest: Cpl_Decision;
  refetchMedications: () => void;
}) => {
  const t = useT();
  const selectedPatientId = useSelectedPatientId();

  const medicationName = curDecision?.components?.[0]?.componentMedication?.legemiddeldose?.navnFormStyrke ?? '';
  // const dosageInstruction = medicationRequest?.dosageInstruction?.[0]?.timing?.code?.coding?.[0]?.display;
  // const reason = medicationRequest?.reasonCode?.[0]?.coding?.[0]?.display;
  // const patientInstruction = medicationRequest?.dosageInstruction?.[0]?.patientInstruction;

  const dosage = curDecision?.components?.[0]?.componentMedication?.doseringsTekst ?? '';

  const legemiddelpakningVarenr = curDecision?.components?.[0]?.componentMedication?.legemiddelpakningId ?? '';
  const heading = legemiddelpakningVarenr ? (
    <a
      className="hover:underline text-black w-full"
      href={getMedicationInfoLink(legemiddelpakningVarenr)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="flex flex-row justify-start items-center">
        <div className="pr-2">{medicationName}</div>
        <FaExternalLinkAlt size={16} color="black" />
      </div>
    </a>
  ) : (
    <h2>{medicationName}</h2>
  );

  return (
    <>
      <Link className="py-4 hover:underline" to={selectedPatientId ? `/${selectedPatientId}/medications` : '/hjem'}>
        {t('Back to medications')}
      </Link>
      <div className="p-4 flex flex-col justify-normal rounded-lg drop-shadow-lg gap-4 bg-white">
        <div className="text-indigo-700 text-xl hover:underline">{heading}</div>
        <div className="">
          <div className="py-4 flex flex-row">
            <div className="basis-1/2">{t('Active substance')}</div>
            <div className="basis-1/2">
              {curDecision?.components?.[0]?.componentMedication?.legemiddeldose?.virkestoffNavn1Engelsk ?? '-'}
            </div>
          </div>
          <div className="py-4 flex flex-row">
            <div className="basis-1/2">{t('Company')}</div>
            <div className="basis-1/2">
              {curDecision?.components?.[0]?.componentMedication?.legemiddeldose?.produsent ?? '-'}
            </div>
          </div>
          <div className="py-4 flex flex-row ">
            <div className="basis-1/2">{t('Dosage')}</div>
            <div className="basis-1/2">{dosage ?? '-'}</div>
          </div>
          <div className="py-4 flex flex-row ">
            <div className="basis-1/2">{t('Reason for use')}</div>
            <div className="basis-1/2">
              {curDecision?.components?.[0]?.componentMedication?.bruksomrade?.display ?? '-'}
            </div>
          </div>
          <div className="py-4 flex flex-row ">
            <div className="basis-1/2">{t('Caution')}</div>
            <div className="basis-1/2">
              {curDecision?.components?.[0]?.componentMedication?.forholdsregler?.display ?? '-'}
            </div>
          </div>
          <div className="py-4 flex flex-row ">
            <div className="basis-1/2">{t('Route of administration')}</div>
            <div className="basis-1/2">
              {curDecision?.components?.[0]?.componentMedication?.administrasjonsvei?.display ?? '-'}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Content = () => {
  const t = useT();
  const { id } = useParams();

  const prescriptionQuery = useQuery({
    queryKey: ['prescription', id],
    queryFn: () => prescriptionClient.getPrescriptionWithMeta(id).catch(toastAndThrowError),
  });

  const medicationRequest = prescriptionQuery.data?.data.decisions?.[0];

  if (prescriptionQuery.isLoading) {
    return <Loader />;
  } else if (prescriptionQuery.isError || !medicationRequest) {
    return <div>{t('Something went wrong')}</div>;
  } else {
    return (
      <>
        <Prescription medicationRequest={medicationRequest} refetchMedications={prescriptionQuery.refetch} />
        {prescriptionQuery.isFetching && <Loader delayMs={0} />}
      </>
    );
  }
};

export const PrescriptionPage: FC<RouteComponentProps<{ id?: string }>> = () => {
  const t = useT();

  return (
    <AuthedPageLayout documentTitle={t('Capable - Medications')}>
      <Content />
    </AuthedPageLayout>
  );
};
