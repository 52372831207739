import { StringMap, TOptions } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import resources from './resources.json';

export type TFunction = (
  key: keyof typeof resources['en']['translation'],
  options?: TOptions<StringMap> | string
) => string;

export const useT = () => {
  const { t: realT } = useTranslation();

  const t: TFunction = React.useCallback(
    (key: keyof typeof resources['en']['translation'], options?: TOptions<StringMap> | string) => {
      return realT(key, options);
    },
    [realT]
  );

  return t;
};

export const warnMissingTranslations = () => {
  if (process.env.NODE_ENV === 'production') {
    return;
  }

  // Delay a bit so we hopefully don't miss it :)
  setTimeout(() => {
    const enKeys = Object.keys(resources.en.translation);
    const nbKeys = Object.keys(resources.no.translation);
    const frKeys = Object.keys(resources.fr.translation);

    const missingNbKeys = enKeys.filter((key) => !nbKeys.includes(key));
    const missingEnKeys = nbKeys.filter((key) => !enKeys.includes(key));
    const missingFrKeys = enKeys.filter((key) => !frKeys.includes(key));

    if (missingNbKeys.length > 0 || missingEnKeys.length > 0 || missingFrKeys.length > 0) {
      console.group('Missing translations');
      console.warn(`no:  ${JSON.stringify(missingNbKeys)}`);
      console.warn(`en: ${JSON.stringify(missingEnKeys)}`);
      console.warn(`fr: ${JSON.stringify(missingFrKeys)}`);
      console.groupEnd();
    }
  }, 1000);
};
