import { AxiosError } from 'axios';
import { uniqBy } from 'lodash';
import { useQuery } from 'react-query';
import { medicationApi } from '../../lib/api';
import {
  Cpl_Administrasjonsvei,
  Cpl_Administreringsdetaljer,
  Cpl_Bruksomrade,
  Cpl_Dosering,
  Cpl_Forholdsregel,
  Cpl_Legemiddelpakning,
} from '../../types/Cpl_FEST';
import useDebounce from '../../utils/useDebounce';

export const useSearchMedicationApi = (query: string) => {
  const debouncedQuery = useDebounce(encodeURIComponent((query ?? '').trim()), query.length === 0 ? 0 : 500);

  const nameQuery = useQuery<Cpl_Legemiddelpakning[], AxiosError>(
    ['Legemiddelpakning/FetchByName', debouncedQuery],
    () => medicationApi.get(`/Legemiddelpakning/FetchByName/${debouncedQuery}`).then((res) => res.data),
    {
      enabled: debouncedQuery.length > 0,
    }
  );

  const itemNumberQuery = useQuery<Cpl_Legemiddelpakning[], AxiosError>(
    ['Legemiddelpakning/GetByVarenr', debouncedQuery],
    () => medicationApi.get(`/Legemiddelpakning/GetByVarenr/${debouncedQuery}`).then((res) => res.data),
    {
      enabled: debouncedQuery.length > 0,
    }
  );

  const idQuery = useQuery<Cpl_Legemiddelpakning[], AxiosError>(
    ['Legemiddelpakning/GetById', debouncedQuery],
    () => medicationApi.get(`/Legemiddelpakning/GetById/${debouncedQuery}`).then((res) => res.data),
    {
      enabled: debouncedQuery.length > 0,
    }
  );

  const eanQuery = useQuery<Cpl_Legemiddelpakning[], AxiosError>(
    ['Legemiddelpakning/GetByEan', debouncedQuery],
    () => medicationApi.get(`/Legemiddelpakning/GetByEan/${debouncedQuery}`).then((res) => res.data),
    {
      enabled: debouncedQuery.length > 0,
    }
  );

  const allData = uniqBy(
    [
      ...(idQuery.data ?? []),
      ...(itemNumberQuery.data ?? []),
      ...(eanQuery.data ?? []),
      ...(nameQuery.data ?? []),
    ].filter(Boolean),
    'legemiddelpakningsId'
  );

  const isLoading = nameQuery.isLoading || itemNumberQuery.isLoading || idQuery.isLoading || eanQuery.isLoading;
  const isSuccess = nameQuery.isSuccess && itemNumberQuery.isSuccess && idQuery.isSuccess && eanQuery.isSuccess;

  return {
    data: allData,
    isLoading,
    isSuccess,
  };
};

export const useFetchDosering = (legemiddelpakningsId?: string) => {
  const dosering = useQuery<Cpl_Dosering[], AxiosError>(
    ['Dosering/FetchByPakningsId', legemiddelpakningsId],
    () => medicationApi.get(`/Dosering/FetchByPakningsId/${legemiddelpakningsId}`).then((res) => res.data),
    {
      enabled: Boolean(legemiddelpakningsId),
    }
  );

  const bruksomrader = useQuery<Cpl_Bruksomrade[], AxiosError>(
    ['Bruksomrader/FetchByPakningsId', legemiddelpakningsId],
    () => medicationApi.get(`/Bruksomrader/FetchByPakningsId/${legemiddelpakningsId}`).then((res) => res.data),
    {
      enabled: Boolean(legemiddelpakningsId),
    }
  );

  const forholdsregler = useQuery<Cpl_Forholdsregel[], AxiosError>(
    ['Forholdsregler/FetchByPakningsId', legemiddelpakningsId],
    () => medicationApi.get(`/Forholdsregler/FetchByPakningsId/${legemiddelpakningsId}`).then((res) => res.data),
    {
      enabled: Boolean(legemiddelpakningsId),
    }
  );

  const administrasjonsveier = useQuery<Cpl_Administrasjonsvei[], AxiosError>(
    ['Administrasjonsveier/FetchByPakningsId', legemiddelpakningsId],
    () => medicationApi.get(`/Administrasjonsveier/FetchByPakningsId/${legemiddelpakningsId}`).then((res) => res.data),
    {
      enabled: Boolean(legemiddelpakningsId),
    }
  );

  const administrasjonsdetaljer = useQuery<Cpl_Administreringsdetaljer[], AxiosError>(
    ['Administrasjonsdetaljer/FetchByPakningsId', legemiddelpakningsId],
    () =>
      medicationApi.get(`/Administrasjonsdetaljer/FetchByPakningsId/${legemiddelpakningsId}`).then((res) => res.data),
    {
      enabled: Boolean(legemiddelpakningsId),
    }
  );

  const isLoading =
    dosering.isLoading ||
    bruksomrader.isLoading ||
    forholdsregler.isLoading ||
    administrasjonsveier.isLoading ||
    administrasjonsdetaljer.isLoading;
  const isSuccess =
    dosering.isSuccess &&
    bruksomrader.isSuccess &&
    forholdsregler.isSuccess &&
    administrasjonsveier.isSuccess &&
    administrasjonsdetaljer.isSuccess;

  return {
    data: {
      dosering: dosering.data ?? [],
      bruksomrader: bruksomrader.data ?? [],
      forholdsregler: forholdsregler.data ?? [],
      administrasjonsveier: administrasjonsveier.data ?? [],
      administrasjonsdetaljer: administrasjonsdetaljer.data ?? [],
    },
    isLoading,
    isSuccess,
  };
};
