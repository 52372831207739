/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import { Button } from '../../components/Button';
import ErrorPageContent from '../../components/ErrorPageContent';
import Loader from '../../components/loader/Loader';
import { ModalTrigger } from '../../components/Modal';
import AuthedPageLayout from '../../components/pagelayout/AuthedPageLayout';
import Table from '../../components/Table';
import { useT } from '../../i18n/useT';
import { useUserContext } from '../../lib/UserContext';
import { useFetchUsers } from '../../network/UserClient';
import { User } from '../../types/User';
import { minutesFromMidnightToDate } from '../profile/Profile';
import CreateUserModal from './CreateUserModal';
import { EditUser } from './EditUserModal';

function UserAdmin() {
  const { loggedInUser } = useUserContext();
  const t = useT();
  const { data, isLoading, isSuccess, error, refetch } = useFetchUsers();

  if (isLoading) {
    return <Loader />;
  }

  if (!isSuccess || !data) {
    return <ErrorPageContent reason={error?.message ?? 'Something went wrong'} />;
  }

  return (
    <div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 32px;
        `}
      >
        <h1>{t('Users')}</h1>
        <ModalTrigger
          button={({ openModal }) => <Button onClick={openModal}>{t('Create new user')}</Button>}
          modal={({ closeModal }) => <CreateUserModal closeModal={closeModal} onSuccess={() => refetch()} />}
        />
      </div>

      <Table>
        <tbody>
          <tr>
            <td>{t('Mobile number')}</td>
            <td>{t('Name')}</td>
            <td>{t('Role')}</td>
            <td>{t('SMS reminder')}</td>
            {loggedInUser?.isAdmin && <td />}
          </tr>
          {data.map((user: User) => {
            const existingSmsAlertOffset = minutesFromMidnightToDate(
              user.userAttributes.filter((item) => item.key === 'SmsAlertOffset').map((item) => item.value)[0],
              t
            );

            return (
              <tr key={user.mobilePhone}>
                <td>{user.mobilePhone}</td>
                <td> {user.displayName}</td>
                <td>{user.displayUserRole}</td>
                <td>{existingSmsAlertOffset}</td>
                {loggedInUser?.isAdmin && <td>{loggedInUser.user.id !== user.id && <EditUser user={user} />}</td>}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export const UserAdminPage: FC<RouteComponentProps> = () => {
  return (
    <AuthedPageLayout documentTitle="Capable - Brukeradmin" requiresOneOfRoles={['SystemAdmin', 'Physician']}>
      <UserAdmin />
    </AuthedPageLayout>
  );
};
