import { addDays, addHours, startOfDay } from 'date-fns';
import { ObservationDetails } from '../pages/sleep-details/types';
import settings from '../settings';
import { WeekAccount } from '../types/SleepObservation';
import { toServerDate } from '../utils/serverDateConverter';
import { ObjectContainer } from './../types/ObjectContainer';
import Fetch from './fetch';

async function fetchSleepDiary(patientId: string) {
  return Fetch.get<any>({ url: `${settings.apiUrl}/SleepObservation/Patient/${patientId}?MetaInfo=true` });
}

async function createSleepObservation(observation: any) {
  const url = `${settings.apiUrl}/SleepObservation?MetaInfo=true`;

  return Fetch.post<any>({ url, body: observation });
}

async function updateSleepObservation(observationId: string, observation: any) {
  const url = `${settings.apiUrl}/SleepObservation/${observationId}?MetaInfo=true`;

  return Fetch.put<any>({ url, body: observation });
}

async function fetchSleepObservationReport(fromDate?: string, toDate?: string) {
  let url = `${settings.apiUrl}/SleepObservation/SleepObservationReport?`;
  if (fromDate) {
    const beginTime = toServerDate(startOfDay(new Date(fromDate)));
    url += `beginTime=${beginTime}&`;
  }
  if (toDate) {
    const endTime = toServerDate(addHours(startOfDay(addDays(new Date(toDate), 1)), 12));
    url += `endTime=${endTime}`;
  }

  return Fetch.get<any>({ url });
}

async function getSleepWeekSummary(patientId: string, weekDate: string) {
  return Fetch.get<ObjectContainer>({
    url: `${settings.apiUrl}/SleepObservation/Patient/${patientId}/GetWeekSummary?weekDate=${weekDate}&metaInfo=true`,
  });
}

async function fetchSleepWeekAccounts(patientId: string) {
  return Fetch.get<WeekAccount[]>({ url: `${settings.apiUrl}/SleepObservation/Patient/${patientId}/GetWeekAccounts` });
}

async function fetchSleepWeekDetails(patientId: string, weekDate: string) {
  return Fetch.get<{ cpl_SleepObservationWeekDetails: ObservationDetails[] }>({
    url: `${settings.apiUrl}/SleepObservation/Patient/${patientId}/GetWeekDetails?weekDate=${weekDate}&metaInfo=true`,
  });
}

export default {
  fetchSleepDiary,
  createSleepObservation,
  updateSleepObservation,
  fetchSleepObservationReport,
  getSleepWeekSummary,
  fetchSleepWeekAccounts,
  fetchSleepWeekDetails,
};
