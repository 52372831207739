/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { sortBy } from 'lodash';
import React from 'react';
import AnimateHeight from 'react-animate-height';
import Block from '../../components/Block';
import { OutlineButton } from '../../components/Button2';
import ErrorPageContent from '../../components/ErrorPageContent';
import { Column, Row } from '../../components/Flex';
import Grid from '../../components/Grid';
import Heading from '../../components/Heading';
import Loader from '../../components/loader/Loader';
import Text from '../../components/Text';
import { useT } from '../../i18n/useT';
import SleepDiaryClient from '../../network/SleepDiaryClient';
import useClientFetch from '../../network/useClientFetch';
import { CplSleepObservationWeekSummary } from '../../types/ObjectContainer';
import formatDate from '../../utils/formatDate';
import useSessionStorageState from '../../utils/useSessionStorageState';
import { ExternalMessageButton } from '../messages/ExternalMessages';
import {
  mapData,
  ObservationDetailsWithOriginals,
  SleepDescriptions,
  SleepHistogram,
} from '../sleep-details/SleepDetailsPage';
import { ObservationDetails } from '../sleep-details/types';
import { DataBox } from './DataBox';
import RegisterWeek from './RegisterWeek';
import SleepEfficiencyGraph from './SleepEfficiencyGraph';

const CollapsableBlock = ({
  title,
  children,
  className,
}: {
  title: string;
  children: React.ReactNode;
  className?: string;
}) => {
  const t = useT();
  const [open, setOpen] = useSessionStorageState(`${title}:open`, false);

  return (
    <Block
      css={[
        css`
          padding-bottom: 16px;
          @media (max-width: 599px) {
            padding-bottom: 12px;
          }
        `,
      ]}
      className={className}
    >
      <Row height={40} vertical="center" horizontal="space-between">
        <Heading level={4}>{title}</Heading>
        <OutlineButton size="small" onClick={() => setOpen(!open)}>
          {open ? t('Close') : t('Open')}
        </OutlineButton>
      </Row>

      <AnimateHeight height={open ? 'auto' : 0}>{children}</AnimateHeight>
    </Block>
  );
};

function SleepWeekReport({ patientId, weekDate }: { patientId: string; weekDate?: string }) {
  const t = useT();
  const weekSummaryResult = useClientFetch(SleepDiaryClient.getSleepWeekSummary, {
    args: [patientId, weekDate],
    skip: !patientId || !weekDate,
  });
  const weekReport: CplSleepObservationWeekSummary | undefined =
    (weekSummaryResult.data as any)?.sleepObservationWeekSummaries?.[0] ??
    weekSummaryResult.data?.cpl_SleepObservationWeekSummaries?.[0];

  const weekDetaulsResult = useClientFetch(SleepDiaryClient.fetchSleepWeekDetails, {
    args: [patientId, weekDate],
  });
  const weekDetails: ObservationDetails[] | undefined =
    (weekDetaulsResult.data as any)?.sleepObservationWeekDetails ??
    weekDetaulsResult.data?.cpl_SleepObservationWeekDetails;

  const sortedWeekDetails = React.useMemo(
    () => sortBy(mapData(weekDetails ?? []), 'dayOffset') as ObservationDetailsWithOriginals[],
    [weekDetails]
  );

  if (weekSummaryResult.loading || weekDetaulsResult.loading) {
    return <Loader padded />;
  } else if (weekSummaryResult.error || weekDetaulsResult.error) {
    return <ErrorPageContent />;
  } else if (!weekReport || !weekDetails) {
    return (
      <Row
        css={css`
          margin: 20px;
        `}
      >
        {t('Missing data')}
      </Row>
    );
  }

  const weekNumber = formatDate(new Date(weekReport.beginWeek), 'w');

  return (
    <Column
      css={css`
        margin-bottom: 40px;
      `}
    >
      <Column
        horizontal="center"
        css={css`
          margin-top: 16px;
          margin-bottom: 20px;
        `}
      >
        <Row vertical="center">
          <Heading level={1} marginTop={0} marginBottom={0}>
            {t('Week {{weekNumber}}', { weekNumber })}
          </Heading>
          <ExternalMessageButton
            modalLabel={t('Comment on week {{weekNumber}}', { weekNumber })}
            patientId={patientId}
            mailMessages={weekReport.mailMessages ?? []}
            attachment={{
              resourceReference: {
                refResourceClass: 'Cpl_SleepObservationWeekSummary',
                refLogicalId: patientId,
                refResourceParamA: weekReport.beginWeek,
              },
              description: t('Sleep observations for week {{weekNumber}}', { weekNumber }),
            }}
            css={css`
              margin-left: 16px;
            `}
          />
        </Row>
        <Heading level={3} looksLikeLevel={4} marginTop={0} marginBottom={8}>
          {formatDate(new Date(weekReport.beginWeek), 'd. MMM')} - {formatDate(new Date(weekReport.endWeek), 'd. MMM')}
        </Heading>
      </Column>

      <Grid
        css={css`
          width: 100%;
        `}
      >
        <Row
          horizontal="center"
          css={css`
            margin-bottom: 20px;
          `}
        >
          <Block>
            <RegisterWeek patientId={patientId} weekDate={new Date(weekReport.beginWeek)} />
          </Block>
        </Row>

        <CollapsableBlock title={t('Sleep overview')}>
          <SleepHistogram sortedWeekDetails={sortedWeekDetails} />
        </CollapsableBlock>

        <CollapsableBlock title={t('Descriptions')}>
          <div
            css={css`
              max-width: calc(100vw - 104px);

              @media (max-width: 599px) {
                max-width: calc(100vw - 64px);
              }
            `}
          >
            <SleepDescriptions sortedWeekDetails={sortedWeekDetails} />
          </div>
        </CollapsableBlock>

        <Grid columns={2}>
          <Block>
            <SleepEfficiencyGraph
              sleepEfficiency={weekReport.sleepEfficiency}
              observationCount={weekReport.nofSleepObservations}
            />
          </Block>

          <Block>
            <Row horizontal="space-evenly">
              <DataBox label={t('Total time in bed')} value={weekReport.timeInBed} img="/illustrations/in-bed.png" />
              <DataBox label={t('Total sleep time')} value={weekReport.totalSleep} img="/illustrations/sleeping.png" />
            </Row>
            <Row horizontal="center">
              <div
                css={css`
                  display: grid;
                  grid-template-columns: 2fr 1fr;
                  grid-gap: 8px;
                  margin-top: 20px;
                `}
              >
                <Text marginRight={12}>{t('Bedtime')}</Text>
                <Text>{weekReport.gotoBedTime}</Text>
                <Text marginRight={12}>{t('Lights off')}</Text>
                <Text>{weekReport.attemptToSleepTime}</Text>
                <Text marginRight={12}>{t('Sleep latency')}</Text>
                <Text>{weekReport.minutesToSleep} min</Text>
                <Text marginRight={12}>{t('Time awake')}</Text>
                <Text>{weekReport.minutesAwakeTimeDuringNight} min</Text>
                <Text marginRight={12}>{t('Final awakening')}</Text>
                <Text>{weekReport.finalWakeupTime}</Text>
                <Text marginRight={12}>{t('Time spent out of bed')}</Text>
                <Text>{weekReport.outOfBedTime}</Text>
              </div>
            </Row>
          </Block>
        </Grid>
      </Grid>
    </Column>
  );
}

export default SleepWeekReport;
