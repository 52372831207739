/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { buttonReset } from '../../../utils/styles';

export function Title({ children, ...rest }) {
  return (
    <div
      css={css`
        font-size: 18px;
        font-weight: 300;
      `}
      {...rest}
    >
      {children}
    </div>
  );
}

export function SubTitle({ children }) {
  return (
    <div
      css={css`
        font-size: 14px;
        font-weight: 200;
        margin-top: 8px;
      `}
    >
      {children}
    </div>
  );
}

export function Button(props) {
  return (
    <button
      css={css`
        background-color: lightgray;
        border: 1px solid gray;
        padding: 8px 16px;
        border-radius: 12px;
        font-size: 18px;
      `}
      {...props}
    />
  );
}

export function TextInput(props) {
  return (
    <input
      type="text"
      css={css`
        width: 100%;
        margin-top: 8px;
        font-size: 22px;
        font-weight: 300;
        padding: 8px 16px;
        border-radius: 12px;
      `}
      {...props}
    />
  );
}

export function TextArea(props) {
  return (
    <textarea
      css={css`
        width: 100%;
        margin-top: 8px;
        font-size: 22px;
        font-weight: 300;
        padding: 8px 16px;
        border-radius: 12px;
      `}
      {...props}
    />
  );
}

export function NumberInput(props) {
  return (
    <input
      type="number"
      css={css`
        width: 100%;
        margin-top: 8px;
        font-size: 22px;
        font-weight: 300;
        padding: 8px 16px;
        border-radius: 12px;
      `}
      {...props}
    />
  );
}

export function Select({ children, ...rest }) {
  return (
    <select
      css={css`
        margin-top: 8px;
        font-size: 22px;
        font-weight: 300;
        background-color: #e3e0ff;
        padding: 8px 16px;
        border-radius: 12px;
        border: none;
        width: 100%;
        appearance: none;

        option {
          font-size: 16px;
        }
      `}
      {...rest}
    >
      {children}
    </select>
  );
}

export function RadioWrapper({ children, ...rest }) {
  return (
    <div
      css={css`
        margin-top: 8px;
        font-size: 22px;
        font-weight: 300;
        margin-bottom: 32px;
        display: flex;
      `}
      {...rest}
    >
      {children}
    </div>
  );
}

export function Radio({ children, selected, onClick }) {
  return (
    <button
      onClick={onClick}
      css={[
        buttonReset,
        css`
          text-align: center;
          max-width: 100px;
          border: 1px solid gray;
          padding: 8px 16px;
          border-radius: 12px;
          font-size: 18px;
        `,
        selected &&
          css`
            background-color: #e3e0ff;
          `,
      ]}
    >
      {children}
    </button>
  );
}

export function ExtraOption({ children }) {
  return (
    <div
      css={css`
        width: 100%;
        background-color: #fbfbfb;
        border: 1px solid #e6e6e6;
        padding: 15px;
        margin-top: 5px;
        margin-bottom: 32px;
        border-radius: 12px;
      `}
    >
      {children}
    </div>
  );
}
