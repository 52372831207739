import { format } from 'date-fns';
import enLocale from 'date-fns/locale/en-GB';
import frLocale from 'date-fns/locale/fr';
import nbLocale from 'date-fns/locale/nb';
import { getSelectedLanguage, Language } from './../i18n/languageStorage';

let locale = getSelectedLanguage() === 'en' ? enLocale : nbLocale;
export const setDateFormatLocale = (lng: Language) => {
  locale = lng === 'fr' ? frLocale : 'en' ? enLocale : nbLocale;
};

function formatDate(date: Date, pattern: string) {
  return format(date, pattern, { locale });
}

export default formatDate;
