import { Router } from '@reach/router';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import './i18n/i18n';
import { NotificationContextProvider } from './lib/notifications/NotificationContext';
import { UserContextProvider } from './lib/UserContext';
import { AccessControlPage } from './pages/accessControl/AccessControlPage';
import { AllergyIntolerancesPage } from './pages/allergies-and-intolerances/AllergyIntolerancesPage';
import { CalendarPage } from './pages/calendar/CalendarPage';
import { Covid19Page } from './pages/covid19/Covid19Page';
import { Covid19Redirect } from './pages/covid19/Covid19Redirect';
import { FeedbackPage } from './pages/feedback/FeedbackPage';
import { HomePage } from './pages/home/HomePage';
import { LandingPage } from './pages/landing/LandingPage';
import { LoginPage } from './pages/login/LoginPage';
import { LogoutPage } from './pages/logout/LogoutPage';
import { MedicalConditionsPage } from './pages/medical-conditions/MedicalConditionsPage';
import { MedicationPage } from './pages/medication/Medication';
import { MedicationsPage } from './pages/medications/MedicationsPage';
import { NewMedicationPage } from './pages/medications/NewMedicationPage';
import { ThreadPage } from './pages/messages/ThreadPage';
import { ThreadsPage } from './pages/messages/ThreadsPage';
import { ThreadsRedirect } from './pages/messages/ThreadsRedirect';
import { NotFoundPage } from './pages/NotFoundPage';
import { ProfilePage } from './pages/profile/ProfilePage';
import { SleepDetailsPage } from './pages/sleep-details/SleepDetailsPage';
import { SleepDiaryPage } from './pages/sleep-diary/SleepDiaryPage';
import { SleepDiaryRedirect } from './pages/sleep-diary/SleepDiaryRedirect';
import { SleepOverviewPage } from './pages/sleep-overview/SleepOverview';
import { UploadPage } from './pages/upload/UploadPage';
import { UserAdminPage } from './pages/user-admin/UserAdmin';
import { Global } from './styles/Global';
import { MedicationRedirect } from './pages/medications/SleepDiaryRedirect';
import { MedicationsPage2 } from './pages/medications/MedicationsPage2';
import { PrescriptionPage } from './pages/medication/prescription/Prescription';
import { MedicalConditionPage } from './pages/medical-condition/MedicalConditionPage';

const queryClient = new QueryClient();

const App = () => {
  return (
    <NotificationContextProvider>
      <QueryClientProvider client={queryClient}>
        <Global />
        <UserContextProvider>
          <Router>
            <LandingPage path="/" />
            <LoginPage path="/login" />
            <LogoutPage path="/logout" />
            <ProfilePage path="/profil" />
            <AccessControlPage path="/access-control" />
            <HomePage path="/hjem" />
            <HomePage path="/:patientId/hjem" />
            <CalendarPage path="/:patientId/kalender" />
            <ThreadsRedirect path="/meldinger" />
            <ThreadsPage path="/:patientId/meldinger" />
            <ThreadPage path="/:patientId/meldinger/:threadId" />
            <UserAdminPage path="/brukere" />
            <FeedbackPage path="/tilbakemelding" />

            <SleepDiaryRedirect path="/sovn" />
            <SleepDiaryPage path="/:patientId/sovn" />
            <SleepDiaryPage path="/:patientId/sovn/:date" />
            <SleepOverviewPage path="/:patientId/sovn-oversikt" />
            <SleepOverviewPage path="/:patientId/sovn-oversikt/:date" />
            <SleepDetailsPage path="/:patientId/sovn-detaljer" />
            <SleepDetailsPage path="/:patientId/sovn-detaljer/:date" />

            {/* This is the new medication page */}
            <MedicationsPage2 path="/:patientId/medications" />

            {/* Not these */}
            <MedicationRedirect path="/medisiner" />
            <NewMedicationPage path="/:patientId/medisiner/new" />
            <MedicationsPage path="/:patientId/medisiner" />
            <MedicationPage path="/:patientId/medisiner/:id" />
            <PrescriptionPage path="/:patientId/prescription/:id" />
            <MedicalConditionsPage path="/:patientId/medical-conditions" />
            <MedicalConditionPage path="/:patientId/medical-condition/:id" />
            <AllergyIntolerancesPage path="/:patientId/allergies-and-intolerances" />
            <Covid19Redirect path="/covid19" />
            <Covid19Page path="/:patientId/covid19" />
            <UploadPage path="/upload" />

            <NotFoundPage default />
          </Router>
        </UserContextProvider>
      </QueryClientProvider>
    </NotificationContextProvider>
  );
};

export default App;
