const colors = {
  blue: '#1A607A',
  lightBlue: '#2f9ec9',
  brightBlue: '#9FD4E9',
  text: '#333333',
  gray: '#777777',
  lightPurple: '#e3e0ff',
  grayBackground: '#EEF4F6',
  errorRed: '#9e0000',
  yellow: '#E5D352',
  yellowLight: '#FFF4D6',
  orange: '#FF6700',
  green: '#75B375',
  red: '#D63D4D',
  white: '#ffffff',
};

const shadows = {
  block: `0 6px 12px rgba(0, 25, 94, 0.1)`,
};

export default {
  colors,
  shadows,
};
