import React from 'react';
import { GH_Legemiddeldose } from '../../../types/gh-types';

export const sortStyrke = (a: GH_Legemiddeldose, b: GH_Legemiddeldose) => {
  if (a.styrkeEnhet === b.styrkeEnhet)
    if (a.styrke < b.styrke) return -1;
    else if (a.styrke === b.styrke) return 0;
    else return 1;
  else if (a.styrkeEnhet === 'mikrog') return -1;
  else if (a.styrkeEnhet === 'mg' && b.styrkeEnhet !== 'mikrog') return -1;
  else if (a.styrkeEnhet === 'g' && b.styrkeEnhet !== 'mikrog' && b.styrkeEnhet !== 'mg') return -1;
  else return 0;
};
