/** @jsxImportSource @emotion/react */
import { Link, RouteComponentProps } from '@reach/router';
import React, { FC, useState } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import Button from '../../components/Button2';
import Loader from '../../components/loader/Loader';
import { ModalTrigger } from '../../components/Modal';
import AuthedPageLayout from '../../components/pagelayout/AuthedPageLayout';
import i18n from '../../i18n/i18n';
import { useT } from '../../i18n/useT';
import useSelectedPatientId from '../../lib/useSelectedPatientId';
import { toastAndThrowError } from '../../network/api';
import prescriptionClient from '../../network/prescriptionClient';
import problemDecisionRelationClient from '../../network/ProblemDecisionClient';
import { Cpl_Decision, Cpl_Decision_Component_Medication, Cpl_ProblemDecisionRelation } from '../../types/Cpl_Decision';
import { PackageLeafletModal } from './PackageLeafletModal';
import { dateFromDayNo } from './prescriptions/convertDosering';
import NewDocumentDialog from './prescriptions/newdocument';
import NewDrugDialog2 from './prescriptions/newdrugDialogWrapper2';
import DiscontinueDrugDialog from './prescriptions/DiscontinueDrug';
import { NotTranslated } from '../../types/utils';
import AllergyIntoleranceClient from '../../network/AllergyIntoleranceClient';
import { fromServerDate, toServerDate } from '../../utils/serverDateConverter';

function formatNorwegianDosageText(medication: Cpl_Decision_Component_Medication) {
  return 'norsk dosage text';
}

function formatDosageText(medication: Cpl_Decision_Component_Medication, lang: string): string {
  if (lang === 'no') {
    return formatNorwegianDosageText(medication);
  } else if (lang === 'en') {
    return 'english dosage text';
  } else if (lang === 'fr') {
    return 'french dosage text';
  }

  return 'unknown';
}

const Medications = () => {
  const t = useT();
  const selectedPatientId = useSelectedPatientId()!;
  const queryClient = useQueryClient();

  const prescriptionsQuery = useQuery({
    queryKey: ['getPrescriptions', selectedPatientId],
    queryFn: () => prescriptionClient.getPrescriptions(selectedPatientId).catch(toastAndThrowError),
  });

  const relationsQuery = useQuery({
    queryKey: ['getProblemDecisionRelations', selectedPatientId],
    queryFn: () =>
      problemDecisionRelationClient.getProblemDecisionRelations(selectedPatientId).catch(toastAndThrowError),
  });

  // console.log(prescriptionsQuery.data?.data);

  const [showDialog, setShowDialog] = useState(false);
  const [showOrderDialog, setShowOrderDialog] = useState(false);
  const [showTerminationDialog, setShowTerminationDialog] = useState(false);
  const [prescrSelectedForTermination, setPrescrSelectedForTermination] = useState<Cpl_Decision | undefined>(undefined);

  const [showHistory, setShowHistory] = useState(false);

  const now = Date.now();

  const allPrescriptions = prescriptionsQuery.data?.data ?? [];

  const currentmed = allPrescriptions?.filter(
    (i) =>
      (i.category === '' || i.category === 'Cpl_Decision_Medication') &&
      fromServerDate(i.computedEndTime!).getTime() >= now
  ); // ZZZ To leave out assessment documents.  Explicit test when that is in place),

  const formermed = allPrescriptions?.filter(
    (i) =>
      (i.category === '' || i.category === 'Cpl_Decision_Medication') &&
      fromServerDate(i.computedEndTime!).getTime() < now
  );

  const faste = currentmed?.filter((i) => i.components?.[0]?.componentMedication?.courseOfTherapy.display === 'Fast');
  const prn = currentmed?.filter((i) => i.components?.[0]?.componentMedication?.courseOfTherapy.display === 'VedBehov');
  const other = currentmed?.filter(
    (i) =>
      i.components?.[0]?.componentMedication?.courseOfTherapy.display !== 'Fast' &&
      i.components?.[0]?.componentMedication?.courseOfTherapy.display !== 'VedBehov'
  );

  const saveNewPrescription = async (newPrescr: Cpl_Decision) => {
    if (selectedPatientId) {
      newPrescr.patientId = selectedPatientId;
      await prescriptionClient.createPrescription(newPrescr);
      queryClient.invalidateQueries({
        queryKey: ['getPrescriptions', selectedPatientId],
      });
    }
  };

  const onSaveNewPrescription = ({
    newDrug: newPrescr,
    relations,
  }: {
    newDrug: Cpl_Decision;
    relations: Cpl_ProblemDecisionRelation[];
  }) => {
    if (selectedPatientId) {
      newPrescr.patientId = selectedPatientId;
      saveNewPrescriptionAndRelationsMutation.mutate({ newPrescr, relations });
    }
  };

  const onDiscontinuePrescription = ({
    decision,
    terminationDay,
    terminationOffset,
    createIntoleranceEntry,
  }: {
    decision: Cpl_Decision;
    terminationDay: number;
    terminationOffset: number;
    createIntoleranceEntry: boolean;
  }) => {
    if (createIntoleranceEntry)
      discontinuePrescriptionAndRegisterIntoleranceMutation.mutate({ decision, terminationDay, terminationOffset });
    else discontinuePrescriptionMutation.mutate({ decision, terminationDay, terminationOffset });
  };

  const discontinuePrescriptionMutation = useMutation({
    mutationFn: ({
      decision,
      terminationDay,
      terminationOffset,
    }: {
      decision: Cpl_Decision;
      terminationDay: number;
      terminationOffset: number;
    }) =>
      prescriptionClient
        .terminateDecision(decision.id, terminationDay, terminationOffset)
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: ['getPrescriptions', selectedPatientId],
          });
        })
        .catch(toastAndThrowError),
  });

  const discontinuePrescriptionAndRegisterIntoleranceMutation = useMutation({
    mutationFn: ({
      decision,
      terminationDay,
      terminationOffset,
    }: {
      decision: Cpl_Decision;
      terminationDay: number;
      terminationOffset: number;
    }) =>
      prescriptionClient
        .terminateDecision(decision.id, terminationDay, terminationOffset)
        .then(() => createIntoleranceEntry(decision, dateFromDayNo(terminationDay)!))
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: ['getPrescriptions', selectedPatientId],
          });
        })
        .catch(toastAndThrowError),
  });

  const saveNewPrescriptionAndRelationsMutation = useMutation({
    mutationFn: ({ newPrescr, relations }: { newPrescr: Cpl_Decision; relations: Cpl_ProblemDecisionRelation[] }) =>
      prescriptionClient
        .createPrescription(newPrescr)
        .then(async (res) => {
          const allRelations = await Promise.all(
            relations.map(async (i) => {
              i.decisionId = res.data.id;
              var ret = problemDecisionRelationClient.createProblemDecisionRelation(i);
              return ret;
            })
          );
          return allRelations;
        })
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: ['getPrescriptions', selectedPatientId],
          });
          queryClient.invalidateQueries({
            queryKey: ['getProblemDecisionRelations', selectedPatientId],
          });
        })
        .catch(toastAndThrowError),
  });

  const deleteSinglePrescription = async (prescrId: string) => {
    await prescriptionClient.deletePrescription(prescrId);
    queryClient.invalidateQueries({
      queryKey: ['getPrescriptions', selectedPatientId],
    });
  };

  // /////////////////////////////////////////////////
  // Document decisions
  // /////////////////////////////////////////////////

  const saveNewOrder = async (newOrder: Cpl_Decision) => {
    if (selectedPatientId) {
      newOrder.patientId = selectedPatientId;
      await prescriptionClient.createPrescription(newOrder);
      queryClient.invalidateQueries({
        queryKey: ['getPrescriptions', selectedPatientId],
      });
    }
  };

  const presentDocumentPrescriptions = (prescriptions: Cpl_Decision[]) => {
    const presentSingleDocumentPrescription = (prescr: Cpl_Decision): JSX.Element => {
      let parseError = false;
      const week = ['mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag', 'søndag'];
      const docCat: string = prescr.category;
      const recurrencePattern = prescr.recurrenceParams?.[0]?.recurrencePattern ?? '';
      const dayNoOffset = prescr.components?.[0]?.recurrentTasks?.[0]?.dayNoOffset ?? -1;
      const beginDayNo = prescr.components?.[0]?.beginDayNo;
      const beginDate = dateFromDayNo(beginDayNo);
      const presentDate =
        beginDate === undefined
          ? ''
          : beginDate.getDate().toString().padStart(2, '0') +
            '.' +
            (beginDate.getMonth() + 1).toString().padStart(2, '0') +
            '.' +
            beginDate.getFullYear().toString().substring(2, 4);
      if (presentDate === '') parseError = true;

      let firstLine = '';
      let thirdLine = '';
      if (parseError === false) {
        thirdLine += 'Fra ' + presentDate + ': Utfylles ';
      }
      if (parseError === false) {
        if (recurrencePattern === 'Weeks*1') thirdLine += 'hver ';
        else if (recurrencePattern === 'Weeks*2') thirdLine += 'annen hver ';
        else parseError = true;
      }
      if (parseError === false) {
        if (dayNoOffset > -1) thirdLine += week[dayNoOffset] + ' ';
        else parseError = true;
      }

      if (parseError === true) thirdLine = 'Unknown recurrence pattern';

      if (docCat === 'Cpl_CrohnSelfAssessment') {
        firstLine = 'Egenvurdering for Crohns sykdom';
      } else if (docCat === 'Cpl_UlcColSelfAssessment') {
        firstLine = 'Egenvurdering for ulcerøs kolitt';
      } else if (docCat === 'Cpl_IBDQuestSelfAssessment') {
        firstLine = 'Spørreskjema for IBD-kontroll';
      }
      // Eksempel:
      // Egenvurdering for Mb Crohn
      // Annenhver mandag fra 25.06.2024

      return (
        <div className="m-2 mx-auto flex  flex-row justify-between rounded-xl bg-green-100 p-4 shadow-lg">
          <div>
            <div className="text-lg">{firstLine}</div>
            <div className="">{thirdLine}</div>
          </div>
          <div>
            <button
              onClick={() => {
                deleteSinglePrescription(prescr.id!);
              }}
              className="m-1 rounded bg-green-200 p-2 hover:bg-green-400 hover:text-white"
            >
              Slett
            </button>
          </div>
        </div>
      );
    };

    const documents = prescriptions.filter(
      (i) =>
        i.category === 'Cpl_CrohnSelfAssessment' ||
        i.category === 'Cpl_UlcColSelfAssessment' ||
        i.category === 'Cpl_IBDQuestSelfAssessment'
    ); // ZZZ To pick out assessment documents.  Explicit test when that is in place

    return documents && documents.length > 0 ? (
      <div className="mx-auto">
        <div>
          {documents.map((i) => (
            <div key={i.id}>{presentSingleDocumentPrescription(i)}</div>
          ))}
        </div>
      </div>
    ) : (
      <></>
    );
  };

  // /////////////////////////////////////////////////
  // End Document decisions
  // /////////////////////////////////////////////////

  const createIntoleranceEntry = async (prescr: Cpl_Decision, discontinuationDate: Date) => {
    try {
      const response = await AllergyIntoleranceClient.createMedicalIntolerance({
        patientId: selectedPatientId,
        type: {
          system: 'https://api-test.capable.healthcare/hl7.org/fhir/sid/icpc-2',
          code: 'A85',
          display: 'Adverse effect medical agent',
        },
        decisionId: prescr?.id,
        onsetDate: toServerDate(discontinuationDate)!,
        nameFormStrength: prescr?.components?.[0]?.componentMedication?.legemiddeldose?.navnFormStyrke ?? '',
        activeIngredient: prescr?.components?.[0]?.componentMedication?.legemiddeldose?.virkestoffNavn1Engelsk ?? '',
        company: prescr?.components?.[0]?.componentMedication?.legemiddeldose?.produsent ?? '',
        severity: 'moderate',
      });

      if (response.success) {
      } else {
        //addError({ message: (response.error as any)?.resultMessage ?? 'Unknown error' });
      }
    } catch (error) {}
  };

  const presentFilteredPrescriptions = (
    prescriptions: Cpl_Decision[],
    filter: 'Fast' | 'VedBehov' | 'Other' | 'Completed'
  ) => {
    let filtered: Cpl_Decision[] = prescriptions;
    /*     const now = Date.now();
    const currentmedications = prescriptions.filter((i) => {
      const check = fromServerDate(i.computedEndTime!).getTime();
      return (
        (i.category === '' || i.category === 'Cpl_Decision_Medication') &&
        fromServerDate(i.computedEndTime!).getTime() >= now
      );
    }); // ZZZ To leave out assessment documents.  Explicit test when that is in place
    const formermedications = prescriptions.filter(
      (i) =>
        (i.category === '' || i.category === 'Cpl_Decision_Medication') &&
        fromServerDate(i.computedEndTime!).getTime() < now
    );

    if (filter === 'Other')
      filtered = currentmedications.filter(
        (i) =>
          i.components?.[0]?.componentMedication?.courseOfTherapy.display !== 'Fast' &&
          i.components?.[0]?.componentMedication?.courseOfTherapy.display !== 'VedBehov'
      );
    else if (filter === 'Completed') filtered = formermedications;
    else
      filtered = currentmedications.filter(
        (i) => i.components?.[0]?.componentMedication?.courseOfTherapy.display === filter
      ); */

    const filterHeader =
      filter === 'Fast'
        ? t('Scheduled medication')
        : filter === 'VedBehov'
        ? t('PRN medication')
        : filter === 'Completed'
        ? showHistory
          ? t('Hide discontinued medications')
          : t('Show discontinued medications')
        : 'Other';

    if (filter === 'Completed') {
      return filtered && filtered.length > 0 ? (
        <div className="mx-auto pt-4 ">
          <button className="hover:underline" onClick={() => setShowHistory(!showHistory)}>
            <span>{filterHeader}</span>
          </button>
          {showHistory && (
            <div>
              {filtered.map((i) => (
                <div key={i.id}>{presentSinglePrescription(i)}</div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <></>
      );
    } else {
      return filtered && filtered.length > 0 ? (
        <div className="mx-auto pt-4 ">
          <div>{filterHeader}</div>
          <div>
            {filtered.map((i) => (
              <div key={i.id}>{presentSinglePrescription(i)}</div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      );
    }
  };

  const presentSinglePrescription = (prescr: Cpl_Decision): JSX.Element => {
    const med = prescr.components?.[0]?.componentMedication;
    const legemiddelpakningId = med?.legemiddelpakningId;
    const navnFormStyrke = med?.legemiddeldose?.navnFormStyrke;

    if (med === undefined) return <div>{'Deleted prescription (?)'}</div>;
    else
      return (
        <div className="m-2 mx-auto flex flex-row  items-center rounded-xl bg-blue-100 p-4 shadow-lg">
          <div className="grow basis-28">
            {legemiddelpakningId ? (
              <ModalTrigger
                button={({ openModal }) => (
                  <button
                    onClick={openModal}
                    className="text-lg px-3 rounded bg-blue-200 hover:bg-blue-400 hover:text-white"
                  >
                    {navnFormStyrke}
                  </button>
                )}
                modal={({ closeModal }) => (
                  <PackageLeafletModal
                    closeModal={closeModal}
                    medicationName={navnFormStyrke}
                    itemNumber={legemiddelpakningId}
                    patientId={selectedPatientId}
                  />
                )}
              />
            ) : (
              <div className="text-lg">{navnFormStyrke}</div>
            )}
            <div className="text-sm text-gray-400">
              {med?.legemiddeldose?.virkestoffNavn1Engelsk +
                ((med?.legemiddeldose?.noOfVirkestoff ?? 0) > 1 ? '++' : '')}
            </div>
            {/*             <div className="text-sm text-gray-400">{med?.legemiddeldose?.produsent}</div>
             */}{' '}
            <div className="text-gray-500">{med?.bruksomrade?.display}</div>
            <div className="">{med?.doseringsTekst}</div>
            {/*             <div className="">{formatDosageText(med, i18n.language)}</div>
             */}{' '}
          </div>
          <div className="basis-16">
            <button
              onClick={() => {
                setShowTerminationDialog(true);
                setPrescrSelectedForTermination(prescr);
              }}
              className="m-1 rounded bg-blue-200 p-2 hover:bg-blue-400 hover:text-white"
            >
              {t('Discontinue')}
            </button>
          </div>
          <div className="basis-16">
            <button
              onClick={() => {
                deleteSinglePrescription(prescr.id!);
              }}
              className="m-1 rounded bg-blue-200 p-2 hover:bg-blue-400 hover:text-white"
            >
              {t('Delete')}
            </button>
          </div>
          <Link
            className="basis-34 m1 p-4 max-h-5 rounded flex justify-center items-center bg-blue-200 hover:bg-blue-400 hover:text-white"
            to={`/${selectedPatientId}/prescription/${prescr.id}`}
            /*               css={css`
                background-color: ${lighten(0.05, theme.colors.blue)};
                color: #ffffff;
                padding: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                text-decoration: none;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;

                @media (max-width: 599px) {
                  border-top-right-radius: 0px;
                  border-bottom-right-radius: 8px;
                  border-bottom-left-radius: 8px;
                }

                &:hover {
                  background-color: ${lighten(0.4, theme.colors.lightBlue)};
                  color: #000000;
                }
              `} */
          >
            {t('To medication')}
          </Link>
        </div>
      );
  };

  return (
    <div>
      <h1 className="mb-4">{t('Medications')}</h1>

      {prescriptionsQuery.isLoading && <Loader />}
      {prescriptionsQuery.data?.data && (
        <>
          {presentFilteredPrescriptions(faste, 'Fast')}
          {presentFilteredPrescriptions(prn, 'VedBehov')}
          {presentFilteredPrescriptions(other, 'Other')}
        </>
      )}
      <div className="pt-4">
        <Button onClick={() => setShowDialog(true)}>{t('Add new medication')}</Button>
      </div>

      {prescriptionsQuery.data?.data && <>{presentFilteredPrescriptions(formermed, 'Completed')}</>}

      {/*       {(
        <>
          <h1 className="pb-2 pt-6">Oppfølging av oppgaver</h1>
          {prescriptionsQuery.data?.data && <>{presentDocumentPrescriptions(prescriptionsQuery.data?.data)}</>}

          <div className="">
            <Button onClick={() => setShowOrderDialog(true)}>Nytt skjema for oppfølging</Button>
          </div>
        </>
      )} */}

      <DiscontinueDrugDialog
        showDlg={showTerminationDialog}
        title={t('Discontinue medication...')}
        curDecision={prescrSelectedForTermination}
        onClose={() => setShowTerminationDialog(false)}
        onSave={onDiscontinuePrescription}
      ></DiscontinueDrugDialog>

      <NewDrugDialog2
        showDlg={showDialog}
        title={t('New medication...')}
        onClose={() => setShowDialog(false)}
        onSave={onSaveNewPrescription}
      ></NewDrugDialog2>

      <NewDocumentDialog
        showDlg={showOrderDialog}
        title={t('New follow-up of self-assessment...')}
        onClose={() => setShowOrderDialog(false)}
        onSave={saveNewOrder}
      ></NewDocumentDialog>
    </div>
  );
};

export const MedicationsPage2: FC<RouteComponentProps> = () => {
  const t = useT();

  return (
    <AuthedPageLayout documentTitle={t('Capable - Medications')}>
      <Medications />
    </AuthedPageLayout>
  );
};
