import React from 'react';
import { Redirect, useLocation } from '@reach/router';
import { SelectedPatientContextProvider } from '../../lib/SelectedPatientContext';
import { useUserContext } from '../../lib/UserContext';
import { Role } from '../../lib/userRoles';
import Loader from '../loader/Loader';
import PageLayout, { PageLayoutProps } from './PageLayout';

type AuthedPageLayoutProps = { requiresOneOfRoles?: Role[] } & PageLayoutProps;

const AuthedPageLayout = ({ children, requiresOneOfRoles, ...rest }: AuthedPageLayoutProps) => {
  const { loading, isLoggedIn, hasRole } = useUserContext();
  const hasAccess = (requiresOneOfRoles ?? []).some((role) => hasRole(role));
  const location = useLocation();

  return (
    <PageLayout {...rest}>
      {loading ? (
        <Loader />
      ) : !isLoggedIn ? (
        <Redirect to={`/login?returnto=${encodeURIComponent(location.pathname)}`} noThrow />
      ) : requiresOneOfRoles && !hasAccess ? (
        <Redirect to="/hjem" noThrow />
      ) : (
        <SelectedPatientContextProvider>
          <>{children}</>
        </SelectedPatientContextProvider>
      )}
    </PageLayout>
  );
};

export default AuthedPageLayout;
