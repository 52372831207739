export function pluralize(singularForm: string, pluralForm: string, count: number) {
  if (count === 1) {
    return singularForm;
  } else {
    return pluralForm;
  }
}

export const stringToInt = (str: string): number | undefined => {
  const val = parseInt(str, 10);
  return Number.isNaN(val) ? undefined : val;
};
