/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { FilledButton } from '../../components/Button2';
import { Column, Row } from '../../components/Flex';
import Text, { getTextStyle } from '../../components/Text';
import SleepDiaryClient from '../../network/SleepDiaryClient';
import formatDate from '../../utils/formatDate';
import { fromServerDate } from '../../utils/serverDateConverter';
import useTimeout from '../../utils/useTimeout';

type DataType = 'string' | 'number' | 'date' | 'boolean';

// label is used for column names, can be changed
const CSV_FIELDS: Array<{
  key: string;
  label: string;
  type: DataType;
}> = [
  { key: 'researchProjectNumber', label: 'researchProjectNumber', type: 'string' },
  { key: 'observationDate', label: 'observationDate', type: 'date' },
  { key: 'daysAfter2000', label: 'daysAfter2000', type: 'number' },
  { key: 'functionLevel', label: 'functionLevel', type: 'number' },
  { key: 'functionalProblems', label: 'functionalProblems', type: 'string' },
  { key: 'functionalMeasures', label: 'functionalMeasures', type: 'string' },
  { key: 'sleepingDrug', label: 'sleepingDrug', type: 'boolean' },
  { key: 'sleepingDrugs', label: 'sleepingDrugs', type: 'string' },
  { key: 'gotoBedTime', label: 'gotoBedTime', type: 'date' },
  { key: 'attemptToSleepTime', label: 'attemptToSleepTime', type: 'date' },
  { key: 'minutesToSleep', label: 'minutesToSleep', type: 'number' },
  { key: 'nofWakeupsDuringNight', label: 'nofWakeupsDuringNight', type: 'number' },
  { key: 'minutesAwakeTimeDuringNight', label: 'minutesAwakeTimeDuringNight', type: 'number' },
  { key: 'finalWakeupTime', label: 'finalWakeupTime', type: 'date' },
  { key: 'outOfBedTime', label: 'outOfBedTime', type: 'date' },
  { key: 'minutesLatestAwakeTime', label: 'minutesLatestAwakeTime', type: 'number' },
  { key: 'sleepQualityMeasure', label: 'sleepQualityMeasure', type: 'number' },
  { key: 'sleepComments', label: 'sleepComments', type: 'string' },
  { key: 'sleepEfficiency', label: 'sleepEfficiency', type: 'number' },
];

function toCsv(sleepData: any) {
  let csv = CSV_FIELDS.map((field) => field.label).join(',') + '\n';

  for (const observation of sleepData) {
    const row = CSV_FIELDS.map((field) => {
      const value = observation[field.key] ?? '';

      switch (field.type) {
        case 'string':
          return `"${value}"`;

        case 'number':
          return value;

        case 'date':
          try {
            const date = fromServerDate(value);
            return `"${formatDate(date, 'dd-MM-yyyy, HH:mm:ss.SSS')}"`;
          } catch (error) {
            return `"${value}"`;
          }

        case 'boolean':
          return `"${String(value)}"`;

        default:
          return '';
      }
    }).join(',');

    csv += row + '\n';
  }

  return csv;
}

function downloadCsv(csv: string) {
  const hiddenElement = document.createElement('a');
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
  hiddenElement.target = '_blank';
  hiddenElement.download = 'report.csv';
  hiddenElement.click();
}

function DownloadSleepObservationReportButton() {
  const [fromDate, setFromDate] = React.useState<string | undefined>(undefined);
  const [toDate, setToDate] = React.useState<string | undefined>(undefined);

  const [numberOfObservationsDownloaded, setNumberOfObservationsDownloaded] = React.useState(-1);
  useTimeout(() => setNumberOfObservationsDownloaded(-1), numberOfObservationsDownloaded !== -1 ? 5000 : null);

  async function handleClick() {
    try {
      const result = await SleepDiaryClient.fetchSleepObservationReport(fromDate, toDate);

      if (!result.success) {
        alert('Noe gikk galt, prøv igjen.');
      }

      const rows = result.data || [];
      setNumberOfObservationsDownloaded(rows.length);

      downloadCsv(toCsv(rows));
    } catch (error) {
      console.error(error);
      alert('Noe gikk galt. Prøv igjen.');
    }
  }

  return (
    <Column>
      <Row>
        <Column
          css={css`
            margin-right: 8px;
          `}
        >
          <label htmlFor="fra-dato">Fra dato</label>
          <input
            id="fra-dato"
            css={getTextStyle('normal')}
            type="date"
            onChange={(event) => setFromDate(event.target.value)}
          />
        </Column>

        <Column>
          <label htmlFor="til-dato">Til dato</label>
          <input
            id="til-dato"
            css={getTextStyle('normal')}
            type="date"
            onChange={(event) => setToDate(event.target.value)}
          />
        </Column>
      </Row>

      <Row
        css={css`
          margin-top: 8px;
        `}
      >
        <FilledButton onClick={handleClick}>Last ned søvnrapport</FilledButton>
      </Row>

      {numberOfObservationsDownloaded > -1 && (
        <Text marginTop={8}>Lastet ned {numberOfObservationsDownloaded} observasjoner</Text>
      )}
    </Column>
  );
}

export default DownloadSleepObservationReportButton;
