import React from 'react';
import { useEffect, useRef, useState } from 'react';
import {
  getDateFromDateControlFormattedDate,
  getNumberofdaysBetweenDates,
  getSimpleFormattedDate,
} from './convertDosering';
//import {DosageTimeParams} from "../../../types/gh-types";

/************************************** 
  Start beginDate and EndDate controls
***************************************/

/* export type DosageTimeParams = {
  startDate: Date | undefined;
  endDate: Date | undefined;
  intervalDays: number;
  endOption: string;
};
 */
type TimeControlParams2 = {
  collapsed: boolean; // describes whether the control presents baseStartDate as startDate, or reccurentStartDate as startDate
  // collapsed = true is called when "simple" dosage, while collapsed = false is called from "advanced" dosage
  isComplex: boolean; // Only relevant for collapsed mode: associated dosage contains both explicit tasks as well as recurrent tasks
  startDate: Date | undefined;
  endDate: Date | undefined;
  intervalDays: number;
  endOption: string;
  baseStartDate: Date | undefined; // never given in collapsed mode. Else given if explicit dates exists
  startDateOnChange: (curStartDate: Date | undefined) => void;
  endDateOnChange: (curEndDate: Date | undefined) => void;
  intervalDaysOnChange: (curIntervalDays: number) => void;
  endOptionOnChange: (curEndOption: string) => void;
  checkAllowedTimeChange: (
    startDate: Date | undefined,
    endDate: Date | undefined,
    intervalDays: number,
    endOption: string
  ) => boolean;
};

export const DosageIntervalControl2 = ({
  collapsed,
  isComplex,
  startDate,
  endDate,
  intervalDays,
  endOption,
  baseStartDate,
  startDateOnChange,
  endDateOnChange,
  intervalDaysOnChange,
  endOptionOnChange,
  checkAllowedTimeChange,
}: TimeControlParams2): JSX.Element => {
  // const [endOption, setEndOption] = useState<string>(initEndOption??"Max"); // "Max"|"endDate"|"interval"  ----- --> "Max", "interval", "endDate"
  // const [startDate, setStartDate] = useState<Date|undefined>((initStartDate)?initStartDate:new Date(thisyear, thismonth, thisday)); // ZZZ Dagens dato ..
  // const [endDate, setEndDate] = useState<Date|undefined>((initEndDate)?initEndDate:undefined); // Date|undefined  -- Need to check endOption to see if endDate is "active"
  //const [intervalDays, setIntervalDays] = useState<number>((initIntervalDays)??0);
  const [draftIntervalDays, setDraftIntervalDays] = useState<number>(0);
  const [numberOfDosageDaysAddingInProcess, setNumberOfDosageDaysAddingInProcess] = useState(false);
  const addNumberOfDosageDaysRef = useRef<null | HTMLDivElement>(null);
  const endOptionMenuRef = useRef<null | HTMLDivElement>(null);
  const [endOptionMenuOpen, setEndOptionMenuOpen] = useState<boolean>(false);

  // This is for the "light dismissed" ui for adding new explicit date
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  const handleOutsideClick = (event: MouseEvent) => {
    if (addNumberOfDosageDaysRef.current && !addNumberOfDosageDaysRef.current.contains(event.target as Node))
      setNumberOfDosageDaysAddingInProcess(false);
    if (endOptionMenuRef.current && !endOptionMenuRef.current.contains(event.target as Node))
      setEndOptionMenuOpen(false);
  };

  //if ((endDate === undefined) && (endOption === "interval") && (intervalDays>0) && (startDate !==undefined))
  if (endOption === 'interval' && intervalDays > 0 && startDate !== undefined)
    endDate = new Date(startDate?.getFullYear(), startDate?.getMonth(), startDate?.getDate() + intervalDays - 1);

  // useEffect(() => {
  //         onDosageTimeChanges({startDate, endDate, intervalDays, endOption})
  // }, [startDate, endDate, intervalDays, endOption, onDosageTimeChanges, init]);

  const getDateControlFormattedDate = (dt: Date | undefined): string => {
    // YYYY-MM-DD
    return dt === undefined
      ? ''
      : dt.getFullYear() +
          '-' +
          (dt.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          dt.getDate().toString().padStart(2, '0');
  };

  /*   const getSimpleFormattedDate = (dt: Date | undefined): string => {
    // DD.MM.YY
    return dt === undefined
      ? ""
      : dt.getDate().toString().padStart(2, "0") +
          "." +
          (dt.getMonth() + 1).toString().padStart(2, "0") +
          "." +
          dt.getFullYear().toString().substring(2, 4);
  }; */

  /*   const getDateFromDateControlFormattedDate = (
    dtstring: string,
  ): Date | undefined => {
    //        return (dtstring === "")? undefined : new Date(Date.parse(dtstring)); // Had frustrating discrepancy in timezone, maybe due to using Date.parse??
    return dtstring === ""
      ? undefined
      : new Date(
          Number(dtstring.substring(0, 4)),
          Number(dtstring.substring(5, 7)) - 1,
          Number(dtstring.substring(8, 10)),
        );
  };
 */
  const onStartDateChange = (dt: string) => {
    const input: Date | undefined = getDateFromDateControlFormattedDate(dt);
    if (checkAllowedTimeChange(input, endDate, intervalDays, endOption)) startDateOnChange(input);
    else return;
    //setStartDate(input);
    if (endOption === 'interval') {
      const newEndDate: Date | undefined =
        input === undefined
          ? undefined
          : new Date(input?.getFullYear(), input?.getMonth(), input?.getDate() + (intervalDays ?? 0) - 1);

      if (checkAllowedTimeChange(input, newEndDate, intervalDays, endOption)) endDateOnChange(newEndDate);
    }
    //onDosageTimeChanges({startDate, endDate, intervalDays, endOption})
  };

  const onEndDateChange = (dt: string) => {
    const input: Date | undefined = getDateFromDateControlFormattedDate(dt);
    if (checkAllowedTimeChange(startDate, input, intervalDays, endOption)) endDateOnChange(input);
    //onDosageTimeChanges({startDate, endDate, intervalDays, endOption})
  };

  const setIntervalOptions = (days: number) => {
    const newDate: Date | undefined =
      startDate === undefined
        ? undefined
        : new Date(startDate?.getFullYear(), startDate?.getMonth(), startDate?.getDate() + days - 1);
    if (checkAllowedTimeChange(startDate, newDate, days, 'interval')) {
      endOptionOnChange('interval');
      intervalDaysOnChange(days);
      endDateOnChange(newDate);
    }
  };

  const onEndOptionChange = (option: string) => {
    if (startDate === undefined) {
      setEndOptionMenuOpen(false); // close "menu"
      return;
    }

    if (option === '3') {
      setIntervalOptions(3);
    } else if (option === '5') {
      setIntervalOptions(5);
    } else if (option === '7') {
      setIntervalOptions(7);
    } else if (option === '10') {
      setIntervalOptions(10);
    } else if (option === '14') {
      setIntervalOptions(14);
    } else if (option === 'interval') {
      if (checkAllowedTimeChange(startDate, endDate, intervalDays, 'interval')) {
        setDraftIntervalDays(1); // ZZZ set intervalDays too?
        setNumberOfDosageDaysAddingInProcess(true);
        endOptionOnChange('interval');
      }
    } else if (option === 'endDate') {
      // Set endDate om den ikke allerede er satt, eller om den er mindre enn startDate
      if (checkAllowedTimeChange(startDate, endDate, intervalDays, 'endDate')) {
        endOptionOnChange('endDate');
        if ((endDate && endDate < startDate) || !endDate)
          endDateOnChange(new Date(startDate?.getFullYear(), startDate?.getMonth(), startDate?.getDate()));
      }
    } else if (option === 'Max') {
      if (checkAllowedTimeChange(startDate, undefined, intervalDays, 'Max')) {
        endDateOnChange(undefined);
        endOptionOnChange('Max');
      }
    } else endOptionOnChange(option); // Should not be neccessary to test first

    setEndOptionMenuOpen(false); // close "menu" if not already closed
    //onDosageTimeChanges({startDate, endDate, intervalDays, endOption});
  };

  const onIntervalDaysChange = () => {
    intervalDaysOnChange(draftIntervalDays);
    endOptionOnChange('interval');
    endDateOnChange(
      startDate && draftIntervalDays
        ? new Date(startDate?.getFullYear(), startDate?.getMonth(), startDate?.getDate() + draftIntervalDays - 1)
        : undefined
    );
    setNumberOfDosageDaysAddingInProcess(false);
    //onDosageTimeChanges({startDate, endDate, intervalDays, endOption});
  };

  const addNewIntervalDaysDlg = () => {
    return (
      <div
        className="absolute left-8 rounded border-2 border-gray-400 bg-white drop-shadow-lg"
        ref={addNumberOfDosageDaysRef}
      >
        <div className="mt-2 rounded p-2 ">
          <div className="bg-gray-100">
            <label className="align-bottom ">
              Antall dager:
              <input
                className="bg-gray-100 pl-2 text-blue-600"
                value={draftIntervalDays}
                onChange={(e) => setDraftIntervalDays(Number(e.target.value))}
                type="number"
                min="1"
                max="50"
              ></input>
            </label>
          </div>
          <div className="flex flex-row justify-between border-t-2 border-gray-400 pt-2">
            <button
              onClick={() => {
                setNumberOfDosageDaysAddingInProcess(false);
              }}
              className="pr-2 text-blue-500 hover:underline"
            >
              Lukk
            </button>
            <button onClick={() => onIntervalDaysChange()} className="pl-2 text-blue-500 hover:underline">
              Legg til antall dager
            </button>
          </div>
        </div>
      </div>
    );
  };

  const presentEndOption = (
    endOption: string,
    intervalDays: number,
    isComplex: boolean,
    collapsedMode: boolean
  ): string => {
    if (collapsedMode && isComplex) return 'se skjema';

    if (endOption === 'interval')
      return 'i ' + intervalDays?.toString() + (intervalDays && intervalDays === 1 ? ' dag' : ' dager');
    else if (endOption === 'Max') return 'inntil videre';
    else if (endOption === 'endDate') return 't.o.m.';
    else if (endOption === 'auto') return 'etter skjema';
    //"til og med"; // Should not happen
    else if (endOption === 'multiple') return 'se skjemaXXX';
    // Should not happen
    else return 'FAIL' + endOption + 'FAIL';
  };
  const popUpButtonStyle: string = 'px-2 text-left static text-blue-600 hover:underline';
  const popUpButtonStyleBorderBottom: string =
    'border-b border-gray-300 px-2 text-left static text-blue-600 hover:underline';

  return (
    <div>
      <div className="mt-2 flex flex-row bg-gray-100 text-sm font-medium text-gray-900">
        <label className="mr-2 pr-2 align-bottom">
          {'Brukes f.o.m.' +
            (!collapsed && !(baseStartDate === undefined || startDate === undefined)
              ? ' dag ' + getNumberofdaysBetweenDates(baseStartDate!, startDate!).toString()
              : '')}
          <input
            className="bg-gray-100 pl-2 text-blue-600"
            value={getDateControlFormattedDate(startDate)}
            onChange={(e) => onStartDateChange(e.target.value)}
            type="date"
          ></input>
        </label>
        <div className="relative">
          <button
            className="min-w-24 cursor-pointer px-2 text-blue-600 hover:underline"
            onClick={() => setEndOptionMenuOpen(!endOptionMenuOpen)}
          >
            {presentEndOption(endOption, intervalDays, isComplex, collapsed)}
          </button>
          {endOptionMenuOpen && (
            <div
              ref={endOptionMenuRef}
              className="border-gray300 absolute left-0 top-0 z-50 flex w-max flex-col rounded-lg border bg-white drop-shadow-md "
            >
              <button className={popUpButtonStyleBorderBottom} onClick={() => onEndOptionChange('Max')}>
                inntil videre
              </button>
              <button className={popUpButtonStyle} onClick={() => onEndOptionChange('3')}>
                i 3 dager
              </button>
              <button className={popUpButtonStyle} onClick={() => onEndOptionChange('5')}>
                i 5 dager
              </button>
              <button className={popUpButtonStyle} onClick={() => onEndOptionChange('7')}>
                i 7 dager
              </button>
              <button className={popUpButtonStyle} onClick={() => onEndOptionChange('10')}>
                i 10 dager
              </button>
              <button className={popUpButtonStyleBorderBottom} onClick={() => onEndOptionChange('14')}>
                i 14 dager
              </button>
              <button className={popUpButtonStyleBorderBottom} onClick={() => onEndOptionChange('interval')}>
                i n dager ...
              </button>
              <button className={popUpButtonStyle} onClick={() => onEndOptionChange('endDate')}>
                til og med ...
              </button>
            </div>
          )}
        </div>
        <span className="relative pl-2 text-gray-400">
          {!(isComplex && collapsed) && (endOption === 'auto' || endOption === 'interval') && endDate !== undefined
            ? '(t.o.m. ' + getSimpleFormattedDate(endDate) + ')'
            : ''}
        </span>
        {numberOfDosageDaysAddingInProcess && addNewIntervalDaysDlg()}
        {/*                     {<div>endOption={endOption}; intervalDays={intervalDays}</div>} */}
        {endOption === 'endDate' && startDate !== undefined && (
          <input
            className="bg-gray-100 pl-2 text-blue-600 disabled:text-gray-400"
            value={getDateControlFormattedDate(endDate)}
            onChange={(e) => onEndDateChange(e.target.value)}
            type="date"
          ></input>
        )}
      </div>
    </div>
  );
};
