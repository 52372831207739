import settings from '../settings';
import { Cpl_ProblemDecisionRelation } from './../types/Cpl_Decision';
import { ObjectContainer } from './../types/ObjectContainer';
import { api } from '../network/api';
import { RecursivePartial } from '../types/utils';

async function getProblemDecisionRelations(patientId: string) {
    const response = await api.get<Cpl_ProblemDecisionRelation[]>(`${settings.apiUrl}/ProblemDecisionRelation/GetByPatient/${patientId}`, {
        params: { metaInfo: false }
    });
  return response;
}

async function getProblemDecisionRelationsWithMeta(patientId: string) {
    const response = await api.get<ObjectContainer>(`${settings.apiUrl}/ProblemDecisionRelation/GetByPatient/${patientId}`, {
        params: { metaInfo: true }
    });
  return response;
}

async function getProblemDecisionRelation(relationId: string) {
    const response = await api.get<Cpl_ProblemDecisionRelation>(`${settings.apiUrl}/ProblemDecisionRelation/GetById/${relationId}`, {
        params: { metaInfo: false }
    });
  return response;
}

async function getProblemDecisionRelationWithMeta(relationId: string) {
    const response = await api.get<ObjectContainer>(`${settings.apiUrl}/ProblemDecisionRelation/GetById/${relationId}`, {
        params: { metaInfo: true }
    });
  return response;
}

async function createProblemDecisionRelation(newRelation: RecursivePartial<Cpl_ProblemDecisionRelation>) {
    const response = await api.post(`${settings.apiUrl}/ProblemDecisionRelation/Post`, newRelation, {
        params: { metaInfo: false },
    });
  return response;
}

async function updateProblemDecisionRelation(relationId: string, relation: Cpl_ProblemDecisionRelation) {
    const response = await api.put(`${settings.apiUrl}/ProblemDecisionRelation/Put/${relationId}`, relation, {
        params: { metaInfo: false },
    });
  return response;
}

async function deleteProblemDecisionRelation(relationId: string) {
    const response = await api.delete(`${settings.apiUrl}/ProblemDecisionRelation/Delete/${relationId}`, {
        params: { metaInfo: false }
    });
  return response;
}

const problemDecisionRelationClient = {
    getProblemDecisionRelations,
    getProblemDecisionRelationsWithMeta,
    getProblemDecisionRelation,
    getProblemDecisionRelationWithMeta,
    createProblemDecisionRelation,
    updateProblemDecisionRelation,
    deleteProblemDecisionRelation
}

export default problemDecisionRelationClient;