import React from 'react';
import PatientClient from '../network/PatientClient';
import { Fhir_Patient } from '../types/Fhir_Patient';
import { useAddNotification } from './notifications/NotificationContext';
import { createSessionCache, TTL } from './sessionCache';
import useSelectedPatientId from './useSelectedPatientId';

type SelectedPatientContextState = {
  loading: boolean;
  selectedPatient?: {
    // user: User;
    patient: Fhir_Patient;
  };
};

export const SelectedPatientContext = React.createContext<SelectedPatientContextState>({
  loading: true,
  selectedPatient: undefined,
});

export const useSelectedPatientContext = () => React.useContext(SelectedPatientContext);

const SelectedPatientSessionStorage = createSessionCache<Fhir_Patient>('SelectedPatient', TTL.THIRTY_SECONDS);

export const SelectedPatientContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { addError } = useAddNotification();
  const selectedPatientId = useSelectedPatientId();
  const [patientFromCache] = React.useState(() =>
    selectedPatientId ? SelectedPatientSessionStorage.get(selectedPatientId) : undefined
  );
  const [state, setState] = React.useState<SelectedPatientContextState>({
    loading: true,
    selectedPatient: patientFromCache ? { patient: patientFromCache } : undefined,
  });

  const fetchSelectedPatient = React.useCallback(async () => {
    if (selectedPatientId) {
      setState({ loading: true, selectedPatient: undefined });
    } else {
      if (selectedPatientId) {
        SelectedPatientSessionStorage.clear(selectedPatientId);
      }
      setState({ loading: false, selectedPatient: undefined });
      return;
    }

    try {
      const result = await PatientClient.fetchPatient(selectedPatientId);

      if (result.success) {
        if (result.data) {
          setState({
            loading: false,
            selectedPatient: {
              patient: result.data,
            },
          });
          SelectedPatientSessionStorage.store(selectedPatientId, result.data);
        } else {
          setState({ loading: false, selectedPatient: undefined });
        }
      } else {
        setState({ loading: false, selectedPatient: undefined });
        addError({ message: (result.error as any)?.resultMessage ?? 'Ukjent feil' });
      }
    } catch (error) {
      console.error(error);
      setState({ loading: false, selectedPatient: undefined });
      addError({ message: 'Ukjent feil' });
      throw error;
    }
  }, [selectedPatientId, addError]);

  React.useEffect(() => {
    if (!patientFromCache) {
      fetchSelectedPatient();
    }
  }, [selectedPatientId, patientFromCache, fetchSelectedPatient]);

  return <SelectedPatientContext.Provider value={state}>{children}</SelectedPatientContext.Provider>;
};
