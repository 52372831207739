export type Language = 'en' | 'no' | 'fr';

export const supportedLanguages: Language[] = ['en', 'no', 'fr'];

const SELECTED_LANGUAGE_STORAGE_KEY = 'SELECTED_LANGUAGE';

let fallbackLanguage: Language = 'no';
if (navigator.language) {
  if (navigator.language.startsWith('en')) {
    fallbackLanguage = 'en';
  }
  if (navigator.language.startsWith('fr')) {
    fallbackLanguage = 'fr';
  }
}

export const getSelectedLanguage = (): Language => {
  try {
    const language = localStorage.getItem(SELECTED_LANGUAGE_STORAGE_KEY);
    return language ? (language as Language) : fallbackLanguage;
  } catch (error) {
    console.error(error);
    return fallbackLanguage;
  }
};

export const setSelectedLanguage = (language: Language) => {
  try {
    localStorage.setItem(SELECTED_LANGUAGE_STORAGE_KEY, language);
  } catch (error) {
    console.error(error);
  }
};
