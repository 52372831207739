import { UserAttribute } from '../types/User';

export type Role = 'SystemAdmin' | 'Physician' | 'Covid19User' | 'TestUser';

const getUserAttributeValue = (attributes: UserAttribute[], key: string) => {
  return attributes?.find((attribute: UserAttribute) => attribute.key === key)?.value;
};

export const hasRole = (userAttributes: UserAttribute[], role: Role) => {
  return getUserAttributeValue(userAttributes, role) === 'true';
};
