import { TFunction } from './../../i18n/useT';
import { Cpl_CourseOfTherapy } from '../../types/Cpl_FEST';
import { Fhir_MedicationRequest } from './../../types/Fhir_MedicationRequest';

export const COURSE_OF_THERAPY_TYPES: Array<Cpl_CourseOfTherapy['courseOfTherapyType']> = [
  'Faste medisiner',
  'Ved behov',
  'Seponert',
];

export const getCourseOfTherapyType = (
  medicationRequest: Fhir_MedicationRequest
): Cpl_CourseOfTherapy['courseOfTherapyType'] => {
  let courseOfTherapyType = '';

  if (medicationRequest.courseOfTherapyType?.translatedText) {
    courseOfTherapyType = medicationRequest.courseOfTherapyType?.translatedText;
  }

  if (COURSE_OF_THERAPY_TYPES.includes(courseOfTherapyType as Cpl_CourseOfTherapy['courseOfTherapyType'])) {
    return courseOfTherapyType as Cpl_CourseOfTherapy['courseOfTherapyType'];
  }

  return 'Faste medisiner';
};

export const translateCourseOfTherapyType = (
  t: TFunction,
  courseOfTherapyType: Cpl_CourseOfTherapy['courseOfTherapyType']
) => {
  switch (courseOfTherapyType) {
    case 'Faste medisiner':
      return t('Regular drug');
    case 'Ved behov':
      return t('As needed (PRN)');
    case 'Seponert':
      return t('Deprescribed');
  }
};
