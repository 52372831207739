import { addMinutes, isBefore } from 'date-fns';
import React, { Fragment, useState } from 'react';
import { useT } from '../../../i18n/useT';
import { ExtraOption, Select, SubTitle, Title } from './InputFields';
import { InputLine } from './InputLine';

export default function SelectTimeOptions({ timeOptions, time, title, subTitle, chosenDate }) {
  const t = useT();
  const defaultTimeOption = timeOptions.filter((time) => time.optionGroup === 'default');
  const [visibleOptionGroup, setVisibleOptionGroup] = useState(
    findOptionGroup(defaultTimeOption, time.value, timeOptions)
  );

  const changeTime = (value) => {
    let valueAsNumber = Number(value);
    const optionGroup = findOptionGroup(defaultTimeOption, valueAsNumber, timeOptions);
    setVisibleOptionGroup(optionGroup);

    if (optionGroup !== 'default') {
      // current value is one of the default values
      if (defaultTimeOption.some((defaultTime) => defaultTime.offset === valueAsNumber)) {
        // finds the closest value in the timeOptions list
        const filterTimeOptions = timeOptions.filter((time) => time.optionGroup === optionGroup);
        const filterTimeOptionsOffsets = filterTimeOptions.map((option) => option.offset);
        valueAsNumber = closestNumber(filterTimeOptionsOffsets, valueAsNumber);
      }
    }

    time.setValue(valueAsNumber);
  };

  return (
    <Fragment>
      <Title htmlFor={title}>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
      <InputLine v={time.validation} noMargin={visibleOptionGroup !== 'default'}>
        <Select
          id={title}
          value={selectedTimeValue(defaultTimeOption, time.value)}
          onChange={(event) => changeTime(event.target.value)}
        >
          <option value={undefined} disabled={time.value !== undefined}>
            {t('Choose from list...')}
          </option>
          {defaultTimeOption.map((option) => (
            <option key={option.offset} value={option.offset}>
              {/* TODO: translate */}
              {option.label}
            </option>
          ))}
        </Select>
      </InputLine>
      {visibleOptionGroup !== 'default' && (
        <ExtraOption>
          <label htmlFor={`${title}-trenger-utdyping`}>{t('NOTE! You need to elaborate some more')}</label>
          <Select
            id={`${title}-trenger-utdyping`}
            value={time.value}
            onChange={(event) => changeTime(event.target.value)}
            style={{ marginBottom: 0 }}
          >
            <option value={undefined} disabled={time.value !== undefined}>
              {t('Choose from list...')}
            </option>
            {filterTimeOptions(timeOptions, visibleOptionGroup, chosenDate).map((option) => (
              <option key={option.offset} value={option.offset}>
                {/* TODO: translate */}
                {option.label}
              </option>
            ))}
          </Select>
        </ExtraOption>
      )}
    </Fragment>
  );
}

function findOptionGroup(list, value, wholeList) {
  const index = list.findIndex((item) => item.offset === value);

  if (index === -1) {
    const option = wholeList.find((item) => item.offset === value);

    return (option && option.optionGroup) || 'default';
  }

  if (index === 0) {
    return 'before';
  } else if (index === list.length - 1) {
    return 'after';
  }

  return 'default';
}

function selectedTimeValue(list, time) {
  const firstTime = list[0];
  const lastTime = list[list.length - 1];

  if (time < firstTime.offset) {
    return firstTime.offset;
  }

  if (time > lastTime.offset) {
    return lastTime.offset;
  }

  return time;
}

function filterTimeOptions(list, optionGroup, chosenDate) {
  const listFilteredByOptionGroup = list.filter((item) => item.optionGroup === optionGroup);
  return listFilteredByOptionGroup.filter((item) => isBeforeNow(chosenDate, item.offset));
}

function isBeforeNow(chosenDate, offset) {
  return isBefore(addMinutes(chosenDate, offset), new Date());
}

function closestNumber(array, num) {
  // https://stackoverflow.com/questions/8584902/get-closest-number-out-of-array
  return array.reduce(function (prev, curr) {
    return Math.abs(curr - num) < Math.abs(prev - num) ? curr : prev;
  });
}
