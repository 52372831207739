type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export type Result<T> = {
  success: boolean;
  data?: T;
  error?: string;
  httpStatus: number;
};

const _fetch =
  (method: RequestMethod) =>
  async <T extends any>({ url, body }: { url: string; body?: unknown }): Promise<Result<T>> => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-capable-session-id': localStorage.getItem('sessionId') || '',
    };

    try {
      const response = await fetch(url, {
        method,
        headers: headers,
        body: JSON.stringify(body),
        credentials: 'include',
      });

      let responseJson = undefined;
      try {
        // check for no content
        if (response.status !== 204) {
          try {
            responseJson = await response.json();
          } catch (e) {}
        }
      } catch (error) {
        console.log(error);
      }

      return {
        success: response.ok,
        data: responseJson,
        error: response.ok ? undefined : responseJson,
        httpStatus: response.status,
      };
    } catch (error) {
      console.error(error);

      return {
        success: false,
        data: undefined,
        error: (error as any)?.message,
        httpStatus: -1,
      };
    }
  };

export default {
  get: _fetch('GET'),
  post: _fetch('POST'),
  put: _fetch('PUT'),
  delete: _fetch('DELETE'),
};
