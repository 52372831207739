import React from 'react';
import { Cpl_Administreringsdetaljer } from '../../../types/gh-types';

export type TaskType = {
  antall: number;
  unit: string;
  timeOfDay: string;
};

export type presentTaskType = {
  pattern: string; // Pattern that the current display is shown as part of (one of Enkeltdoser, Dag, Uke, Måned)
  dayPresentation: string; // Presentaton of current day
  tasks: TaskType[]; // Tasks for the given (or any) day and to be displayed
  setTasks: (tasks: TaskType[], dayReference: number) => void; // Function to set given tasks for the given dayReference
  changeCurDay: (dayIncrement: number) => void; // Function to update the current (selected) day)
  copyToAllDays: (tasks: TaskType[], dayReference: number) => void; // Function to update the current tasks to all days
  dayReference: number; // current day for the tasks to display
  administreringsdetaljer: Cpl_Administreringsdetaljer | undefined; // administreringsdetaljer for the given drugdose
};

const rightarrow1 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.0}
      stroke="currentColor"
      className="h-5 w-5 text-blue-500"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
    </svg>
  );
};

const leftarrow1 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.0}
      stroke="currentColor"
      className="h-5 w-5 text-blue-500"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
    </svg>
  );
};

const duplicate1 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-4 w-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
      />
    </svg>
  );
};

/* ************************************************************************** 
Function that presents the list of tasks for the different dosage patterns
**************************************************************************  */

export const PresentTasks = ({
  pattern,
  dayPresentation,
  tasks,
  setTasks,
  changeCurDay,
  copyToAllDays,
  dayReference,
  administreringsdetaljer,
}: presentTaskType) => {
  const entallunit: string = administreringsdetaljer?.enhetForDosering ?? '';
  const flertallunit: string = administreringsdetaljer?.enhetForDosering_Plural ?? '';
  const maxAntall: number = 50;

  if (pattern === 'Enkeltdoser' && dayReference === -1) return <></>;

  const updateTask = (index: number, antall: number, timeofday: string): TaskType[] => {
    // at index =item, update the task to given params
    const taskcopy = [...tasks];
    const safeAntall = Math.abs(antall) < maxAntall ? Math.abs(antall) : maxAntall;

    taskcopy[index] = {
      antall: safeAntall,
      unit: antall === 1 ? entallunit : flertallunit,
      timeOfDay: timeofday,
    };
    return taskcopy;
  };

  const removeTask = (index: number) => {
    // remove task with index = index
    const taskcopy = [...tasks];
    taskcopy.splice(index, 1); //& returns the deleted element
    return taskcopy;
    // return tasks.toSpliced(index,1); // Fails in Azure
  };

  const addTasks = () => {
    if (tasks.length === 0) setTasks([...tasks, { antall: 1, unit: entallunit, timeOfDay: 'Morgen' }], dayReference);
    else if (tasks.length === 1 && tasks[0].timeOfDay === 'Morgen')
      setTasks([...tasks, { antall: 1, unit: entallunit, timeOfDay: 'Kveld' }], dayReference);
    else if (tasks.length === 2 && tasks[0].timeOfDay === 'Morgen' && tasks[1].timeOfDay === 'Kveld')
      //setTasks([...tasks.splice(1,0,{antall: 1, unit: entallunit, timeOfDay: "Midt på dagen"})], dayReference)
      setTasks(
        [...tasks.slice(0, 1), { antall: 1, unit: entallunit, timeOfDay: 'Midt på dagen' }, ...tasks.slice(1)],
        dayReference
      );
    else if (
      tasks.length === 3 &&
      tasks[0].timeOfDay === 'Morgen' &&
      tasks[1].timeOfDay === 'Midt på dagen' &&
      tasks[1].antall === 1 &&
      tasks[2].timeOfDay === 'Kveld'
    )
      setTasks(
        [
          ...tasks.slice(0, 1),
          { antall: 1, unit: entallunit, timeOfDay: 'Formiddag' },
          { antall: 1, unit: entallunit, timeOfDay: 'Ettermiddag' },
          ...tasks.slice(2),
        ],
        dayReference
      );
    else if (
      tasks.length === 4 &&
      tasks[0].timeOfDay === 'Morgen' &&
      tasks[1].timeOfDay === 'Formiddag' &&
      tasks[2].timeOfDay === 'Ettermiddag' &&
      tasks[3].timeOfDay === 'Kveld'
    )
      setTasks(
        [...tasks.slice(0, 2), { antall: 1, unit: entallunit, timeOfDay: 'Midt på dagen' }, ...tasks.slice(2)],
        dayReference
      );
    else setTasks([...tasks, { antall: 1, unit: entallunit, timeOfDay: 'Morgen' }], dayReference);
  };

  return (
    <div className="w-64 text-sm font-medium">
      <div className="flex flex-row justify-between">
        <div>Tidspunkt/dose</div>
        {dayReference !== -1 && (
          <div className="flex flex-row rounded-xl bg-blue-200 text-blue-500">
            <button onClick={() => changeCurDay(-1)}>{leftarrow1()}</button>
            <div className="px-1">{dayPresentation}</div>
            <button onClick={() => changeCurDay(1)}>{rightarrow1()}</button>
          </div>
        )}
      </div>
      <div className="rounded bg-gray-100">
        <div>
          {tasks.map((item, index) => (
            <div className="flex flex-row justify-between border-b-[1px] border-gray-300" key={index}>
              <button onClick={() => setTasks(removeTask(index), dayReference)} className="">
                ⛔
              </button>
              <select
                className=" bg-gray-100 text-blue-500 hover:underline"
                value={item.timeOfDay}
                onChange={(e) => setTasks(updateTask(index, item.antall, e.target.value), dayReference)}
                id="timeofday"
              >
                <option value="Ikke valgt tid">Ikke valgt tid</option>
                <option value="Morgen">Morgen</option>
                <option value="Formiddag">Formiddag</option>
                <option value="Midt på dagen">Midt på dagen</option>
                <option value="Ettermiddag">Ettermiddag</option>
                <option value="Kveld">Kveld</option>
              </select>
              {/*                     <select className=" bg-gray-100 text-blue-500 hover:underline" value={item.antall} onChange={(e) => setTasks(updateTask(index, Number(e.target.value), item.timeOfDay), dayReference)} id="antall">
                        <option value="0">0 {administreringsdetaljer?.enhetForDosering_Plural??""}</option>
                        {(administreringsdetaljer?.deling === "Delbar i 4") && <option value="0.25">0.25 {administreringsdetaljer?.enhetForDosering_Plural??""}</option>}
                        {(["Delbar i 2", "Delbar i 4"].includes(administreringsdetaljer?.deling??"")) && <option value="0.5">0.5 {administreringsdetaljer?.enhetForDosering_Plural??""}</option>}
                        {(administreringsdetaljer?.deling === "Delbar i 4") && <option value="0.75">0.75 {administreringsdetaljer?.enhetForDosering_Plural??""}</option>}
                        <option value="1">1 {administreringsdetaljer?.enhetForDosering??""}</option>
                        <option value="2">2 {administreringsdetaljer?.enhetForDosering_Plural??""}</option>
                        <option value="3">3 {administreringsdetaljer?.enhetForDosering_Plural??""}</option>
                        <option value="4">4 {administreringsdetaljer?.enhetForDosering_Plural??""}</option>
                        <option value="5">5 {administreringsdetaljer?.enhetForDosering_Plural??""}</option>
                    </select> */}

              <span>
                <input
                  className=" w-12 bg-gray-100 text-right text-blue-500 hover:underline"
                  value={item.antall}
                  onChange={(e) => setTasks(updateTask(index, Number(e.target.value), item.timeOfDay), dayReference)}
                  type="number"
                  min="0"
                  max="50"
                  step="1"
                  id="antall"
                ></input>
                {item.antall === 1
                  ? administreringsdetaljer?.enhetForDosering ?? ''
                  : administreringsdetaljer?.enhetForDosering_Plural ?? ''}
              </span>
              <span></span>
            </div>
          ))}
          <button onClick={() => addTasks()} className="text-blue-500 hover:underline">
            <span className="ml-1.5 pr-4 font-bold text-green-600 no-underline">+</span>
            Legg til ny tid og dose
          </button>
        </div>
      </div>
      {dayReference !== -1 && (
        <button
          onClick={() => copyToAllDays(tasks, dayReference)}
          className="mt-2 flex flex-row text-blue-500 hover:underline "
        >
          <span className="font-bold text-blue-600 ">{duplicate1()}</span>
          <div>{pattern === 'Enkeltdoser' ? 'Benytt på alle dagene' : 'Benytt på hele uken'}</div>
        </button>
      )}
    </div>
  );
};
