import React, { forwardRef } from 'react';

export type TextInputProps = React.ComponentPropsWithRef<'input'> & {
  label: string;
  sublabel?: string;
};

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const { label, sublabel, type, ...rest } = props;

  return (
    <label className="flex flex-col gap-1">
      <div className="break-normal text-sm font-semibold">{label}</div>
      <div className="break-normal text-xs font-normal">{sublabel}</div>
      <input type={type ?? 'text'} className="w-full rounded-md border border-gray-300 p-2" {...rest} ref={ref} />
    </label>
  );
});
