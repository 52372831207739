import React from 'react';
import settings from '../../settings';

function log(str) {
  const el = document.getElementById('logger');
  if (el) {
    el.value += str + '\n\n';
  }
}

async function readTextFile(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onload = function (evt) {
      const json = evt.target.result;
      resolve(json);
    };
    reader.onerror = function (evt) {
      log('Something went wrong when reading file: ' + file.name);
      reject('Something went wrong when reading file: ' + file.name);
    };
  });
}

async function upload(url, json, filename) {
  return new Promise((resolve, reject) => {
    const sessionId = localStorage.getItem('sessionId');

    console.log('url, filename, sessionId', url, filename, sessionId);
    console.log(json);

    log('Trying to POST ' + filename + ' to ' + url);
    fetch(url, {
      method: 'POST',
      body: json,
      headers: {
        'Content-Type': 'application/json',
        'x-capable-session-id': sessionId,
      },
    })
      .then((response) => {
        if (response.ok) {
          log('POST success: ' + filename);

          response.json().then((res) => {
            const el = document.getElementById('logger2');
            if (el) {
              el.value = JSON.stringify(res, null, 4);
            }
          });
        } else {
          log('POST failed: ' + filename + '. Response: ' + response.status + ': ' + response.statusText);

          try {
            response.json().then((res) => {
              const el = document.getElementById('logger2');
              if (el) {
                el.value = JSON.stringify(res, null, 4);
              }
            });
          } catch (error) {}
        }

        resolve();
      })
      .catch((error) => {
        log('POST failed: ' + filename + '. Error message: ' + error.message);
        resolve();
      });
  });
}

function getUrl(type) {
  switch (type) {
    case 'Patient':
      return `${settings.apiUrl}/Patient`;
    case 'MedicationRequest':
      return `${settings.apiUrl}/MedicationRequest`;
    case 'User':
      return `${settings.apiUrl}/User`;
    case 'Practitioner':
      return `${settings.apiUrl}/Practitioner`;
    default:
      log('No support for type: ' + type);
      throw Error('No support for type: ' + type);
  }
}

export class Upload extends React.Component {
  constructor(props) {
    super(props);

    this.selectRef = React.createRef();
    this.fileInputRef = React.createRef();
  }

  submit = async () => {
    const files = this.fileInputRef.current.files;
    const url = getUrl(this.selectRef.current.value);

    for (let i = 0; i < files.length; i++) {
      const json = await readTextFile(files[i]);
      await upload(url, json, files[i].name);
    }

    this.fileInputRef.current.value = '';
  };

  render() {
    return (
      <div>
        <select ref={this.selectRef}>
          <option value="Patient">Patient</option>
          <option value="MedicationRequest">Medication Request</option>
          <option value="User">User</option>
          <option value="Practitioner">Practitioner</option>
        </select>
        <br />
        <br />
        <input type="file" multiple="multiple" ref={this.fileInputRef} />
        <br />
        <br />
        <br />
        <button onClick={this.submit}>Submit</button>

        <br />
        <br />
        <br />

        <div>Log:</div>
        <textarea id="logger" style={{ width: '100%', height: 300 }}></textarea>

        <br />
        <div>Response:</div>
        <textarea id="logger2" style={{ width: '100%', height: 500 }}></textarea>
      </div>
    );
  }
}
