import settings from '../settings';
import { Fhir_Coding } from '../types/Fhir_Element';
import { RecursivePartial } from '../types/utils';
import { Cpl_DictionaryContents } from './../types/Cpl_DictionaryContents';
import { Fhir_Condition } from './../types/Fhir_Condition';
import Fetch from './fetch';

async function fetchAvailableMedicalConditions() {
  return Fetch.get<Cpl_DictionaryContents>({
    url: `${settings.apiUrl}/Condition/AvailableConditions`,
  });
}

export type Cpl_DictEntry = Pick<Fhir_Coding, 'system' | 'code' | 'display'>;

type CreateMedicalConditionParams = {
  patientId: string;
  type: Cpl_DictEntry;
};
function _buildCreateMedicalConditionBody(params: CreateMedicalConditionParams): RecursivePartial<Fhir_Condition> {
  return {
    subject: {
      reference: `Patient/${params.patientId}`,
    },
    clinicalStatus: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/condition-clinical',
          code: 'active',
        },
      ],
    },
    code: {
      coding: [params.type],
      text: params.type.display,
    },
  };
}

async function createMedicalCondition(params: CreateMedicalConditionParams) {
  const response = await Fetch.post<Fhir_Condition>({
    url: `${settings.apiUrl}/Condition`,
    body: _buildCreateMedicalConditionBody(params),
  });

  return response;
}

async function updateMedicalCondition(params: Fhir_Condition) {
  const response = await Fetch.put<Fhir_Condition>({
    url: `${settings.apiUrl}/Condition/Put/${params.id}`,
    body: params,
  });

  return response;
}

async function deleteMedicalCondition(conditionId: string) {
  const response = await Fetch.delete({
    url: `${settings.apiUrl}/Condition/${conditionId}`,
  });

  return response;
}

const MedicalConditionClient = {
  fetchAvailableMedicalConditions,
  createMedicalCondition,
  updateMedicalCondition,
  deleteMedicalCondition,
};

export default MedicalConditionClient;
