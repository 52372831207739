/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';

import settings from '../../settings';
import Fetch from '../../network/fetch';

function AddDataModal({ onClose }) {
  const [input, setInput] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const validateEvent = () => {
    try {
      JSON.parse(input);
      setError('');
    } catch (e) {
      setError(e.toString());
    }
  };

  const sendData = async () => {
    setLoading(true);
    const res = await Fetch.post({ url: `${settings.apiUrl}/EPrescription`, body: input });

    setLoading(false);

    if (res.ok) {
      setSuccess(true);
    } else {
      setSuccess(false);
      setError(res.json.message);
    }
  };

  return (
    <div
      css={css`
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.2);
      `}
    >
      <div
        css={css`
          margin: auto;
          margin-top: 5vh;
          width: 80vw;
          height: 80vh;
          border-radius: 8px;
          background-color: #fff;
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `}
      >
        <div>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <h1>Add data here</h1>
            <button onClick={onClose}>x</button>
          </div>
          <div style={{ height: 20 }} />
          <p>Data need to be in json format</p>
          <div style={{ height: 20 }} />
          <p>How to add data</p>
          <div style={{ height: 20 }} />
        </div>
        <textarea
          css={css`
            width: 100%;
            height: 100%;
            padding: 10px;
            border-radius: 5px;
          `}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onBlur={() => validateEvent()}
        />
        <div
          css={css`
            padding-top: 20px;
          `}
        >
          {error && (
            <p
              css={css`
                padding-bottom: 20px;
                color: #ff0000;
              `}
            >
              {error}
            </p>
          )}
          {loading && (
            <p
              css={css`
                padding-bottom: 20px;
              `}
            >
              Loading...
            </p>
          )}
          {success && (
            <p
              css={css`
                padding-bottom: 20px;
                color: #00ff00;
              `}
            >
              Success!
            </p>
          )}
          <div>
            <button disabled={error} onClick={sendData}>
              Send
            </button>
            <button onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddDataModal;
