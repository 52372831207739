import React from 'react';
import uuid from '../../utils/uuid';
import { IndexedNotification, Notification } from './types';
import { Notifications } from './Notifications';

type NotificationContextType = {
  addNotification: (notification: Notification) => void;
};

const NotificationContext = React.createContext<NotificationContextType>({ addNotification: () => {} });

export const useAddNotification = () => {
  const { addNotification } = React.useContext(NotificationContext);

  const addInfo = React.useCallback(
    (notification: Omit<Notification, 'type'>) => {
      addNotification({ ...notification, type: 'info' });
    },
    [addNotification]
  );

  const addWarn = React.useCallback(
    (notification: Omit<Notification, 'type'>) => {
      addNotification({ ...notification, type: 'warning' });
    },
    [addNotification]
  );

  const addError = React.useCallback(
    (notification: Omit<Notification, 'type'>) => {
      addNotification({ ...notification, type: 'error' });
    },
    [addNotification]
  );

  return {
    addInfo,
    addWarn,
    addError,
  };
};

export const NotificationContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [notifications, setNotifications] = React.useState<IndexedNotification[]>([]);

  const addNotification = React.useCallback((notification: Notification) => {
    setNotifications((notifications) => {
      const newNotification = { id: uuid(), ...notification, timeout: notification.timeout ?? 10000 };
      return [...notifications, newNotification];
    });
  }, []);

  const removeNotification = React.useCallback((notificationId) => {
    setNotifications((notifications) => {
      return notifications.filter((notification) => notification.id !== notificationId);
    });
  }, []);

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      {children}
      <Notifications notifications={notifications} onRemoveNotification={removeNotification} />
    </NotificationContext.Provider>
  );
};
