/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import Text from '../../components/Text';
import { useT } from '../../i18n/useT';
import formatDate from '../../utils/formatDate';
import { fromServerDate } from '../../utils/serverDateConverter';
import SleepObservationEdit from './SleepObservationEdit';

export default function SleepObservation({ patientId, chosenDate, observation, lastObservation, refetchSleepDiary }) {
  const t = useT();
  const [useLastObservation, setUseLastObservation] = useState(false);
  const [edit, setEdit] = useState(false);

  if (!observation && !edit) {
    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <Title>
          <span
            css={css`
              text-align: center;
            `}
          >
            {t('No observation registered for this day')}
          </span>
        </Title>

        <div style={{ height: 32 }}></div>
        <Text size="huge">{t('Register for this day')}</Text>
        <div style={{ height: 16 }}></div>

        <Button onClick={() => setEdit(true)}>{t('Empty fields')}</Button>

        {lastObservation && (
          <Button
            style={{ marginTop: 8 }}
            onClick={() => {
              setUseLastObservation(true);
              setEdit(true);
            }}
          >
            {t('Copy data from')}: {formatDate(fromServerDate(lastObservation.observationDate), 'dd-MM-yyyy')}
          </Button>
        )}
      </div>
    );
  }

  if (edit) {
    return (
      <SleepObservationEdit
        observation={observation ?? (useLastObservation ? lastObservation : undefined)}
        updateExisitingOberservation={Boolean(observation)}
        patientId={patientId}
        chosenDate={chosenDate}
        refetchSleepDiary={() => {
          setEdit(false);
          refetchSleepDiary();
        }}
        cancelEdit={() => setEdit(false)}
      />
    );
  } else {
    return (
      <div>
        <Button onClick={() => setEdit(true)}>{t('Edit')}</Button>
        <div style={{ height: 32 }}></div>
        <SleepObservationView observation={observation} />
      </div>
    );
  }
}

function SleepObservationView({ observation }) {
  const t = useT();

  return (
    <div>
      <Title>{t('How did you function yesterday?')}</Title>
      <Answer>{functionLevelToText(observation.functionLevel, t)}</Answer>

      {observation.functionLevel <= 2 && (
        <div>
          <Title>{t('What was it that was bad about your function yesterday?')}</Title>
          <Answer>{observation.functionalProblems || '-'}</Answer>
        </div>
      )}

      <Title>{t('Write down any activity you did yesterday to function better')}</Title>
      <Answer>{observation.functionalMeasures || '-'}</Answer>

      {false && (
        <div>
          <Title>
            {t('Did you take sleeping pills, consumed alcohol and/or illegal drugs 5 hours prior to getting to bed?')}
          </Title>
          <Answer>{observation.sleepingDrug ? observation.sleepingDrugs || t('Yes') : t('No')}</Answer>
        </div>
      )}

      {observation.sleepingDrugs && (
        <div>
          <Title>
            {t('What kind of sleep aids and/or other substances did you take 5 hours prior to getting to bed?')}
          </Title>
          <Answer>{observation.sleepingDrugs}</Answer>
        </div>
      )}

      <Title>{t('When did you get to bed?')}</Title>
      <Answer>{`Kl. ${formatDate(fromServerDate(observation.gotoBedTime), 'HH:mm')}`}</Answer>

      <Title>{t('When did you try to sleep?')}</Title>
      <Answer>{`Kl. ${formatDate(fromServerDate(observation.attemptToSleepTime), 'HH:mm')}`}</Answer>

      <Title>{t('How long time did it take you to fall asleep?')}</Title>
      <Answer>{t('{{value}} minutes', { value: observation.minutesToSleep })}</Answer>

      <Title>{t('How many times during the night did you wake up (not counting the final awakening)?')}</Title>
      <Answer>{t('{{value}} times', { value: observation.nofWakeupsDuringNight })}</Answer>

      <Title>{t('During the night, how much time was spent awake?')}</Title>
      <Answer>{t('{{value}} minutes', { value: observation.minutesAwakeTimeDuringNight })}</Answer>

      <Title>{t('When did you wake up in the morning without falling asleep again?')}</Title>
      <Answer>
        {`Kl. ${formatDate(fromServerDate(observation.finalWakupTime || observation.finalWakeupTime), 'HH:mm')}`}
      </Answer>

      <Title>{t('When did you get up from bed to start the day?')}</Title>
      <Answer>{`Kl. ${formatDate(fromServerDate(observation.outOfBedTime), 'HH:mm')}`}</Answer>

      <Title>
        {t('After the final awakening, how many minutes did you spent in bed trying to sleep before you got up?')}
      </Title>
      <Answer>{t('{{value}} minutes', { value: observation.minutesLatestAwakeTime })}</Answer>

      <Title>{t('Evaluate your sleep quality')}</Title>
      <Answer>{sleepQualityMeasureToText(observation.sleepQualityMeasure, t)}</Answer>

      <Title>{t('Comments on your sleep')}</Title>
      <Answer>{observation.sleepComments || '-'}</Answer>
    </div>
  );
}

function Title({ children }) {
  return (
    <div
      css={css`
        font-size: 18px;
        font-weight: 300;
      `}
    >
      {children}
    </div>
  );
}

function Answer({ children }) {
  return (
    <div
      css={css`
        margin-top: 8px;
        font-size: 22px;
        font-weight: 300;
        background-color: #e3e0ff;
        padding: 8px 16px;
        border-radius: 12px;
        margin-bottom: 32px;
      `}
    >
      {children}
    </div>
  );
}

// TRANSLATION TO-DO
function functionLevelToText(level, t) {
  switch (level) {
    case 1:
      return t('Very bad');
    case 2:
      return t('Bad');
    case 3:
      return t('Neither good or bad');
    case 4:
      return t('Good');
    case 5:
      return t('Very good');
    default:
      return t('No answer');
  }
}

// TRANSLATION TO-DO
function sleepQualityMeasureToText(level, t) {
  switch (level) {
    case 1:
      return t('Very bad');
    case 2:
      return t('Bad');
    case 3:
      return t('Fine');
    case 4:
      return t('Well');
    case 5:
      return t('Very well');
    default:
      return t('No answer');
  }
}

function Button(props) {
  return (
    <button
      css={css`
        background-color: lightgray;
        border: 1px solid gray;
        padding: 8px 16px;
        border-radius: 12px;
        font-size: 18px;
      `}
      {...props}
    />
  );
}
