/** @jsxImportSource @emotion/react */
import { Link, RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import { Column } from '../components/Flex';
import OpenPageLayout from '../components/pagelayout/OpenPageLayout';
import Text from '../components/Text';
import { useT } from '../i18n/useT';

export const NotFoundPage: FC<RouteComponentProps> = () => {
  const t = useT();
  return (
    <OpenPageLayout>
      <Column vertical="center" horizontal="center" height="100%">
        <Text size="huge" marginBottom={20}>
          {t('The page do not exist')}
        </Text>

        <Link to="/hjem">
          <Text>{t('Go to front page')}</Text>
        </Link>
      </Column>
    </OpenPageLayout>
  );
};
