/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { ReactNode } from 'react';
import { Column } from './Flex';
import readShortProps, { ShortProps } from './helpers/readShortProps';

type PageContentWidth = 'normal' | 'narrow';

const NORMAL_WIDTH = 1000;
const NARROW_WIDTH = 720;
const PADDING = 32;
const SMALL_SCREEN_PADDING = 16;
const NORMAL_WIDTH_WITH_PADDING = NORMAL_WIDTH + PADDING * 2;
const NARROW_WIDTH_WITH_PADDING = NARROW_WIDTH + PADDING * 2;

export type PageContentProps = {
  width?: PageContentWidth;
  className?: string;
  children?: ReactNode;
  grow?: boolean;
} & ShortProps;

/* Conform to the grid (ie. left and right page limits), should only be used inside things that are 100% page width */
const PageContent = ({ className, width = 'normal', grow, ...restBeforeShortProps }: PageContentProps) => {
  const [shortPropClass, rest] = readShortProps(restBeforeShortProps);
  const prettierFix = width === 'normal' ? `${NORMAL_WIDTH_WITH_PADDING - 1}px` : `${NARROW_WIDTH_WITH_PADDING - 1}px`;

  return (
    <Column
      grow={grow}
      css={[
        'page-content',
        css`
          width: ${width === 'normal' ? `${NORMAL_WIDTH_WITH_PADDING}px` : `${NARROW_WIDTH_WITH_PADDING}px`};
          margin-left: auto;
          margin-right: auto;
          padding-left: ${PADDING}px;
          padding-right: ${PADDING}px;
          position: relative;

          @media (max-width: ${prettierFix}) {
            width: 100%;
          }

          @media (max-width: 599px) {
            padding-left: ${SMALL_SCREEN_PADDING}px;
            padding-right: ${SMALL_SCREEN_PADDING}px;
          }
        `,
        shortPropClass,
      ]}
      className={className}
      {...rest}
    />
  );
};

export default React.memo(PageContent);
