/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link } from '@reach/router';
import { addDays, addYears, isAfter, isSameDay, isToday, startOfWeek } from 'date-fns';
import React from 'react';
import { Column, Row } from '../../components/Flex';
import Heading from '../../components/Heading';
import Loader from '../../components/loader/Loader';
import Text from '../../components/Text';
import { useT } from '../../i18n/useT';
import useSelectedPatientId from '../../lib/useSelectedPatientId';
import SleepDiaryClient from '../../network/SleepDiaryClient';
import useClientFetch from '../../network/useClientFetch';
import { SleepObservation } from '../../types/SleepObservation';
import formatDate from '../../utils/formatDate';
import { fromServerDate } from '../../utils/serverDateConverter';
import theme from '../../utils/theme';

const Day = ({ date, hasObservation, index }: { date: Date; hasObservation: boolean; index: number }) => {
  const selectedPatientId = useSelectedPatientId()!;
  const isFuture = isAfter(date, new Date());

  return (
    <div
      css={[
        css`
          height: 48px;
          border: 1px solid ${theme.colors.gray};
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          color: black;
          margin: 4px;
          padding: 0 8px;
          transition: background-color ${100 + index * 40}ms ease-in;
        `,
        isFuture &&
          css`
            background-color: #ddd;
          `,
        hasObservation &&
          css`
            background-color: ${theme.colors.lightBlue};
          `,
      ]}
    >
      {isFuture ? (
        <Text color="black">{formatDate(date, 'iiii')}</Text>
      ) : (
        <Link
          to={`/${selectedPatientId}/sovn/${formatDate(date, 'yyyy-MM-dd')}`}
          css={css`
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <Text color="black" strong={isToday(date)}>
            {formatDate(date, 'iiii')}
          </Text>
        </Link>
      )}
    </div>
  );
};

function getWeek(date: Date) {
  const monday = startOfWeek(date, { weekStartsOn: 1 });

  const week = [monday];
  for (let i = 1; i < 7; i++) {
    const day = addDays(monday, i);
    week.push(day);
  }

  return week;
}

const Week = ({ weekDate, sleepObervations }: { weekDate: Date; sleepObervations: SleepObservation[] }) => {
  const week = getWeek(weekDate);

  return (
    <Column
      css={css`
        @media screen and (max-width: 400px) {
          max-width: 400px;
        }
      `}
    >
      <Row wrap>
        {week.map((day, i) => {
          const hasObservation = sleepObervations.find((observation) =>
            isSameDay(fromServerDate(observation.observationDate), day)
          );
          return <Day date={day} key={i} hasObservation={Boolean(hasObservation)} index={i} />;
        })}
      </Row>
    </Column>
  );
};

const RegisterWeek = ({ patientId, weekDate }: { patientId: string; weekDate: Date }) => {
  const t = useT();
  const { data, loading, error } = useClientFetch<{ sleepObservations: SleepObservation[] }>(
    SleepDiaryClient.fetchSleepDiary,
    {
      args: [patientId],
    }
  );

  return (
    <Column>
      <Row horizontal="space-between" vertical="center">
        <Heading level={4}>{t('Register sleep')}</Heading>
        {loading && (
          <Loader
            css={css`
              margin: 0;
            `}
          />
        )}
      </Row>

      {loading ? (
        // loading show 1 week as disabled (just add one year)
        <Week weekDate={addYears(weekDate, 1)} sleepObervations={[]} />
      ) : error ? (
        <Text>{t('Something went wrong')}</Text>
      ) : (
        <Week weekDate={weekDate} sleepObervations={data?.sleepObservations ?? []} />
      )}
    </Column>
  );
};

export default RegisterWeek;
