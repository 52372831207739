/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { repeat } from 'lodash';
import React from 'react';
import readShortProps, { ShortProps } from './helpers/readShortProps';

export type GridProps = {
  as?: React.ElementType;
  columns?: number;
  mobileBreakpoint?: number;
  className?: string;
  children?: React.ReactNode;
} & ShortProps;

const Grid: React.FC<GridProps> = React.forwardRef(
  (
    { as: Component = 'div', className, columns = 1, mobileBreakpoint = 799, ...restBeforeShortProps }: GridProps,
    ref: React.Ref<{}>
  ) => {
    const [shortClass, rest] = readShortProps(restBeforeShortProps);

    return (
      <Component
        ref={ref}
        css={[
          css`
            display: grid;
            grid-template-columns: ${repeat('1fr ', columns)};
            grid-gap: 24px;

            @media (max-width: ${mobileBreakpoint}px) {
              grid-template-columns: 1fr;
            }
          `,
          shortClass,
        ]}
        className={className}
        {...rest}
      />
    );
  }
);

export default Grid;
