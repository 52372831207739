/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link, RouteComponentProps, useParams } from '@reach/router';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import Button from '../../components/Button2';
import { Datalist } from '../../components/Datalist';
import { Column, Row } from '../../components/Flex';
import Loader from '../../components/loader/Loader';
import { Modal, ModalTrigger } from '../../components/Modal';
import AuthedPageLayout from '../../components/pagelayout/AuthedPageLayout';
import Table from '../../components/Table';
import Text from '../../components/Text';
import { useT } from '../../i18n/useT';
import { useAddNotification } from '../../lib/notifications/NotificationContext';
import useSelectedPatientId from '../../lib/useSelectedPatientId';
import MedicationClient from '../../network/MedicationClient';
import { LogHistory, useQueryWithLogHistory } from '../../network/useQueryWithLogHistory';
import {
  Cpl_Administrasjonsvei,
  Cpl_Administreringsdetaljer,
  Cpl_Bruksomrade,
  Cpl_CourseOfTherapy,
  Cpl_Dosering,
  Cpl_Forholdsregel,
} from '../../types/Cpl_FEST';
import { Fhir_MedicationRequest } from '../../types/Fhir_MedicationRequest';
import { VersionedText } from '../../VersionedText';
import { CourseOfTherapySelector } from '../medications/CourseOfTherapySelector';
import { getMedicationInfoLink } from '../medications/medicationInfo';
import { useFetchDosering } from '../medications/useSearchMedicationApi';
import { HistorySelector } from './HistorySelector';
import './Medication.css';
import { getCourseOfTherapyType } from './utils';

const Medication = ({
  medicationRequest,
  logHistory,
  refetchMedications,
}: {
  medicationRequest: Fhir_MedicationRequest;
  logHistory: LogHistory;
  refetchMedications: () => void;
}) => {
  const t = useT();
  const selectedPatientId = useSelectedPatientId();

  const medicationName = medicationRequest?.medication?.code.coding?.[0].display;
  // const dosageInstruction = medicationRequest?.dosageInstruction?.[0]?.timing?.code?.coding?.[0]?.display;
  // const reason = medicationRequest?.reasonCode?.[0]?.coding?.[0]?.display;
  // const patientInstruction = medicationRequest?.dosageInstruction?.[0]?.patientInstruction;

  const dosage = medicationRequest.dosageInstruction?.[0];

  const legemiddelpakningVarenr = medicationRequest.medication?.identifier?.[0]?.value;
  const heading = legemiddelpakningVarenr ? (
    <a
      css={css`
        text-decoration: none;
        color: black;

        &:hover {
          text-decoration: underline;
        }
      `}
      href={getMedicationInfoLink(legemiddelpakningVarenr)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Row vertical="center">
        <h2 style={{ marginRight: 12 }}>{medicationName}</h2>
        <FaExternalLinkAlt size={16} color="black" />
      </Row>
    </a>
  ) : (
    <h2>{medicationName}</h2>
  );

  return (
    <div className="Medication">
      <Row horizontal="space-between">
        <Row vertical="center">
          <Link to={selectedPatientId ? `/${selectedPatientId}/medisiner` : '/hjem'}>{t('Back to medications')}</Link>
        </Row>
        <ModalTrigger
          button={({ openModal }) => {
            return logHistory.pointInTime ? null : <Button onClick={openModal}>{t('Edit')}</Button>;
          }}
          modal={({ closeModal }) => (
            <EditMedicationModal
              medicationRequest={medicationRequest}
              closeModal={closeModal}
              refetchMedications={refetchMedications}
            />
          )}
        />
      </Row>
      <div style={{ height: 16 }} />
      <Table>
        <thead>
          <tr>
            <td colSpan={2}>{heading}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('Packaging')}</td>
            <td>
              <Text>
                <VersionedText crudObject={medicationRequest?.dispenseRequest?.quantity}>
                  {medicationRequest?.dispenseRequest?.quantity?.value ?? '-'}
                </VersionedText>
                {/*                 <VersionedText crudObject={medicationRequest?.dispenseRequest?.quantity}>
                  {medicationRequest?.dispenseRequest?.quantity?.unit ?? ''}
                </VersionedText>
 */}{' '}
              </Text>
            </td>
          </tr>
          {/* <tr>
            <td>{t('Indication')}</td>
            <td>
              <Text>{reason}</Text>
            </td>
          </tr>
          <tr>
            <td>{t('Dosage')}</td>
            <td>
              <Text>{dosageInstruction}</Text>
            </td>
          </tr>
          <tr>
            <td>{t('Guidance')}</td>
            <td>
              <Text>{patientInstruction}</Text>
            </td>
          </tr> */}
          <tr>
            <td>{t('Active substance')}</td>
            <td>
              <VersionedText crudObject={medicationRequest?.medication?.code.coding?.[1]}>
                {medicationRequest?.medication?.code.coding?.[1]?.display ?? '-'}
              </VersionedText>
            </td>
          </tr>
          <tr>
            <td>{t('Dosage')}</td>
            <td>
              <VersionedText crudObject={dosage}>{dosage?.text ?? '-'}</VersionedText>
            </td>
          </tr>
          <tr>
            <td>{t('Reason for use')}</td>
            <td>
              <VersionedText crudObject={medicationRequest.reasonCode?.[0]}>
                {medicationRequest.reasonCode?.[0]?.translatedText ?? '-'}
              </VersionedText>
            </td>
          </tr>
          <tr>
            <td>{t('Caution')}</td>
            <td>
              <VersionedText crudObject={dosage?.additionalInstruction?.[0]}>
                {dosage?.additionalInstruction?.[0].translatedText ?? '-'}
              </VersionedText>
            </td>
          </tr>
          <tr>
            <td>{t('Route of administration')}</td>
            <td>
              <VersionedText crudObject={dosage?.route}>{dosage?.route?.translatedText ?? '-'}</VersionedText>
            </td>
          </tr>
        </tbody>
      </Table>
      <div style={{ height: 32 }} />

      <HistorySelector logHistory={logHistory} />
    </div>
  );
};

const Content = () => {
  const t = useT();
  const { id } = useParams();

  const [query, logHistory, refetch] = useQueryWithLogHistory(['medication', id], `/MedicationRequest/${id}`);
  const medicationRequest = query.data?.medicationRequests?.[0];

  if (query.isLoading) {
    return <Loader />;
  } else if (query.isError || !medicationRequest) {
    return <div>{t('Something went wrong')}</div>;
  } else {
    return (
      <>
        <Medication medicationRequest={medicationRequest} logHistory={logHistory} refetchMedications={refetch} />
        {query.isFetching && <Loader delayMs={0} />}
      </>
    );
  }
};

const EditMedicationModal: FC<{
  medicationRequest: Fhir_MedicationRequest;
  closeModal: () => void;
  refetchMedications: () => void;
}> = ({ medicationRequest, closeModal, refetchMedications }) => {
  const t = useT();

  const lekemiddelpakningsId = medicationRequest?.medication?.code.coding.find(
    (c) => c.system === 'http://ehelse.no/fhir/CodeSystem/FEST'
  )?.code;

  const medicationName = medicationRequest?.medication?.code.coding?.[0].display;
  const { data, isLoading } = useFetchDosering(lekemiddelpakningsId ?? '');

  return (
    <Modal title={medicationName ? `${t('Edit')} ${medicationName}` : t('Edit')} closeModal={closeModal}>
      {isLoading ? (
        <Loader />
      ) : (
        <EditMedicationModalWithData
          data={data}
          medicationRequest={medicationRequest}
          refetchMedications={refetchMedications}
          closeModal={closeModal}
        />
      )}
    </Modal>
  );
};

const EditMedicationModalWithData: FC<{
  medicationRequest: Fhir_MedicationRequest;
  data: {
    dosering: Cpl_Dosering[];
    bruksomrader: Cpl_Bruksomrade[];
    forholdsregler: Cpl_Forholdsregel[];
    administrasjonsveier: Cpl_Administrasjonsvei[];
    administrasjonsdetaljer: Cpl_Administreringsdetaljer[];
  };
  refetchMedications: () => void;
  closeModal: () => void;
}> = ({ medicationRequest, data, refetchMedications, closeModal }) => {
  const { addError, addInfo } = useAddNotification();

  const t = useT();

  const [doseringString, setDoseringString] = useState<string>(medicationRequest.dosageInstruction?.[0]?.text ?? '');
  const dosering = useMemo(
    () =>
      data.dosering.find((dosering) => dosering.doseringLang === doseringString) ?? {
        code7478_Dosering: '' as const,
        doseringLang: doseringString,
      },
    [data.dosering, doseringString]
  );

  const [bruksomraderString, setBruksomraderString] = useState<string>(
    medicationRequest.reasonCode?.[0]?.translatedText ?? ''
  );
  const bruksomrade = useMemo(
    () =>
      data.bruksomrader.find((bruksomrade) => bruksomrade.bruksomrade === bruksomraderString) ?? {
        id: '' as const,
        bruksomrade: bruksomraderString,
      },
    [bruksomraderString, data.bruksomrader]
  );

  const [forholdsreglerString, setForholdsreglerString] = useState<string>(
    medicationRequest.dosageInstruction?.[0]?.additionalInstruction?.[0]?.translatedText ?? ''
  );
  const forholdsregel = useMemo(
    () =>
      data.forholdsregler.find((forholdsregel) => forholdsregel.forholdsregel === forholdsreglerString) ?? {
        id: '' as const,
        forholdsregel: forholdsreglerString,
      },
    [data.forholdsregler, forholdsreglerString]
  );

  const [administrasjonsveiString, setAdministrasjonsveiString] = useState<string>(
    medicationRequest.dosageInstruction?.[0]?.route?.translatedText ?? ''
  );
  const administrasjonsvei = useMemo(
    () =>
      data.administrasjonsveier.find(
        (administrasjonsvei) => administrasjonsvei.administrasjonsvei === administrasjonsveiString
      ) ?? { id: '' as const, administrasjonsvei: administrasjonsveiString },
    [administrasjonsveiString, data.administrasjonsveier]
  );

  const [courseOfTherapyType, setCourseOfTherapyType] = useState<Cpl_CourseOfTherapy['courseOfTherapyType']>(
    getCourseOfTherapyType(medicationRequest)
  );

  const onSubmit = useCallback(async () => {
    try {
      const result = await MedicationClient.updateMedicationRequest(medicationRequest.id, {
        dosering,
        bruksomrade,
        forholdsregel,
        administrasjonsvei,
        courseOfTherapy: { courseOfTherapyType },
      });

      if (result.success) {
        addInfo({ message: t('Medication updated') });
        refetchMedications();
        closeModal();
      } else {
        addError({ message: (result.error as any)?.resultMessage ?? 'Ukjent feil' });
      }
    } catch (error) {
      addError({ message: t('Something went wrong, please try again.') });
    }
  }, [
    medicationRequest.id,
    dosering,
    bruksomrade,
    forholdsregel,
    administrasjonsvei,
    courseOfTherapyType,
    addInfo,
    refetchMedications,
    closeModal,
    addError,
    t,
  ]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
    >
      <Column>
        {/* <Text>{selctedLegemiddelpakning.navnFormStyrke}</Text>
        {selctedLegemiddelpakning.produsent && <Text>{selctedLegemiddelpakning.produsent}</Text>}
        <Text>{selctedLegemiddelpakning.atC_DN}</Text> */}

        <Datalist
          label={t('Dosage')}
          value={doseringString}
          onValue={(val) => setDoseringString(val)}
          options={data.dosering.map((d) => d.doseringLang)}
        />
        <Datalist
          label={t('Reason for use')}
          value={bruksomraderString}
          onValue={(val) => setBruksomraderString(val)}
          options={data.bruksomrader.map((d) => d.bruksomrade)}
        />
        <Datalist
          label={t('Caution')}
          value={forholdsreglerString}
          onValue={(val) => setForholdsreglerString(val)}
          options={data.forholdsregler.map((d) => d.forholdsregel)}
        />
        <Datalist
          label={t('Route of administration')}
          value={administrasjonsveiString}
          onValue={(val) => setAdministrasjonsveiString(val)}
          options={data.administrasjonsveier.map((d) => d.administrasjonsvei)}
        />
        <CourseOfTherapySelector value={courseOfTherapyType} onValue={(val) => setCourseOfTherapyType(val)} />
      </Column>

      <Row
        horizontal="flex-end"
        css={css`
          margin-top: 20px;
        `}
      >
        <Button type="submit">{t('Save')}</Button>
      </Row>
    </form>
  );
};

export const MedicationPage: FC<RouteComponentProps<{ id?: string }>> = () => {
  const t = useT();

  return (
    <AuthedPageLayout documentTitle={t('Capable - Medications')}>
      <Content />
    </AuthedPageLayout>
  );
};
