import { parseISO } from 'date-fns';

/**
 * Date strings from API doesn't contain timezone information.
 * `fromServerDate` creates an Date object with the correct values from
 * the server date string.
 *
 * `parseISO` works because it assumes local timezone, which is correct.
 *
 * Format from server: YYYY-MM-DDTHH:MM[:SS[.mmm]]
 */
export function fromServerDate(serverDateString: string) {
  return parseISO(serverDateString);
}

/**
 * Date strings sent to API doesn't allow timezone information.
 * `toServerDate` creates a date string that ignore timezones.
 *
 * Format to server: YYYY-MM-DDTHH:MM:SS.mmm
 */
export function toServerDate(date: Date) {
  if (isNaN(date.getTime())) {
    return undefined;
  }
  const tzoffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
  return new Date(date.getTime() - tzoffset).toISOString().slice(0, -1);
}
