import React from 'react';

export default function Gear({ color = '#ffffff', size = 50 }) {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 1000 1000"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
    >
      <path
        d="M547.356,2.248C515.857,-0.749 484.143,-0.749 452.644,2.248L436.994,126.105C413.332,130.093 390.103,136.317 367.618,144.695L292.136,45.256C263.358,58.41 235.893,74.267 210.112,92.612L258.488,207.701C239.99,222.985 222.985,239.99 207.701,258.488L92.612,210.112C74.267,235.893 58.41,263.358 45.256,292.136L144.695,367.618C136.317,390.103 130.093,413.332 126.105,436.994L2.248,452.644C-0.749,484.143 -0.749,515.857 2.248,547.356L126.105,563.006C130.093,586.668 136.317,609.897 144.695,632.382L45.256,707.864C58.41,736.642 74.267,764.107 92.612,789.888L207.701,741.512C222.985,760.01 239.99,777.015 258.488,792.299L210.112,907.388C235.893,925.733 263.358,941.59 292.136,954.744L367.618,855.305C390.103,863.683 413.332,869.907 436.994,873.895L452.644,997.752C484.143,1000.75 515.857,1000.75 547.356,997.752L563.006,873.895C586.668,869.907 609.897,863.683 632.382,855.305L707.864,954.744C736.642,941.59 764.107,925.733 789.888,907.388L741.512,792.299C760.01,777.015 777.015,760.01 792.299,741.512L907.388,789.888C925.733,764.107 941.59,736.642 954.744,707.864L855.305,632.382C863.683,609.897 869.907,586.668 873.895,563.006L997.752,547.356C1000.75,515.857 1000.75,484.143 997.752,452.644L873.895,436.994C869.907,413.332 863.683,390.103 855.305,367.618L954.744,292.136C941.59,263.358 925.733,235.893 907.388,210.112L792.299,258.488C777.015,239.99 760.01,222.985 741.512,207.701L789.888,92.612C764.107,74.267 736.642,58.41 707.864,45.256L632.382,144.695C609.897,136.317 586.668,130.093 563.006,126.105L547.356,2.248ZM500,240.327C643.317,240.327 759.673,356.683 759.673,500C759.673,643.317 643.317,759.673 500,759.673C356.683,759.673 240.327,643.317 240.327,500C240.327,356.683 356.683,240.327 500,240.327Z"
        style={{ fill: color }}
      />
      <g transform="matrix(1.52624,0,0,1.52624,-448.008,-139.443)">
        <path
          d="M621.141,262.229C707.647,262.229 777.879,332.461 777.879,418.967C777.879,505.473 707.647,575.705 621.141,575.705C534.635,575.705 464.403,505.473 464.403,418.967C464.403,332.461 534.635,262.229 621.141,262.229ZM621.141,301.414C686.021,301.414 738.694,354.088 738.694,418.967C738.694,483.847 686.021,536.52 621.141,536.52C556.262,536.52 503.588,483.847 503.588,418.967C503.588,354.088 556.262,301.414 621.141,301.414Z"
          style={{ fill: color }}
        />
      </g>
    </svg>
  );
}
