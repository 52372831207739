/** @jsxImportSource @emotion/react */
import { Link, RouteComponentProps } from '@reach/router';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loader from '../../components/loader/Loader';
import { Modal, ModalTrigger } from '../../components/Modal';
import { useT } from '../../i18n/useT';
import { Fhir_Condition } from '../../types/Fhir_Condition';
import { api, toastAndThrowError } from '../../network/api';
import { Cpl_Decision, Cpl_ProblemDecisionRelation } from '../../types/Cpl_Decision';
import { NotTranslated, RecursivePartial } from '../../types/utils';
import {
  getDateControlFormattedDate,
  getDateFromDateControlFormattedDate,
} from '../medications/prescriptions/convertDosering';
import { Select } from '../../components/Select2';
import { TextInput } from '../../components/TextInput2';
import { Button } from '../../components/Button3';
import { fromServerDate, toServerDate } from '../../utils/serverDateConverter';
import problemDecisionRelationClient from '../../network/ProblemDecisionClient';
import prescriptionClient from '../../network/prescriptionClient';
import MedicalConditionClient from '../../network/MedicalConditionClient';

export const EditMedicalConditionModal = ({
  patientId: selectedPatientId,
  medicalCondition,
  relations, // current relations for this medicalCondition
  closeModal,
  refetchCondition,
}: {
  patientId: string;
  medicalCondition: Fhir_Condition;
  relations: Cpl_ProblemDecisionRelation[];
  closeModal: () => void;
  refetchCondition: () => void;
}) => {
  const { i18n } = useTranslation();
  const t = useT();

  const [startDate, setStartDate] = useState<Date | undefined>(
    fromServerDate(medicalCondition?.onSetDateTime ?? '1900-01-01T00:00:00:000')
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    fromServerDate(medicalCondition?.abatementDateTime ?? '3000-01-01T00:00:00:000')
  );
  const [source, setSource] = useState<string>('');
  const [addedDecisions, setAddedDecisions] = useState<string[]>(relations?.map((i) => i.decisionId) ?? []);
  const [selectedDecision, setSelectedDecision] = useState<Cpl_Decision | undefined>(undefined);

  const medicationDecisionsQuery = useQuery({
    queryKey: ['/Decision/GetByPatient', selectedPatientId],
    //    queryFn: () => api.get<{ decisions: Cpl_Decision[] }>(`/Decision/GetByPatient/${selectedPatientId}?metaInfo=true`),
    queryFn: () => prescriptionClient.getPrescriptionsWithMeta(selectedPatientId),
  });

  const decisions = medicationDecisionsQuery.data?.data.decisions ?? [];
  const medicalprescriptions = decisions.filter((i) => i.category === '' || i.category === 'Cpl_Decision_Medication'); // ZZZ To leave out assessment documents
  const savedDecisionIds = relations?.map((i) => i.decisionId) ?? [];

  const relationIdsToDelete = (): string[] => {
    // In savedDecisionIds but not in addedDecisions
    const retDecisionIds = savedDecisionIds.filter((i) => !addedDecisions.includes(i));
    const retRelationIds = relations.filter((i) => retDecisionIds.some((decId) => i.decisionId === decId));
    return retRelationIds?.map((i) => i.id);
  };

  const decisionIdsToAdd = (): string[] => {
    // In addedDecisions but not in savedDecisionIds
    const retDecisionIds = addedDecisions.filter((i) => !savedDecisionIds.includes(i));
    return retDecisionIds;
  };

  const queryClient = useQueryClient();

  const editConditionMutation = useMutation({
    mutationFn: (data: Fhir_Condition) =>
      //      api
      //        .put(`/Condition/Put/${data.id}`, data)
      MedicalConditionClient.updateMedicalCondition(data)
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: [`MedicalCondition`, data.id, true],
          });
          //.then(() => {
          //  queryClient.invalidateQueries({
          //    queryKey: ['/Condition/GetAll/Patient'],
          //  });
          closeModal();
          //});
        })
        .catch(toastAndThrowError),
  });

  const updateAllRelations = (patientId: string) => {
    relationIdsToDelete()?.forEach((i) => deleteRelationMutation.mutate({ relationId: i, patientId }));
    decisionIdsToAdd()?.forEach((i) =>
      addNewRelationMutation.mutate({
        data: {
          patientId: patientId,
          problemType: '',
          problemId: medicalCondition.id,
          decisionId: i,
          relation: '',
        },
        patientId: patientId,
      })
    );
  };

  const deleteSingleRelation = async (relationId: string, patientId: string) => {
    await problemDecisionRelationClient.deleteProblemDecisionRelation(relationId).then(() =>
      queryClient.invalidateQueries({
        queryKey: ['getProblemDecisionRelations', patientId],
      })
    );
  };

  const deleteRelationMutation = useMutation({
    mutationFn: ({ relationId, patientId }: { relationId: string; patientId: string }) =>
      problemDecisionRelationClient
        .deleteProblemDecisionRelation(relationId)
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: ['getProblemDecisionRelations', patientId],
          });
        })
        .catch(toastAndThrowError),
  });

  const addNewRelationMutation = useMutation({
    mutationFn: ({ data, patientId }: { data: RecursivePartial<Cpl_ProblemDecisionRelation>; patientId: string }) =>
      problemDecisionRelationClient
        .createProblemDecisionRelation(data)
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: ['getProblemDecisionRelations', patientId],
          });
        })
        .catch(toastAndThrowError),
  });

  const conditionChanged = (): boolean => {
    return !(
      medicalCondition?.onSetDateTime === toServerDate(startDate ?? new Date(1900, 0, 1)) &&
      medicalCondition?.abatementDateTime === toServerDate(endDate ?? new Date(3000, 0, 1))
    );
  };

  const relationsChanged = (): boolean => {
    return relationIdsToDelete().length > 0 || decisionIdsToAdd().length > 0;
  };

  const onSubmit = async () => {
    if (!conditionChanged() && !relationsChanged()) {
      return;
    }
    if (conditionChanged()) {
      const newValue = {
        ...medicalCondition,
        onSetDateTime: startDate ? toServerDate(startDate) : '1900-01-01T00:00:00.000',
        abatementDateTime: endDate ? toServerDate(endDate) : '3000-01-01T00:00:00.000',
      };
      editConditionMutation.mutate(newValue);
    }
    if (relationsChanged()) {
      updateAllRelations(selectedPatientId);
    }
  };

  const onStartDateChange = (dt: string) => {
    const input: Date | undefined = getDateFromDateControlFormattedDate(dt);
    setStartDate(input);
  };

  const onEndDateChange = (dt: string) => {
    const input: Date | undefined = getDateFromDateControlFormattedDate(dt);
    setEndDate(input);
  };

  const onAddDecisionToList = (id: string) => {
    setAddedDecisions([...addedDecisions, id]);
    const selectedDec = medicalprescriptions.find((i) => i.id === id);
    setSelectedDecision(selectedDec);
  };

  const onRemoveDecision = (id: string): void => {
    const ix = addedDecisions.findIndex((i) => i === id);
    addedDecisions.splice(ix, 1);
    if (ix > -1) setAddedDecisions([...addedDecisions]);
  };

  return (
    <Modal title={t('Edit medical condition')} closeModal={closeModal}>
      <div className="text-2xl pb-4 text-indigo-700">{medicalCondition?.code?.translatedText}</div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-row justify-start">
          <label className="break-normal pr-2 text-sm font-semibold">
            {t('Onset')}
            <input
              className="w-full rounded-md border border-gray-300 p-2 text-base font-normal"
              value={getDateControlFormattedDate(startDate)}
              onChange={(e) => onStartDateChange(e.target.value)}
              type="date"
            ></input>
          </label>
          <label className="break-normal text-sm font-semibold">
            {t('End date')}
            <input
              className="w-full rounded-md border border-gray-300 p-2 text-base font-normal"
              value={getDateControlFormattedDate(endDate)}
              onChange={(e) => onEndDateChange(e.target.value)}
              type="date"
            ></input>
          </label>
        </div>
        <TextInput label={t('Source')} value={source} onChange={(e) => setSource(e.target.value)} />
        <div className="break-normal pr-2 text-sm font-semibold">
          {t('Drug treatment')}

          <div className="text-base font-normal">
            {medicalprescriptions
              ?.filter((item) => addedDecisions.includes(item.id!))
              .map((i) => (
                <div key={i.id} className="flex flex-row justify-start">
                  <button className="" onClick={() => onRemoveDecision(i.id ?? '0')}>
                    ⛔
                  </button>
                  <div>{i.components?.[0]?.componentMedication?.legemiddeldose?.navnFormStyrke}</div>
                </div>
              ))}
          </div>
          <Select
            label=""
            value={selectedDecision?.decisionMedication?.medicationDisplayValue}
            onChange={(e) => onAddDecisionToList(e.target.value)}
          >
            <option key="" value="">
              Select
            </option>
            {medicalprescriptions
              .filter((item) => !addedDecisions.includes(item.id!))
              .map((item) => (
                <option key={item.id ?? '0'} value={item.id ?? '0'}>
                  {item.components?.[0]?.componentMedication?.legemiddeldose?.navnFormStyrke}
                </option>
              ))}
          </Select>
        </div>
        <div className="flex items-start justify-end gap-4">
          <Button onClick={closeModal} variant="secondary">
            {t('Cancel')}
          </Button>
          <Button
            onClick={onSubmit}
            disabled={false}
            loading={editConditionMutation.isLoading || addNewRelationMutation.isLoading}
          >
            {t('Save')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
