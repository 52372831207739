import { Link, RouteComponentProps, useParams } from '@reach/router';
import React, { FC } from 'react';
import AuthedPageLayout from '../../components/pagelayout/AuthedPageLayout';
import Loader from '../../components/loader/Loader';
import useSelectedPatientId from '../../lib/useSelectedPatientId';
import { useT } from '../../i18n/useT';
import { Fhir_Condition } from '../../types/Fhir_Condition';
import { Cpl_Decision, Cpl_ProblemDecisionRelation } from '../../types/Cpl_Decision';
import { useQuery } from 'react-query';
import { api } from '../../network/api';
import { NotTranslated } from '../../types/utils';
import ObjectContainer from '../../types/ObjectContainer';
import { fromServerDate } from '../../utils/serverDateConverter';
import { getSimpleFormattedDate2 } from '../medications/prescriptions/convertDosering';
import { Cpl_DiagnosisInfoLink } from '../../types/Cpl_DiagnosisInfoLink';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Modal, ModalTrigger } from '../../components/Modal';
import { Button } from '../../components/Button3';
import { EditMedicalConditionModal } from '../medical-conditions/EditMedicalCondition';
import problemDecisionRelationClient from '../../network/ProblemDecisionClient';

const serverDate = (inDate: string | undefined) => {
  if (inDate === undefined) return undefined;
  const dt = fromServerDate(inDate);
  if (dt.getTime() === new Date(1900, 0, 1).getTime() || dt.getTime() === new Date(3000, 0, 1).getTime())
    return undefined;
  else return dt;
};

const MedicalCondition = ({
  condition: curCondition,
  relations,
  decisions,
  url,
  refetchConditions,
}: {
  condition: Fhir_Condition;
  relations: Cpl_ProblemDecisionRelation[];
  decisions: Cpl_Decision[];
  url: string | undefined;
  refetchConditions: () => void;
}) => {
  const presentDecision = (decisionId: string) => {
    return (
      decisions.find((i) => i.id === decisionId)?.components?.[0]?.componentMedication?.legemiddeldose
        ?.navnFormStyrke ?? decisionId
    );
  };

  const t = useT();
  const selectedPatientId = useSelectedPatientId();

  const heading = curCondition?.code?.coding?.[0]?.display ?? '-';

  const externalLink = url ? (
    <a className="hover:underline text-black w-full" href={url} target="_blank" rel="noopener noreferrer">
      <div className="flex flex-row justify-start items-center">
        <div className="pr-2">{url}</div>
        <FaExternalLinkAlt size={16} color="black" />
      </div>
    </a>
  ) : (
    <div>{t('No external link')}</div>
  );

  return (
    <>
      <Link
        className="py-4 hover:underline"
        to={selectedPatientId ? `/${selectedPatientId}/medical-conditions` : '/hjem'}
      >
        {t('Back to medical conditions')}
      </Link>
      <div className="p-4 flex flex-col justify-normal rounded-lg drop-shadow-lg gap-4 bg-white">
        <div className="text-indigo-700 text-xl">{heading}</div>
        <div className="">
          <div className="py-4 flex flex-row">
            <div className="basis-1/2">{t('Condition')}</div>
            <div className="basis-1/2">{heading ?? '-'}</div>
          </div>
          <div className="py-4 flex flex-row">
            <div className="basis-1/2">{t('Code')}</div>
            <div className="basis-1/2">{curCondition?.code?.coding?.[0]?.code ?? '-'}</div>
          </div>
          <div className="py-4 flex flex-row ">
            <div className="basis-1/2">{t('Onset')}</div>
            <div className="basis-1/2">
              {getSimpleFormattedDate2({ dt: serverDate(curCondition?.onSetDateTime), unsetPlaceholder: '-' })}
            </div>
          </div>
          <div className="py-4 flex flex-row ">
            <div className="basis-1/2">{t('End date')}</div>
            <div className="basis-1/2">
              {getSimpleFormattedDate2({ dt: serverDate(curCondition?.abatementDateTime), unsetPlaceholder: '-' })}
            </div>
          </div>
          <div className="py-4 flex flex-row ">
            <div className="basis-1/2">{t('Source')}</div>
            <div className="basis-1/2">{curCondition?.asserter?.display ?? '-'}</div>
          </div>
          <div className="py-4 flex flex-row ">
            <div className="basis-1/2">{t('Additional info')}</div>
            <div className="basis-1/2">{externalLink}</div>
          </div>

          <div className="py-4 flex flex-row ">
            <div className="basis-1/2">{t('Drug treatment')}</div>
            <div className="basis-1/2 flex flex-col">
              {relations.map((i) => (
                <div key={i.decisionId}>{presentDecision(i.decisionId)}</div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Content = () => {
  const t = useT();
  const { patientId, id } = useParams();

  const conditionQuery = useQuery({
    queryKey: ['MedicalCondition', id, true],
    queryFn: () => api.get<ObjectContainer>(`/Condition/GetById/${id}`, { params: { metaInfo: true } }),
  });

  const diagnosisInfoQuery = useQuery({
    queryKey: ['diagnosisInfo'],
    queryFn: () => api.get<Cpl_DiagnosisInfoLink[]>(`/Condition/GetDiagnosisInfoLinks`),
  });

  const relationQuery = useQuery({
    queryKey: ['getProblemDecisionRelations', patientId, true],
    queryFn: () =>
      //api.get<ObjectContainer>(`/ProblemDecisionRelation/GetByPatient/${patientId}`, { params: { metaInfo: true } }),
      problemDecisionRelationClient.getProblemDecisionRelationsWithMeta(patientId),
  });

  const decisionQuery = useQuery({
    queryKey: ['/api/Decision/GetByPatient', patientId],
    queryFn: () => api.get<Cpl_Decision[]>(`/Decision/GetByPatient/${patientId}`),
  });

  const relations: Cpl_ProblemDecisionRelation[] = relationQuery.data?.data?.problemDecisionRelations ?? [];
  const curRelations = relations.filter((i) => i.problemId === id);

  const decisions = decisionQuery.data?.data ?? [];

  const condition = conditionQuery.data?.data.conditions?.[0];
  const diagnosisInfoList = diagnosisInfoQuery.data?.data;
  const curDiagnosisInfo =
    condition && diagnosisInfoList
      ? diagnosisInfoList?.find((item) => item.diagnosisCode === condition?.code?.coding?.[0]?.code)
      : undefined;

  if (conditionQuery.isLoading) {
    return <Loader />;
  } else if (conditionQuery.isError || !condition) {
    return <div>{t('Something went wrong')}</div>;
  } else {
    return (
      <>
        <MedicalCondition
          condition={condition}
          relations={curRelations}
          decisions={decisions}
          url={curDiagnosisInfo?.diagnosisInfoLink}
          refetchConditions={conditionQuery.refetch}
        />
        {conditionQuery.isFetching && <Loader delayMs={0} />}

        <ModalTrigger
          button={({ openModal }) => (
            <div className="pt-4">
              <Button onClick={openModal}>{t('Edit medical condition')}</Button>
            </div>
          )}
          modal={({ closeModal }) => (
            <EditMedicalConditionModal
              patientId={patientId}
              medicalCondition={condition}
              relations={curRelations}
              closeModal={closeModal}
              refetchCondition={conditionQuery.refetch}
            />
          )}
        />
      </>
    );
  }
};

export const MedicalConditionPage: FC<RouteComponentProps<{ id?: string }>> = () => {
  const t = useT();

  return (
    <AuthedPageLayout documentTitle={t('Capable - MedicalCondition')}>
      <Content />
    </AuthedPageLayout>
  );
};
