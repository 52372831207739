import React, { FC } from 'react';
import AuthedPageLayout from '../../components/pagelayout/AuthedPageLayout';
import useSelectedPatientId from '../../lib/useSelectedPatientId';
import { useT } from '../../i18n/useT';
import { SleepDiary } from './SleepDiary';
import { RouteComponentProps } from '@reach/router';

export const SleepDiaryPage: FC<RouteComponentProps> = () => {
  const t = useT();
  const selectedPatientId = useSelectedPatientId();

  return (
    <AuthedPageLayout documentTitle={t('Capable - Sleep')}>
      <SleepDiary selectedPatientId={selectedPatientId} />
    </AuthedPageLayout>
  );
};
