import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { setDateFormatLocale } from '../utils/formatDate';
import { getSelectedLanguage, Language, setSelectedLanguage, supportedLanguages } from './languageStorage';
import resources from './resources.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getSelectedLanguage(),
    supportedLngs: supportedLanguages,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export const setLanguage = (language: Language) => {
  setDateFormatLocale(language);
  i18n.changeLanguage(language);
  setSelectedLanguage(language);
  const html = document.getElementsByTagName('html')[0];
  html?.setAttribute('lang', language);
};

setLanguage(getSelectedLanguage());

export default i18n;
