/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import theme from '../utils/theme';
import readShortProps, { ShortProps } from './helpers/readShortProps';
import { getTextStyle } from './Text';

const Textarea = ({
  value,
  onValue,
  className,
  placeholder,
  ...restBeforeShortProps
}: { value: string; onValue: (value: string) => void } & ShortProps & React.ComponentPropsWithoutRef<'textarea'>) => {
  const [shortPropClass, rest] = readShortProps(restBeforeShortProps);

  return (
    <>
      {placeholder && (
        <label
          htmlFor={placeholder || ''}
          css={css`
            display: none;
          `}
        >
          {placeholder}
        </label>
      )}
      <textarea
        value={value}
        onChange={(event) => onValue(event.target.value)}
        id={placeholder || ''}
        css={[
          getTextStyle('normal'),
          css`
            appearance: none;
            border: 1px solid ${theme.colors.gray};
            padding: 8px;
            border-radius: 4px;

            &:disabled {
              background-color: ${theme.colors.grayBackground};
              color: ${theme.colors.gray};
            }

            &:focus {
              color: initial;
              border: 1px solid ${theme.colors.blue};
            }
          `,
          shortPropClass,
        ]}
        className={className}
        placeholder={placeholder || ''}
        {...rest}
      />
    </>
  );
};

export default Textarea;
