/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import React from 'react';
import readShortProps, { ShortProps } from './helpers/readShortProps';
import { buttonReset } from '../utils/styles';
import theme from '../utils/theme';
import ScreenReaderOnly from './ScreenreaderOnly';
import { lighten } from 'polished';

const isSmallDevice = 'max-width: 799px';
const isLargeDevice = 'min-width: 800px';

export type ButtonSize = 'small' | 'normal' | 'large';
export type ButtonVariant = 'blue';

export type BaseButtonProps = {
  size?: ButtonSize;
} & React.ComponentPropsWithRef<'button'> &
  ShortProps;

export const styleBySize = {
  small: css`
    height: 32px;
    padding: 0 12px;
    font-size: 14px;
  `,
  normal: css`
    height: 40px;
    padding: 0 16px;
    font-size: 16px;
  `,
  large: css`
    @media (${isLargeDevice}) {
      height: 48px;
      padding: 0 18px;
      font-size: 18px;
    }

    @media (${isSmallDevice}) {
      /* Medium on small screens */
      height: 40px;
      padding: 0 16px;
      font-size: 16px;
    }
  `,
};

const BaseButton = ({ size = 'normal', className, ...restBeforeShortProps }: BaseButtonProps) => {
  const [shortPropClass, rest] = readShortProps(restBeforeShortProps);

  return (
    <button
      css={[
        buttonReset,
        css`
          border-radius: 4px;
          font-weight: 500;
          letter-spacing: 0.02em;
          line-height: 1;
          transition: border-color 0.2s ease-in-out, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
          display: flex;
          justify-content: center;
          align-items: center;
          white-space: nowrap;
        `,
        styleBySize[size],
        shortPropClass,
      ]}
      className={className}
      type="button"
      {...rest}
    />
  );
};

const colors: {
  [key in ButtonVariant]: {
    primary: string;
    secondary: string;
  };
} = {
  blue: {
    primary: theme.colors.blue,
    secondary: lighten(0.3, theme.colors.blue),
  },
};
const outlineHoverColor = '#ced6f5';
const disabledColor = '#6b6e77';

const colorStyles = (variant: ButtonVariant, outline: boolean) => {
  if (outline) {
    return css`
      background-color: white;
      color: ${colors[variant].primary};
      border: 1px solid ${colors[variant].primary};

      &:hover {
        background-color: ${outlineHoverColor};
      }
    `;
  } else {
    return css`
      background-color: ${colors[variant].primary};
      border: 1px solid ${colors[variant].primary};
      color: white;

      &:hover {
        background-color: ${colors[variant].secondary};
        border-color: ${colors[variant].secondary};
      }
    `;
  }
};

const disabledStyle = (outline: boolean) => {
  if (outline) {
    return css`
      &:disabled {
        background-color: white;
        border: 1px solid ${disabledColor};
        color: ${disabledColor};
        cursor: auto;

        svg path {
          fill: ${disabledColor};
        }
      }
    `;
  } else {
    return css`
      &:disabled {
        background-color: ${disabledColor};
        border-color: ${disabledColor};
        cursor: auto;
      }
    `;
  }
};

const gradientKeyframes = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
`;
const loadingStyle = (variant: ButtonVariant, outline: boolean) => {
  const color1 = lighten(0.1, outline ? outlineHoverColor : colors[variant].primary);
  const color2 = lighten(0.1, outline ? 'white' : colors[variant].secondary);

  return [
    css`
      background: repeating-linear-gradient(to right, ${color2} 0%, ${color1} 50%, ${color2} 100%);
      background-size: 200% auto;
      background-position: 0 100%;
      animation: ${gradientKeyframes} 2s infinite linear;
      animation-fill-mode: forwards;
      opacity: 0.5;
      cursor: wait;
    `,
    !outline &&
      css`
        border-color: transparent;
      `,
  ];
};

export type ButtonProps = {
  variant?: ButtonVariant;
  outline?: boolean;
  loading?: boolean;
} & React.ComponentPropsWithRef<'button'> &
  BaseButtonProps &
  ShortProps;

export const Button = ({
  variant = 'blue',
  size = 'normal',
  outline = false,
  loading,
  disabled,
  className,
  children,
  ...rest
}: ButtonProps) => {
  if (!children) {
    throw Error('Missing children prop');
  }

  return (
    <BaseButton
      size={size}
      disabled={loading || disabled}
      css={[
        colorStyles(variant, outline),
        !loading && disabledStyle(outline), // no disabled colors even though button is disabled when loading
        loading && loadingStyle(variant, outline),
      ]}
      className={className}
      {...rest}
    >
      {children}
      {loading && <ScreenReaderOnly>Laster...</ScreenReaderOnly>}
    </BaseButton>
  );
};

export const FilledButton = (props: ButtonProps) => <Button outline={false} {...props} />;

export const OutlineButton = (props: ButtonProps) => <Button outline={true} {...props} />;

export default Button;
