import { RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import AuthedPageLayout from '../../components/pagelayout/AuthedPageLayout';
import { Upload } from './Upload';

export const UploadPage: FC<RouteComponentProps> = () => {
  return (
    <AuthedPageLayout documentTitle="Capable - Last opp" requiresOneOfRoles={['SystemAdmin']}>
      <Upload />
    </AuthedPageLayout>
  );
};
