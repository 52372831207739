/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link, useNavigate } from '@reach/router';
import React from 'react';
import { useT } from '../../i18n/useT';
import { useUserContext } from '../../lib/UserContext';
import useSelectedPatientId from '../../lib/useSelectedPatientId';
import settings from '../../settings';
import { buttonReset } from '../../utils/styles';
import theme from '../../utils/theme';
import { Row } from '../Flex';
import Heading from '../Heading';
import Loader from '../loader/Loader';
import PageContent from '../PageContent';
import Text from '../Text';
import UserMenu from './UserMenu';

const UserMenuWrapper = () => {
  const navigate = useNavigate();
  const { loggedInUser, hasRole } = useUserContext();
  const selectedPatientId = useSelectedPatientId() ?? loggedInUser?.user.patientId;
  const user = loggedInUser?.user;

  const changeSelectedPatient = React.useCallback(
    (patientId: string) => {
      navigate(`/${patientId}/hjem`);
    },
    [navigate]
  );

  if (loggedInUser && user) {
    if (hasRole('Covid19User')) {
      return (
        <button
          css={[buttonReset]}
          onClick={() => {
            navigate('/logout');
          }}
        >
          <Text color="white">Log out</Text>  
        </button>
      );
    }

    const accessibleUsers = (user.accessibleUsers || []).filter((user) => !(user as any).metaAttributes?.deleted);

    const userList = accessibleUsers.map((user) => ({
      userName: user.displayName,
      photoUri: user.photoUri,
      userId: user.grantedUserId,
      patientId: user.patientId,
    }));

    const selectedUser = userList.find(
      (user) => user.patientId.toUpperCase() === (selectedPatientId ?? '').toUpperCase()
    );

    return (
      <UserMenu
        selectedUser={selectedUser}
        user={user}
        onSelectPatientId={changeSelectedPatient}
        availableUsers={userList}
      />
    );
  }

  return null;
};

const Header = () => {
  const t = useT();
  const { isLoggedIn, loading } = useUserContext();
  const selectedPatientId = useSelectedPatientId();

  const logoLinkTo = isLoggedIn && selectedPatientId ? `/${selectedPatientId}/hjem` : isLoggedIn ? '/hjem' : '/';

  return (
    <nav
      css={css`
        background-color: ${settings.environment === 'test' ? theme.colors.orange : theme.colors.blue};
        box-shadow: 0 2px 8px -4px ${theme.colors.gray};
      `}
    >
      <PageContent>
        <Row
          horizontal="space-between"
          vertical="center"
          css={css`
            height: 60px;

            a {
              color: white;
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            }
          `}
        >
          <Link
            to={logoLinkTo}
            css={css`
              color: white;
            `}
          >
            <Heading level={1} looksLikeLevel={4}>
              Capable
            </Heading>
          </Link>

          {loading ? (
            <Loader
              invert
              css={css`
                margin-right: 0;
              `}
            />
          ) : isLoggedIn ? (
            <UserMenuWrapper />
          ) : (
            <Link to="/login">
              <Text color="white">{t('Log in')}</Text>
            </Link>
          )}
        </Row>
      </PageContent>
    </nav>
  );
};

export default Header;
