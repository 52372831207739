import settings from '../settings';
import { User } from '../types/User';
import Fetch from './fetch';

async function isLoggedIn() {
  const url = `${settings.apiUrl}/Login`;

  return Fetch.get<User>({ url });
}

async function logIn(mobilePhone: string | number, password: string, smsCode: string) {
  const url = `${settings.apiUrl}/Login`;
  const body = {
    mobilePhone: mobilePhone,
    password: password,
    smsCode: smsCode,
  };

  const response = await Fetch.post<{ sessionId: string }>({ url, body });

  if (response.success) {
    localStorage.setItem('sessionId', response.data!.sessionId);
  }

  return response;
}

async function logOut() {
  const url = `${settings.apiUrl}/Login`;

  return Fetch.delete<void>({ url });
}

async function requestSmsCode(mobilePhone: string) {
  const url = `${settings.apiUrl}/Login/get-login-code?mobilePhone=${encodeURIComponent(mobilePhone)}`;

  return Fetch.post<void>({ url });
}

export default {
  isLoggedIn,
  logIn,
  logOut,
  requestSmsCode,
};
