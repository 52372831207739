/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '../../components/Button';
import { defaultInput, Input } from '../../components/Input';
import { Modal } from '../../components/Modal';
import { Select } from '../../components/Select';
import { useT } from '../../i18n/useT';
import { useAddNotification } from '../../lib/notifications/NotificationContext';
import UserClient from '../../network/UserClient';
import { useInput } from '../../utils/useInput';
import { InputLine } from '../sleep-diary/components/InputLine';
import { matchOtherField, required, validEmailOrEmpty } from '../sleep-diary/utils/rules';

function CreateUserModal({ closeModal, onSuccess }: { closeModal: () => void; onSuccess: () => void }) {
  const t = useT();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const mobilePhone = useInput<string>('', t('Mobile number'), [required(t)]);
  const mobilePhoneVerification = useInput<string>('', t('Confirm mobile number'), [
    required(t),
    matchOtherField(t, mobilePhone.value, t('Mobile number')),
  ]);
  const givenName = useInput<string>('', t('First name'), []);
  const familyName = useInput<string>('', t('Last name'), []);
  const gender = useInput<string>('', t('Gender'), []);
  const platformLanguage = useInput<string>('', t('SelectPlatformLanguage'), []);
  const eMail = useInput<string>('', t('E-mail'), [validEmailOrEmpty(t)]);
  const researchProjectNumber = useInput<string>('', t('Research number'), []);
  const birthDate = useInput<Date>(new Date(), 'Birth date', []);
  const { addError } = useAddNotification();

  useEffect(() => {
    if (mobilePhone.isInFocus) {
      if (
        mobilePhone.value.startsWith('+') &&
        !mobilePhone.value.startsWith('+47') &&
        !platformLanguage.validation.touched
      ) {
        platformLanguage.setValue('en');
      } else {
        if (mobilePhone.value && !platformLanguage.validation.touched) {
          platformLanguage.setValue('no');
        }
      }
    }
  }, [platformLanguage, mobilePhone]);

  const submit = React.useCallback(async () => {
    setSubmitting(true);
    const isValid =
      mobilePhone.validation.validate() &&
      givenName.validation.validate() &&
      familyName.validation.validate() &&
      gender.validation.validate() &&
      eMail.validation.validate() &&
      researchProjectNumber.validation.validate() &&
      birthDate.validation.validate() &&
      platformLanguage.validation.validate();

    if (isValid) {
      const result = await UserClient.createUser({
        mobilePhone: mobilePhone.value,
        givenName: givenName.value,
        familyName: familyName.value,
        gender: gender.value,
        eMail: eMail.value,
        researchProjectNumber: researchProjectNumber.value,
        birthDate: birthDate.value,
        platformLanguage: platformLanguage.value,
      });
      setSubmitting(false);

      if (result.success) {
        onSuccess();
        closeModal();
      } else {
        addError({ message: (result.error as any)?.resultMessage });
      }
    }
  }, [
    mobilePhone,
    onSuccess,
    closeModal,
    setSubmitting,
    givenName,
    familyName,
    gender,
    eMail,
    researchProjectNumber,
    birthDate,
    addError,
    platformLanguage,
  ]);

  console.log(`mobilePhone`, mobilePhone.validation);
  return (
    <Modal title={t('Create new user')} closeModal={closeModal}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <Input validation={mobilePhone.validation} type="text" label={t('Mobile number')} {...mobilePhone.bind} />
        <Input
          validation={mobilePhoneVerification.validation}
          type="text"
          label={t('Confirm mobile number')}
          {...mobilePhoneVerification.bind}
        />
        <Input validation={givenName.validation} type="text" label={t('First name')} {...givenName.bind} />
        <Input validation={familyName.validation} type="text" label={t('Last name')} {...familyName.bind} />
        <label htmlFor="gender">{t('Gender')}</label>
        <InputLine v={gender.validation} noMargin>
          <Select
            id="gender"
            name="gender"
            {...gender.bind}
            css={css`
              margin: 12px 0;
            `}
          >
            <option value=""></option>
            <option value="female">{t('Female')}</option>
            <option value="male">{t('Male')}</option>
            <option value="other">{t('Other')}</option>
            <option value="unknown">{t('Unknown')}</option>
          </Select>
        </InputLine>

        <Input validation={eMail.validation} type="text" label={t('E-mail')} {...eMail.bind} />

        <Input
          validation={researchProjectNumber.validation}
          type="text"
          label={t('Research number')}
          {...researchProjectNumber.bind}
        />
        <label htmlFor="birthDay">{t('Birth date')}</label>
        <InputLine v={birthDate.validation} noMargin>
          <div
            css={css`
              input {
                ${defaultInput};
                margin: 12px 0;
                width: initual;
              }
              .react-datepicker-wrapper {
                width: initial;
              }
            `}
          >
            <DatePicker
              id="birthDay"
              selected={birthDate.value}
              onChange={(date: Date) => birthDate.handleChange(date)}
              maxDate={new Date()}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="dd.MM.yyyy"
            />
          </div>
        </InputLine>
        <label htmlFor="selectPlatformLanguage">{t('SelectPlatformLanguage')}</label>
        <InputLine v={platformLanguage.validation} noMargin>
          <Select
            id="platformLanguage"
            name="platformLanguage"
            {...platformLanguage.bind}
            css={css`
              margin: 12px 0;
              margin-bottom: 8px;
            `}
          >
            <option value=""></option>
            <option value="no">{t('Norwegian')}</option>
            <option value="en">{t('English')}</option>
          </Select>
        </InputLine>
        <div
          css={css`
            margin-bottom: 12px;
            font-size: 14px;
          `}
        >
          {t('TheUserCanLaterChangeThePlatformLanguageInTheProfileOrAtTheBottomOfThePage')}
        </div>

        <Button onClick={submit} disabled={submitting}>
          {t('Create')}
        </Button>
      </div>
    </Modal>
  );
}

export default CreateUserModal;
