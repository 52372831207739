import settings from '../settings';
import { Cpl_ClinicalUseIssue_PilFormatting } from '../types/Fhir_ClinicalUseIssue';
import Fetch from './fetch';

async function fetchPilFormatting(patientId: string, varenr: string) {
  const url = `${settings.apiUrl}/WithPilFormatting?patientId=${patientId}&legemiddelpakning_Varenr=${varenr}`;
  return Fetch.get<{ pilFormatting: Cpl_ClinicalUseIssue_PilFormatting }>({ url });
}

export default {
  fetchPilFormatting,
};
