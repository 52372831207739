function ignoreException<T>(func: () => T, fallbackValue: T) {
  try {
    return func();
  } catch (error) {
    console.error(error);
    return fallbackValue;
  }
}

export default ignoreException;
