import { RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import AuthedPageLayout from '../../components/pagelayout/AuthedPageLayout';
import { useT } from '../../i18n/useT';
import { AccessControl } from './AccessControl';

export const AccessControlPage: FC<RouteComponentProps> = () => {
  const t = useT();
  return (
    <AuthedPageLayout documentTitle={t('Capable - Access control')}>
      <AccessControl />
    </AuthedPageLayout>
  );
};
