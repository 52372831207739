/** @jsxImportSource @emotion/react */
import { css, Global as GlobalEmotion, useTheme } from '@emotion/react';
import { FC } from 'react';
import { reset } from './reset';

export const Global: FC = () => {
  const theme = useTheme();

  return (
    <GlobalEmotion
      styles={[
        reset,
        css`
          body {
            margin: 0;
            padding: 0;
            font-size: 16px;
            font-family: Helvetica, Arial, sans-serif;
          }

          h1 {
            font-size: 24px;
          }

          h2 {
            font-size: 22px;
          }

          h3 {
            font-size: 20px;
          }

          input::placeholder {
            color: #565756;
          }
        `,
      ]}
    />
  );
};
