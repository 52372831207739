import React from 'react';

function useTimeout(callback: () => void, delayMs: number | null) {
  const savedCallback = React.useRef<() => void>();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the timeout.
  React.useEffect(() => {
    function tick() {
      savedCallback.current!();
    }
    if (delayMs !== null && delayMs !== Infinity) {
      let id = setTimeout(tick, delayMs);
      return () => clearTimeout(id);
    }
  }, [delayMs]);
}

export default useTimeout;
