/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import useDocumentTitle from '../../lib/useDocumentTitle';
import theme from '../../utils/theme';
import { Column } from '../Flex';
import Footer from '../Footer';
import Header from '../header/Header';
import PageContent, { PageContentProps } from '../PageContent';

export type PageLayoutProps = {
  children: React.ReactNode;
  documentTitle?: string;
  ignorePageContent?: boolean;
  showFooter?: boolean;
  showHeader?: boolean;
} & PageContentProps;
const PageLayout = ({
  children,
  documentTitle,
  ignorePageContent = false,
  showFooter = true,
  showHeader = true,
  ...pageContentProps
}: PageLayoutProps) => {
  useDocumentTitle(documentTitle ?? 'Capable');

  return (
    <Column
      css={css`
        min-height: 100vh;
        background-color: ${theme.colors.grayBackground};
      `}
    >
      {showHeader && <Header />}

      {ignorePageContent ? (
        <Column grow>{children}</Column>
      ) : (
        <PageContent
          css={css`
            flex-grow: 1;
            padding-top: 20px;
            padding-bottom: 40px;
          `}
          {...pageContentProps}
        >
          {children}
        </PageContent>
      )}

      {showFooter && <Footer />}
    </Column>
  );
};

export default PageLayout;
