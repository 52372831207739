/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link, RouteComponentProps } from '@reach/router';
import { reverse, sortBy, uniqBy } from 'lodash';
import React, { FC } from 'react';
import Block from '../../components/Block';
import Button from '../../components/Button2';
import ErrorPageContent from '../../components/ErrorPageContent';
import { Column, Row } from '../../components/Flex';
import Grid from '../../components/Grid';
import Heading from '../../components/Heading';
import { MessageIcon } from '../../components/icons/MessageIcon';
import Loader from '../../components/loader/Loader';
import { Modal, ModalTrigger } from '../../components/Modal';
import AuthedPageLayout from '../../components/pagelayout/AuthedPageLayout';
import Text from '../../components/Text';
import { useT } from '../../i18n/useT';
import useSelectedPatientId from '../../lib/useSelectedPatientId';
import MessagesClient from '../../network/MessagesClient';
import useClientFetch from '../../network/useClientFetch';
import { MailMessageAddress, Thread } from '../../types/Messages';
import formatDate from '../../utils/formatDate';
import { fromServerDate } from '../../utils/serverDateConverter';
import { buttonReset, lineClamp } from '../../utils/styles';
import theme from '../../utils/theme';
import { createAttachmentUrl } from './createAttachmentUrl';
import { CreateNewThread } from './ExternalMessages';

const NewThreadButton = () => {
  const t = useT();
  return (
    <ModalTrigger
      button={({ openModal }) => <Button onClick={openModal}>{t('New conversation')}</Button>}
      modal={({ closeModal }) => (
        <Modal title={t('Create new conversation')} closeModal={closeModal}>
          <CreateNewThread />
        </Modal>
      )}
    />
  );
};

const PageContent = ({ threads }: { threads: Thread[] }) => {
  const t = useT();
  const selectedPatientId = useSelectedPatientId()!;
  const sortedThreads = reverse(sortBy(threads, (thread) => thread.latestLogTime));

  return (
    <Column>
      <Row vertical="center" horizontal="space-between">
        <Row vertical="center">
          <MessageIcon />
          <Heading marginLeft={12}>{t('Conversations')}</Heading>
        </Row>
        <NewThreadButton />
      </Row>

      <Grid>
        {sortedThreads.map((thread) => {
          const patient = thread.threadMembers.find((member) => member.threadRelation === 'Patient');
          const attachment = thread.mailMessageAttachments?.[0];
          const otherParticipants = uniqBy(thread.threadMembers, (member) => member.mailMessageAddress_Id)
            .filter((member) => member.mailMessageAddress_Id !== patient?.mailMessageAddress_Id)
            .filter((member) => Boolean(member.mailMessageAddress));

          return (
            <Block key={thread.id}>
              <Column>
                <Link
                  to={`/${selectedPatientId}/meldinger/${thread.id}`}
                  css={css`
                    color: ${theme.colors.text};
                    &:hover {
                      color: ${theme.colors.blue};
                      span {
                        color: ${theme.colors.blue};
                      }
                    }
                  `}
                >
                  <Text size="huge" strong css={lineClamp(1)} marginBottom={8}>
                    {thread.initialMessageTitle || t('No tile')}
                  </Text>
                </Link>

                {/* KEEP */}
                {attachment && createAttachmentUrl(attachment) && (
                  <Text>
                    {t('Attachments')}:{' '}
                    <Link
                      to={createAttachmentUrl(attachment)}
                      css={css`
                        color: ${theme.colors.text};
                        &:hover {
                          color: ${theme.colors.blue};
                          span {
                            color: ${theme.colors.blue};
                          }
                        }
                      `}
                    >
                      {attachment.description}
                    </Link>
                  </Text>
                )}
                {patient && (
                  <span>
                    <Text>{t('Patient')}: </Text>
                    <MailMessageAddressWithModal mailMessageAddress={patient.mailMessageAddress} />
                  </span>
                )}
                {otherParticipants.length > 0 && (
                  <span>
                    <Text>{t('Participants')}: </Text>
                    {otherParticipants.map((member, index) => (
                      <span key={member.mailMessageAddress_Id}>
                        <MailMessageAddressWithModal mailMessageAddress={member.mailMessageAddress} />
                        {index < otherParticipants.length - 1 && <Text>, </Text>}
                      </span>
                    ))}
                  </span>
                )}
                <Row
                  css={css`
                    margin-top: 8px;
                  `}
                  horizontal="space-between"
                >
                  <Text dimmed>
                    {thread.messageCounter}{' '}
                    {(thread.messageCounter === 1 ? t('Message') : t('Messages')).toLocaleLowerCase()}
                  </Text>

                  <Text dimmed>
                    {formatDate(fromServerDate(thread.latestLogTime), "d. MMM yyyy, 'kl.'\u00a0HH.mm")}
                  </Text>
                </Row>
              </Column>
            </Block>
          );
        })}
      </Grid>
    </Column>
  );
};

const MailMessageAddressWithModal = ({ mailMessageAddress }: { mailMessageAddress?: MailMessageAddress }) => {
  return (
    <ModalTrigger
      button={({ openModal }) => (
        <button
          css={[
            buttonReset,
            css`
              text-decoration: underline;
              &:hover {
                color: ${theme.colors.blue};
                span {
                  color: ${theme.colors.blue};
                }
              }
            `,
          ]}
          onClick={openModal}
          disabled={!mailMessageAddress}
        >
          <Text>{mailMessageAddress?.description}</Text>
        </button>
      )}
      modal={({ closeModal }) => (
        <Modal title="Mer info" closeModal={closeModal}>
          <Column>
            <Text strong>{mailMessageAddress!.description}</Text>
            <Text>{mailMessageAddress!.title}</Text>
          </Column>
        </Modal>
      )}
    />
  );
};

const PageLoader = () => {
  const selectedPatientId = useSelectedPatientId()!;
  const t = useT();
  const { data, loading, error, errorMessage } = useClientFetch(MessagesClient.fetchThreads, {
    args: [selectedPatientId],
  });

  if (loading) {
    return <Loader />;
  } else if (error) {
    return <ErrorPageContent reason={errorMessage} />;
  } else if (!data) {
    return (
      <Row
        css={css`
          margin: 20px;
        `}
      >
        {t('Missing data')}
      </Row>
    );
  }

  return <PageContent threads={data} />;
};

export const ThreadsPage: FC<RouteComponentProps> = () => {
  return (
    <AuthedPageLayout>
      <PageLoader />
    </AuthedPageLayout>
  );
};
