/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import React from 'react';
import readShortProps, { ShortProps } from './helpers/readShortProps';

export const styleByLevel = (level: number): SerializedStyles => {
  switch (level) {
    case 1:
      return css`
        font-size: 36px;
        font-weight: 500;
        margin-top: 0.3em;
        margin-bottom: 0.3em;
      `;
    case 2:
      return css`
        font-size: 28px;
        font-weight: 500;
        margin-top: 0.3em;
        margin-bottom: 0.3em;
      `;
    case 3:
      return css`
        font-size: 24px;
        font-weight: 500;
        margin-top: 0.3em;
        margin-bottom: 0.3em;
      `;
    case 4:
      return css`
        font-size: 20px;
        font-weight: 500;
        margin-top: 0.3em;
        margin-bottom: 0.3em;
      `;
    case 5:
      return css`
        font-size: 18px;
        font-weight: 500;
        margin-top: 0.3em;
        margin-bottom: 0.3em;
      `;
    // Not supported
    default:
      return css`
        color: red;
      `;
  }
};

export type HeadingLevel = 1 | 2 | 3 | 4 | 5;

type HeadingProps = {
  level?: HeadingLevel;
  looksLikeLevel?: HeadingLevel;
  className?: string;
  textAlign?: 'left' | 'center' | 'right';
} & React.ComponentPropsWithRef<'h1'> &
  ShortProps;

const Heading = ({ level, looksLikeLevel, className, textAlign, children, ...restBeforeShortProps }: HeadingProps) => {
  level = level || 1;
  const [shortPropClass, rest] = readShortProps(restBeforeShortProps);
  const Component: React.ElementType = `h${level}` as React.ElementType;

  return (
    <Component
      css={[
        css`
          word-break: break-word;
          max-width: 100%;
          line-height: 1.5;
        `,
        styleByLevel(looksLikeLevel || level),
        textAlign
          ? css`
              text-align: ${textAlign};
            `
          : null,
        shortPropClass,
      ]}
      className={className}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default Heading;
