import React, { forwardRef } from 'react';

export type SelectProps = React.ComponentPropsWithRef<'select'> & {
  label: string;
  sublabel?: string;
};

export const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const { label, sublabel, ...rest } = props;

  return (
    <label className="flex flex-col gap-1">
      <div className="break-normal text-sm font-semibold">{label}</div>
      <div className="break-normal text-xs font-normal">{sublabel}</div>
      <select className="w-full rounded-md border border-gray-300 p-2" {...rest} ref={ref} />
    </label>
  );
});
