import React, { FC } from 'react';

export const MessageIcon: FC<{ size?: number }> = ({ size = 32 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
    >
      <g transform="matrix(1,0,0,1,-10,-10)">
        <g id="messages">
          <g transform="matrix(0.324305,0,0,0.324305,-59.3712,-23.2916)">
            <path
              d="M411.252,151.991C411.252,124.762 389.145,102.655 361.916,102.655L263.243,102.655C236.014,102.655 213.907,124.762 213.907,151.991L213.907,250.664C213.907,277.893 236.014,300 263.243,300L361.916,300C389.145,300 411.252,277.893 411.252,250.664L411.252,151.991Z"
              fill="#2F9EC9"
            />
          </g>
          <g transform="matrix(0.295185,0,0,0.388483,-49.0779,-39.7325)">
            <path
              d="M388.156,198.29C388.156,171.581 352.484,149.897 308.545,149.897C264.606,149.897 228.934,171.581 228.934,198.29C228.934,214.074 241.597,228.867 262.863,237.924L252.817,270.881L287.917,245.031C294.645,246.128 301.58,246.684 308.545,246.684C352.484,246.684 388.156,225 388.156,198.29Z"
              fill="#f2f2f2"
            />
          </g>
        </g>
        <g transform="matrix(1.04444,0,0,1.04444,1.11111,0.222222)">
          <g id="message"></g>
        </g>
      </g>
    </svg>
  );
};
