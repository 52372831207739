/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link, useNavigate } from '@reach/router';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { useT } from '../../i18n/useT';
import ScreenReaderOnly from '../ScreenreaderOnly';
import Gear from './Gear';

const defaultProfileIcon = require('./person_icon.png');

export default function UserMenu({ selectedUser, availableUsers, onSelectPatientId, user }) {
  const t = useT();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [filter, setFilter] = useState('');

  const menuRef = createRef();
  const toggleExpanded = useCallback(
    (event) => {
      if (expanded && event) {
        if (event.target.id === 'filterUserInput' || (menuRef.current && menuRef.current.contains(event.target))) {
          return;
        }
      }

      setExpanded(!expanded);
    },
    [expanded, setExpanded, menuRef]
  );

  useEffect(() => {
    const handleClick = (event) => {
      if (event.target.id === 'filterUserInput' || (menuRef.current && menuRef.current.contains(event.target))) {
        return;
      }

      setExpanded(false);
    };

    if (expanded) {
      document.addEventListener('mousedown', handleClick, false);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  }, [expanded, setExpanded, menuRef]);

  const logOut = async () => {
    navigate('/logout');
  };

  const filteredUsers = selectedUser
    ? availableUsers.filter((user) => user.userId !== selectedUser.userId)
    : availableUsers;

  return (
    <Wrapper>
      <UserMenuButton onClick={toggleExpanded} expanded={expanded} aria-expanded={expanded}>
        <UserName>{(selectedUser && selectedUser.userName) || user.displayName}</UserName>
        <ProfilePhoto>
          <img
            src={selectedUser ? selectedUser.photoUri || defaultProfileIcon : user.photoUri || defaultProfileIcon}
            alt="profile"
          />
        </ProfilePhoto>
      </UserMenuButton>

      {expanded && (
        <ExpandedUserMenu ref={menuRef}>
          <button
            css={css`
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              padding: 2px 8px;
              font-weight: bold;
            `}
            onClick={() => {
              onSelectPatientId(user.patientId);
              toggleExpanded();
            }}
          >
            {user.displayName}
            <ScreenReaderOnly>{t('Menu')}</ScreenReaderOnly>
          </button>
          <div
            css={css`
              height: 16px;
            `}
          />
          <Link
            to="/profil"
            onClick={() => {
              toggleExpanded();
            }}
          >
            <span
              css={css`
                width: 100%;
                height: 26px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              `}
            >
              {t('Profile')}
              <Gear color="#000000" size="20" />
            </span>
          </Link>
          <Hr />
          {filteredUsers.length > 10 && (
            <FilterUsersInput
              id="filterUserInput"
              type="text"
              placeholder="Filter users"
              onChange={(event) => setFilter(event.target.value)}
            />
          )}
          <UserList scrollList={filteredUsers.length > 10}>
            {filteredUsers
              .filter((user) => user.userName.toLowerCase().includes(filter.toLowerCase()))
              .map((user) => (
                <button
                  key={user.userId}
                  onClick={() => {
                    onSelectPatientId(user.patientId);
                    toggleExpanded();
                  }}
                >
                  {user.userName}
                </button>
              ))}
          </UserList>
          <Hr />
          <button
            css={css`
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              padding: 2px 8px;
              font-weight: bold;
            `}
            onClick={() => logOut()}
          >
            {t('Log out')}
          </button>
        </ExpandedUserMenu>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000;
  position: relative;
`;

const UserMenuButton = styled.button`
  height: 40px;
  padding: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  border: none;
  justify-content: space-between;
  color: white;
  cursor: pointer;
  font-size: 16px;
`;

const Hr = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
`;

const ExpandedUserMenu = styled.div`
  width: 200px;
  background-color: #ffffff;
  box-shadow: 0 0 8px -4px gray;
  color: black;
  position: absolute;
  top: 43px;
  left: -1px;
  border-top: 0;
  padding: 8px 0;
  font-size: inherit;
  text-align: left;
  display: flex;
  flex-direction: column;

  button,
  a {
    width: 200px;
    height: 30px;
    padding: 2px 8px;
    background-color: white;
    border: 0;
    text-align: left;
    cursor: pointer;
    font-size: 16px;
    color: black;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
`;

const FilterUsersInput = styled.input`
  height: 30px;
  border: 0px;
  padding: 2px 8px;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const UserList = styled.div`
  width: 100%;
  ${(props) =>
    props.scrollList &&
    `
      max-height: 300px;
      overflow: auto;
    `}
`;

const UserName = styled.div`
  height: 100%;
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProfilePhoto = styled.div`
  width: 38px;
  height: 38px;
  overflow: hidden;
  border: 1px solid white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    max-width: 38px;
    max-height: 38px;
    width: auto;
    height: auto;
  }
`;
