/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link, useNavigate } from '@reach/router';
import { addMinutes, differenceInMinutes, startOfDay } from 'date-fns';
import React, { useState } from 'react';
import { ModalTrigger } from '../../components/Modal';
import { useT } from '../../i18n/useT';
import { useUserContext } from '../../lib/UserContext';
import formatDate from '../../utils/formatDate';
import theme from '../../utils/theme';
import AddDataModal from './AddDataModal';
import EditPasswordModal from './EditPasswordModal';
import {
  EditBirthDate,
  EditEmail,
  EditFamilyName,
  EditGender,
  EditGivenName,
  EditResearchProjectNumber,
  EditSmsAlertOffset,
} from './EditProfileField';
import './Profile.css';

const getFormattedGender = (gender, t) => {
  if (gender === 'female') {
    return t('Female');
  } else if (gender === 'male') {
    return t('Male');
  } else if (gender === 'unknown') {
    return t('Unknown');
  } else if (gender === 'other') {
    return t('Other');
  }
  return '';
};

const Line = ({ label, value, editComponent }) => {
  return (
    <div
      css={css`
        display: grid;
        padding: 16px;
        grid-row-gap: 8px;
        grid-template-columns: 160px 1fr 50px;
        grid-template-areas: 'label value edit';

        @media (max-width: 599px) {
          grid-template-columns: 1fr 50px;
          grid-template-rows: auto auto;
          grid-template-areas:
            'label edit'
            'value .';
        }

        &:hover {
          background-color: ${theme.colors.grayBackground};
        }
      `}
    >
      <div
        css={css`
          word-break: break-word;
          max-width: 160px;
          grid-area: label;
          font-weight: bold;
        `}
      >
        {label}
      </div>
      <div
        css={css`
          word-break: break-all;
          grid-area: value;
        `}
      >
        {value}
      </div>
      <div
        css={css`
          width: 50px;
          grid-area: edit;
        `}
      >
        {editComponent}
      </div>
    </div>
  );
};

export function Profile() {
  const t = useT();
  const { user, patient } = useUserContext().loggedInUser;
  const navigate = useNavigate();

  const [showAddDataModal, setShowAddDataModal] = useState(false);

  const logOut = () => {
    navigate('/logout');
  };

  if (!patient) {
    return (
      <div className="Profile">
        <div className="Profile-bottom-container">
          <button onClick={() => logOut()} className="Profile-logout-button">
            {t('Log out')}
          </button>
        </div>
      </div>
    );
  }

  const phone = patient.telecom && patient.telecom.find((element) => element.use === 'UserMobilePhone');
  const mobilePhone = phone && phone.value;
  const userIsAdmin = user?.userAttributes?.find((attribute) => attribute.key === 'SystemAdmin') || false;

  return (
    <div>
      <h1
        css={css`
          margin-bottom: 16px;
        `}
      >
        {t('Profile')}
      </h1>
      <div>
        <h2
          css={css`
            margin-bottom: 16px;
          `}
        >
          {t('Logged in user')}
        </h2>
        <div
          css={css`
            box-shadow: ${theme.shadows.block};
            background-color: #f5f7fb;
            border-radius: 8px;
          `}
        >
          {/*userIsAdmin &&*/ <Line label="Database" value={user.database} />}
          <Line
            label={t('First name')}
            value={patient.name[0].given && patient.name[0].given.join(' ')}
            editComponent={<EditGivenName patient={patient} />}
          />
          <Line
            label={t('Last name')}
            value={patient.name[0].family}
            editComponent={<EditFamilyName patient={patient} />}
          />
          <Line label={t('Phone')} value={mobilePhone} />
          <Line
            label={t('E-mail')}
            value={
              patient.telecom && patient.telecom.find((tele) => tele.system === 'email')
                ? patient.telecom.find((tele) => tele.system === 'email').value
                : ''
            }
            editComponent={<EditEmail patient={patient} />}
          />
          <Line
            label={t('Gender')}
            value={getFormattedGender(patient.gender, t)}
            editComponent={<EditGender patient={patient} />}
          />
          <Line
            label={t('Birth date')}
            value={formatDate(new Date(patient.birthDate), 'dd.MM.yyyy')}
            editComponent={<EditBirthDate patient={patient} />}
          />
          <Line
            label={t('Research number')}
            value={
              (patient.identifier || [])
                .filter((item) => item.use === 'ResearchProjectNumber')
                .map((item) => item.value)[0]
            }
            editComponent={<EditResearchProjectNumber patient={patient} />}
          />
          <Line
            label={t('SMS reminder for sleep diary registration')}
            value={
              minutesFromMidnightToDate(
                user.userAttributes.filter((item) => item.key === 'SmsAlertOffset').map((item) => item.value)[0],
                t
              ) || t('No SMS reminder')
            }
            editComponent={<EditSmsAlertOffset user={user} />}
          />

          {patient.deceasedBoolean && <Line label={t('Date of death')} value={patient.deceasedBoolean} />}
          {patient.multipleBirthBoolean && (
            <Line label={t('Number of twin siblings')} value={patient.multipleBirthBoolean} />
          )}
        </div>
      </div>

      <div style={{ height: 40 }} />
      <Link to="/access-control">{t('Click here to go to access control')}</Link>

      {showAddDataModal && <AddDataModal onClose={() => setShowAddDataModal(false)} />}
      <div className="Profile-bottom-container">
        <ModalTrigger
          button={({ openModal }) => (
            <button onClick={openModal} className="Profile-logout-button">
              {t('Change password')}
            </button>
          )}
          modal={({ closeModal }) => <EditPasswordModal onClose={closeModal} />}
        />

        <div style={{ height: 20 }} />
        <button onClick={() => logOut()} className="Profile-logout-button">
          {t('Log out')}
        </button>
      </div>
    </div>
  );
}

export function minutesFromMidnightToDate(minutes, t) {
  if (typeof minutes === 'number') {
    return;
  }

  if (minutes < 0) {
    if (t) {
      return t('SMS notifications is turned off');
    } else {
      return -1;
    }
  }

  return formatDate(addMinutes(startOfDay(new Date()), minutes ? minutes : 0), 'HH:mm');
}

export function timeToMinutesFromMidnight(time) {
  if (!time || time === -1) {
    return -1;
  }

  const [hours, minutes] = time.split(':');

  let date = startOfDay(new Date());
  date.setHours(hours);
  date.setMinutes(minutes);

  return differenceInMinutes(date, startOfDay(new Date()));
}
