import styled from '@emotion/styled';

export const defaultButton = {
  height: 32,
  paddingLeft: 16,
  paddingRight: 16,
  borderRadius: 4,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'grey',
  backgroundColor: '#1a647e',
  color: 'white',
  fontSize: 16,
  border: 'none',
};

export const Button = styled.button`
  ${defaultButton}
`;
