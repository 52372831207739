import { useCallback, useMemo, useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { capableApi } from '../lib/api';
import { Cpl_LogHistoryEntry } from '../types/Cpl_LogHistory';
import { _DtoContainer } from '../types/_DtoContainer';

export type LogHistory = {
  entries: Cpl_LogHistoryEntry[];
  pointInTime?: string;
  setPointInTime: (isoDate: string) => void;
  reset: () => void;
};

export const useQueryWithLogHistory = (
  key: (string | number | undefined)[],
  url: string,
  options?: { enabled?: boolean }
) => {
  const [pointInTime, setPointInTime] = useState<string | undefined>(undefined);

  const realQuery = useQuery(key, () => capableApi.get<_DtoContainer>(`${url}?MetaInfo=true`).then((res) => res.data), {
    ...options,
    keepPreviousData: true,
  });

  const pointInTimeQuery = useQuery(
    [...key, pointInTime],
    () =>
      capableApi
        .get<_DtoContainer>(`${url}?MetaInfo=true${pointInTime ? `&pointInTime=${pointInTime}` : ''}`)
        .then((res) => res.data),
    {
      ...options,
      keepPreviousData: true,
      placeholderData: realQuery.data,
      enabled: Boolean(pointInTime),
    }
  );

  const logHistoryParamString = realQuery.data?.logHistoryParamString;
  const logQuery = useQuery(
    ['logHistory', ...key, logHistoryParamString],
    () =>
      capableApi
        .get<_DtoContainer>(`/LogHistory/LogEntries/${encodeURIComponent(logHistoryParamString ?? '')}?MetaInfo=true`)
        .then((res) => res.data),
    {
      enabled: Boolean(logHistoryParamString),
    }
  );

  const logHistoryEntries = logQuery.data?.logHistoryEntries;
  const logHistory: LogHistory = useMemo(() => {
    return {
      entries: logHistoryEntries ?? [],
      pointInTime,
      setPointInTime: (isoDate: string) => setPointInTime(isoDate),
      reset: () => setPointInTime(undefined),
    };
  }, [logHistoryEntries, pointInTime]);

  const refetch = useCallback(async () => {
    realQuery.refetch();
    pointInTimeQuery.refetch();
    logQuery.refetch();
  }, [logQuery, realQuery, pointInTimeQuery]);

  const query = pointInTime ? pointInTimeQuery : realQuery;

  return [query, logHistory, refetch] as [UseQueryResult<_DtoContainer, unknown>, LogHistory, () => void];
};
