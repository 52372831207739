import { Cpl_Decision } from '../types/Cpl_Decision';
import ObjectContainer from '../types/ObjectContainer';
import { api } from './api';

async function createPrescription(newPresc: Cpl_Decision) {
  const response = await api.post<Cpl_Decision>('/Decision/Post', newPresc);

  return response;
}

async function getPrescription() {
  const response = await api.get<Cpl_Decision>('/Decision/GetById/%3F', {
    params: {
      metaInfo: false,
    },
  });

  return response; // "list" of prescriptions
}

async function getPrescriptionWithMeta(prescrId: string) {
  const response = await api.get<ObjectContainer>(`/Decision/GetById/${prescrId}`, {
    params: {
      metaInfo: true,
    },
  });

  return response; // containing "list" of prescriptions
}

async function getPrescriptions(patientId: string) {
  const response = await api.get<Cpl_Decision[]>(`/Decision/GetByPatient/${patientId}`, {
    params: {
      metaInfo: false,
    },
  });

  return response;
}

async function getPrescriptionsWithMeta(patientId: string) {
  const response = await api.get<ObjectContainer>(`/Decision/GetByPatient/${patientId}`, {
    params: {
      metaInfo: true,
    },
  });

  return response;
}

async function deletePrescription(prescrId: string) {
  const response = await api.delete('/Decision/Delete/' + prescrId.toString());
  return response;
}

async function terminateDecision(decisionId: string, terminationDay: number, terminationOffset: number) {
  const response = await api.put(`/Decision/TerminateDecision`, null, {
    params: { 
      id: decisionId,
      terminationDay,
      terminationOffset,
      metaInfo: false },
  });
  return response;

}

const prescriptionClient = {
  createPrescription,
  getPrescription,
  getPrescriptionWithMeta,
  getPrescriptions,
  getPrescriptionsWithMeta,
  deletePrescription,
  terminateDecision,
};

export default prescriptionClient;
