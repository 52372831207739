import { RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import { Redirect } from '@reach/router';
import Loader from '../../components/loader/Loader';
import OpenPageLayout from '../../components/pagelayout/OpenPageLayout';
import { useUserContext } from '../../lib/UserContext';

export const Covid19Redirect: FC<RouteComponentProps> = () => {
  const { loading, loggedInUser } = useUserContext();

  if (loading) {
    return (
      <OpenPageLayout documentTitle="Capable - Covid 19">
        <Loader />
      </OpenPageLayout>
    );
  } else if (loggedInUser) {
    return <Redirect to={`/${loggedInUser.user.patientId}/covid19`} noThrow />;
  } else {
    return <Redirect to={`/login?returnto=${encodeURIComponent('/covid19')}`} noThrow />;
  }
};
