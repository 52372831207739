import settings from '../settings';
import { MailMessage, Thread } from '../types/Messages';
import { ObjectContainer } from '../types/ObjectContainer';
import Fetch from './fetch';

async function fetchThreads(patientId: string) {
  const url = `${settings.apiUrl}/MailMessageThreads/${patientId}`;

  return Fetch.get<Thread[]>({ url });
}

async function fetchThread(threadId: string) {
  const url = `${settings.apiUrl}/MailMessageThread/${threadId}`;

  return Fetch.get<Thread>({ url });
}

async function fetchMessages(threadId: string) {
  return Fetch.get<ObjectContainer>({
    url: `${settings.apiUrl}/MailMessage/MailMessageThread/${threadId}?MetaInfo=true`,
  });
}

async function createMessage(
  message: { mailMessageThread_Id: number; title: string; contents: string } | Omit<MailMessage, 'id'>
) {
  const url = `${settings.apiUrl}/MailMessage?MetaInfo=true`;

  return Fetch.post<ObjectContainer>({ url, body: message });
}

async function createMessageInThread(threadId: string, message: { title: string; contents: string }) {
  const url = `${settings.apiUrl}/MailMessage/${threadId}?MetaInfo=true`;

  return Fetch.post<ObjectContainer>({ url, body: message });
}

async function createMessageWithAttachment(message: any) {
  const url = `${settings.apiUrl}/MailMessage?MetaInfo=true`;

  return Fetch.post<ObjectContainer>({ url, body: message });
}

export default {
  fetchThreads,
  fetchThread,
  fetchMessages,
  createMessage,
  createMessageInThread,
  createMessageWithAttachment,
};
