import {
  Cpl_Decision,
  Cpl_Decision_Component,
  Cpl_Decision_Component_Medication,
  Cpl_Decision_ComputedTask,
  Cpl_Decision_Medication,
  Cpl_Decision_RecurrenceParams,
  Cpl_Decision_RecurrentTask,
} from '../../../types/Cpl_Decision';
import { DosageTimeParams, DoseringParams, ImportTaskType } from '../../../types/gh-types';
import { NewExplicitTasks } from './dosage4';
import { TaskType } from './presenttasks';

/* *******************************************************
CONVERSION of DoseringParams to and from DoseringInternal
********************************************************** */

// First a constant and a helper and a sort function:
const timeOfDayOptions: string[] = ['Ikke valgt tid', 'Morgen', 'Formiddag', 'Midt på dagen', 'Ettermiddag', 'Kveld']; // timeOfDay potential values, "Ikke valgt tid" has to be alone, the rest is chronologically ordered
const timeOfDayOptionsDb: string[] = ['Ikke valgt tid', 'Morning', 'BeforeNoon', 'Noon', 'AfterNoon', 'Evening']; // timeOfDay potential values, "Ikke valgt tid" has to be alone, the rest is chronologically ordered

// Her er det mismatch:
// Db-versjonen har "TimeOffset", og "Night"  i tillegg
// Db versjonen mangler "Ikke valgt tid"

const rankTimeOfDay = (timeOfDayOption: string): number => {
  return timeOfDayOptions.findIndex((item) => item === timeOfDayOption);
};
const sortImportTasks = (a: ImportTaskType, b: ImportTaskType) => {
  return a.dayNo === b.dayNo
    ? rankTimeOfDay(a.daySegment) - rankTimeOfDay(b.daySegment)
    : a.dayNoOffset - b.dayNoOffset;
};

// Formål:
// Lese fra FEST, og oversette til intern modell
// Lese fra databasen og oversette til intern klientmodell
// Lese fra egendefinerte templates

// I størst mulig grad forholde seg til relative tidsangivelser må vel være lurt. Templates og Fest er iallefall lagt opp slik

export type DoseringInternal = {
  beginDayNo?: number | undefined;
  startRecurrentDayNo?: number | undefined;
  endRecurrentDayNo?: number | undefined;
  interval?: number; // Number of days between startDate and endDate, value 0 means not in use
  endOption: string | undefined; // "Max", "interval", "endDate", "auto", "multiple" ("Max" har ikke noen sluttdato (endDate settes kanskje til 3000 eller noe slikt), "interval" (betyr tallet er gitt ved intervalDays), "endDate" (betyr at endDate har blitt satt)) "auto" benyttes ved kun explicitTasks
  // Either interval is given alone, or startDate and interval and not endDate, or startDate and endDate and not interval
  pattern: string; //"Dag"|"Uke"|"Måned"|"Enkeltdoser"
  dagrep?: string | undefined; //"Hver dag"|"Hver 2.dag"|"Hver 3.dag"|"Hver 4.dag"|"Hver 5.dag"|"Hver 6.dag"|"Hver 7.dag"
  ukerep?: string | undefined; //"Hver uke"|"Hver 2.uke"|"Hver 3.uke"|"Hver 4.uke"|"Hver 5.uke"|"Hver 6.uke"|"Hver 7.uke"|"Hver 8.uke"
  mndrep?: string | undefined; //"Hver måned"|"Hver 2.måned"|"Hver 3.måned"|"Hver 4.måned"|"Hver 5.måned"|"Hver 6.måned"|"Hver 7.måned"|"Hver 8.måned"|"Hver 9.måned"|"Hver 10.måned"|"Hver 11.måned"|"Hver 12.måned"
  monthDay?: string | undefined; //"1"-"31"
  noOfWeeks?: number | undefined; //1, 2, 3 ++ (antall uker i alterenerende mønster)
  weeks: TaskType[][][]; // benyttes for Uke. Siste dimensjon svarer til noOfWeeks
  dosagetasks?: TaskType[] | undefined; // benyttes for Dag, Måned
  explicitTasks: NewExplicitTasks[]; // benyttes for Enkeltdoser
  conversionState?: string | undefined; // undefined or 'Ok' is 'Ok',  'Error' used if there are errors in the format.
  conversionMsg?: string | undefined; // used if there are errors in the format.
};

/* 
export type ImportTaskType = {
  dayNo?: number; // Day number counting days from day 0: jan 1 1900 (dB).  Not in use at all? What value to use when not set? == -1
  dayNoOffset: number; // Offset in days from a startDate (only in use for explicitTasks, or what about week patterns)
  dosageAmount: number; // antall
  dosageUnit: string; // unit
  daySegment: string; // timeOfDay
};

// Eksterne doseringsparametre:
export type DoseringParams = {
  beginDate: Date | undefined; // First date, either first explicit tasks if such, or start of recurrence interval (when adding RecurrenceOffset)
  //startRecurrenceDate,      // = beginDate + recurrenceIntervalOffset
  endRecurrenceDate: Date | undefined;
  recurrenceInterval: number | undefined; // number of days between startRecurrenceDate and endRecurrenceDate
  // Either interval is given alone, or startDate and interval and not endDate, or startDate and endDate and not interval  (governed by recurrenceIntervalOption)
  recurrenceIntervalOption: string; // '' or 'interval' or 'Max' or 'auto'. '' means 'use endRecurrenceDate'. 'interval' means endRecurrenceDate = beginRecurrenceDate (i.e. startDate + recurrenceOffset) + interval.  'auto' is used when only savedTasks.  Must be given. "Max", "interval", "endDate", "auto", "multiple" (describes user choices when setting up date/interval)
  recurrenceIntervalOffset: number | undefined; // gluing together savedTasks and (recurrent) tasks
  //pattern: string,            // ""|"Dag"|"Uke"|"Måned"
  recurrencePattern: string; // ""|"Day"|"AlternatingWeeks"|"Weeks"|"Month" + "*" + cycle given in days/weeks // Month not yet implemented in Db // Weeks*2 (annenhver uke)
  recurrenceType: string; // Daily, Calendar, Cyclic, setting it to blank (fed from recurrencePattern)
  cycle: number | undefined; // 1, 2, 3, ++, in days, used by "Dag" and "Uke" pattern. For "Måned" pattern: number is in months
  // 1 means every day, 2 means every other day, 7 means once every week, 14 means once every other week.
  // dayOffset in "ImportTaskType" is limited by this number (for "Uke" pattern)
  monthDay: string | undefined; //"1"-"31"
  //alternatingWeeks:boolean|undefined
  tasks: ImportTaskType[] | undefined; // benyttes for Dag, Uke, Måned
  savedTasks: ImportTaskType[] | undefined; // benyttes for enkeltdoser
}; 

*/

// Eksempler:
//
// 1+0+0+1 for legemiddel med dosageUnit = "tablett"|"tabletter": {pattern="Dag", cycle=1, tasks=[{dayOffset=0, dosageAmount=1, dosageUnit="tablett", dayPart="Morgen"}, {dayOffset=0, dosageAmount=1, dosageUnit="tablett", dayPart="Kveld"}]}
// 1+0+0+0x3 for legemiddel med dosageUnit = "tablett"|"tabletter": {interval=3, pattern="Dag", cycle=1, tasks=[{dayOffset=0, dosageAmount=1, dosageUnit="tablett", dayPart="Morgen"}]}

// Further accomodating db-model

/* Mangler et sted å legge ned:
"Alternating weeks": Snakket med Knut: gjør det ved recurrenceParam: Weekly*n eller AlternateWeekly*n 
EndDateOption (om man har beskrevet kur (antall dager) eller eksplisitt sluttdato)
Lager ny param i component, ved siden av beginRecurrenceDayNo og endRecurrenceDayNo, parameter Interval og  liknende endDateOption
Må også få de tallene som representerer (1900) startdato ikke satt  og sluttdato ikke satt (3000)
 */

/* 
export type Fhir_Medication = {
  // ZZZ Fill in here
};

export type Cpl_KeyStringValue = {
  key: string;
  value: string;
  displayValue: string;
  beginTime: Date;
  endTime: Date;
};

export interface Cpl_Decision_Task_Medication {
  dosageUnit: string;
  dosageAmount: number;
}

export interface Cpl_Decision_Task_Result {
  state: string;
  value: string;
}

export interface Cpl_Decision_BaseTask {
  dayNoOffset: number;
  daySegment: string;
  timeOffset: number;
  alertOffset: number;
  duration: number;
  state: string;
  calendarInfo: string;
  taskMedication: Cpl_Decision_Task_Medication;
}

export interface Cpl_Decision_RecurrentTask extends Cpl_Decision_BaseTask {
  recurrenceTag: string;
}

export interface Cpl_Decision_SavedTask extends Cpl_Decision_BaseTask {
  sourceTask_Id: number;
  dayNo: number;
  taskResult: Cpl_Decision_Task_Result;
}

export interface Cpl_Decision_AutoSavedTask extends Cpl_Decision_BaseTask {
  sourceTask_Id: number;
  dayNo: number;
  taskResult: Cpl_Decision_Task_Result;
}

export interface Cpl_Decision_ComputedTask extends Cpl_Decision_BaseTask {
  sourceClass: string;
  sourceTask_Id: number;
  dayNo: number;
  medication_DisplayName: string;
  taskResult: Cpl_Decision_Task_Result;
}

export interface Cpl_Decision_Component_Medication
  extends Cpl_Decision_Base_Medication {
  medicationId: string;
  medication_Id: number;
  legemiddeldose: Cpl_LegemiddeldoseSaved | undefined;
  doseringsTekst: string;
  bruksomrade?: Fhir_Coding;
  forholdsregler?: Fhir_Coding;
  administrasjonsvei?: Fhir_Coding;
  courseOfTherapy: Fhir_Coding;

  medicationDisplayValue: string;
  fhir_Medication?: Fhir_Medication;
}

export interface Cpl_Decision_Component {
  beginDayNo: number; // start day of dosage, wehter it is purely explicit tasks, purely recurrent tasks, or a combination: (explicit tasks followed by recurrent tasks)
  // beginRecurrenceDayNo: number, // now calculated as beginDayNo + RecurrenceIntervalOffset
  endRecurrenceDayNo: number; // End of interval for recurrence dosage
  recurrenceInterval: number; // numberOfDays for recurrent dosage pattern
  recurrenceIntervalOption: string; // "interval", or "". "interval" means endRecrrenceDayNo is given by beginRecurrenceDayNo + recurrenceInterval, "" means endRecurrenceDayNo is given by explicit date
  recurrenceIntervalOffset: number; // used to calculate beginRecurrenceDayNo, as beginDayNo + recurrenceIntervalOffset - is always monday so can use value
  savedTaskOffset: number; // What is this one?, always 0 if first saved task starts at beginDayNo? When will it have other values
  description: string;
  recurrentTasks: Cpl_Decision_RecurrentTask[];
  savedTasks: Cpl_Decision_SavedTask[];
  componentMedication: Cpl_Decision_Component_Medication;
}

export interface Cpl_Decision_RecurrenceParams {
  tag: string;
  recurrencePattern: string;
  recurrenceType?: string;
  recurrenceCycle?: number;
  recurrenceOffset?: number; // ZZZ ??? Maybe the one to tell that week start on monday
  years?: Cpl_KeyStringValue[];
  quarters?: Cpl_KeyStringValue[];
  yearDays?: Cpl_KeyStringValue[];
  months?: Cpl_KeyStringValue[];
  monthDays?: Cpl_KeyStringValue[];
  weeks?: Cpl_KeyStringValue[];
  weekDays?: Cpl_KeyStringValue[];
}

export interface Cpl_Decision_Base_Medication {
  medicationDisplayValue: string;
  state: string;
}

export interface Cpl_Decision_Medication extends Cpl_Decision_Base_Medication {}

export interface Cpl_Decision {
  patientId: string;
  category: string;
  decisionMedication: Cpl_Decision_Medication;
  recurrenceParams: Cpl_Decision_RecurrenceParams[];
  components: Cpl_Decision_Component[];
  computedTasks: Cpl_Decision_ComputedTask[];
}
 */
/******************************************************************* */

export const getDateControlFormattedDate = (dt: Date | undefined): string => {
  // YYYY-MM-DD
  // Addition 15.08.2024: Remove (return '' when date is 1900 or 3000)
  if (
    dt === undefined ||
    dt.getTime() === new Date(1900, 0, 1).getTime() ||
    dt.getTime() === new Date(3000, 0, 1).getTime()
  )
    return '';
  return (
    dt.getFullYear().toString().padStart(4, '0') +
    '-' +
    (dt.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    dt.getDate().toString().padStart(2, '0')
  );
};

export const getDateControlFormattedDateFromDbDayNo = (dayno: number | undefined): string => {
  // YYYY-MM-DD
  const dt: Date | undefined = dateFromDayNo(dayno ?? 0);
  return dt === undefined
    ? ''
    : dt.getFullYear() +
        '-' +
        (dt.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        dt.getDate().toString().padStart(2, '0');
};

export const getNumberofdaysBetweenDates = (startDate: Date, endDate: Date): number => {
  if (startDate === undefined || endDate === undefined) return 0;
  const differenceInTime = endDate.getTime() - startDate.getTime();
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  return differenceInDays;
};

export const getNumberofdaysBetweenTimes = (startDate: number, endDate: number): number => {
  // number is .getTime() applied on startDate/endDate
  const differenceInTime = endDate - startDate;
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  return differenceInDays;
};

export const dayNoFromDate = (inDate: Date | undefined, representingEndDate: boolean = false): number => {
  const Date1900: Date = new Date(1900, 0, 1);
  const Date3000: Date = new Date(3000, 0, 1);

  if (inDate === undefined) inDate = representingEndDate ? Date3000 : Date1900;

  return getNumberofdaysBetweenDates(Date1900, inDate);
};

export const getDateFromDateControlFormattedDate = (dtstring: string): Date | undefined => {
  //        return (dtstring === "")? undefined : new Date(Date.parse(dtstring)); // Had frustrating discrepancy in timezone, maybe due to using Date.parse??

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#interpretation_of_two-digit_years
  // Conversion of two digit dates leads to disaster for typing year in the control
  const year = Number(dtstring.substring(0, 4));
  let retDate: Date | undefined =
    dtstring === ''
      ? undefined
      : new Date(
          Number(dtstring.substring(0, 4)),
          Number(dtstring.substring(5, 7)) - 1,
          Number(dtstring.substring(8, 10))
        );
  if (year < 100) retDate?.setFullYear(year);

  return retDate;
};

const dayNo1900 = 0;
const dayNo3000 = 401767;

export const getDayNoFromDateControlFormattedDate = (
  dtstring: string,
  representingEndDate: boolean = false
): number => {
  //        return (dtstring === "")? undefined : new Date(Date.parse(dtstring)); // Had frustrating discrepancy in timezone, maybe due to using Date.parse??
  return dtstring === ''
    ? representingEndDate
      ? dayNo3000
      : dayNo1900
    : dayNoFromDate(
        new Date(
          Number(dtstring.substring(0, 4)),
          Number(dtstring.substring(5, 7)) - 1,
          Number(dtstring.substring(8, 10))
        )
      );
};

export const dbDayFromDateCtrlString = (newDate: string): number => {
  // YYYY-MM-DD
  return dayNoFromDate(getDateFromDateControlFormattedDate(newDate));
};

export const dateFromDayNo = (inDayNo: number): Date | undefined => {
  if (inDayNo === dayNo1900 || inDayNo === dayNo3000) return undefined;
  return new Date(1900, 0, inDayNo + 1);
};

export const getSimpleFormattedDate = (dt: Date | undefined): string => {
  // DD.MM.YY
  return dt === undefined
    ? ''
    : dt.getDate().toString().padStart(2, '0') +
        '.' +
        (dt.getMonth() + 1).toString().padStart(2, '0') +
        '.' +
        dt.getFullYear().toString().substring(2, 4);
};

export const getSimpleFormattedDate2 = ({
  dt,
  unsetPlaceholder = '',
}: {
  dt: Date | undefined;
  unsetPlaceholder?: string;
}): string => {
  // DD.MM.YYYY
  return dt === undefined
    ? unsetPlaceholder
    : dt.getDate().toString().padStart(2, '0') +
        '.' +
        (dt.getMonth() + 1).toString().padStart(2, '0') +
        '.' +
        dt.getFullYear().toString();
};

export const getSimpleFormattedLogTimeString = (logtime: string): string => {
  // DD.MM.YY HH.mm.ss
  if (logtime === '') return '';
  const dt: Date = new Date(
    Number(logtime.substring(0, 4)),
    Number(logtime.substring(5, 7)) - 1,
    Number(logtime.substring(8, 10)),
    Number(logtime.substring(11, 13)),
    Number(logtime.substring(14, 16)),
    Number(logtime.substring(17, 19))
  );
  return dt === undefined
    ? ''
    : dt.getDate().toString().padStart(2, '0') +
        '.' +
        (dt.getMonth() + 1).toString().padStart(2, '0') +
        '.' +
        dt.getFullYear().toString().substring(2, 4) +
        ' ' +
        dt.getHours().toString().padStart(2, '0') +
        ':' +
        dt.getMinutes().toString().padStart(2, '0') +
        '.' +
        dt.getSeconds().toString().padStart(2, '0');
};

export const weekDayFromDbDay = (inDayNo: number): number | undefined => {
  // 0: Monday -> 6: Sunday
  const curDate: Date | undefined = dateFromDayNo(inDayNo);
  const curDaynoInternal = curDate?.getDay() ?? undefined;
  if (curDaynoInternal === undefined) return undefined;
  return curDaynoInternal === 0 ? 6 : curDaynoInternal - 1; // 0:Monday --> 6: sunday
};

const addDaysToDate = (startDate: Date, daysToAdd: number) => {
  return new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + daysToAdd);
};

export const convertFreeTextPrescrToBackend = (param: DosageTimeParams): Cpl_Decision | undefined => {
  if (param === undefined) return undefined;

  // The only values that are to be used: (ZZZ Must be converted!)
  const beginDayNo: number | undefined = dayNoFromDate(param.startDate, false);
  let recurrenceInterval: number = param.intervalDays ?? 0;
  let recurrenceIntervalOption: string = param.endOption;
  let endRecurrenceDayNo: number | undefined = dayNoFromDate(param.endDate, true);
  if (param.endOption === 'Max') {
    recurrenceInterval = 0;
    endRecurrenceDayNo = dayNoFromDate(undefined, true);
    recurrenceIntervalOption = '';
  } else if (param.endOption === 'interval') {
    recurrenceInterval = param.intervalDays ?? 0;
    endRecurrenceDayNo = beginDayNo + recurrenceInterval - 1;
    recurrenceIntervalOption = 'Interval';
  } else if (param.endOption === '') {
    recurrenceInterval = 0; // ZZZ should not matter
    endRecurrenceDayNo = dayNoFromDate(param.endDate, true);
  } else return undefined; // ZZZ Fail return

  const recurrenceTag: string = 'RecurrenceParams_Tag#1';

  const componentMedication: Cpl_Decision_Component_Medication = {
    state: '',
    legemiddeldose: undefined,
    doseringsTekst: '',
    bruksomrade: undefined,
    forholdsregler: undefined,
    administrasjonsvei: undefined,
    courseOfTherapy: { display: '' },
    legemiddelpakningKilde: '',
    legemiddelpakningId: '',
    medicationDisplayValue: '',
  };

  const recurrentTask: Cpl_Decision_RecurrentTask = {
    recurrenceTag,
    dayNoOffset: 0,
    daySegment: 'Morning', // ZZZ
    timeOffset: 0,
    alertOffset: 0,
    duration: 5,
    state: '',
    calendarInfo: '',
    taskMedication: {
      dosageUnit: '', /// ZZZZ temporary flag to indicate free text prescription
      dosageAmount: 0,
      medicationDisplayValue: '',
      state: 'Task_MedicationState',
    },
    autoSavedTasks: [],
  };

  const component: Cpl_Decision_Component = {
    beginDayNo: beginDayNo,
    endRecurrenceDayNo: endRecurrenceDayNo,
    recurrenceInterval: recurrenceInterval,
    recurrenceIntervalOption: recurrenceIntervalOption,
    recurrenceIntervalOffset: 0,
    savedTasksOffset: 0,
    description: '',
    recurrentTasks: [recurrentTask],
    savedTasks: [],
    componentMedication,
  };

  const recurrenceParam: Cpl_Decision_RecurrenceParams = {
    tag: recurrenceTag,
    recurrencePattern: 'Day*1', // set
    recurrenceType: '', // not set
    recurrenceCycle: 0, // not set
    recurrenceOffset: 0,
    years: [],
    quarters: [],
    yearDays: [],
    months: [],
    monthDays: [],
    weeks: [],
    weekDays: [],
  };

  const decisionMedication: Cpl_Decision_Medication = {
    medicationDisplayValue: '',
    state: '',
  };

  const curBackendDecision: Cpl_Decision = {
    patientId: 'Id_1',
    category: '',
    decisionMedication,
    recurrenceParams: [recurrenceParam],
    components: [component],
    computedTasks: [],
    // a lot of crap from base structures:
    mailMessages: [],
    text: { status: '', div: '' }, // Dette her blir for dumt. Den er frivillig i FHIR
    contained: [],
    extension: [],
    modifierExtension: [],
    id: '',
    meta: { versionId: '', profile: [], security: [], tag: [] },
    implicitRules: 'https://capableapi.azurewebsites.net/ImplicitRules', // ZZZ No meaning whatsoever, but follows URI-syntax
    language: '',
  };

  return curBackendDecision;
};

export const convertParamToBackend = (param: DoseringParams | undefined): Cpl_Decision | undefined => {
  const decisionMedication: Cpl_Decision_Medication = {
    medicationDisplayValue: '',
    state: '',
  };
  const componentMedication: Cpl_Decision_Component_Medication = {
    state: '',
    legemiddeldose: undefined,
    doseringsTekst: '',
    bruksomrade: undefined,
    forholdsregler: undefined,
    administrasjonsvei: undefined,
    courseOfTherapy: { display: '' },
    legemiddelpakningKilde: '',
    legemiddelpakningId: '',
    medicationDisplayValue: '',
  };
  //const recurrenceParams:Cpl_Decision_RecurrenceParams[]=[];
  const computedTasks: Cpl_Decision_ComputedTask[] = [];

  const recurrenceTag: string = 'RecurrenceParams_Tag#1';
  //let recurrencePattern:string="";
  const recurrenceType: string = '';
  const recurrenceCycle: number = 0;
  let recurrenceOffset: number = 45402; // a monday
  let beginRecurrenceDayNo: number = 0;
  let endRecurrenceDayNo: number = 0;
  //let recurrenceInterval:number=0;
  //let recurrenceIntervalOption: string="";
  const description: string = '';
  //let recurrentTasks: Cpl_Decision_RecurrentTask[]=[]
  //let savedTasks:Cpl_Decision_SavedTask[]=[];

  if (param === undefined) return undefined;
  let {
    // eslint-disable-next-line prefer-const
    beginDate,
    // eslint-disable-next-line prefer-const
    endRecurrenceDate,
    recurrenceInterval,
    recurrenceIntervalOption,
    // eslint-disable-next-line prefer-const
    recurrenceIntervalOffset,
    // eslint-disable-next-line prefer-const
    recurrencePattern,
    // eslint-disable-next-line prefer-const
    tasks,
    // eslint-disable-next-line prefer-const
    savedTasks,
  } = param;

  const component: Cpl_Decision_Component = {
    beginDayNo: beginRecurrenceDayNo,
    endRecurrenceDayNo,
    recurrenceInterval: recurrenceInterval ?? 0,
    recurrenceIntervalOption,
    recurrenceIntervalOffset: 0,
    savedTasksOffset: 0,
    description,
    recurrentTasks: [],
    savedTasks: [],
    componentMedication,
  };
  const components: Cpl_Decision_Component[] = [component];

  const curBackendDecision: Cpl_Decision = {
    patientId: 'Id_1',
    category: '',
    decisionMedication,
    recurrenceParams: [],
    components,
    computedTasks,
    // a lot of crap from base structures:
    mailMessages: [],
    text: { status: '', div: '' }, // Dette her blir for dumt. Den er frivillig i FHIR
    contained: [],
    extension: [],
    modifierExtension: [],
    id: '',
    meta: { versionId: '', profile: [], security: [], tag: [] },
    implicitRules: 'https://capableapi.azurewebsites.net/ImplicitRules', // ZZZ No meaning whatsoever, but follows URI-syntax
    language: '',
  };

  const convertDaySegment = (input: string): string | undefined => {
    const indx: number = timeOfDayOptions.findIndex((i) => i === input);
    return indx === -1 ? undefined : timeOfDayOptionsDb[indx];
  };

  // if (param === undefined)
  //     return curBackendDecision;

  const beginDayNo = dayNoFromDate(beginDate);

  if (savedTasks)
    component.savedTasks = savedTasks.map((item) => {
      return {
        sourceTask_Id: 0,
        dayNo: dayNoFromDate(beginDate) + item.dayNoOffset,
        taskResult: {
          state: '',
          value: '',
        },
        dayNoOffset: 0,
        daySegment: convertDaySegment(item.daySegment) ?? 'UNDEFINED',
        timeOffset: 0,
        alertOffset: 0,
        duration: 5,
        state: '',
        calendarInfo: '',
        taskMedication: {
          dosageUnit: item.dosageUnit,
          dosageAmount: item.dosageAmount,
          medicationDisplayValue: '',
          state: 'Task_MedicationState',
        },
      };
    });

  if (recurrencePattern !== '') {
    // recurrence pattern exists

    beginRecurrenceDayNo = beginDayNo + (recurrenceIntervalOffset ?? 0);
    if (recurrenceIntervalOption === 'Max') {
      // eslint-disable-next-line
      recurrenceInterval = 0; // ZZZ Should not matter
      endRecurrenceDayNo = dayNoFromDate(undefined, true);
      recurrenceIntervalOption = '';
    } else if (recurrenceIntervalOption === 'interval') {
      recurrenceInterval = recurrenceInterval ?? 0;
      endRecurrenceDayNo = beginRecurrenceDayNo + (recurrenceInterval ? recurrenceInterval : 1) - 1;
      recurrenceIntervalOption = 'Interval';
    } else if (recurrenceIntervalOption === '') {
      recurrenceInterval = 0; // ZZZ should not matter
      endRecurrenceDayNo = dayNoFromDate(endRecurrenceDate, true);
    } else return curBackendDecision; // ZZZ Fail return (auto or multiple)

    if (recurrencePattern.startsWith('Day')) {
      //recurrenceType="Day";
      //recurrenceCycle=cycle??0;
      recurrenceOffset = 0; // Check with Knut
    } else if (recurrencePattern.startsWith('Weeks')) {
      recurrenceOffset = 45402; // Any monday is appearantly good
    } else if (recurrencePattern.startsWith('AlternatingWeeks')) {
      recurrenceOffset = 45402; // Any monday is appearantly good
    } else if (recurrencePattern === 'Month')
      // Not supported
      return curBackendDecision;
    else {
      // Unknown type, return empty
      return curBackendDecision;
    }

    const recurrenceParam: Cpl_Decision_RecurrenceParams = {
      tag: recurrenceTag,
      recurrencePattern, // set
      recurrenceType, // not set
      recurrenceCycle, // not set
      recurrenceOffset,
      years: [],
      quarters: [],
      yearDays: [],
      months: [],
      monthDays: [],
      weeks: [],
      weekDays: [],
    };

    curBackendDecision.recurrenceParams = [recurrenceParam];

    component.beginDayNo = beginDayNo;
    component.endRecurrenceDayNo = endRecurrenceDayNo;
    component.recurrenceInterval = recurrenceInterval;
    component.recurrenceIntervalOption = recurrenceIntervalOption;
    component.recurrenceIntervalOffset = recurrenceIntervalOffset ?? 0;

    // recurrent tasks:
    if (tasks)
      component.recurrentTasks = tasks.map((item) => {
        return {
          recurrenceTag,
          dayNoOffset: item.dayNoOffset,
          daySegment: convertDaySegment(item.daySegment) ?? 'UNDEFINED',
          timeOffset: 0,
          alertOffset: 0,
          duration: 5,
          state: '',
          calendarInfo: '',
          taskMedication: {
            dosageUnit: item.dosageUnit,
            dosageAmount: item.dosageAmount,
            medicationDisplayValue: '',
            state: 'Task_MedicationState',
          },
          autoSavedTasks: [],
        };
      });
  }
  curBackendDecision.components = [component];
  return curBackendDecision;
};

const dagrepArr: string[] = [
  'none',
  'Hver dag',
  'Hver 2.dag',
  'Hver 3.dag',
  'Hver 4.dag',
  'Hver 5.dag',
  'Hver 6.dag',
  'Hver 7.dag',
];
const ukerepArr: string[] = [
  'none',
  'Hver uke',
  'Hver 2.uke',
  'Hver 3.uke',
  'Hver 4.uke',
  'Hver 5.uke',
  'Hver 6.uke',
  'Hver 7.uke',
  'Hver 8.uke',
];
const mndrepArr: string[] = [
  'none',
  'Hver måned',
  'Hver 2.måned',
  'Hver 3.måned',
  'Hver 4.måned',
  'Hver 5.måned',
  'Hver 6.måned',
  'Hver 7.måned',
  'Hver 8.måned',
  'Hver 9.måned',
  'Hver 10.måned',
  'Hver 11.måned',
  'Hver 12.måned',
];

export const convertParamToDosering = (param: DoseringParams | undefined): DoseringInternal | undefined => {
  if (param === undefined) return undefined;
  const {
    beginDate,
    // endRecurrenceDate,
    // recurrenceInterval,
    // recurrenceIntervalOption,
    recurrenceIntervalOffset,
    recurrencePattern,
    // cycle,
    monthDay,
    tasks,
    savedTasks,
  } = param;
  // Inital values
  //const beginDate: Date|undefined = param.beginDate,
  const startRecurrentDate: Date | undefined = param.beginDate
    ? addDaysToDate(param.beginDate, param.recurrenceIntervalOffset ?? 0)
    : undefined;
  const endRecurrentDate: Date | undefined = param.endRecurrenceDate;
  const interval: number = param.recurrenceInterval ?? 0; // number of days between startDate and endDate
  // Either interval is given alone, or startDate and interval and not endDate, or startDate and endDate and not interval
  let pattern: string = ''; //""|"Dag"|"Uke"|"Måned"
  let dagrep: string = 'Hver dag'; //"Hver dag"|"Hver 2.dag"|"Hver 3.dag"|"Hver 4.dag"|"Hver 5.dag"|"Hver 6.dag"|"Hver 7.dag"
  let ukerep: string = 'Hver uke'; //"Hver uke"|"Hver 2.uke"|"Hver 3.uke"|"Hver 4.uke"|"Hver 5.uke"|"Hver 6.uke"|"Hver 7.uke"|"Hver 8.uke"
  let mndrep: string = 'Hver måned'; //"Hver måned"|"Hver 2.måned"|"Hver 3.måned"|"Hver 4.måned"|"Hver 5.måned"|"Hver 6.måned"|"Hver 7.måned"|"Hver 8.måned"|"Hver 9.måned"|"Hver 10.måned"|"Hver 11.måned"|"Hver 12.måned"
  //const monthDay: string|undefined = (new Date()).getDate().toString(), //"1"-"31"
  let noOfWeeks: number | undefined = 1; //1, 2, 3 ++ (antall uker i alterenerende mønster)
  let weeks: TaskType[][][] = [[[], [], [], [], [], [], []]]; // benyttes for Uke. Siste dimensjon svarer til noOfWeeks
  let dosagetasks: TaskType[] | undefined = []; // benyttes for Dag, Måned
  const explicitTasks: NewExplicitTasks[] = []; // benyttes for Enkeltdoser
  //conversionState?:string|undefined; // undefined or 'Ok' is 'Ok',  'Error' used if there are errors in the format.
  //conversionMsg?:string|undefined; // used if there are errors in the format.

  const savedTasksExists: boolean = (savedTasks?.length ?? 0) > 0;
  const endOption: string | undefined =
    savedTasksExists && recurrencePattern === ''
      ? 'auto'
      : param.recurrenceIntervalOption === 'interval'
      ? 'interval'
      : param.endRecurrenceDate === undefined
      ? 'Max'
      : 'endDate';

  const getCycle = (bigString: string, searchFor: string): number | undefined => {
    // according to pattern "Week*3"
    const retval: number = Number(bigString.substring(searchFor.length + 1)); // including the *
    if (Number.isNaN(retval)) return undefined;
    return retval;
  };

  let newCycle: number | undefined;
  let dosageDayNo: number;

  const orderedTasks = tasks !== undefined ? [...tasks].sort(sortImportTasks) : [];
  const orderedSavedTasks = savedTasks !== undefined ? [...savedTasks].sort(sortImportTasks) : [];

  if (recurrencePattern === '') {
    // No recurrence pattern
    if (!(recurrenceIntervalOffset === undefined || recurrenceIntervalOffset === 0)) return undefined; // does not make sense
  } else if (recurrencePattern.startsWith('Day')) {
    pattern = 'Dag';
    newCycle = getCycle(recurrencePattern, 'Day');
    dagrep = newCycle === 1 ? 'Hver dag' : 'Hver ' + newCycle?.toString() + '. dag';
    if (tasks && tasks.filter((i) => i.dayNoOffset !== 0).length !== 0) return undefined; //{conversionState:"Error", conversionMsg:"Day pattern with Task.dayOffset !==0"}
    dosagetasks =
      orderedTasks.map((item) => {
        return {
          antall: item.dosageAmount,
          unit: item.dosageUnit,
          timeOfDay: item.daySegment,
        };
      }) ?? [];
  } else if (recurrencePattern.startsWith('Weeks')) {
    pattern = 'Uke';
    newCycle = getCycle(recurrencePattern, 'Weeks');
    ukerep = newCycle === 1 ? 'Hver uke' : 'Hver ' + newCycle?.toString() + '. uke';
  } else if (recurrencePattern.startsWith('AlternatingWeeks')) {
    pattern = 'Uke';
    newCycle = getCycle(recurrencePattern, 'AlternatingWeeks');
    ukerep = 'Hver uke'; // Not used, but as a default?
    noOfWeeks = newCycle;
  } else if (recurrencePattern.startsWith('Month')) {
    pattern = 'Måned';
    newCycle = getCycle(recurrencePattern, 'Month');
    mndrep = newCycle === 1 ? 'Hver måned' : 'Hver ' + newCycle?.toString() + '. måned';
    if (tasks && tasks.filter((i) => i.dayNoOffset !== 0).length !== 0) return undefined; //{conversionState:"Error", conversionMsg:"Day pattern with Task.dayOffset !==0"}
    dosagetasks =
      orderedTasks.map((item) => {
        return {
          antall: item.dosageAmount,
          unit: item.dosageUnit,
          timeOfDay: item.daySegment,
        };
      }) ?? [];
  } else {
    // Not known pattern
    return undefined;
  }

  if (pattern === 'Uke') {
    // The name is week, but nevertheless uses days in cycle
    if (newCycle === undefined) return undefined;
    if (noOfWeeks === undefined) return undefined;

    const maxOffset: number = Math.max(...(tasks?.map((i) => i.dayNoOffset) ?? []), -Infinity);
    if (maxOffset > noOfWeeks * 7 - 1) return undefined;

    weeks = [...Array(noOfWeeks)].reduce((acc) => [...acc, [[], [], [], [], [], [], []]], []); // ZZZ Ok?
    // Ok, fill in the task values
    orderedTasks.forEach((item) => {
      const weekIx: number = Math.floor(item.dayNoOffset / 7);
      const dayIx: number = item.dayNoOffset % 7;
      weeks[weekIx][dayIx].push({
        antall: item.dosageAmount,
        unit: item.dosageUnit,
        timeOfDay: item.daySegment,
      });
    });
  }

  if (savedTasks && savedTasks.length > 0) {
    // Any saved tasks
    const dosageBaseDayNo = dayNoFromDate(beginDate);
    orderedSavedTasks.forEach((item) => {
      dosageDayNo = dosageBaseDayNo === undefined ? 0 /* ZZZ change before noon */ : dosageBaseDayNo + item.dayNoOffset;
      const explTsk: NewExplicitTasks | undefined = explicitTasks.find((i) => i.dateOffset === item.dayNoOffset);
      if (explTsk === undefined)
        // new ExplicitTask should be added
        explicitTasks.push({
          dosageDayNo: dosageDayNo,
          dateOffset: item.dayNoOffset,
          tasks: [
            {
              antall: item.dosageAmount,
              unit: item.dosageUnit,
              timeOfDay: item.daySegment,
            },
          ],
        });
      // add new element to exisiting ExplicitTask
      else
        explTsk.tasks.push({
          antall: item.dosageAmount,
          unit: item.dosageUnit,
          timeOfDay: item.daySegment,
        });
    });
  }

  return {
    beginDayNo: dayNoFromDate(beginDate),
    startRecurrentDayNo: dayNoFromDate(startRecurrentDate),
    endRecurrentDayNo: dayNoFromDate(endRecurrentDate),
    interval,
    endOption,
    // Either interval is given alone, or startDate and interval and not endDate, or startDate and endDate and not interval
    pattern, //"Dag"|"Uke"|"Måned"|"Enkeltdoser"
    dagrep, //"Hver dag"|"Hver 2.dag"|"Hver 3.dag"|"Hver 4.dag"|"Hver 5.dag"|"Hver 6.dag"|"Hver 7.dag"
    ukerep, //"Hver uke"|"Hver 2.uke"|"Hver 3.uke"|"Hver 4.uke"|"Hver 5.uke"|"Hver 6.uke"|"Hver 7.uke"|"Hver 8.uke"
    mndrep, //"Hver måned"|"Hver 2.måned"|"Hver 3.måned"|"Hver 4.måned"|"Hver 5.måned"|"Hver 6.måned"|"Hver 7.måned"|"Hver 8.måned"|"Hver 9.måned"|"Hver 10.måned"|"Hver 11.måned"|"Hver 12.måned"
    monthDay, //"1"-"31"
    noOfWeeks, //1, 2, 3 ++ (antall uker i alterenerende mønster)
    weeks, // benyttes for Uke. Siste dimensjon svarer til noOfWeeks
    dosagetasks, // benyttes for Dag, Måned
    explicitTasks, // benyttes for Enkeltdoser
  };
};

export const convertDoseringToParam = (dosering: DoseringInternal): DoseringParams | undefined => {
  const {
    beginDayNo,
    startRecurrentDayNo,
    endRecurrentDayNo,
    interval,
    endOption,
    pattern,
    dagrep,
    ukerep,
    mndrep,
    monthDay,
    noOfWeeks,
    weeks,
    dosagetasks,
    explicitTasks,
  } = dosering;
  const beginDate = dateFromDayNo(beginDayNo ?? 0);
  const startRecurrentDate = dateFromDayNo(startRecurrentDayNo ?? 0);
  const endRecurrentDate = dateFromDayNo(endRecurrentDayNo ?? dayNo3000);

  let cycle: number = 0;
  let alternatingWeeks: boolean = false;
  let tasks: ImportTaskType[] = [];
  const savedTasks: ImportTaskType[] = [];
  let newBeginDate: Date | undefined = beginDate;
  let newEndRecurrenceDate: Date | undefined = endRecurrentDate;
  let newRecurrenceInterval: number | undefined = interval;
  let newRecurrenceIntervalOption: string = '';
  let newRecurrenceIntervalOffset: number | undefined = undefined;
  let newRecurrencePattern = '';

  /*   const calculateDayOffset = (
    startDateTimestamp: number,
    curDate: Date,
  ): number => {
    // return no of days between curDate and a startDate given by its timestamp
    return Math.round(
      (curDate.getTime() - startDateTimestamp) / (1000 * 3600 * 24),
    );
  }; */

  if (pattern === 'Dag') {
    cycle = dagrepArr.findIndex((i) => i === dagrep);
    if (cycle === -1) return undefined;
    tasks =
      dosagetasks?.map((i) => {
        return {
          dayNoOffset: 0,
          dosageAmount: i.antall,
          dosageUnit: i.unit,
          daySegment: i.timeOfDay,
        };
      }) ?? [];
    newRecurrencePattern = 'Day*' + cycle.toString();
  } else if (pattern === 'Uke') {
    alternatingWeeks = noOfWeeks && noOfWeeks > 1 ? true : false;
    if (noOfWeeks && alternatingWeeks) {
      // for typescripts sake
      cycle = 7 * noOfWeeks;
      newRecurrencePattern = 'AlternatingWeeks*' + noOfWeeks?.toString();
    } else {
      cycle = ukerepArr.findIndex((i) => i === ukerep);
      if (cycle === -1) return undefined;
      newRecurrencePattern = 'Weeks*' + cycle.toString();
      cycle = 7 * cycle; // Ask Knut. cycle is days for "Dag" and "Uke" pattern
    }
    weeks.forEach((week, weekIx) =>
      week.forEach((day, dayIx) =>
        day.forEach(
          (task) =>
            task &&
            tasks.push({
              dayNoOffset: 7 * weekIx + dayIx,
              dosageAmount: task.antall,
              dosageUnit: task.unit,
              daySegment: task.timeOfDay,
            })
        )
      )
    );
  } else if (pattern === 'Måned') {
    cycle = mndrepArr.findIndex((i) => i === mndrep);
    tasks =
      dosagetasks?.map((i) => {
        return {
          dayNoOffset: 0,
          dosageAmount: i.antall,
          dosageUnit: i.unit,
          daySegment: i.timeOfDay,
        };
      }) ?? [];
    newRecurrencePattern = 'Month*' + cycle.toString();
  }

  if (explicitTasks && explicitTasks.length > 0) {
    // Explicit Tasks given
    // Get first dose:
    const firstDayNo: number = Math.min(...(explicitTasks?.map((i) => i.dosageDayNo) ?? []), Infinity);
    // Get last dose:
    const lastDayNo: number = Math.max(...(explicitTasks?.map((i) => i.dosageDayNo) ?? []), -Infinity);
    if (firstDayNo === Infinity || lastDayNo === -Infinity) return undefined;
    explicitTasks.forEach((taskdays) =>
      taskdays.tasks.forEach((task) =>
        savedTasks.push({
          dayNoOffset: taskdays.dosageDayNo - firstDayNo,
          dosageAmount: task.antall,
          dosageUnit: task.unit,
          daySegment: task.timeOfDay,
        })
      )
    );

    if (pattern === '') {
      // saved tasks without recurrent pattern
      newBeginDate = dateFromDayNo(firstDayNo);
      newEndRecurrenceDate = dateFromDayNo(lastDayNo);
      newRecurrenceInterval = lastDayNo - firstDayNo;
      newRecurrenceIntervalOption = 'auto'; // Not consequently used throughout
      newRecurrenceIntervalOffset = undefined;
      newRecurrencePattern = '';
    } else {
      // recurrent pattern with saved tasks
      newBeginDate = dateFromDayNo(firstDayNo); // get it from tasks
      newRecurrenceIntervalOption =
        endOption === 'Max'
          ? 'Max'
          : endOption === 'endDate'
          ? ''
          : endOption === 'interval'
          ? 'interval'
          : endOption === 'auto'
          ? ''
          : '';
      newRecurrenceIntervalOffset = startRecurrentDayNo! - firstDayNo;
      // StartRecurrentDate must be given
      newEndRecurrenceDate = endOption === 'endDate' ? endRecurrentDate : undefined;
      newRecurrenceInterval = endOption === 'interval' ? interval : undefined;
      // this one is already set: newRecurrencePattern = "";
    }
  } else {
    // recurrent pattern without saved tasks
    newBeginDate = startRecurrentDate;
    newRecurrenceIntervalOption =
      endOption === 'Max'
        ? 'Max'
        : endOption === 'endDate'
        ? ''
        : endOption === 'interval'
        ? 'interval'
        : endOption === 'auto'
        ? ''
        : '';
    newRecurrenceIntervalOffset = 0;
    newEndRecurrenceDate = endOption === 'endDate' ? endRecurrentDate : undefined;
    newRecurrenceInterval = endOption === 'interval' ? interval : undefined;
    // this one is already set: newRecurrencePattern = "";
  }

  return {
    beginDate: newBeginDate,
    endRecurrenceDate: newEndRecurrenceDate,
    recurrenceInterval: newRecurrenceInterval,
    recurrenceIntervalOffset: newRecurrenceIntervalOffset,
    recurrenceIntervalOption: newRecurrenceIntervalOption,
    recurrencePattern: newRecurrencePattern,
    cycle: undefined,
    recurrenceType: '',
    monthDay,
    tasks,
    savedTasks,
  };
};

export const convertDoseringsparamsToParam = (
  beginDayNo: number,
  startRecurrentDayNo: number,
  endDayNo: number,
  interval: number | undefined,
  endOption: string,
  pattern: string,
  dagrep: string,
  ukerep: string,
  mndrep: string,
  monthDay: string,
  noOfWeeks: number,
  weeks: TaskType[][][],
  dosagetasks: TaskType[],
  explicitTasks: NewExplicitTasks[]
): DoseringParams | undefined => {
  return convertDoseringToParam({
    beginDayNo: beginDayNo,
    startRecurrentDayNo: startRecurrentDayNo,
    endRecurrentDayNo: endDayNo,
    interval,
    endOption,
    pattern,
    dagrep,
    ukerep,
    mndrep,
    monthDay,
    noOfWeeks,
    weeks,
    dosagetasks,
    explicitTasks,
  });
};

export const convertBackendToDosering = (decision: Cpl_Decision | undefined): void /*DoseringInternal*/ => {
  /*   
DoseringInternal = {
    beginDayNo?: number | undefined;
    startRecurrentDayNo?: number | undefined;
    endRecurrentDayNo?: number | undefined;
    interval?: number; // Number of days between startDate and endDate, value 0 means not in use
    endOption: string | undefined; // "Max", "interval", "endDate", "auto", "multiple" ("Max" har ikke noen sluttdato (endDate settes kanskje til 3000 eller noe slikt), "interval" (betyr tallet er gitt ved intervalDays), "endDate" (betyr at endDate har blitt satt)) "auto" benyttes ved kun explicitTasks
    // Either interval is given alone, or startDate and interval and not endDate, or startDate and endDate and not interval
    pattern: string; //"Dag"|"Uke"|"Måned"|"Enkeltdoser"
    dagrep?: string | undefined; //"Hver dag"|"Hver 2.dag"|"Hver 3.dag"|"Hver 4.dag"|"Hver 5.dag"|"Hver 6.dag"|"Hver 7.dag"
    ukerep?: string | undefined; //"Hver uke"|"Hver 2.uke"|"Hver 3.uke"|"Hver 4.uke"|"Hver 5.uke"|"Hver 6.uke"|"Hver 7.uke"|"Hver 8.uke"
    mndrep?: string | undefined; //"Hver måned"|"Hver 2.måned"|"Hver 3.måned"|"Hver 4.måned"|"Hver 5.måned"|"Hver 6.måned"|"Hver 7.måned"|"Hver 8.måned"|"Hver 9.måned"|"Hver 10.måned"|"Hver 11.måned"|"Hver 12.måned"
    monthDay?: string | undefined; //"1"-"31"
    noOfWeeks?: number | undefined; //1, 2, 3 ++ (antall uker i alterenerende mønster)
    weeks: TaskType[][][]; // benyttes for Uke. Siste dimensjon svarer til noOfWeeks
    dosagetasks?: TaskType[] | undefined; // benyttes for Dag, Måned
    explicitTasks: NewExplicitTasks[]; // benyttes for Enkeltdoser
    conversionState?: string | undefined; // undefined or 'Ok' is 'Ok',  'Error' used if there are errors in the format.
    conversionMsg?: string | undefined; // used if there are errors in the format.
  };  
*/
  return;
};
