/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Redirect, RouteComponentProps } from '@reach/router';
import { addMinutes, startOfDay } from 'date-fns';
import { capitalize, sortBy } from 'lodash';
import React, { FC } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Block from '../../components/Block';
import ErrorPageContent from '../../components/ErrorPageContent';
import { Column, Row } from '../../components/Flex';
import Heading from '../../components/Heading';
import Loader from '../../components/loader/Loader';
import AuthedPageLayout from '../../components/pagelayout/AuthedPageLayout';
import Text from '../../components/Text';
import { TFunction, useT } from '../../i18n/useT';
import useSelectedPatientId from '../../lib/useSelectedPatientId';
import SleepDiaryClient from '../../network/SleepDiaryClient';
import useClientFetch from '../../network/useClientFetch';
import formatDate from '../../utils/formatDate';
import { round } from '../../utils/math';
import { toServerDate } from '../../utils/serverDateConverter';
import theme from '../../utils/theme';
import { ObservationDetails } from './types';

const formatLegend = (label: string, t: TFunction) => {
  switch (label) {
    case 'outOfBedTimeOffset':
      return t('Time spent out of bed');
    case 'finalAttemptToSleepTimeOffset':
      return t('Could not sleep more');
    case 'finalWakeupTimeOffset':
      return t('Final awakening');
    case 'startToSleepTimeOffset':
      return t('Sleep latency');
    case 'attemptToSleepTimeOffset':
      return t('Lights off');
    case 'gotoBedTimeOffset':
      return t('Bedtime');
    default:
      return label;
  }
};

export const mapData = (elements: ObservationDetails[]) => {
  return elements.map((el) => {
    if (el.patientId) {
      return {
        ...el,

        outOfBedTimeOffsetOriginal: el.outOfBedTimeOffset,
        finalAttemptToSleepTimeOffsetOriginal: el.finalAttemptToSleepTimeOffset,
        finalWakeupTimeOffsetOriginal: el.finalWakeupTimeOffset,
        startToSleepTimeOffsetOriginal: el.startToSleepTimeOffset,
        attemptToSleepTimeOffsetOriginal: el.attemptToSleepTimeOffset,
        gotoBedTimeOffsetOriginal: el.gotoBedTimeOffset,

        outOfBedTimeOffset: el.outOfBedTimeDeltaOffset,
        finalAttemptToSleepTimeOffset: el.finalAttemptToSleepTimeDeltaOffset,
        finalWakeupTimeOffset: el.finalWakeupTimeDeltaOffset,
        startToSleepTimeOffset: el.startToSleepTimeDeltaOffset,
        attemptToSleepTimeOffset: el.attemptToSleepTimeDeltaOffset,
        gotoBedTimeOffset: el.gotoBedTimeOffset,
      };
    } else {
      return {
        ...el,

        outOfBedTimeOffset: undefined,
        finalAttemptToSleepTimeOffset: undefined,
        finalWakeupTimeOffset: undefined,
        startToSleepTimeOffset: undefined,
        attemptToSleepTimeOffset: undefined,
        gotoBedTimeOffset: undefined,
      };
    }
  });
};

const MainChartTooltip = ({ active, payload, label }: any) => {
  const t = useT();
  const someDay = startOfDay(new Date());

  if (active) {
    return (
      <Column
        css={css`
          background-color: white;
          border-radius: 4px;
          border: 1px solid ${theme.colors.gray};
          padding: 4px 8px;
        `}
      >
        <Text size="large" strong>
          {capitalize(formatDate(new Date(label), 'iiii'))}
        </Text>
        {payload.map((item: any, index: number) => {
          const val = item.payload[`${item.dataKey}Original`];
          return (
            <Row key={index} horizontal="space-between">
              <Text>{formatLegend(item.dataKey, t)}</Text>
              {typeof val !== 'undefined' && (
                <Text marginLeft={12}>{formatDate(addMinutes(someDay, val as number), 'HH:mm')}</Text>
              )}
            </Row>
          );
        })}
      </Column>
    );
  }

  return null;
};

const SecondaryChartTooltip = ({ active, payload, label }: any) => {
  const t = useT();
  if (!active || !payload[0] || !payload[0].payload) {
    return null;
  }

  const observationDetails: ObservationDetails = payload[0].payload;

  return (
    <Column
      css={css`
        background-color: white;
        border-radius: 4px;
        border: 1px solid ${theme.colors.gray};
        padding: 4px 8px;
      `}
    >
      <Text size="large" strong>
        {capitalize(formatDate(new Date(observationDetails.observationDate), 'iiii'))}
      </Text>
      <Text>
        {t('Minutes awake during the night {{value}} min', { value: -observationDetails.minutesAwakeTimeDuringNight })}
      </Text>
    </Column>
  );
};

export type ObservationDetailsWithOriginals = ObservationDetails & {
  outOfBedTimeOffsetOriginal: number;
  finalAttemptToSleepTimeOffsetOriginal: number;
  finalWakeupTimeOffsetOriginal: number;
  startToSleepTimeOffsetOriginal: number;
  attemptToSleepTimeOffsetOriginal: number;
  gotoBedTimeOffsetOriginal: number;
};
export const SleepHistogram = ({ sortedWeekDetails }: { sortedWeekDetails: ObservationDetailsWithOriginals[] }) => {
  const someDay = startOfDay(new Date());

  return (
    <div
      css={css`
        margin-top: 20px;
      `}
    >
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={sortedWeekDetails}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="observationDate"
            tickFormatter={(observationDate) => capitalize(formatDate(new Date(observationDate), 'iii'))}
            orientation="top"
            tickMargin={12}
          />
          <YAxis
            tickCount={12}
            tickFormatter={(minuteOffsetFromMidnight) =>
              formatDate(addMinutes(someDay, minuteOffsetFromMidnight), 'HH:mm')
            }
          />
          <Tooltip content={<MainChartTooltip />} />

          <Bar maxBarSize={64} stackId="a" dataKey="gotoBedTimeOffset" fill="transparent" />
          <Bar maxBarSize={64} stackId="a" dataKey="attemptToSleepTimeOffset" fill={theme.colors.brightBlue} />
          <Bar maxBarSize={64} stackId="a" dataKey="startToSleepTimeOffset" fill={theme.colors.lightBlue} />
          <Bar maxBarSize={64} stackId="a" dataKey="finalWakeupTimeOffset" fill={theme.colors.blue} />
          <Bar maxBarSize={64} stackId="a" dataKey="finalAttemptToSleepTimeOffset" fill={theme.colors.lightBlue} />
          <Bar maxBarSize={64} stackId="a" dataKey="outOfBedTimeOffset" fill={theme.colors.brightBlue} />
        </BarChart>
      </ResponsiveContainer>

      <ResponsiveContainer width="100%" height={80}>
        <BarChart
          data={sortedWeekDetails.map((x) => {
            if (x.minutesAwakeTimeDuringNight) {
              return { ...x, minutesAwakeTimeDuringNight: -x.minutesAwakeTimeDuringNight };
            } else {
              return x;
            }
          })}
        >
          <CartesianGrid strokeDasharray="3 3" />

          <YAxis tickFormatter={(minutesAwakeTimeDuringNight) => `${-minutesAwakeTimeDuringNight} min`} />
          <Tooltip content={<SecondaryChartTooltip />} />
          <Bar dataKey="minutesAwakeTimeDuringNight" fill="orange" maxBarSize={64} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const fields = [
  'functionLevel',
  'functionalProblems',
  'functionalMeasures',
  'sleepingDrugs',
  'sleepQualityMeasure',
  'sleepComments',
];

const formatField = (field: string, t: TFunction) => {
  switch (field) {
    case 'functionLevel':
      return t('How did you feel yesterday?');
    case 'functionalProblems':
      return t('What about your function was bad?');
    case 'functionalMeasures':
      return t('Measures taken to improve function');
    case 'sleepingDrugs':
      return t('Sleep aid and/or other substances');
    case 'sleepQualityMeasure':
      return t('Sleep quality');
    case 'sleepComments':
      return t('Comment');
    default:
      return field;
  }
};

const Box = ({ children }: { children: React.ReactNode }) => {
  return (
    <Block
      shadow="none"
      css={css`
        padding: 4px 8px;
        border: 1px solid ${theme.colors.gray};

        @media (max-width: 599px) {
          padding: 4px;
        }
      `}
    >
      {children}
    </Block>
  );
};

export const SleepDescriptions = ({ sortedWeekDetails }: { sortedWeekDetails: ObservationDetailsWithOriginals[] }) => {
  const t = useT();
  return (
    <div
      css={css`
        overflow-x: scroll;
        margin-top: 20px;
      `}
    >
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
          grid-gap: 24px;
        `}
      >
        <div />
        {sortedWeekDetails.map((weekDetail) => {
          return (
            <Box key={weekDetail.observationDate}>
              <Text strong>{capitalize(formatDate(new Date(weekDetail.observationDate), 'iii'))}</Text>
            </Box>
          );
        })}
        <Box>
          <Text>{t('Total sleep efficiency')}</Text>
        </Box>
        {sortedWeekDetails.map((weekDetail) => {
          return (
            <Box key={weekDetail.observationDate}>
              <Text>{weekDetail.sleepEfficiency > -1 ? `${round(weekDetail.sleepEfficiency, 1)}%` : '-'}</Text>
            </Box>
          );
        })}
        {fields.map((field) => {
          const ret = [
            <Box key={field}>
              <Text>{formatField(field, t)}</Text>
            </Box>,
          ];

          sortedWeekDetails.forEach((weekDetail) => {
            const value = (weekDetail as any)[field] as string;

            ret.push(
              <Box key={field + weekDetail.observationDate}>
                <Text>{value ?? '-'}</Text>
              </Box>
            );
          });

          return ret;
        })}
      </div>
    </div>
  );
};

const PageContent = ({ weekDetails }: { weekDetails: ObservationDetails[] }) => {
  const t = useT();
  const sortedWeekDetails = sortBy(mapData(weekDetails), 'dayOffset') as ObservationDetailsWithOriginals[];

  return (
    <Column>
      <Heading>{t('Detailed sleep overview (beta)')}</Heading>

      <SleepHistogram sortedWeekDetails={sortedWeekDetails} />

      <SleepDescriptions sortedWeekDetails={sortedWeekDetails} />
    </Column>
  );
};

const PageLoader = ({ date }: { date?: string }) => {
  const selectedPatientId = useSelectedPatientId()!;
  const [weekDate] = React.useState(toServerDate(date ? new Date(date) : new Date()));

  const { data, loading, error, errorMessage } = useClientFetch(SleepDiaryClient.fetchSleepWeekDetails, {
    args: [selectedPatientId, weekDate],
  });
  const weekDetails: ObservationDetails[] | undefined =
    (data as any)?.sleepObservationWeekDetails ?? data?.cpl_SleepObservationWeekDetails;

  if (loading) {
    return <Loader />;
  } else if (error) {
    return <ErrorPageContent reason={errorMessage} />;
  } else if (!weekDetails) {
    return (
      <Row
        css={css`
          margin: 20px;
        `}
      >
        Mangler data
      </Row>
    );
  }

  return <PageContent weekDetails={weekDetails} />;
};

export const SleepDetailsPage: FC<RouteComponentProps<{ date?: string }>> = ({ date }) => {
  const selectedPatientId = useSelectedPatientId();

  if (!selectedPatientId) {
    return <Redirect to="/hjem" noThrow />;
  }

  return (
    <AuthedPageLayout>
      <PageLoader date={date} />
    </AuthedPageLayout>
  );
};
