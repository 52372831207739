/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import FocusTrap from 'focus-trap-react';
import React from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { animated, useTransition } from 'react-spring';
import { CrossButton } from './CrossButton';

const MODAL_ANIMATION_MS = 25;

export function ModalTrigger({
  button,
  modal,
  forceIsOpen = false,
}: {
  button: (params: { openModal: () => void }) => React.ReactNode;
  modal: (params: { closeModal: () => void }) => React.ReactNode;
  forceIsOpen?: boolean;
}) {
  const [isOpen, setIsOpen] = React.useState(forceIsOpen);
  const [animating, setAnimating] = React.useState(forceIsOpen);
  React.useEffect(() => {
    if (forceIsOpen) {
      setTimeout(() => setAnimating(false), MODAL_ANIMATION_MS);
    }
  }, [forceIsOpen]);
  const openModal = React.useCallback(() => {
    setIsOpen(true);
    setAnimating(true);
    setTimeout(() => setAnimating(false), MODAL_ANIMATION_MS);
  }, []);
  const closeModal = React.useCallback(() => {
    setAnimating(true);
    setTimeout(() => {
      setIsOpen(false);
      setAnimating(false);
    }, MODAL_ANIMATION_MS);
  }, []);

  React.useEffect(() => {
    if (!isOpen) {
      return;
    }

    function handleKey(event: { key: string }) {
      if (event.key === 'Escape') {
        closeModal();
      }
    }

    document.addEventListener('keydown', handleKey);
    return () => {
      document.removeEventListener('keydown', handleKey);
    };
  }, [isOpen, closeModal]);

  return (
    <React.Fragment>
      {button({ openModal })}
      {(isOpen || animating) && modal({ closeModal })}
    </React.Fragment>
  );
}

export const Modal: React.FC<{ title: string; closeModal: () => void; focusTrapActive?: boolean; width?: number }> = ({
  title,
  closeModal,
  children,
  focusTrapActive = true,
  width = 600,
}) => {
  const [show, setShow] = React.useState(false);
  React.useEffect(() => setShow(true), []);

  const close = React.useCallback(() => {
    setShow(false);
    closeModal();
  }, [closeModal]);

  const transitions = useTransition(show, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: MODAL_ANIMATION_MS,
    },
  });

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <div
              key={key}
              css={css`
                position: fixed;
                z-index: 1002;
              `}
            >
              <animated.div style={props}>
                <FocusTrap active={focusTrapActive}>
                  <div
                    css={css`
                      position: fixed;
                      left: 0;
                      right: 0;
                      top: 0;
                      bottom: 0;
                      background-color: rgba(0, 0, 0, 0.2);
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    `}
                    onClick={(event) => {
                      event.stopPropagation();
                      close();
                    }}
                  >
                    <div
                      css={css`
                        /* need to put max-width here because of RemoveScroll */
                        width: ${width}px;
                        max-width: 100%;
                      `}
                    >
                      <RemoveScroll>
                        <div
                          css={css`
                            padding: 20px;
                            border-radius: 20px;
                            background-color: white;
                            box-shadow: 4px 4px 24px 8px rgba(0, 0, 0, 0.2);
                            max-height: 90vh;
                            overflow-y: auto;
                          `}
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        >
                          <div
                            css={css`
                              font-size: 20px;
                              margin-bottom: 12px;
                              display: flex;
                              justify-content: space-between;
                            `}
                          >
                            {title}

                            <CrossButton onClick={close} />
                          </div>
                          {children}
                        </div>
                      </RemoveScroll>
                    </div>
                  </div>
                </FocusTrap>
              </animated.div>
            </div>
          )
      )}
    </>
  );
};
