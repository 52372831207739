/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useNavigate } from '@reach/router';
import _ from 'lodash';
import React from 'react';
import { OutlineButton } from '../../components/Button2';
import { Column, Row } from '../../components/Flex';
import ScreenReaderOnly from '../../components/ScreenreaderOnly';
import Text from '../../components/Text';
import { useT } from '../../i18n/useT';
import SleepDiaryClient from '../../network/SleepDiaryClient';
import { WeekAccount } from '../../types/SleepObservation';
import formatDate from '../../utils/formatDate';
import { round } from '../../utils/math';
import { buttonReset } from '../../utils/styles';
import theme from '../../utils/theme';

const WeekAccounts = ({
  patientId,
  weekAccounts,
  selectedWeekDate,
}: {
  patientId: string;
  weekAccounts: WeekAccount[];
  selectedWeekDate?: string;
}) => {
  const t = useT();
  const navigate = useNavigate();

  const groups = React.useMemo(() => {
    const groups = _.reverse(_.chunk(_.reverse(_.sortBy(weekAccounts, 'beginWeek')), 3));
    for (let i = 0; i < groups.length; i++) {
      groups[i] = _.reverse(groups[i]);
    }
    return groups;
  }, [weekAccounts]);

  const [selectedGroup, setSelectedGroup] = React.useState(groups.length - 1);

  if (weekAccounts.length === 0) {
    return <Row horizontal="center">{t('Missing sleep data')}</Row>;
  }

  return (
    <Row vertical="center">
      <OutlineButton
        onClick={() => setSelectedGroup(selectedGroup - 1)}
        marginRight={4}
        disabled={selectedGroup <= 0}
        css={
          selectedGroup <= 0
            ? css`
                opacity: 0;
              `
            : ''
        }
      >
        <ScreenReaderOnly>{t('Back')}</ScreenReaderOnly>
        {'<'}
      </OutlineButton>

      {groups[selectedGroup].map((week) => {
        const isSelected = selectedWeekDate === week.beginWeek;

        return (
          <button
            onClick={(event) => {
              event.preventDefault();
              navigate(`/${patientId}/sovn-oversikt/${week.beginWeek}`);
            }}
            key={week.weekOffset}
            css={[
              buttonReset,
              css`
                margin-left: 4px;
                margin-right: 4px;
                border-radius: 8px;
                border: 1px solid ${theme.colors.blue};
                background-color: ${isSelected ? theme.colors.blue : 'white'};
              `,
            ]}
          >
            <Column
              horizontal="center"
              css={css`
                margin: 4px 8px;
              `}
            >
              <Text strong color={isSelected ? 'white' : theme.colors.blue} size="large">
                {t('Week {{weekNumber}}', { weekNumber: formatDate(new Date(week.beginWeek), 'w') })}
              </Text>
              <Text color={isSelected ? 'white' : theme.colors.blue} size="small" marginBottom={2}>
                {formatDate(new Date(week.beginWeek), 'd. MMM')}
              </Text>
              <Text color={isSelected ? 'white' : theme.colors.blue}>
                {week.sleepEfficiency < 0 ? '-' : `${round(week.sleepEfficiency, 1)}%`}
              </Text>
            </Column>
          </button>
        );
      })}

      <OutlineButton
        onClick={() => setSelectedGroup(selectedGroup + 1)}
        marginLeft={4}
        disabled={selectedGroup === groups.length - 1}
        css={
          selectedGroup === groups.length - 1
            ? css`
                opacity: 0;
              `
            : ''
        }
      >
        <ScreenReaderOnly>{t('Forwards')}</ScreenReaderOnly>
        {'>'}
      </OutlineButton>
    </Row>
  );
};

export default WeekAccounts;

export function useWeekAccounts(patientId: string | undefined) {
  const [result, setResult] = React.useState<{
    data: WeekAccount[] | null;
    error: boolean;
    loading: boolean;
  }>({ loading: true, data: null, error: false });

  React.useEffect(() => {
    if (!patientId) {
      return;
    }

    let cancelled = false;
    setResult({ loading: true, data: null, error: false });

    SleepDiaryClient.fetchSleepWeekAccounts(patientId)
      .then((response) => {
        if (!cancelled) {
          if (response.success) {
            setResult({
              data: response.data ?? [],
              error: false,
              loading: false,
            });
          } else {
            setResult({ data: null, error: true, loading: false });
          }
        }
      })
      .catch(() => {
        if (!cancelled) {
          setResult({ error: true, loading: false, data: null });
        }
      });

    return () => {
      cancelled = true;
    };
  }, [patientId]);

  return result;
}
