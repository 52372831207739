/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

export const DataBox = ({ value, label, img }: { value: string | number; label: string; img?: string }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 8px;
        width: 120px;
      `}
    >
      {img && (
        <img
          src={img}
          alt=""
          css={css`
            width: 32px;
            height: 32px;
          `}
        />
      )}
      <div
        css={css`
          font-size: 24px;
          margin: 12px 0;
        `}
      >
        {value}
      </div>
      <div
        css={css`
          max-width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
        `}
      >
        {label}
      </div>
    </div>
  );
};
