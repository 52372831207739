import React, { forwardRef } from 'react';
import { cx } from '../utils/cx';
import Loader from './loader/Loader';

export type ButtonVariant = 'primary' | 'secondary';

export type ButtonSize = 'sm' | 'md' | 'lg';

export type ButtonProps = React.ComponentPropsWithRef<'button'> & {
  variant?: ButtonVariant;
  size?: ButtonSize;
  loading?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { variant = 'primary', size = 'md', disabled, loading, children, className, ...rest } = props;

  return (
    <button
      className={cx(
        'relative rounded-md',
        size === 'sm' && 'px-2 text-sm',
        size === 'md' && 'px-4 py-2 text-base',
        size === 'lg' && 'px-6 py-3 text-lg',
        variant === 'primary' && 'border border-blue-500 bg-blue-500 text-white',
        variant === 'secondary' && 'border border-blue-900 bg-white text-blue-900',
        className
      )}
      disabled={disabled || loading}
      {...rest}
      ref={ref}
    >
      {children}

      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black/25">
          <Loader />
        </div>
      )}
    </button>
  );
});
