/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { buttonReset } from '../utils/styles';
import ScreenReaderOnly from './ScreenreaderOnly';

export const CrossButton = ({
  onClick,
  label = 'Lukk',
  className,
}: {
  onClick: () => void;
  label?: string;
  className?: string;
}) => {
  const ref = React.useRef<HTMLButtonElement | null>(null);
  React.useEffect(() => {
    setTimeout(() => {
      ref?.current?.blur();
    }, 0);
  }, [ref]);

  return (
    <button
      ref={ref}
      onClick={onClick}
      css={[
        buttonReset,
        css`
          width: 24px;
          height: 24px;
          position: relative;
          transform: rotate(45deg);
          cursor: pointer;
          border-radius: 12px;
          transition: transform ease-in-out 200ms;

          &:hover {
            transform: rotate(135deg);

            background-color: #333;

            > div {
              background-color: white;
            }
          }
        `,
      ]}
      className={className}
    >
      <ScreenReaderOnly>{label}</ScreenReaderOnly>
      <div
        css={[
          'cross',
          css`
            background-color: #333;
            width: 16px;
            height: 2px;
            position: absolute;
            left: 4px;
            top: 11px;
          `,
        ]}
      />
      <div
        css={[
          'cross',
          css`
            background-color: #333;
            width: 2px;
            height: 16px;
            position: absolute;
            top: 4px;
            left: 11px;
          `,
        ]}
      />
    </button>
  );
};
