/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { RouteComponentProps } from '@reach/router';
import { format } from 'date-fns';
import React, { FC } from 'react';
import ErrorPageContent from '../../components/ErrorPageContent';
import { Column, Row } from '../../components/Flex';
import Heading from '../../components/Heading';
import Loader from '../../components/loader/Loader';
import AuthedPageLayout from '../../components/pagelayout/AuthedPageLayout';
import Table from '../../components/Table';
import Text from '../../components/Text';
import { useT } from '../../i18n/useT';
import { useUserContext } from '../../lib/UserContext';
import useSelectedPatientId from '../../lib/useSelectedPatientId';
import Covid19Client from '../../network/Covid19Client';
import useClientFetch from '../../network/useClientFetch';
import { Covid19Result } from './types';

const PageContent = ({ covid19Results }: { covid19Results: Covid19Result[] }) => {
  const t = useT();
  const user = useUserContext();

  return (
    <Column grow>
      <Heading level={2}>{t('Covid 19 results')}</Heading>
      <Text size="large">
        {t('Showing result for')}: {user.loggedInUser?.user.mobilePhone}
      </Text>

      <Row
        css={css`
          margin-top: 32px;
        `}
      >
        <Table>
          <thead>
            <tr>
              <td>{t('Date')}</td>
              <td>{t('Result')}</td>
            </tr>
          </thead>
          <tbody>
            {covid19Results.map((result) => (
              <tr key={result.id}>
                <td>{format(new Date(result.observationDate), 'yyyy-MM-dd')}</td>
                <td>{result.testResult}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>

      <Column
        css={css`
          margin-top: auto;
        `}
      >
        <div style={{ height: 40 }} />
        <Covid19Info />
      </Column>
    </Column>
  );
};

const Covid19Info = () => {
  const t = useT();
  return (
    <Column
      css={css`
        p {
          margin-bottom: 18px;
        }
      `}
    >
      <Text marginBottom={8} size="huge">
        ⓘ
      </Text>
      <Text marginBottom={8}>{t('Covid19InfoLine1')}</Text>
      <Text marginBottom={8}>{t('Covid19InfoLine2')}</Text>
      <Text marginBottom={8}>{t('Covid19InfoLine3')}</Text>
      <Text>
        {t('Covid19InfoLine4')}
        <a href={t('covid19 FHI URL')} target="_blank" rel="noopener noreferrer">
          {t('covid19 FHI URL')}
        </a>
      </Text>
    </Column>
  );
};

const PageLoader = () => {
  const selectedPatientId = useSelectedPatientId()!;

  const { data, loading, error, errorMessage } = useClientFetch(Covid19Client.fetchCovid19, {
    args: [selectedPatientId],
  });

  if (loading) {
    return <Loader />;
  } else if (error) {
    return <ErrorPageContent reason={errorMessage} />;
  } else if (!data) {
    return (
      <Row
        css={css`
          margin: 20px;
        `}
      >
        Missing data
      </Row>
    );
  }

  return <PageContent covid19Results={data ?? []} />;
};

export const Covid19Page: FC<RouteComponentProps> = () => {
  return (
    <AuthedPageLayout>
      <PageLoader />
    </AuthedPageLayout>
  );
};
