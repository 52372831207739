import React from 'react';
import { Selector } from '../../components/Selector';
import { useT } from '../../i18n/useT';
import { Cpl_CourseOfTherapy } from '../../types/Cpl_FEST';
import { translateCourseOfTherapyType } from '../medication/utils';

export const CourseOfTherapySelector = ({
  value,
  onValue,
}: {
  value: Cpl_CourseOfTherapy['courseOfTherapyType'];
  onValue: (val: Cpl_CourseOfTherapy['courseOfTherapyType']) => void;
}) => {
  const t = useT();

  return (
    <Selector
      title={t('Medication status')}
      value={value}
      onValue={(val) => onValue(val as Cpl_CourseOfTherapy['courseOfTherapyType'])}
      data={[
        {
          value: 'Faste medisiner',
          label: translateCourseOfTherapyType(t, 'Faste medisiner'),
        },
        {
          value: 'Ved behov',
          label: translateCourseOfTherapyType(t, 'Ved behov'),
        },
        {
          value: 'Seponert',
          label: translateCourseOfTherapyType(t, 'Seponert'),
        },
      ]}
    />
  );
};
