import settings from '../settings';
import { Fhir_Patient } from '../types/Fhir_Patient';
import Fetch from './fetch';

async function fetchPatient(id: string) {
  const url = `${settings.apiUrl}/Patient/${id}`;

  return Fetch.get<Fhir_Patient>({ url });
}

async function updatePatient(id: string, patient: Fhir_Patient) {
  const url = `${settings.apiUrl}/Patient/${id}`;

  return Fetch.put<Fhir_Patient>({ url, body: patient });
}

export default {
  fetchPatient,
  updatePatient,
};
