/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import React from 'react';
import { textStyle } from '../utils/styles';
import theme from '../utils/theme';
import readShortProps, { ShortProps } from './helpers/readShortProps';

type TextSizeKind = Exclude<TextProps['size'], undefined>;

export const textStyleBySize: { [key in TextSizeKind]: SerializedStyles } = {
  huge: css`
    font-size: 20px;
  `,

  large: css`
    font-size: 18px;
  `,

  normal: css`
    font-size: 16px;
  `,

  small: css`
    font-size: 14px;
  `,
};

type TextStyleOptions = {
  invert?: boolean;
  strong?: boolean;
  dimmed?: boolean;
  color?: string;
  align?: 'center' | 'left' | 'right' | 'justify';
};

export const getTextStyle = (size: TextSizeKind, { invert, strong, dimmed, color, align }: TextStyleOptions = {}) => {
  return [
    textStyle,
    css`
      line-height: 1.4;
    `,
    size && textStyleBySize[size],
    invert
      ? css`
          color: white;
        `
      : css`
          color: ${theme.colors.text};
        `,
    dimmed &&
      css`
        color: ${theme.colors.gray};
      `,
    strong
      ? css`
          font-weight: 600;
        `
      : css`
          font-weight: 500;
        `,
    color &&
      css`
        color: ${color};
      `,
    align &&
      css`
        text-align: ${align};
      `,
  ];
};

export type TextProps = {
  as?: React.ElementType;
  size?: 'huge' | 'large' | 'normal' | 'small';
  dimmed?: boolean;
  invert?: boolean;
  strong?: boolean;
  color?: string;
  align?: 'center' | 'left' | 'right' | 'justify';
} & React.ComponentPropsWithRef<'span'> &
  ShortProps;

const Text = ({
  as: Component = 'span',
  dimmed,
  className,
  size = 'normal',
  invert,
  strong,
  color,
  align,
  ...restBeforeShortProps
}: TextProps) => {
  const [shortPropClass, rest] = readShortProps(restBeforeShortProps);

  return (
    <Component
      css={[getTextStyle(size, { invert, dimmed, strong, color, align }), shortPropClass]}
      className={className}
      {...rest}
    />
  );
};

export default React.memo(Text);
