/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link } from '@reach/router';
import React, { FC } from 'react';

type IconLinkProps = {
  title: string;
  to: string;
  description?: string;
  disabled?: boolean;
} & (
  | {
      iconElement: React.ReactNode;
    }
  | {
      icon: string;
    }
);
export const IconLink: FC<IconLinkProps> = ({ title, to, description, disabled, ...rest }) => (
  <Link
    to={to}
    css={[
      css`
        text-decoration: none;
        color: #203864;
        padding: 16px;
        border-radius: 16px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

        &:hover {
          color: black;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        }
      `,
      disabled &&
        css`
          pointer-events: none;
          cursor: not-allowed;
          opacity: 0.3;
        `,
    ]}
    aria-disabled={disabled}
  >
    <span
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      {'icon' in rest && (
        <img
          src={rest.icon}
          alt=""
          css={css`
            height: 35px;
            width: 35px;
          `}
        />
      )}
      {'iconElement' in rest && rest.iconElement}
      <h2
        css={css`
          margin-left: 10px;
          font-size: 24px;
        `}
      >
        {title}
      </h2>
    </span>
    {description && (
      <p
        css={css`
          padding-top: 16px;
        `}
      >
        {description}
      </p>
    )}
  </Link>
);

export default IconLink;
