/** @jsxImportSource @emotion/react */
import { Link } from '@reach/router';
import React from 'react';
import { useT } from '../i18n/useT';
import { FilledButton } from './Button2';
import { Column } from './Flex';
import Text from './Text';

const ErrorPageContent = ({ reason }: { reason?: string }) => {
  const t = useT();

  return (
    <Column vertical="center" horizontal="center" height="100%">
      <Text size="huge" marginTop={40} marginBottom={20}>
        {t('Something went wrong')}
      </Text>

      {reason && <Text>{reason}</Text>}

      <FilledButton
        marginTop={20}
        onClick={() => {
          window.location.reload();
        }}
      >
        {t('Refresh page')}
      </FilledButton>

      <Text margin={[20, 'auto']}>{t('or')}</Text>

      <Link to="/hjem">
        <Text>{t('Go to front page')}</Text>
      </Link>
    </Column>
  );
};

export default ErrorPageContent;
