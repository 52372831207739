import React from 'react';

// Use as: const isSmallScreen = useMediaQuery('(max-width: 800px)')
export default function useMediaQuery(query: string, defaultMatches: boolean = true) {
  const [matches, setMatches] = React.useState(defaultMatches);

  React.useLayoutEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    let active = true;

    const listener = () => {
      if (!active) {
        return;
      }

      if (mediaQueryList.matches) {
        setMatches(true);
      } else {
        setMatches(false);
      }
    };

    mediaQueryList.addListener(listener);
    setMatches(mediaQueryList.matches);

    return () => {
      active = false;
      mediaQueryList.removeListener(listener);
    };
  }, [query]);

  return matches;
}

export const useIsSmallScreen = () => {
  return useMediaQuery('(max-width: 799px)');
};

export const useIsLargeScreen = () => {
  return !useIsSmallScreen();
};
