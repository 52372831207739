/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';

type FlexAlign =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'baseline'
  | 'stretch'
  | 'space-around'
  | 'space-between'
  | 'space-evenly';

type FlexProps = {
  direction?: 'row' | 'column';
  flex?: number;
  justify?: FlexAlign;
  align?: FlexAlign;
  wrap?: boolean;
  height?: string | number;
  width?: string | number;
  grow?: boolean;
};

export const Flex = styled.div<FlexProps>`
  display: flex;

  ${({ height, width, wrap, grow, flex, direction, justify, align }) => [
    height &&
      css`
        height: ${typeof height === 'number' ? `${height}px` : height};
      `,
    width &&
      css`
        width: ${typeof width === 'number' ? `${width}px` : width};
      `,
    wrap &&
      css`
        flex-wrap: wrap;
      `,
    grow &&
      css`
        flex-grow: 1;
      `,
    flex &&
      css`
        flex: ${flex};
      `,
    direction &&
      css`
        flex-direction: ${direction};
      `,
    justify &&
      css`
        justify-content: ${justify};
      `,
    align &&
      css`
        align-items: ${align};
      `,
  ]}
`;

type RowColumnProps = FlexProps & {
  horizontal?: FlexAlign;
  vertical?: FlexAlign;
};

export const Row = styled(Flex)<RowColumnProps>`
  flex-direction: row;

  ${({ horizontal, vertical }) => [
    horizontal &&
      css`
        justify-content: ${horizontal};
      `,
    vertical &&
      css`
        align-items: ${vertical};
      `,
  ]}
`;

export const Column = styled(Flex)<RowColumnProps>`
  flex-direction: column;

  ${({ horizontal, vertical }) => [
    horizontal &&
      css`
        align-items: ${horizontal};
      `,
    vertical &&
      css`
        justify-content: ${vertical};
      `,
  ]}
`;
