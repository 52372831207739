import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useSelectedPatientId from '../../../lib/useSelectedPatientId';
import { api, toastAndThrowError } from '../../../network/api';
import {
  Cpl_Administrasjonsvei,
  Cpl_Administreringsdetaljer,
  Cpl_Bruksomrade,
  Cpl_Dosering,
  Cpl_Forholdsregel,
  DosageTimeParams,
  DoseringParams,
  GH_PrescriptionNoFestDetails,
} from '../../../types/gh-types';
import { Cpl_ProblemDecisionRelation } from '../../../types/Cpl_Decision';
import { Fhir_Condition } from '../../../types/Fhir_Condition';
import { convertParamToBackend } from './convertDosering';
import { DoseringAlt4 } from './dosage4';
import { DosageIntervalControl2 } from './dosagedate2';

import { Notepad, X } from '@phosphor-icons/react';
import { NotTranslated } from '../../../types/utils';
import { Select } from '../../../components/Select2';
import { useT } from '../../../i18n/useT';

type PrescrProps = {
  merkevareId: string;
  onSubmit: ({
    prescriptionDetails,
    relations,
  }: {
    prescriptionDetails: GH_PrescriptionNoFestDetails;
    relations: Cpl_ProblemDecisionRelation[];
  }) => void;
  onCancel: () => void;
};

const PrescriptionDetails = ({ merkevareId, onSubmit, onCancel }: PrescrProps) => {
  const [curDoseringsText, setCurDoseringsText] = useState<string>(''); // cur value in dosering-field (Free text doseringstekst)
  const [curDoseringsParam, setCurDoseringsParam] = useState<DoseringParams | undefined>(undefined); // Actual param for currently selected dosage

  const [curLangDoseringsText, setCurLangDoseringsText] = useState<string>(''); // cur value of long version of dosering-field
  const [dosageParam, setDosageParam] = useState<DoseringParams | undefined>(); // params for recently created advanced dosage
  const [dosageParamOneLineText, setDosageParamOneLineText] = useState<string | undefined>(); // corresponding textual representation of dosageParam (that goes into the freetext-field)
  const [dosageParamMultiLineText, setDosageParamMultiLineText] = useState<string | undefined>(); // corresponding textual representation of dosageParam (that could be displayed when more room)

  const [doseringsvarsel, setDoseringsvarsel] = useState<string>('');

  const [bruksomrade, setBruksomrade] = useState<string>('');
  const [forholdsregler, setForholdsregler] = useState<string>('');
  const [administrasjonsvei, setAdministrasjonsvei] = useState<string>('');

  const [doseringsforslag, setDoseringsforslag] = useState<Cpl_Dosering[]>([]);

  const today: Date = new Date();
  const [year, month, day] = [today.getFullYear(), today.getMonth(), today.getDate()];

  // values from timecontrol used when not advanced dosering is active:
  const [showDbParam, setShowDbParam] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | undefined>(new Date(year, month, day)); // ZZZ Dagens dato .. er det "no-no"?
  const [endDate, setEndDate] = useState<Date | undefined>(new Date(year, month, day));
  const [intervalDays, setIntervalDays] = useState<number>(0);
  const [endOption, setEndOption] = useState<string>('Max'); // "Max"|"endDate"|"interval"|"auto"|"multiple" <-- used for common intervalsection when more than one section in advanced dosering

  const [bruksomradeforslag, setBruksomradeforslag] = useState<Cpl_Bruksomrade[]>([]);
  const [forholdsregelforslag, setForholdsregelforslag] = useState<Cpl_Forholdsregel[]>([]);
  const [administrasjonsveiforslag, setAdministrasjonsveiforslag] = useState<Cpl_Administrasjonsvei[]>([]);
  const [administreringsdetaljer, setAdministreringsdetaljer] = useState<Cpl_Administreringsdetaljer | undefined>();

  const [fastVedBehov, setFastVedBehov] = useState<'Fast' | 'VedBehov'>('Fast'); // Fast | VedBehov
  const [doseringOpen, setDoseringOpen] = useState<boolean>(false);
  const [debug, setDebug] = useState<boolean>(false);
  const [visibilityLangDosering, setVisibilityLangDosering] = useState<boolean>(false);

  const [addedConditions, setAddedConditions] = useState<string[]>([]);
  const [selectedCondition, setSelectedCondition] = useState<Fhir_Condition | undefined>(undefined);
  //const [conditions, setConditions] = useState<Fhir_Condition[]>([]);

  const t = useT();
  const selectedPatientId = useSelectedPatientId()!; // Works here??

  const conditionQuery = useQuery({
    queryKey: ['/api/Condition/GetAll/Patient', selectedPatientId],
    queryFn: () => api.get<Fhir_Condition[]>(`/Condition/GetAll/Patient/${selectedPatientId}`),
  });

  const conditions: Fhir_Condition[] = conditionQuery?.data?.data ?? [];

  const onAddConditionToList = (id: string) => {
    setAddedConditions([...addedConditions, id]);
    const selectedDec = conditions.find((i) => i.id === id);
    setSelectedCondition(selectedDec);
  };

  const onRemoveCondition = (id: string): void => {
    const ix = addedConditions.findIndex((i) => i === id);
    addedConditions.splice(ix, 1);
    if (ix > -1) setAddedConditions([...addedConditions]);
  };

  const getAsRelations = (conditions: string[]): Cpl_ProblemDecisionRelation[] => {
    return conditions.map((item) => {
      return {
        patientId: selectedPatientId,
        id: '',
        problemType: '',
        problemId: item,
        decisionId: '<NotFilledIn>',
        relation: '',
        mailMessages: [],
        text: { status: '', div: '' }, // Dette her blir for dumt. Den er frivillig i FHIR,
        contained: [],
        extension: [],
        modifierExtension: [],
        meta: { versionId: '', profile: [], security: [], tag: [] },
        implicitRules: 'https://capableapi.azurewebsites.net/ImplicitRules', // ZZZ No meaning whatsoever, but follows URI-syntax
        language: '',
      };
    });
  };

  const onStartDateChange = (curStartDate: Date | undefined) => {
    // ZZZZZZ ALSO updatedosageParam
    if (checkAllowedTimeChange(curStartDate, endDate, intervalDays, endOption)) setStartDate(curStartDate);
  };

  const onEndDateChange = (curEndDate: Date | undefined) => {
    if (checkAllowedTimeChange(startDate, curEndDate, intervalDays, endOption)) setEndDate(curEndDate);
  };

  const onIntervalDaysChange = (curIntervalDays: number) => {
    if (checkAllowedTimeChange(startDate, endDate, curIntervalDays, endOption)) setIntervalDays(curIntervalDays);
  };

  const onEndOptionChange = (curEndOption: string) => {
    if (checkAllowedTimeChange(startDate, endDate, intervalDays, curEndOption)) setEndOption(curEndOption);
  };

  const checkAllowedTimeChange = (
    curStartDate: Date | undefined,
    curEndDate: Date | undefined,
    curIntervalDays: number,
    curEndOption: string
  ): boolean => {
    //true return all ok
    // Det må være begrensning på hva brukeren får lov til å endre, når det er valgt en dosering:
    // Dersom doseringen kommer fra FEST:
    // Dersom endOption === "Max" eller "endDate" (inntil videre, eller eksplisitt sluttdato (skjer ikke via FEST)) - alt kan endres
    // Dersom endOption === "interval" (bestemt antall dager) - kan IKKE endre antall dager eller endOption (kan kun endre startdato (sluttdato beregnes da automatisk)), mens dager holdes fast)
    // Dersom endOption === "auto" Kan kun endre startDate
    // Dersom endOption === "multiple" kan ikke endre noe, alt må gjøres fra "avansert dosering".
    // Dersom doseringsparam på current består av flere seksjoner, kan ikke endre noe, alt må gjøres fra "avansert dosering"

    // Dersom doseringen kommer fra Avansert dosering: samme regler

    // Return value:
    // true: allow the update
    // false: disallow the update

    // NYE REGLER:
    // Om doseringen er kompleks (både faste og gjentakende doseringer)
    // -- Kan kun endre startdato

    const endDateOption = getEndDateOption(curDoseringsParam);
    if (
      isMultiple(curDoseringsParam) &&
      !(
        curStartDate === startDate &&
        curEndDate === endDate &&
        curIntervalDays === intervalDays &&
        curEndOption === endOption
      )
    ) {
      setDoseringsvarsel('For å endre doseringsvalget må du gå til avansert dosering');
      return false;
    }
    /* else if (curDoseringsParam && curDoseringsParam.length > 1) {
      setDoseringsvarsel(
        "For å endre sammensatte doseringer må du gå til avansert dosering",
      );
      return false;
    }
 */
    if (
      endDateOption === 'multiple' && // ZZZ No longer in use ...
      !(
        curStartDate === startDate &&
        curEndDate === endDate &&
        curIntervalDays === intervalDays &&
        curEndOption === endOption
      )
    ) {
      setDoseringsvarsel('For å endre doseringsvalget må du gå til avansert dosering');
      return false;
    } else if (
      endDateOption === 'auto' &&
      !(curEndDate === endDate && curIntervalDays === intervalDays && curEndOption === endOption)
    ) {
      setDoseringsvarsel('For å endre doseringsvalget må du gå til avansert dosering');
      return false;
    } else if (endDateOption === 'interval' && (curIntervalDays !== intervalDays || curEndOption !== endOption)) {
      setDoseringsvarsel('For å endre dette doseringsvalget må du gå til avansert dosering');
      return false;
    } else if (curEndDate && curStartDate && curEndDate < curStartDate && curEndOption === 'endDate') {
      setDoseringsvarsel('Startdato kan ikke være senere enn sluttdato!');
      return true; // ZZZ allow but msg??
    } else {
      setDoseringsvarsel('');
      return true;
    }
  };

  // fetch data
  useEffect(() => {
    if (merkevareId && merkevareId.length >= 3) {
      fetch('https://capablemedapi.azurewebsites.net/api/Dosering/FetchByMerkevareId/' + merkevareId)
        .then((res) => res.json())
        .then((data: Cpl_Dosering[]) => {
          setDoseringsforslag(data);
          setCurDoseringsText(data?.length === 1 ? data[0].doseringLang : '');
          setCurDoseringsParam(
            data?.length === 1
              ? data[0].doseringCplDecoded
                ? JSON.parse(data[0].doseringCplDecoded)
                : undefined
              : undefined
          ); // ZZZ Fragile?
          setCurLangDoseringsText(data?.length === 1 ? data[0].doseringLang : '');
          setFastVedBehov(data?.length === 1 ? (data[0].prn ? 'VedBehov' : 'Fast') : 'Fast');

          // ZZZ should set time control as well(?) : updateTimeControlFromDosageSelection()
        });
      fetch('https://capablemedapi.azurewebsites.net/api/Bruksomrader/FetchByMerkevareId/' + merkevareId)
        .then((res) => res.json())
        .then((data: Cpl_Bruksomrade[]) => {
          setBruksomradeforslag(data);
          setBruksomrade(data?.length === 1 ? data[0].bruksomrade : '');
        });
      fetch('https://capablemedapi.azurewebsites.net/api/Forholdsregler/FetchByMerkevareId/' + merkevareId)
        .then((res) => res.json())
        .then((data: Cpl_Forholdsregel[]) => {
          setForholdsregelforslag(data);
          setForholdsregler(data?.length === 1 ? data[0].forholdsregel : '');
        });
      fetch('https://capablemedapi.azurewebsites.net/api/Administrasjonsveier/FetchByMerkevareId/' + merkevareId)
        .then((res) => res.json())
        .then((data: Cpl_Administrasjonsvei[]) => {
          setAdministrasjonsveiforslag(data);
          setAdministrasjonsvei(data?.length === 1 ? data[0].administrasjonsvei : '');
        });
      fetch('https://capablemedapi.azurewebsites.net/api/Administrasjonsdetaljer/FetchByMerkevareId/' + merkevareId)
        .then((res) => res.json())
        .then((data) => setAdministreringsdetaljer(data));
    } else setDoseringsforslag([]);
    setBruksomradeforslag([]);
    setForholdsregelforslag([]);
    setAdministrasjonsveiforslag([]);
    setAdministreringsdetaljer(undefined);
  }, [merkevareId]);

  const updateDosageTextAndParam = useCallback(
    (dosageMultiLineText: string, updatedDosageParam: DoseringParams | undefined) => {
      // Update dosage proc from DosageAlt4 component:

      // First convert from old proc:
      const updatedMultiLineDosageText: string = dosageMultiLineText;
      const updatedOneLineDosageText: string = dosageMultiLineText.replaceAll('\n', '').trim();

      setCurDoseringsText(updatedOneLineDosageText);
      setCurLangDoseringsText(updatedMultiLineDosageText);
      setCurDoseringsParam(updatedDosageParam);
      setDosageParam(updatedDosageParam);
      setDosageParamOneLineText(updatedOneLineDosageText);
      setDosageParamMultiLineText(updatedMultiLineDosageText);
      // Gjør ikke dette før avansert dosering lukkes:
      /*         if  ((updatedDosageParam) && (updatedDosageParam?.length>=1)) {
            const {startDate, endDate, interval, endDateOption} = {...updatedDosageParam[0]}; // ZZZ fix
            setStartDate(startDate); //ZZZ no consistence check is ok? Already done?
            setEndDate(endDate);
            setIntervalDays(interval??0);
            setEndOption(endDateOption);
            //onDosageTimeChanges({startDate:startDate, endDate, intervalDays:interval??0, endOption:endDateOption})
        }
 */
    },
    []
  );

  const expandLineBreaks = (input: string): JSX.Element => {
    //return dosering.replace("\n", "<br />")
    return (
      <div>
        {input.split('\n').map((value, index) => (
          <div key={index}>
            {value}
            <br />
          </div>
        ))}
      </div>
    );
  };

  const isMultiple = (param: DoseringParams | undefined): boolean => {
    // tells whether a dosage is a complicated type (containing both explicit tasks as well as recurrent tasks)
    let savedTasksExists: boolean = false;
    let recurrentTasksExists: boolean = false;

    if (param) {
      if (param.savedTasks && param.savedTasks.length > 0) savedTasksExists = true;
      recurrentTasksExists = !(param.recurrencePattern === undefined || param.recurrencePattern === '');

      return savedTasksExists && recurrentTasksExists;
    }

    return false;
  };

  const updateTimeControlFromDosageSelection = (param: DoseringParams | undefined) => {
    // Called when dosage is selected from dropDown
    if (param === undefined) return;
    // Changing from this:
    // let curParam:DoseringParams=param[0];
    // if (curParam.endDateOption === "interval" && (curParam.interval) && (curParam.interval>0)) {
    //     setEndOption("interval");
    //     setIntervalDays(curParam.interval);
    //     const newEndDate:Date|undefined = (startDate === undefined)? undefined:
    //             new Date(startDate?.getFullYear(), startDate?.getMonth(), startDate?.getDate()+(curParam.interval??0)-1);
    //     setEndDate(newEndDate);
    // }
    //
    // To this: ZZZZZZ
    else {
      // param.length === 1
      const curParam: DoseringParams = param; // A bit cautious as it also represents selection from FEST (where you want to be able to use useful time-settings in dialog)
      if (curParam.beginDate) setStartDate(curParam.beginDate);
      if (curParam.endRecurrenceDate) setEndDate(curParam.endRecurrenceDate);
      if (curParam.recurrenceInterval) setIntervalDays(curParam.recurrenceInterval ?? 0);
      if (curParam.recurrenceIntervalOption) setEndOption(curParam.recurrenceIntervalOption);
      else setEndOption('Max'); // If endOption is currently "auto" and another dosage is selected that doesn't care, cur endOption should move away from "auto"
      if (curParam.recurrenceIntervalOption === 'interval') {
        const usableStartDate = curParam.beginDate ?? startDate ?? undefined;
        const newEndDate: Date | undefined =
          usableStartDate === undefined
            ? undefined
            : new Date(
                usableStartDate?.getFullYear(),
                usableStartDate?.getMonth(),
                usableStartDate?.getDate() +
                  (curParam.recurrenceInterval ?? 0) +
                  (curParam.recurrenceIntervalOffset ?? 0) -
                  1
              );
        setEndDate(newEndDate);
      } else if (curParam.recurrenceIntervalOption === 'auto') {
        if (curParam.savedTasks && curParam.savedTasks.length > 0) {
          const usableStartDate = curParam.beginDate ?? startDate ?? undefined;
          const maxDayNo = Math.max(...curParam.savedTasks.map((i) => i.dayNoOffset), -Infinity);
          if (maxDayNo !== -Infinity) {
            const newEndDate: Date | undefined =
              usableStartDate === undefined
                ? undefined
                : new Date(
                    usableStartDate?.getFullYear(),
                    usableStartDate?.getMonth(),
                    usableStartDate?.getDate() + maxDayNo
                  );
            setEndDate(newEndDate);
          }
        }
      }
    }
  };

  const updateTimeControlFromAdvancedDosage = (param: DoseringParams | undefined) => {
    // Called when advanced dosage is closed
    if (param !== undefined) {
      // param.length === 1
      const curParam: DoseringParams = param;
      setStartDate(curParam.beginDate);
      setEndDate(curParam.endRecurrenceDate);
      setIntervalDays(curParam.recurrenceInterval ?? 0);
      setEndOption(curParam.recurrenceIntervalOption);
    }
  };

  const updatedDosageParamFromTimeControlNEW = (
    timeParam: DosageTimeParams,
    curParam: DoseringParams | undefined
  ): DoseringParams | undefined => {
    // Called when sending param to advanced dosering
    const curDoseringsParam = curParam;
    if (curDoseringsParam === undefined) return undefined;
    else {
      // curDoseringsParam.length===0
      const newDoseringsParam: DoseringParams = { ...curDoseringsParam };
      newDoseringsParam.beginDate = timeParam.startDate;
      newDoseringsParam.endRecurrenceDate = timeParam.endOption === 'Max' ? undefined : timeParam.endDate;
      // the other two params should not be able to change
      // Above not true!!!!!?
      newDoseringsParam.recurrenceIntervalOption = timeParam.endOption;
      newDoseringsParam.recurrenceInterval = timeParam.intervalDays;
      //const newCurDoseringsParam: DoseringParams[] = [newFirstDoseringsParam];
      //setCurDoseringsParam(newCurDoseringsParam);
      return newDoseringsParam;
    }
  };

  /*   const updatedDosageParamFromTimeControlOLD = (
    param: DosageTimeParams,
    curParam: DoseringParams[] | undefined,
  ): DoseringParams[] | undefined => {
    // Called when sending param to advanced dosering
    const curDoseringsParam = curParam;
    if (curDoseringsParam === undefined || curDoseringsParam.length === 0)
      return undefined;
    else if (curDoseringsParam.length > 1)
      return curDoseringsParam; // return unchanged
    else {
      // curDoseringsParam.length===0
      const newFirstDoseringsParam: DoseringParams = {
        ...curDoseringsParam[0],
      };
      newFirstDoseringsParam.beginDate = param.startDate;
      newFirstDoseringsParam.endRecurrenceDate =
        param.endOption === "Max" ? undefined : param.endDate;
      // the other two params should not be able to change
      // Above not true!!!!!?
      newFirstDoseringsParam.recurrenceIntervalOption = param.endOption;
      newFirstDoseringsParam.recurrenceInterval = param.intervalDays;
      const newCurDoseringsParam: DoseringParams[] = [newFirstDoseringsParam];
      //setCurDoseringsParam(newCurDoseringsParam);
      return newCurDoseringsParam;
    }
  }; */

  const setDosage = (dosering: string) => {
    // New dosage is selected in dropdown
    let selectionAndFreetext: boolean = false;
    //let curParam;
    if (dosering.endsWith(' (fritekst)')) {
      selectionAndFreetext = true;
      dosering = dosering.substring(0, dosering.length - ' (fritekst)'.length);
    }
    const ix: number = doseringsforslag.findIndex((i) => i.doseringLang === dosering);
    if (ix === -1) {
      // not one of the FEST-defined dosage strings
      if (dosering === dosageParamOneLineText) {
        // Found as previously defined advanced dosage
        setCurDoseringsParam(dosageParam);
        updateTimeControlFromDosageSelection(dosageParam);
        setCurLangDoseringsText(dosageParamMultiLineText ?? '');
        setFastVedBehov('Fast');
      } else {
        // Free text, not in the list
        setCurDoseringsParam(undefined);
        setCurLangDoseringsText(dosering);
      }
    } else {
      // found in FEST-list
      if (selectionAndFreetext) setCurDoseringsParam(undefined);
      // freetext dosage, dont initialize advanced dosage
      else {
        // coded FEST-dosage
        const param: DoseringParams = JSON.parse(doseringsforslag[ix].doseringCplDecoded);
        setCurDoseringsParam(param); // ZZZZ [] for now
        updateTimeControlFromDosageSelection(param);
      }
      setCurLangDoseringsText(dosering);
      setFastVedBehov(doseringsforslag[ix].prn ? 'VedBehov' : 'Fast');
    }
    setCurDoseringsText(dosering);
    setDoseringsvarsel(''); // Clear any potential warnings upon selecting (or writing) new dosagetext
  };

  const getEndDateOption = (param: DoseringParams | undefined): string | undefined => {
    if (param) {
      const { recurrenceIntervalOption } = { ...param };
      return recurrenceIntervalOption;
    }
  };

  /*     const onDosageTimeChanges = (param:DosageTimeParams) => {
        // Called from DosageTimeControl
        
        // Tillegg:
        // Det må være begrensning på hva brukeren får lov til å endre, når det er valgt en dosering:
        // Dersom doseringen kommer fra FEST:
        // Dersom endOption === "Max" eller "endDate" (inntil videre, eller bestemt sluttdato (skjer ikke via FEST)) - alt kan endres
        // Dersom endOption === "interval" (bestemt antall dager) - kan IKKE endre antall dager eller endOption (kan kun endre startdato (sluttdato beregnes da automatisk)), mens dager holdes fast)

        // Dersom doseringen kommer fra Avansert dosering: samme regler

        const endDateOption = getEndDateOption(curDoseringsParam);
        if (endDateOption === "interval") {
            if ((param.intervalDays !==intervalDays) || (param.endOption !==endOption)) {
                setDoseringsvarsel("For å endre dette doseringsvalget må du gå til avansert dosering");
                return;   
            }
            else 
                setDoseringsvarsel("");    
        }

        // updateDosageParamFromTimeControl(param);

        setStartDate(param.startDate);
        setEndDate(param.endDate);
        setIntervalDays(param.intervalDays);
        setEndOption(param.endOption);

        if ((param) && (param.endDate) && (param.startDate) && (param.endDate < param.startDate) && param.endOption=== "endDate")
            setDoseringsvarsel("Startdato kan ikke være senere enn sluttdato!");
        else
            setDoseringsvarsel("");
    } */

  const toggleAdvancedDosage = () => {
    if (doseringOpen) {
      // closing
      setDosage(curDoseringsText); // make sure the proc is run upon closing advanced dosage
      // Update timecontrol according to value in advanced dosage (cur param?)
      updateTimeControlFromAdvancedDosage(dosageParam);
    } else {
      // opening
      // Set cur dosage to undefined????? ZZZZZ (it is not relevant to have a value!)
      // setCurDoseringsParam(undefined); // ZZZ Did not work, sends over undefined
    }
    setDoseringsvarsel(''); // Empty warning when entering or leaving simple mode ...
    setDoseringOpen(!doseringOpen);
  };

  const labelstyle = 'block text-sm font-medium leading-6 text-gray-900';
  const inputtextstyle =
    'block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6';
  const inputtextBaseStyle =
    'block w-full rounded-md border-0 py-1.5 pl-2 pr-2 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6';
  const doseringstextStyle = inputtextBaseStyle + ' text-gray-900';
  const doseringstextWParamstyle = inputtextBaseStyle + ' text-blue-500';

  //const outerstyle="rounded-xl shadow-lg my-5 text-base"
  const outerstyle = 'rounded-xl  my-5 text-base';

  return (
    <div className={outerstyle}>
      <div className="-my-5 mb-2 bg-gray-100 text-sm font-medium text-gray-900">
        <label className="align-bottom">
          Debug admin-detaljer
          <input
            className="mx-1"
            type="checkbox"
            onChange={() => setDebug(!debug)}
            checked={debug === true}
            name="debug"
          />
        </label>
        {debug && (
          <div>
            <div>
              Administreringsdetaljer
              <div className="px-2">Kan åpnes = {administreringsdetaljer?.kanApnes ?? ''}</div>
              <div className="px-2">Kan knuses = {administreringsdetaljer?.kanKnuses ?? ''}</div>
              <div className="px-2">Deling = {administreringsdetaljer?.deling ?? ''}</div>
              <div className="px-2">Doseringsenhet, entall = {administreringsdetaljer?.enhetForDosering ?? ''}</div>
              <div className="px-2">
                Doseringsenhet, flertall = {administreringsdetaljer?.enhetForDosering_Plural ?? ''}
              </div>
            </div>
          </div>
        )}
      </div>

      <div>
        <div className="flex flex-row items-end justify-between">
          <label htmlFor="dosering" className={labelstyle + ' basis-full'}>
            Dosering
            <input
              className={!curDoseringsParam ? doseringstextStyle : doseringstextWParamstyle}
              autoComplete="off"
              list="opts1"
              value={curDoseringsText}
              onChange={(e) => setDosage(e.target.value)}
              id="dosering"
            />
            <datalist id="opts1">
              {' '}
              {doseringsforslag.map((e) => (
                <option key={e.code7478_Dosering}>
                  {e.doseringLang + (e.doseringCplDecoded === '' ? ' (fritekst)' : '')}
                </option>
              ))}
              {dosageParam !== undefined && <option key="advanced">{dosageParamOneLineText}</option>}
            </datalist>
          </label>
          {curDoseringsText.length > 30 && (
            <button
              onClick={() => setVisibilityLangDosering(!visibilityLangDosering)}
              className="m-2 basis-auto rounded bg-blue-500 text-white"
            >
              {!visibilityLangDosering ? <Notepad size={24} /> : <X size={24} />}
            </button>
          )}
          <button className="m-2 basis-auto rounded bg-blue-500 px-2 text-white" onClick={() => toggleAdvancedDosage()}>
            {doseringOpen ? '-' : '+'}
          </button>
        </div>
        {visibilityLangDosering && (
          <div className="my-2 bg-yellow-200 text-sm">{expandLineBreaks(curLangDoseringsText)}</div>
        )}
        <div>
          {doseringOpen && (
            <DoseringAlt4
              onUpdateDosageText={updateDosageTextAndParam}
              administreringsdetaljer={administreringsdetaljer}
              doseringsParams={
                curDoseringsParam
                  ? updatedDosageParamFromTimeControlNEW(
                      { startDate, endDate, intervalDays, endOption },
                      curDoseringsParam
                    )
                  : undefined
              }
            />
          )}
        </div>
      </div>

      {/*         {(!doseringOpen) && <div className="text-sm font-medium text-gray-900 bg-gray-100">
            <label className="align-bottom">Brukes f.o.m.
                <input className="pl-2 text-blue-600 bg-gray-100" value={getDateControlFormattedDate(startDate)} onChange={(e) => onStartDateChange(e.target.value)} type="date"></input>
            </label>
            <select className="pl-2 text-blue-600 bg-gray-100" value={endOption} onChange={(e) => setEndOption(e.target.value)} id="endoption">
                <option>Inntil videre</option>
                <option>Til og med</option>
            </select>
            {(endOption === "Til og med") && (<input className="pl-2 text-blue-600 disabled:text-gray-400 bg-gray-100" type="date"></input>)}
        </div>}
 */}

      {/*         {(!doseringOpen) && <DosageIntervalControl initStartDate={startDate} initEndDate={endDate} initIntervalDays={intervalDays} initEndOption={endOption} onDosageTimeChanges={onDosageTimeChanges} />
        }
 */}
      {!doseringOpen && (
        <DosageIntervalControl2
          collapsed={true}
          isComplex={isMultiple(curDoseringsParam)}
          startDate={startDate}
          endDate={endDate}
          intervalDays={intervalDays}
          endOption={endOption}
          baseStartDate={undefined}
          startDateOnChange={onStartDateChange}
          endDateOnChange={onEndDateChange}
          intervalDaysOnChange={onIntervalDaysChange}
          endOptionOnChange={onEndOptionChange}
          checkAllowedTimeChange={checkAllowedTimeChange}
        />
      )}
      {doseringsvarsel !== '' && !doseringOpen && <div className="pt-2 text-sm text-red-500">{doseringsvarsel}</div>}

      <div className="mb-2 bg-gray-100 text-sm font-medium text-gray-900">
        <label className="align-bottom">
          Fast
          <input
            className="mx-1"
            type="radio"
            onChange={() => setFastVedBehov('Fast')}
            checked={fastVedBehov === 'Fast'}
            name="prescrtype"
          />
        </label>
        <label className="mx-2">
          Ved behov
          <input
            className="mx-1"
            type="radio"
            onChange={() => setFastVedBehov('VedBehov')}
            checked={fastVedBehov === 'VedBehov'}
            name="prescrtype"
          />
        </label>
      </div>

      <label htmlFor="bruksomrade" className={labelstyle}>
        Bruksområde
        <input
          className={inputtextstyle}
          list="opts2"
          value={bruksomrade}
          onChange={(e) => setBruksomrade(e.target.value)}
          id="bruksomrade"
        />{' '}
        <datalist id="opts2">
          {' '}
          {bruksomradeforslag.map((e) => (
            <option key={e.id}>{e.bruksomrade}</option>
          ))}
        </datalist>
      </label>

      <div className={labelstyle + ' pt-2'}>
        {t('Behandling for sykdom' as NotTranslated)}

        <div className="">
          {conditions
            ?.filter((item) => addedConditions.includes(item.id!))
            .map((i) => (
              <div key={i.id} className="flex flex-row justify-start h-5">
                <button className="" onClick={() => onRemoveCondition(i.id ?? '0')}>
                  ⛔
                </button>
                <div>{i.code.coding?.[0]?.display}</div>
              </div>
            ))}
        </div>
        <Select
          label=""
          value={selectedCondition?.code?.coding?.[0]?.display}
          onChange={(e) => onAddConditionToList(e.target.value)}
        >
          <option key="" value="">
            Select
          </option>
          {conditions
            .filter((item) => !addedConditions.includes(item.id!))
            .map((item) => (
              <option key={item.id ?? '0'} value={item.id ?? '0'}>
                {item?.code?.coding?.[0]?.display}
              </option>
            ))}
        </Select>
      </div>

      <label htmlFor="forholdsregler" className={labelstyle}>
        Forholdsregler
        <input
          className={inputtextstyle}
          list="opts3"
          value={forholdsregler}
          onChange={(e) => setForholdsregler(e.target.value)}
          id="forholdsregler"
        />{' '}
      </label>
      <datalist id="opts3">
        {' '}
        {forholdsregelforslag.map((e) => (
          <option key={e.id}>{e.forholdsregel}</option>
        ))}
      </datalist>

      <label htmlFor="administrasjonsvei" className={labelstyle}>
        Administrasjonsvei
        <input
          className={inputtextstyle}
          list="opts4"
          value={administrasjonsvei}
          onChange={(e) => setAdministrasjonsvei(e.target.value)}
          id="administrasjonsvei"
        />{' '}
      </label>
      <datalist id="opts4">
        {' '}
        {administrasjonsveiforslag.map((e) => (
          <option key={e.id}>{e.administrasjonsvei}</option>
        ))}
      </datalist>

      <div className="flex flex-row justify-end">
        <button
          className="m-2 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
          onClick={() => onCancel()}
        >
          Avbryt, søk videre
        </button>
        <button
          className="m-2 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
          onClick={() => setShowDbParam(!showDbParam)}
        >
          Vis param
        </button>
        <button
          disabled={curDoseringsText && curDoseringsText !== '' ? false : true}
          className="m-2 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 disabled:bg-gray-300"
          onClick={() =>
            onSubmit({
              prescriptionDetails: {
                dosering: curDoseringsText,
                doseringParams: curDoseringsParam
                  ? !doseringOpen
                    ? updatedDosageParamFromTimeControlNEW(
                        { startDate, endDate, intervalDays, endOption },
                        curDoseringsParam
                      )
                    : curDoseringsParam
                  : undefined,
                freeTextPrescrTimeParams: !curDoseringsParam
                  ? { startDate, endDate, intervalDays, endOption }
                  : undefined,
                bruksomrade,
                bruksomradeIx: bruksomradeforslag?.find((i) => i.bruksomrade === bruksomrade)?.id ?? '',
                forholdsregler,
                forholdsreglerIx: forholdsregelforslag?.find((i) => i.forholdsregel === forholdsregler)?.id ?? '',
                administrasjonsvei,
                administrasjonsveiIx:
                  administrasjonsveiforslag?.find((i) => i.administrasjonsvei === administrasjonsvei)?.id ?? '',
                status: fastVedBehov,
              },
              relations: getAsRelations(addedConditions),
            })
          }
        >
          Velg dette
        </button>
      </div>

      {showDbParam && (
        <div className="text-xs">
          {curDoseringsParam &&
            JSON.stringify(
              convertParamToBackend(
                curDoseringsParam
                  ? !doseringOpen
                    ? updatedDosageParamFromTimeControlNEW(
                        { startDate, endDate, intervalDays, endOption },
                        curDoseringsParam
                      )
                    : curDoseringsParam
                  : undefined
              )
            )}
        </div>
      )}
    </div>
  );
};

export default PrescriptionDetails;
