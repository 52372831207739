/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import React from 'react';
import theme from '../../utils/theme';
import useTimeout from '../../utils/useTimeout';

const animationName = keyframes`
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

const Loader = ({
  invert,
  padded,
  delayMs = 300,
  className,
}: {
  invert?: boolean;
  padded?: boolean;
  delayMs?: number;
  className?: string;
}) => {
  const [show, setShow] = React.useState(false);
  useTimeout(() => setShow(true), delayMs);

  return (
    <div
      css={[
        css`
          margin: ${padded ? '20vh' : '20px'} auto;
          width: 70px;
          text-align: center;
          opacity: 0;
          transition: opacity 150ms ease-in-out;

          & > div {
            width: 18px;
            height: 18px;
            background-color: ${invert ? 'white' : theme.colors.blue};

            border-radius: 100%;
            display: inline-block;
            animation: ${animationName} 1.4s infinite ease-in-out both;

            &.bounce1 {
              animation-delay: -0.32s;
            }

            &.bounce2 {
              animation-delay: -0.16s;
            }
          }
        `,
        show &&
          css`
            opacity: 1;
          `,
      ]}
      className={className}
    >
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  );
};

export default Loader;
