import { RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import Heading from '../../components/Heading';
import AuthedPageLayout from '../../components/pagelayout/AuthedPageLayout';
import { useT } from '../../i18n/useT';

const PageContent = () => {
  const t = useT();
  return <Heading>{t('Calendar')}</Heading>;
};

export const CalendarPage: FC<RouteComponentProps> = () => {
  return (
    <AuthedPageLayout>
      <PageContent />
    </AuthedPageLayout>
  );
};
