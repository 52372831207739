import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

const settings = process.env.REACT_APP_LOCAL
  ? {
      debugMode: process.env.NODE_ENV === 'development',
      environment: process.env.REACT_APP_ENV as 'test' | 'prod',
      apiUrl: process.env.REACT_APP_API_URL,
      medApiUrl: process.env.REACT_APP_MED_API_URL,
    }
  : {
      debugMode: env.NODE_ENV === 'development',
      environment: env.REACT_APP_ENV as 'test' | 'prod',
      apiUrl: env.REACT_APP_API_URL,
      medApiUrl: env.REACT_APP_MED_API_URL,
    };

export default settings;

// let prodApiUrl = 'https://api.capable.healthcare/api';
// export let usingBetaApi = false;

// export const toggleBetaApi = () => {
//   if (localStorage.getItem('USE_BETA_API')) {
//     localStorage.removeItem('USE_BETA_API');
//   } else {
//     localStorage.setItem('USE_BETA_API', '1');
//   }
// };

// try {
//   if (localStorage.getItem('USE_BETA_API')) {
//     prodApiUrl = 'https://capableapi2.azurewebsites.net/api';
//     usingBetaApi = true;
//   }
// } catch (error) {}

// const devSettings = {
//   // apiUrl: 'http://localhost:54941/api',
//   apiUrl: prodApiUrl,
// };

// const prodSettings = {
//   apiUrl: prodApiUrl,
// };

// export default process.env.NODE_ENV === 'development' ? devSettings : prodSettings;
