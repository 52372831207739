/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useParams } from '@reach/router';
import { addDays, isSameDay, startOfDay, subDays } from 'date-fns';
import React, { useCallback, useState } from 'react';
import Loader from '../../components/loader/Loader';
import { useT } from '../../i18n/useT';
import { useQueryWithLogHistory } from '../../network/useQueryWithLogHistory';
import formatDate from '../../utils/formatDate';
import { fromServerDate } from '../../utils/serverDateConverter';
import SleepObservation from './SleepObservation';

export function SleepDiary({ selectedPatientId }: { selectedPatientId?: string }) {
  const t = useT();
  const { date: initialDate } = useParams();

  const [query] = useQueryWithLogHistory(
    ['sleepDiary', selectedPatientId],
    `/SleepObservation/Patient/${selectedPatientId}`,
    {
      enabled: Boolean(selectedPatientId),
    }
  );

  const [chosenDate, setChosenDate] = useState(startOfDay(initialDate ? new Date(initialDate) : new Date()));
  const chosenDateIsToday = isSameDay(chosenDate, new Date());
  const prevDay = useCallback(() => {
    setChosenDate(subDays(chosenDate, 1));
  }, [chosenDate, setChosenDate]);
  const nextDay = useCallback(() => {
    if (!chosenDateIsToday) {
      setChosenDate(addDays(chosenDate, 1));
    }
  }, [chosenDateIsToday, chosenDate, setChosenDate]);

  const observations = query.data?.sleepObservations ?? [];
  const observationForChosenDay =
    observations &&
    observations.find((observation) => {
      return isSameDay(fromServerDate(observation.observationDate), chosenDate);
    });

  const lastObservation = observations && observations.length > 0 ? observations[observations.length - 1] : undefined;

  if (query.isLoading) {
    return <Loader />;
  } else if (query.isError) {
    return <div>{t('Something went wrong')}</div>;
  }

  return (
    <div
      css={css`
        max-width: 800px;
        margin: 0 auto;
      `}
    >
      <div
        css={css`
          text-align: center;
          margin-bottom: 16px;
        `}
      >
        <h1>{t('Sleep diary')}</h1>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <Button onClick={prevDay} title={t('Previous day')}>
          &lt;
        </Button>
        <div
          css={css`
            margin: 0 16px;
            font-size: 26px;
            font-weight: 300;
          `}
        >
          {formatDate(chosenDate, 'dd.MM.yyyy')}
        </div>
        <Button onClick={nextDay} title={t('Next day')}>
          &gt;
        </Button>
      </div>

      <div style={{ height: 40 }} />

      <SleepObservation
        key={chosenDate.toISOString() + selectedPatientId}
        chosenDate={chosenDate}
        observation={observationForChosenDay}
        lastObservation={lastObservation}
        patientId={selectedPatientId}
        refetchSleepDiary={() => query.refetch()}
      />
    </div>
  );
}

function Button(props: { onClick: () => void; title: string; children: React.ReactNode }) {
  return (
    <button
      css={css`
        background-color: lightgray;
        border: 1px solid gray;
        padding: 8px 16px;
        border-radius: 12px;
        font-size: 18px;
      `}
      {...props}
    />
  );
}
