/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import React from 'react';
import Block from '../../components/Block';
import { CrossButton } from '../../components/CrossButton';
import { Column } from '../../components/Flex';
import Text from '../../components/Text';
import { useT } from '../../i18n/useT';
import theme from '../../utils/theme';
import useTimeout from '../../utils/useTimeout';
import { IndexedNotification } from './types';

const enter = keyframes`
  from {
      transform: translateX(200%);
  }
  to {
      transform: translateX(0);
  }
`;
const exit = keyframes`
  from {
      transform: translateX(0);
  }
  to {
      transform: translateX(200%);
  }
`;
const duration = 200;

const Notification = ({ notification, onRemove }: { notification: IndexedNotification; onRemove: () => void }) => {
  const { title, message, type, timeout } = notification;

  const [stage, setStage] = React.useState<'enter' | 'exit'>('enter');
  useTimeout(() => setStage('exit'), timeout - duration);
  useTimeout(onRemove, timeout);
  const t = useT();

  const title2 = type === 'error' ? title ?? t('Something went wrong') : title;

  const textColor = type === 'error' ? 'white' : theme.colors.text;
  const backgroundColor =
    type === 'error' ? theme.colors.red : type === 'warning' ? theme.colors.yellow : theme.colors.green;

  return (
    <Block
      backgroundColor={backgroundColor}
      css={css`
        position: relative;
        pointer-events: auto;
        animation: ${stage === 'enter' ? enter : exit} ${duration}ms ease-out;
        min-width: 200px;
        max-width: 500px;
        padding: 20px;
        @media (max-width: 599px) {
          padding: 16px;
        }
        z-index: 1004; // higher than Notifications
      `}
    >
      <div aria-live="polite" role="region">
        <Column>
          {title2 && (
            <Text size="huge" strong color={textColor} marginBottom={4} marginRight={12}>
              {title2}
            </Text>
          )}
          <Text size="large" color={textColor}>
            {message}
          </Text>
        </Column>
      </div>
      <CrossButton
        onClick={onRemove}
        css={css`
          position: absolute;
          top: 8px;
          right: 8px;

          & .cross {
            background-color: ${textColor};
          }
        `}
      />
    </Block>
  );
};

export const Notifications = ({
  notifications,
  onRemoveNotification,
}: {
  notifications: IndexedNotification[];
  onRemoveNotification: (notificationId: string) => void;
}) => {
  return (
    <Column
      horizontal="flex-end"
      vertical="flex-end"
      width={500}
      height="100%"
      css={css`
        position: fixed;
        z-index: 1003; // higher than Modal
        right: 20px;
        bottom: 20px;
        max-width: calc(100% - 40px);
        pointer-events: none;

        & > :not(:last-child) {
          margin-bottom: 20px;
        }
      `}
    >
      {notifications.map((notification) => (
        <Notification
          key={notification.id}
          notification={notification}
          onRemove={() => onRemoveNotification(notification.id)}
        />
      ))}
    </Column>
  );
};
