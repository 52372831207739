/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Pie, PieChart } from 'recharts';
import Text from '../../components/Text';
import { useT } from '../../i18n/useT';
import { round } from '../../utils/math';
import { pluralize } from '../../utils/strings';
import theme from '../../utils/theme';

// IKKE OVERSATT
const SleepEfficiencyGraph = ({
  sleepEfficiency,
  observationCount,
}: {
  sleepEfficiency: number;
  observationCount: number;
}) => {
  const t = useT();
  const fill =
    sleepEfficiency < 80 ? theme.colors.red : sleepEfficiency < 91 ? theme.colors.orange : theme.colors.green;
  const sleepEfficiencyPieChart = sleepEfficiency < 0 ? 0 : sleepEfficiency;
  const explanation =
    sleepEfficiency < 0
      ? `Du har ${observationCount === 0 ? 'ingen' : observationCount} ${pluralize(
          'observasjon',
          'observasjoner',
          observationCount
        )} denne uken. Beregning av søvneffektivitet forutsetter minst 3 observasjoner.`
      : sleepEfficiency < 80
      ? 'Ved søvneffektivitet under 80% bør du vurdere å søke profesjonell help dersom søvnproblemene også påvirker dagfunksjonen din. Søvnrestriksjonsbehandling kan også ha god effekt når søvneffektiviteten kommer under 80%.'
      : sleepEfficiency < 91
      ? 'Redusert søvneffektivitet kan bedres ved hjelp av søvnrestriksjonsbehandling. Du gjør dette ved å tillate deg å maksimal tid i sengen = den gjennomsnittlige søvnlengden i ukegjennomsnittet. Hvis ukegjennomsnittet var kortere enn 5 timer bør du ikke lage en strengere søvnrestriksjon enn 5 timer. Gjennomfør restriksjonen ut denne uken og vurder om den gjennomsnittlige søvneffektiviteten din nå er bedret.'
      : 'Søvneffektiviteten din regnes som normal.';

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 500px;
      `}
    >
      <div
        css={css`
          position: relative;
          width: 210px;
          height: 180px;
        `}
      >
        <PieChart width={210} height={180}>
          <Pie
            data={[
              { name: t('Total sleep efficiency'), value: sleepEfficiencyPieChart, fill },
              { name: '-', value: 100 - sleepEfficiency, fill: 'rgba(0, 0, 0, 0.2)' },
            ]}
            cx={100}
            cy={100}
            startAngle={180 + 45}
            endAngle={-45}
            innerRadius={75}
            outerRadius={100}
            dataKey="value"
          />
        </PieChart>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -40%);
          `}
        >
          <Text
            css={css`
              font-size: 32px;
              font-weight: 600;
            `}
          >
            {sleepEfficiency < 0 ? '-' : `${round(sleepEfficiency, 1)}%`}
          </Text>
          <Text size="large" align="center">
            {t('Total sleep efficiency')}
          </Text>
        </div>
      </div>

      <Text
        css={css`
          margin-top: 20px;
          text-align: center;
          padding: 8px 12px;
          border: 1px solid #333;
          border-radius: 4px;
        `}
      >
        {explanation}
      </Text>
    </div>
  );
};

export default SleepEfficiencyGraph;
