/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { isUndefined } from 'lodash';

type MarginValue = number | 'auto';

// Let's be VERY restrictive with what we add as short props...
export type ShortProps = {
  margin?:
    | MarginValue
    | Array<MarginValue>
    | { left: MarginValue; top: MarginValue; right: MarginValue; bottom: MarginValue };
  marginTop?: MarginValue;
  marginBottom?: MarginValue;
  marginLeft?: MarginValue;
  marginRight?: MarginValue;
  zIndex?: number | string;
};

const autoOrPx = (margin: MarginValue) => (margin === 'auto' ? 'auto' : `${margin}px`);

const readShortProps = ({
  margin,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  zIndex,
  ...rest
}: ShortProps): [SerializedStyles | SerializedStyles[] | undefined, any] => {
  if (
    !isUndefined(margin) ||
    !isUndefined(marginTop) ||
    !isUndefined(marginBottom) ||
    !isUndefined(marginLeft) ||
    !isUndefined(marginRight) ||
    !isUndefined(zIndex)
  ) {
    let returnClass = undefined;
    switch (typeof margin) {
      case 'object':
        if (Array.isArray(margin)) {
          returnClass = css`
            margin: ${margin.map((px) => `${autoOrPx(px)}`).join(' ')};
          `;
        } else {
          marginLeft = margin['left'];
          marginTop = margin['top'];
          marginRight = margin['right'];
          marginBottom = margin['bottom'];
        }
        break;
      case 'number':
        returnClass = css`
          margin: ${margin}px;
        `;
        break;

      case 'undefined':
        break;

      default:
        throw new Error('Unexpected margin specification to `readShortProps`');
    }

    if (!returnClass) {
      returnClass = css`
        ${isUndefined(marginTop) ? '' : `margin-top: ${autoOrPx(marginTop)}`};
        ${isUndefined(marginBottom) ? '' : `margin-bottom: ${autoOrPx(marginBottom)}`};
        ${isUndefined(marginLeft) ? '' : `margin-left: ${autoOrPx(marginLeft)}`};
        ${isUndefined(marginRight) ? '' : `margin-right: ${autoOrPx(marginRight)}`};
      `;
    }

    if (zIndex) {
      returnClass = [
        returnClass,
        css`
          position: relative;
          z-index: ${zIndex};
        `,
      ];
    }

    return [returnClass, rest];
  }

  return [undefined, rest];
};

export default readShortProps;
