import React, { FC } from 'react';
import Button from '../../components/Button2';
import { Column, Row } from '../../components/Flex';
import Heading from '../../components/Heading';
import { Modal, ModalTrigger } from '../../components/Modal';
import Text from '../../components/Text';
import TextInput from '../../components/TextInput';
import { useT } from '../../i18n/useT';
import { useAddNotification } from '../../lib/notifications/NotificationContext';
import { useUserContext } from '../../lib/UserContext';
import { useUpdateUser } from '../../network/UserClient';
import { isValidMobileNumber } from '../../utils/mobileNumber';
import theme from '../../utils/theme';
import { useForm } from '../../utils/useForm';
import { InvitedUserList } from './InvitedUserList';

export const AccessControl: FC = () => {
  const t = useT();

  return (
    <div>
      <Heading>{t('Access control')}</Heading>

      <InvitedUserList />

      <div style={{ height: 40 }} />

      <ModalTrigger
        button={({ openModal }) => <Button onClick={openModal}>{t('Invite person for access')}</Button>}
        modal={({ closeModal }) => <InviteUserModal closeModal={closeModal} />}
      />
    </div>
  );
};

const InviteUserModal = ({ closeModal }: { closeModal: () => void }) => {
  const t = useT();
  const { loggedInUser, checkIfLoggedIn } = useUserContext();
  const { addError, addInfo } = useAddNotification();
  const updateUser = useUpdateUser();

  const form = useForm({
    initialValues: {
      mobileNumber: '',
      mobilePhoneVerification: '',
    },
    validate: {
      mobileNumber: (val) => (isValidMobileNumber(val) ? undefined : t('Not valid mobile number')),
      mobilePhoneVerification: (val, values) =>
        val === values.mobileNumber ? undefined : t('Mobile numbers must match'),
    },
    onSubmit: async ({ values }) => {
      if (form.submitting) {
        return;
      }

      try {
        const res = await updateUser.mutateAsync({
          invitedUsers: [
            ...(loggedInUser?.user.invitedUsers ?? []),
            {
              mobilePhone: values.mobileNumber,
            },
          ],
        });

        if (res.success) {
          checkIfLoggedIn();
          closeModal();
          addInfo({ message: t('Access given to {{mobileNumber}}', { mobileNumber: values.mobileNumber }) });
        } else {
          addError({ message: (res.error as any)?.resultMessage ?? 'Ukjent feil' });
        }
      } catch (error) {
        addError({ message: 'Ukjent feil' });
      }
    },
  });

  return (
    <Modal title={t('Invite person for access')} closeModal={closeModal}>
      <form onSubmit={form.submitForm}>
        <Column>
          <TextInput
            value={form.values.mobileNumber}
            onValue={(val) => form.setValue('mobileNumber', val)}
            type="tel"
            placeholder={t('Mobile number')}
            autoFocus
            disabled={form.submitting}
            marginBottom={4}
          />

          {form.submitAttempted && form.fieldErrors.mobileNumber && (
            <Text size="small" color={theme.colors.errorRed}>
              {form.fieldErrors.mobileNumber}
            </Text>
          )}

          <div style={{ height: 20 }} />

          <TextInput
            value={form.values.mobilePhoneVerification}
            onValue={(val) => form.setValue('mobilePhoneVerification', val)}
            type="tel"
            placeholder={t('Repeat mobile number')}
            disabled={form.submitting}
            marginBottom={4}
          />

          {form.submitAttempted && form.fieldErrors.mobilePhoneVerification && (
            <Text size="small" color={theme.colors.errorRed}>
              {form.fieldErrors.mobilePhoneVerification}
            </Text>
          )}

          <div style={{ height: 20 }} />

          <Row horizontal="flex-end">
            <Button onClick={form.submitForm} loading={form.submitting}>
              {t('Give access')}
            </Button>
          </Row>
        </Column>
      </form>
    </Modal>
  );
};
