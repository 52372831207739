import { Dispatch, SetStateAction, useState } from 'react';

type OnChangeType = React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>;

export function useInput<T>(
  initialValue: T,
  fieldName: string,
  validationRules: Array<(value: string, fieldName: string) => string>
): {
  value: T;
  setValue: Dispatch<SetStateAction<T>>;
  isInFocus: boolean;
  validation: {
    touched: boolean;
    errors: string[];
    validate: () => boolean;
  };
  reset: () => void;
  bind: {
    value: T;
    onChange: (event: OnChangeType) => void;
    onBlur: () => void;
    onFocus: () => void;
  };
  handleChange: (value: T) => void;
} {
  const [value, setValue] = useState<T>(initialValue);
  const [touched, setTouched] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [isInFocus, setIsInFocus] = useState<boolean>(false);

  const handleOnChange = (event: OnChangeType) => {
    if (touched) {
      const errorMessages = validationRules.map((rule) => rule(event.target.value, fieldName)).filter(Boolean);
      setErrors(errorMessages);
    }

    const v = event.target.value as any;

    setValue(v);
  };

  const setStateDirectly = (v: T) => {
    if (touched) {
      const errorMessages = validationRules.map((rule) => rule(String(v), fieldName)).filter(Boolean);
      setErrors(errorMessages);
    }

    setValue(v);
  };

  const handleValidation = () => {
    const errorMessages = validationRules.map((rule) => rule(String(value), fieldName)).filter(Boolean) || [];

    setTouched(true);
    setIsInFocus(false);
    setErrors(errorMessages);
    return errorMessages.length === 0;
  };

  return {
    value,
    setValue,
    isInFocus,
    validation: {
      touched,
      errors,
      validate: () => handleValidation(),
    },
    reset: () => {
      setValue(initialValue);
      setTouched(false);
      setErrors([]);
    },
    bind: {
      value,
      onChange: (event) => handleOnChange(event),
      onBlur: () => handleValidation(),
      onFocus: () => setIsInFocus(true),
    },
    handleChange: (value) => setStateDirectly(value),
  };
}
