/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Redirect, RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import { Column } from '../../components/Flex';
import Heading from '../../components/Heading';
import OpenPageLayout from '../../components/pagelayout/OpenPageLayout';
import Text from '../../components/Text';
import { useT } from '../../i18n/useT';
import { useUserContext } from '../../lib/UserContext';
import logo from './capable-logo.svg';

function Landing() {
  const t = useT();

  return (
    <Column vertical="space-between" grow>
      <Column>
        <img
          src={logo}
          alt="Capable"
          css={css`
            width: 100%;
            margin-top: 40px;
            max-height: 400px;
            object-fit: cover;
          `}
        />

        <Heading level={2} marginTop={80} marginBottom={40}>
          Capable &mdash; {t('Your health your data')}
        </Heading>
        <Text
          css={css`
            line-height: 1.7;
          `}
        >
          {t('Landing page text')}{' '}
        </Text>
      </Column>

      <Text>Copyright {new Date().getFullYear()}</Text>
    </Column>
  );
}

export const LandingPage: FC<RouteComponentProps> = () => {
  const { isLoggedIn } = useUserContext();

  if (isLoggedIn) {
    return <Redirect to="/hjem" noThrow />;
  }

  return (
    <OpenPageLayout documentTitle="Capable">
      <Landing />
    </OpenPageLayout>
  );
};
