import { useState } from 'react';

export function useObservationInput(observation, fieldName, validationRules) {
  let initialValue = observation ? observation[fieldName] : '';
  // Replacing init value with empty string if fieldName is undefined
  initialValue = typeof initialValue === 'undefined' ? '' : initialValue;
  const [value, setValue] = useState(initialValue);
  const [touched, setTouched] = useState(false);
  const [errors, setErrors] = useState([]);

  const validate = (onChangeValue) => {
    setTouched(true);
    const errorMessages = validationRules.map((rule) => rule(onChangeValue || value, fieldName)).filter(Boolean) || [];
    setErrors(errorMessages);

    if (errorMessages.length > 0) {
      return false;
    }

    return true;
  };

  const handleOnBlur = () => {
    validate();
  };

  const handleOnChange = (event) => {
    if (touched) {
      validate(event.target.value);
    }

    setValue(event.target.value);
  };

  return {
    bind: {
      onBlur: () => handleOnBlur(),
      onChange: (event) => handleOnChange(event),
      value,
    },
    reset: () => {
      setValue(initialValue);
      setTouched(false);
      setErrors([]);
    },
    validate,
    validation: { touched, errors },
    value,
  };
}

export function useObservationSelect(observation, fieldName, validationRules) {
  const initialValue = observation ? observation[fieldName] : undefined;
  const [value, setValue] = useState(initialValue);
  const [errors, setErrors] = useState([]);

  const validate = (onChangeValue) => {
    const errorMessages =
      validationRules
        .map((rule) => rule(onChangeValue || Number.isInteger(onChangeValue) ? onChangeValue : value))
        .filter(Boolean) || [];
    setErrors(errorMessages);

    if (errorMessages.length > 0) {
      return false;
    }

    return true;
  };

  const handleOnChange = (event) => {
    validate(event.target.value);
    setValue(event.target.value);
  };

  return {
    bind: { value, onChange: (event) => handleOnChange(event) },
    reset: () => {
      setValue(initialValue);
      setErrors([]);
    },
    setValue: (value) => {
      validate(value);
      setValue(value);
    },
    validate,
    validation: { errors },
    value,
  };
}

export function useSelect(initialValue, validationRules) {
  const [value, setValue] = useState(initialValue);
  const [errors, setErrors] = useState([]);

  const validate = (onChangeValue) => {
    const errorMessages =
      validationRules
        .map((rule) => rule(onChangeValue || Number.isInteger(onChangeValue) ? onChangeValue : value))
        .filter(Boolean) || [];
    setErrors(errorMessages);

    if (errorMessages.length > 0) {
      return false;
    }

    return true;
  };

  const handleOnChange = (event) => {
    validate(event.target.value);
    setValue(event.target.value);
  };

  return {
    bind: { value, onChange: (event) => handleOnChange(event) },
    reset: () => {
      setValue(initialValue);
      setErrors([]);
    },
    setValue: (value) => {
      validate(value);
      setValue(value);
    },
    validate,
    validation: { errors },
    value,
  };
}

export function useObservationRadio(observation, fieldName, validationRules) {
  const initialValue = observation ? observation[fieldName] : undefined;
  const [value, setValue] = useState(initialValue);
  const [errors, setErrors] = useState([]);

  const validate = (onChangeValue) => {
    const errorMessages =
      validationRules
        .map((rule) => rule(typeof onChangeValue !== 'undefined' ? onChangeValue : value))
        .filter(Boolean) || [];
    setErrors(errorMessages);

    if (errorMessages.length > 0) {
      return false;
    }

    return true;
  };

  return {
    reset: () => {
      setValue(initialValue);
      setErrors([]);
    },
    setValue: (value) => {
      setValue(value);
      validate(value);
    },
    validate,
    validation: { errors },
    value,
  };
}
