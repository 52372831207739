/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { screenReaderOnlyStyle } from '../utils/styles';
import { defaultButton } from './Button';

export const defaultInput = css`
  height: 32px;
  font-size: 16px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: grey;
`;

export const Input: FC<{
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  label: string;
  type: string;
  validation?: {
    touched: boolean;
    errors: string[];
    validate: () => boolean;
  };
  placeholder?: string;
  labelOnlyForScreenReaders?: boolean;
  unit?: string;
}> = ({ value, onChange, onBlur, onFocus, label, type, validation, placeholder, labelOnlyForScreenReaders }) => {
  const hasErrors = validation && validation.errors.length > 0;

  return (
    <label
      css={css`
        position: relative;
        font-size: 18px;
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;
      `}
    >
      <span
        css={[
          css`
            margin-bottom: 8px;
          `,
          labelOnlyForScreenReaders && screenReaderOnlyStyle,
        ]}
      >
        {label}
      </span>
      <Wrapper error={hasErrors}>
        <StyledInput
          type={type}
          value={value}
          onChange={(event) => onChange(event)}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={placeholder}
        />
      </Wrapper>
      {hasErrors && (
        <ErrorMessage>{validation && validation.errors.map((error) => <div key={error}>{error}</div>)}</ErrorMessage>
      )}
    </label>
  );
};

const Wrapper = styled.div<{ error?: boolean }>`
  input {
    width: 100%;
  }
  ${(props) =>
    props.error &&
    css`
      * {
        background-color: rgba(255, 0, 0, 0.1);
        border-color: rgba(255, 0, 0, 0.3);
      }
    `}
`;

const ErrorMessage = styled.div`
  margin-top: 5px;
  color: #9e0000;
`;

export const StyledInput = styled.input`
  ${defaultInput}
`;

export const InputWithButton = styled.div`
  display: flex;
  flex-direction: row;

  input: {
    ${defaultInput}
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  button: {
    ${defaultButton}
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
