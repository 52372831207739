/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { FC } from 'react';
import { Column } from './Flex';
import { Select } from './Select';
import Text from './Text';

export const Selector: FC<{
  title: string;
  value?: string;
  onValue: (value: string) => void;
  data: Array<{ value: string; label: string; disabled?: boolean }>;
  undefinedLabel?: string;
}> = ({ title, value, onValue, data, undefinedLabel }) => (
  <label
    css={css`
      margin-top: 16px;
    `}
  >
    <Column>
      <Text marginBottom={4}>{title}</Text>
      <Select value={value} onChange={(event) => onValue(event.target.value)}>
        {undefinedLabel && <option value={undefined}>{undefinedLabel}</option>}
        {data.map((item) => (
          <option key={item.value ?? item.label} value={item.value} disabled={item.disabled}>
            {item.label}
          </option>
        ))}
      </Select>
    </Column>
  </label>
);
