import { Redirect, RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import Loader from '../../components/loader/Loader';
import OpenPageLayout from '../../components/pagelayout/OpenPageLayout';
import { useT } from '../../i18n/useT';
import { useUserContext } from '../../lib/UserContext';

export const MedicationRedirect: FC<RouteComponentProps> = () => {
  const t = useT();
  const { loading, loggedInUser } = useUserContext();

  if (loading) {
    return (
      <OpenPageLayout documentTitle={t('Capable - Medications')}>
        <Loader />
      </OpenPageLayout>
    );
  } else if (loggedInUser) {
    return <Redirect to={`/${loggedInUser.user.patientId}/medisiner`} noThrow />;
  } else {
    return <Redirect to={`/login?returnto=${encodeURIComponent('/medisiner')}`} noThrow />;
  }
};
