/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { FC } from 'react';

// https://material-ui.com/components/alert/#alert
export const Alert: FC<{ level: 'SUCCESS' | 'WARNING' | 'ERROR' | 'INFO'; marginTop?: number; className?: string }> = ({
  level,
  className,
  marginTop,
  children,
}) => (
  <div
    css={[
      css`
        background-color: ${colors[level].background};
        color: ${colors[level].text};
        border-radius: 4px;
        padding: 10px 20px;
        margin-top: ${marginTop || 0}px;
      `,
    ]}
    className={className}
  >
    {children}
  </div>
);
const colors = {
  SUCCESS: {
    text: '#1e4620',
    background: '#edf7ed',
  },
  WARNING: {
    text: '#663c00',
    background: '#fef4e5',
  },
  ERROR: {
    text: '#611a15',
    background: '#fdecea',
  },
  INFO: {
    text: '#0d3c61',
    background: '#e8f4fe',
  },
};
