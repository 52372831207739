/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useNavigate } from '@reach/router';
import { addMinutes, differenceInMinutes } from 'date-fns';
import React, { useCallback } from 'react';
import { useT } from '../../i18n/useT';
import { useAddNotification } from '../../lib/notifications/NotificationContext';
import SleepDiaryClient from '../../network/SleepDiaryClient';
import { toServerDate } from '../../utils/serverDateConverter';
import { Button, ExtraOption, NumberInput, Select, SubTitle, TextInput, Title } from './components/InputFields';
import { InputLine } from './components/InputLine';
import SelectTimeOptions from './components/SelectTimeOptions';
import { useObservationInput, useObservationSelect, useSelect } from './utils/hooks';
import { number, requiredInput, requiredSelect } from './utils/rules';
import { morningTimeOptions, nightTimeOptions } from './utils/timeOptions';

export default function SleepObservationEdit({
  patientId,
  chosenDate,
  observation,
  refetchSleepDiary,
  updateExisitingOberservation,
}) {
  const navigate = useNavigate();
  const t = useT();
  const { addInfo } = useAddNotification();

  const functionalProblems = useObservationInput(observation, 'functionalProblems', [requiredInput(t)]);
  const functionalMeasures = useObservationInput(observation, 'functionalMeasures', []);
  const minutesToSleep = useObservationInput(observation, 'minutesToSleep', [requiredInput(t), number(t)]);
  const nofWakeupsDuringNight = useObservationInput(observation, 'nofWakeupsDuringNight', [
    requiredInput(t),
    number(t),
  ]);
  const minutesAwakeTimeDuringNight = useObservationInput(observation, 'minutesAwakeTimeDuringNight', [
    requiredInput(t),
    number(t),
  ]);
  const minutesLatestAwakeTime = useObservationInput(observation, 'minutesLatestAwakeTime', [
    requiredInput(t),
    number(t),
  ]);
  const sleepComments = useObservationInput(observation, 'sleepComments', []);
  // const sleepingDrugs = useObservationInput(observation, 'sleepingDrugs', []);
  const functionLevel = useObservationSelect(observation, 'functionLevel', [
    requiredSelect(t, t('Choose from list...')),
  ]);
  const sleepQualityMeasure = useObservationSelect(observation, 'sleepQualityMeasure', [
    requiredSelect(t, t('Choose from list...')),
  ]);
  const gotoBedTime = useSelect(convertToOffsetDate('gotoBedTime', observation), [
    requiredSelect(t, t('Choose from list...')),
  ]);
  const attemptToSleepTime = useSelect(convertToOffsetDate('attemptToSleepTime', observation), [
    requiredSelect(t, t('Choose from list...')),
  ]);
  const finalWakeupTime = useSelect(convertToOffsetDate('finalWakeupTime', observation), [
    requiredSelect(t, t('Choose from list...')),
  ]);
  const outOfBedTime = useSelect(convertToOffsetDate('outOfBedTime', observation), [
    requiredSelect(t, t('Choose from list...')),
  ]);
  // const sleepingDrug = useObservationRadio(observation, 'sleepingDrug', [requiredRadio(t)]);

  const onCancelEdit = () => {
    if (window.confirm(t('Are you sure you want to cancel? The changes will not be saved'))) {
      navigate(-1);
    }
  };

  const submit = useCallback(async () => {
    const allFieldsAreValid = () => {
      let allOK = true;
      [
        sleepComments,
        minutesLatestAwakeTime,
        minutesAwakeTimeDuringNight,
        nofWakeupsDuringNight,
        minutesToSleep,
        functionalMeasures,
        functionLevel,
        gotoBedTime,
        attemptToSleepTime,
        finalWakeupTime,
        outOfBedTime,
        sleepQualityMeasure,
        // sleepingDrug,
      ].forEach((func, i) => {
        if (!func.validate()) {
          allOK = false;
        }
      });

      if (functionLevel.value <= 2 && !functionalProblems.validate()) {
        allOK = false;
      }

      // if (sleepingDrug.value && !sleepingDrugs.validate()) {
      //   allOK = false;
      // }

      return allOK;
    };

    if (!allFieldsAreValid()) {
      return;
    }

    const updatedObservation = {
      ...(updateExisitingOberservation ? observation ?? {} : {}),
      observationDate: toServerDate(chosenDate),
      patientId,
      functionLevel: parseInt(functionLevel.value || '0', 10),
      functionalProblems: functionalProblems.value,
      functionalMeasures: functionalMeasures.value,
      // sleepingDrug: sleepingDrug.value,
      // sleepingDrugs: sleepingDrug ? sleepingDrugs.value : '',
      sleepingDrug: false,
      sleepingDrugs: '',
      gotoBedTime: toServerDate(addMinutes(chosenDate, gotoBedTime.value)),
      attemptToSleepTime: toServerDate(addMinutes(chosenDate, attemptToSleepTime.value)),
      finalWakeupTime: toServerDate(addMinutes(chosenDate, finalWakeupTime.value)),
      outOfBedTime: toServerDate(addMinutes(chosenDate, outOfBedTime.value)),
      minutesToSleep: parseInt(minutesToSleep.value || '0', 10),
      nofWakeupsDuringNight: parseInt(nofWakeupsDuringNight.value || '0', 10),
      minutesAwakeTimeDuringNight: parseInt(minutesAwakeTimeDuringNight.value || '0', 10),
      minutesLatestAwakeTime: parseInt(minutesLatestAwakeTime.value || '0', 10),
      sleepQualityMeasure: parseInt(sleepQualityMeasure.value || '0', 10),
      sleepComments: sleepComments.value,
    };

    if (observation && updateExisitingOberservation) {
      await SleepDiaryClient.updateSleepObservation(observation.id, updatedObservation).then(() => {
        refetchSleepDiary();
      });
    } else {
      await SleepDiaryClient.createSleepObservation(updatedObservation).then(() => {
        refetchSleepDiary();
      });
    }

    addInfo({
      message: t('Observation registered'),
    });
    navigate(`/${patientId}/sovn-oversikt`);
  }, [
    updateExisitingOberservation,
    navigate,
    chosenDate,
    t,
    observation,
    patientId,
    functionLevel,
    functionalProblems,
    functionalMeasures,
    // sleepingDrug,
    // sleepingDrugs,
    gotoBedTime,
    attemptToSleepTime,
    finalWakeupTime,
    outOfBedTime,
    minutesToSleep,
    nofWakeupsDuringNight,
    minutesAwakeTimeDuringNight,
    sleepQualityMeasure,
    minutesLatestAwakeTime,
    sleepComments,
    refetchSleepDiary,
    addInfo,
  ]);

  return (
    <div>
      <Title htmlFor="hvordan-fungerte-du">{t('How did you function yesterday?')}</Title>
      <InputLine v={functionLevel.validation} noMargin={functionLevel.value <= 2}>
        <Select id="hvordan-fungerte-du" {...functionLevel.bind}>
          <option value={undefined} disabled={functionLevel.value !== undefined}>
            {t('Choose from list...')}
          </option>
          <option value={1}>{t('Very bad')}</option>
          <option value={2}>{t('Bad')}</option>
          <option value={3}>{t('Neither good or bad')}</option>
          <option value={4}>{t('Good')}</option>
          <option value={5}>{t('Very good')}</option>
        </Select>
      </InputLine>
      {functionLevel.value <= 2 && (
        <ExtraOption>
          <Title htmlFor="hva-var-dårlig-med-funksjonen">
            {t('What was it that was bad about your function yesterday?')}
          </Title>
          <InputLine v={functionalProblems.validation} noMargin>
            <TextInput
              id="hva-var-dårlig-med-funksjonen"
              placeholder={t('Write keywords')}
              {...functionalProblems.bind}
            />
          </InputLine>
        </ExtraOption>
      )}
      <Title htmlFor="tiltak-du-gjorde-i-går">
        {t('Write down any activity you did yesterday to function better')}
      </Title>
      <InputLine v={functionalMeasures.validation}>
        <TextInput id="tiltak-du-gjorde-i-går" placeholder={t('Write keywords')} {...functionalMeasures.bind} />
      </InputLine>

      {/*
        We don't want to ask about alcohol/drugs for now, maybe some other time
        
       <Title>
        {t('Did you take sleeping pills, consumed alcohol and/or illegal drugs 5 hours prior to getting to bed?')}
      </Title>
      <InputLine v={sleepingDrug.validation}>
        <RadioWrapper style={sleepingDrug ? { marginBottom: 5 } : {}}>
          <Radio selected={sleepingDrug.value === true} onClick={() => sleepingDrug.setValue(true)}>
            {t('Yes')}
          </Radio>
          <div style={{ width: 16 }} />
          <Radio selected={sleepingDrug.value === false} onClick={() => sleepingDrug.setValue(false)}>
            {t('No')}
          </Radio>
        </RadioWrapper>
      </InputLine>
      {sleepingDrug.value && (
        <ExtraOption>
          <Title htmlFor="hvilke-sovemedisiner">
            {t('What kind of sleep aids and/or other substances did you take 5 hours prior to getting to bed?')}
          </Title>
          <InputLine v={sleepingDrugs.validation} noMargin>
            <TextArea id="hvilke-sovemedisiner" placeholder={t('Write here')} {...sleepingDrugs.bind} />
          </InputLine>
        </ExtraOption>
      )}
      */}

      <SelectTimeOptions
        title={t(
          'When did you go to bed? This does not have to be the same time as you tried to sleep (such as looking at a screen, read a book or other activities)'
        )}
        subTitle={t('Choose time, round off to closest half hour')}
        timeOptions={nightTimeOptions}
        time={gotoBedTime}
        chosenDate={chosenDate}
      />
      <SelectTimeOptions
        title={t('When did you try to sleep?')}
        subTitle={t('Choose time, round off to closest half hour')}
        timeOptions={nightTimeOptions}
        time={attemptToSleepTime}
        chosenDate={chosenDate}
      />
      <Title htmlFor="hvor-lang-tid-sovne">{t('How long time did it take you to fall asleep?')}</Title>
      <InputLine v={minutesToSleep.validation}>
        <NumberInput id="hvor-lang-tid-sovne" placeholder={t('In minutes')} {...minutesToSleep.bind} />
      </InputLine>
      <Title htmlFor="hvor-mange-ganger-våknet">
        {t('How many times during the night did you wake up (not counting the final awakening)?')}
      </Title>
      <InputLine v={nofWakeupsDuringNight.validation}>
        <NumberInput id="hvor-mange-ganger-våknet" placeholder={t('Number of time')} {...nofWakeupsDuringNight.bind} />
      </InputLine>
      <Title htmlFor="hvor-lang-tid-våken-natten">{t('During the night, how much time was spent awake?')}</Title>
      <SubTitle>
        {t(
          'Note number of minutes you were awake after you fell asleep the first time. For instance, if you were awake 3 times for 20 minutes, 35 minutes and 15 minutes, you summarise all these into one number (20+35+15=70 min or 1 hour og 10 min)'
        )}
      </SubTitle>
      <InputLine v={minutesAwakeTimeDuringNight.validation}>
        <NumberInput
          id="hvor-lang-tid-våken-natten"
          {...minutesAwakeTimeDuringNight.bind}
          placeholder={t('In minutes')}
        />
      </InputLine>
      <SelectTimeOptions
        title={t('When did you wake up in the morning without falling asleep again?')}
        subTitle={t('Choose time for final awakening, round off to closest half hour')}
        timeOptions={morningTimeOptions}
        time={finalWakeupTime}
        chosenDate={chosenDate}
      />
      <SelectTimeOptions
        title={t('When did you get up from bed to start the day?')}
        subTitle={t('Choose time, round off to closest half hour')}
        timeOptions={morningTimeOptions}
        time={outOfBedTime}
        chosenDate={chosenDate}
      />
      <Title htmlFor="hvor-mange-minutter-i-sengen">
        {t('After the final awakening, how many minutes did you spent in bed trying to sleep before you got up?')}
      </Title>
      <InputLine v={minutesLatestAwakeTime.validation}>
        <NumberInput id="hvor-mange-minutter-i-sengen" {...minutesLatestAwakeTime.bind} placeholder={t('In minutes')} />
      </InputLine>
      <Title htmlFor="vurder-sovnkvalitet">{t('Evaluate your sleep quality')}</Title>
      <InputLine v={sleepQualityMeasure.validation}>
        <Select id="vurder-sovnkvalitet" {...sleepQualityMeasure.bind}>
          <option value={undefined} disabled={sleepQualityMeasure.value !== undefined}>
            {t('Choose from list...')}
          </option>
          <option value={1}>{t('Very bad')}</option>
          <option value={2}>{t('Bad')}</option>
          <option value={3}>{t('Neither good or bad')}</option>
          <option value={4}>{t('Good')}</option>
          <option value={5}>{t('Very good')}</option>
        </Select>
      </InputLine>
      <Title htmlFor="kommentar-til-sovn">{t('Comments on your sleep')}</Title>
      <InputLine v={sleepComments.validation}>
        <TextInput id="kommentar-til-sovn" {...sleepComments.bind} placeholder={t('Write a comment')} />
      </InputLine>
      <div
        css={css`
          display: flex;
          margin-top: 32px;
          margin-bottom: 32px;
        `}
      >
        <Button onClick={submit}>{t('Save')}</Button>
        <div style={{ width: 20 }} />
        <Button onClick={() => onCancelEdit()}>{t('Cancel')}</Button>
      </div>
    </div>
  );
}

function convertToOffsetDate(timeName, observation) {
  if (!observation) {
    return undefined;
  }
  return differenceInMinutes(new Date(observation[timeName]), new Date(observation.observationDate));
}
