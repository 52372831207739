import { RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import AuthedPageLayout from '../../components/pagelayout/AuthedPageLayout';
import { useT } from '../../i18n/useT';
import { Profile } from './Profile';

export const ProfilePage: FC<RouteComponentProps> = () => {
  const t = useT();
  return (
    <AuthedPageLayout documentTitle={t('Capable - Profile')}>
      <Profile />
    </AuthedPageLayout>
  );
};
