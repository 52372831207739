import React from 'react';
import { Question } from '@phosphor-icons/react';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Cpl_Administreringsdetaljer, DoseringParams } from '../../../types/gh-types';
import {
  convertDoseringsparamsToParam,
  convertParamToDosering,
  dateFromDayNo,
  dayNoFromDate,
  getDateControlFormattedDateFromDbDayNo,
  getDayNoFromDateControlFormattedDate,
} from './convertDosering';
import { DosageIntervalControl2 } from './dosagedate2';
import { PresentTasks, TaskType } from './presenttasks';
import { findLastIndex } from 'lodash';

/* *******************************

Based on (copied from) DoseringAlt1...
Enhanced by giving all days the possibility of having its own dosage pattern

********************************** */

// https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/viewBox
// https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d#path_commands

const tinyrightarrow2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 8"
      strokeWidth={1.0}
      stroke="currentColor"
      className="h-2 w-5 text-blue-500"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 0.25 21 4m0 0-3.75 3.75M21 4H2 M0 0.5 0 7.5" />
    </svg>
  );
};

const tinyleftarrow2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 8"
      strokeWidth={1.0}
      stroke="currentColor"
      className="h-2 w-5 text-blue-500"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 7.75 3 4m0 0 3.75-3.75M3 4h18 M23 0.5 23 7.5" />
    </svg>
  );
};

const nothing1 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 8"
      strokeWidth={1.0}
      stroke="currentColor"
      className="h-2 w-5 text-blue-500"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="" />
    </svg>
  );
};

const checkMark1 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-5 w-6"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
    </svg>
  );
};

const checkMarkSmaller = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-4 w-5"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
    </svg>
  );
};

const weekds: string[] = ['man', 'tir', 'ons', 'tor', 'fre', 'lør', 'søn'];
const weekdays: string[] = ['mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag', 'søndag'];
const Weekdays: string[] = ['Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag', 'Søndag'];

/* type TaskType = {
    antall: number,
    unit: string,
    timeOfDay: string
} */

const sortExplicitTasks = (a: NewExplicitTasks, b: NewExplicitTasks) => {
  if (a.dosageDayNo < b.dosageDayNo) return -1;
  else if (a.dosageDayNo === b.dosageDayNo) return 0;
  else return 1;
};

export type NewExplicitTasks = {
  dosageDayNo: number;
  dateOffset: number; // offset from startDate (in days) ie. explicitStartDate, -1 if not used;
  //  dateOffsetUnit: string, // "Dag"|"Uke"|"Måned" - for now, only days
  tasks: TaskType[];
};

/* type ExplicitTasks = {
  dosageDate: Date;
  tasks: TaskType[];
}; */

/* type presentTaskType = {
    pattern: string,                                                // Pattern that the current display is shown as part of (one of Enkeltdoser, Dag, Uke, Måned)
    dayPresentation: string                                         // Presentaton of current day      
    tasks: TaskType[],                                              // Tasks for the given (or any) day and to be displayed
    setTasks: (tasks: TaskType[], dayReference:number) => any       // Function to set given tasks for the given dayReference
    changeCurDay: (dayIncrement: number) => void,                   // Function to update the current (selected) day)
    copyToAllDays: (tasks: TaskType[], dayReference:number) => any  // Function to update the current tasks to all days
    dayReference: number,                                           // current day for the tasks to display 
    administreringsdetaljer: Cpl_Administreringsdetaljer|undefined  // administreringsdetaljer for the given drugdose
} */

type DoseringType2 = {
  // internal interface
  onUpdateDosageText: (dosageMultiLineText: string, updatedDosageParam: DoseringParams | undefined) => void;
  administreringsdetaljer: Cpl_Administreringsdetaljer | undefined;
  doseringsParams: DoseringParams | undefined;
};

/* ********************************
Component for single dosage section
*********************************** */
export const DoseringAlt4 = ({ onUpdateDosageText, administreringsdetaljer, doseringsParams }: DoseringType2) => {
  const now: Date = new Date();
  const [thisyear, thismonth, thisday] = [now.getFullYear(), now.getMonth(), now.getDate()];
  const today = new Date(thisyear, thismonth, thisday);
  const toDayNo: number = dayNoFromDate(today);
  const dayNo3000: number = dayNoFromDate(new Date(3000, 0, 1));

  const doseringparam = convertParamToDosering(doseringsParams); // ZZZ useMemo

  const [valgtPattern, setValgtPattern] = useState<string>(doseringparam ? doseringparam.pattern : ''); // ""|"Dag"|"Uke"|"Måned"
  const [dagrepetisjon, setDagRepetisjon] = useState<string>(
    doseringparam && doseringparam.dagrep ? doseringparam.dagrep : 'Hver dag'
  ); // "Hver dag"|"Hver 2.dag"|"Hver 3.dag| ...
  const [ukerepetisjon, setUkeRepetisjon] = useState<string>(
    doseringparam && doseringparam.ukerep ? doseringparam.ukerep : 'Hver uke'
  ); // "Hver uke"|"Hver 2.uke"|"Hver 3.uke"| ...
  const [mndrepetisjon, setMndRepetisjon] = useState<string>(
    doseringparam && doseringparam.mndrep ? doseringparam.mndrep : 'Hver måned'
  ); // "Hver måned"|"Hver 2.måned"|"Hver 3.måned"| ...
  const [tasks, setTasks] = useState<TaskType[]>(
    doseringparam && doseringparam.dosagetasks ? doseringparam.dosagetasks : []
  );
  const [curWeekDay, setCurWeekDay] = useState<number>(-1); // -1 (ingen dag valgt) (Day pattern) eller 0-13 (0-6 er dag i uke 1, 7-13 er dag i uke 2 ved alt uker)

  const [weekArr, setWeekArr] = useState<TaskType[][][]>(
    doseringparam && doseringparam.weeks ? doseringparam.weeks : [[[], [], [], [], [], [], []]]
  ); // Multiple weeks with seven days with each a TaskType[]
  const [noOfWeeks, setNoOfWeeks] = useState<number>(
    doseringparam && doseringparam.noOfWeeks ? doseringparam.noOfWeeks : 1
  );

  const [monthDay, setMonthDay] = useState<string>(
    doseringparam && doseringparam.monthDay ? doseringparam.monthDay : today.getDate().toString()
  ); // A number or blank

  const [explicitDates, setExplicitDates] = useState<NewExplicitTasks[]>(
    doseringparam && doseringparam.explicitTasks ? doseringparam.explicitTasks : []
  );
  const [explDateInputMode, setExplDateInputMode] = useState<'date' | 'number'>('date');
  const [explicitStartDayNo, setExplicitStartDayNo] = useState<number>(
    doseringparam && doseringparam.explicitTasks && doseringparam.explicitTasks.length > 0
      ? dayNoFromDate(doseringsParams?.beginDate)
      : 0
  );
  const [curExplicitDayNo, setCurExplicitDayNo] = useState<number>(0);
  const [explicitDateAddingInProcess, setExplicitDateAddingInProcess] = useState(false);
  const [explicitDayNoToBeAdded, setExplicitDayNoToBeAdded] = useState<number>(toDayNo);
  const [explicitNoOfDaysToBeAdded, setExplicitNoOfDaysToBeAdded] = useState<number | undefined>(0);
  const [explicitMsg, setExplicitMsg] = useState<string>('');
  const addExplicitDateRef = useRef<null | HTMLDivElement>(null);

  const [doseringsvarsel, setDoseringsvarsel] = useState<string>('');

  const [endOption, setEndOption] = useState<string>(doseringparam?.endOption ?? 'Max'); // "Max"|"endDate"|"interval"|"auto"
  const [startRecurrentDayNo, setStartRecurrentDayNo] = useState<number>(
    doseringparam && doseringparam.startRecurrentDayNo ? doseringparam.startRecurrentDayNo : toDayNo
  ); // ZZZ Dagens dato ..
  const [endDayNo, setEndDayNo] = useState<number>(
    doseringparam && doseringparam.endRecurrentDayNo ? doseringparam.endRecurrentDayNo : dayNo3000
  ); // Date|undefined  -- Need to check endOption to see if endDate is "active"
  const [intervalDays, setIntervalDays] = useState<number>(doseringparam?.interval ?? 0);
  const prevEndOption = useRef('Max');

  const thisdropdownstyle = ' align-middle text-blue-500 hover:underline bg-gray-100';

  // This is for the "light dismissed" ui for adding new explicit date
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  const handleOutsideClick = (event: MouseEvent) => {
    if (addExplicitDateRef.current && !addExplicitDateRef.current.contains(event.target as Node))
      setExplicitDateAddingInProcess(false);
  };

  /* ************************************************************************** 
    Common routine(for all dosage patterns) 
    that calculates doseringsvarsel that is presented in the dosageSection
    **************************************************************************  */

  const calculateDoseringsvarsel = useCallback(
    (
      beginDayNo: number, // explicit startdate
      startDayNo: number, // Start recurrent dayNo
      endDayNo: number,
      interval: number,
      endOption: string | undefined,
      pattern: string,
      dagrep: string,
      ukerep: string,
      mndrep: string,
      noOfWks: number,
      weeks: TaskType[][][],
      dosagetasks: TaskType[],
      explisitTasks: NewExplicitTasks[]
    ): string => {
      const checkDosering = (dosage: TaskType[]): string => {
        // Check1: "Ikke valgt tid" sammen med en annen tidsangivelse:
        const ubestemt: boolean =
          dosage.find((e) => e.timeOfDay === 'Ikke valgt tid') !== undefined && dosage.length > 1;
        if (ubestemt) return '"Ikke valgt tid" kan ikke stå sammen med andre doseringstidspunkt';
        // Check2: Flere identiske tidsangivelser:
        const timeOfDay: string[] = dosage.map((e) => e.timeOfDay);
        const duplicates = timeOfDay.filter((item, index) => timeOfDay.indexOf(item) !== index);
        if (duplicates.length > 0) return 'Du kan ikke ha flere doseringer med samme tidsangivelse';
        return '';
      };

      if (pattern !== '') {
        // recurrent
        if (explisitTasks && explisitTasks.length > 0) {
          const explicitEndDayNo = getLastDayNo(beginDayNo, explisitTasks);
          if (startDayNo !== 0 && explicitEndDayNo >= startDayNo)
            return 'Start av gjentakende dosering må være etter siste enkeltdose';
        }
      }
      if (pattern === 'Dag' || pattern === 'Måned') {
        // Check0: Ingen "tidspunkt/dose" finnes i det hele tatt
        if (!dosagetasks || dosagetasks.length === 0) return "Du må angi minst en dose (under 'tidspunkt/dose')";
        const ret = checkDosering(dosagetasks);
        if (ret !== '') return ret;
      } else if (pattern === 'Uke') {
        const anydosageday = weeks.find((i, index1) =>
          weeks[index1].find((i, index2) => weeks[index1][index2].find((i) => i.timeOfDay.length > 0))
        );
        if (anydosageday === undefined) return 'Du må angi dosering for minst en dag';
        let retval: string;
        for (let wkIx = 0; wkIx < noOfWks; wkIx++) {
          for (let i = 0; i < 7; i++) {
            retval = checkDosering(weeks[wkIx][i]);
            if (retval !== '') {
              return (noOfWks > 1 ? 'Uke' + (wkIx + 1).toString() + ' på ' : '') + Weekdays[i] + ': ' + retval;
            }
          }
        }
      }

      if (explisitTasks && explisitTasks.length > 0) {
        const days = explisitTasks.find((i) => i.tasks.length === 0);
        if (days !== undefined) return 'Du må angi dosering for alle enkeltdosedager';
      }

      return '';
    },
    []
  );

  /* ************************************************************************** 
    Common routine(for all dosage patterns) 
    that calculates text presenting the dosage pattern that is currently configured in the section 
    **************************************************************************  */

  const calculateDosageText = useCallback(
    (
      beginDayNo: number,
      startRecurrentDayNo: number,
      endDayNo: number,
      interval: number,
      endOption: string | undefined,
      pattern: string,
      dagrep: string,
      ukerep: string,
      mndrep: string,
      monthDay: string,
      noOfWeeks: number,
      weeks: TaskType[][][],
      dosagetasks: TaskType[],
      explisitTasks: NewExplicitTasks[]
    ): string => {
      let result: string = '';
      //const Weekdays: string[] = ["mandag", "tirsdag", "onsdag", "torsdag", "fredag", "lørdag", "søndag"];
      const tasker: string = dosagetasks
        .map(
          (e) =>
            e.antall.toLocaleString() +
            ' ' +
            e.unit.toLowerCase() +
            (e.timeOfDay !== 'Ikke valgt tid' ? ' ' + e.timeOfDay.toLowerCase() : '')
        )
        .join(', ');
      const uniqueDosages: TaskType[][] = [];
      let weekDosages: number[] = [-1, -1, -1, -1, -1, -1, -1]; // Pointer to correct entry in uniqueDosages
      let index: number;

      const compareDosage = (d1: TaskType[], d2: TaskType[]): boolean => {
        if (d1.length !== d2.length) return false;
        for (let i = 0; i < d1.length; i++) {
          if (d1[i]?.antall !== d2[i]?.antall || d1[i]?.timeOfDay !== d2[i]?.timeOfDay || d1[i].unit !== d2[i].unit)
            return false;
        }
        return true;
      };

      const calculateTimeSpanPostlude = (interval: number, endOption: string | undefined): string => {
        if (endOption === 'interval' && interval > 0)
          return ' i ' + interval.toString() + (interval === 1 ? ' dag' : ' dager');
        else return '';
      };

      const getUniqueDosageIx = (uniquedosages: TaskType[][], d1: TaskType[]): number => {
        // Return index if found, else returns -1
        let ret: boolean;
        for (let i = 0; i < uniquedosages.length; i++) {
          ret = compareDosage(uniquedosages[i], d1);
          if (ret) return i;
        }
        return -1;
      };

      const getDosageText = (dosage: TaskType[]): string => {
        if (dosage === undefined) return '';
        const tasker: string = dosage
          ?.map(
            (e) =>
              e.antall.toLocaleString() +
              ' ' +
              e.unit.toLowerCase() +
              (e.timeOfDay !== 'Ikke valgt tid' ? ' ' + e.timeOfDay.toLowerCase() : '')
          )
          .join(', ');

        // Passer ikke så godt sammen med ukemønster, må se nøyere på dette:
        /*         if (dosage.length === 1) { // Spesialbehandling:
                if (dosage[0].timeOfDay === "Ikke valgt tid") 
                    return tasker + " hver dag";
                else if (dosage[0].timeOfDay === "Midt på dagen")
                    return dosage[0.].antall + " " + dosage[0.].unit.toLowerCase() + " " + dosage[0.].timeOfDay.toLowerCase() + " hver dag"; 
                else 
                    return dosage[0.].antall + " " + dosage[0.].unit.toLowerCase() + " hver " + dosage[0.].timeOfDay.toLowerCase(); 
            }
     */ return tasker;
      };

      const buildWeekUniqueDosages = (week: TaskType[][]): number[] => {
        const weekDosages: number[] = [-1, -1, -1, -1, -1, -1, -1];
        for (let i = 0; i < 7; i++) {
          if (week[i].length !== 0) {
            index = getUniqueDosageIx(uniqueDosages, week[i]);
            if (index === -1) {
              // not found
              uniqueDosages.push(week[i]); // add it
              index = uniqueDosages.length - 1;
            }
            weekDosages[i] = index;
          }
        }
        return weekDosages;
      };

      const getRelativeDay = (dateOffset: number): string => {
        const numerator: string[] = [
          'Første',
          'Andre',
          'Tredje',
          'Fjerde',
          'Femte',
          'Sjette',
          'Syvende',
          'Åttende',
          'Niende',
          'Tiende',
          'Ellevte',
          'Tolvte',
          'Trettende',
          'Fjortende',
        ];
        if (dateOffset >= 0 && dateOffset <= 14) return numerator[dateOffset] + ' dag';
        else return dateOffset.toString() + ' dag';
      };

      const presentWeek = (weekDosages: number[]): string => {
        const weekDosageUnprocessed: boolean[] = [];
        let resultdays: string;
        let resultdose: string;
        let result: string = '';
        weekDosages?.forEach((item, index) => (weekDosageUnprocessed[index] = item < 0 ? false : true));
        for (let i = 0; i < 7; i++) {
          if (weekDosages[i] !== -1 && weekDosageUnprocessed[i]) {
            const days = weekDosages
              .map((item, index) => {
                if (weekDosages[index] === weekDosages[i]) return index;
              })
              .filter((item) => item !== undefined); // index til dager som har samme dosagepattern som i
            resultdays = weekds.filter((item, index) => days.includes(index))?.join(', ');
            resultdose = getDosageText(uniqueDosages[weekDosages[i]]);
            result += ' ' + resultdays + ': ' + resultdose + ';\n';
            days?.forEach((i) => {
              if (i !== undefined) weekDosageUnprocessed[i] = false;
            });
            resultdays = '';
            resultdose = '';
          }
        }
        return result.trimStart();
      };

      // Sjekk enkeltdoser:
      // sjekk om strukturen er ok først, om ikke: gå ut
      const days = explisitTasks.find((i) => i.tasks.length === 0);
      if (days !== undefined) return '';

      // Sjekk om recurrentstart er for tidlig i forhold til explicit tasks:
      // Matches "Start av gjentakende dosering må være etter siste enkeltdose"
      if (pattern !== '') {
        // recurrent
        if (explisitTasks && explisitTasks.length > 0) {
          const explicitEndDayNo = getLastDayNo(beginDayNo, explisitTasks);
          if (startRecurrentDayNo !== 0 && explicitEndDayNo >= startRecurrentDayNo) return '';
        }
      }

      // Sjekk alternative mønstre:
      let enkeltdoseText: string = '';
      if (!explisitTasks || (explisitTasks && explisitTasks?.length === 0)) {
        // Ingen enkeltdoser
        enkeltdoseText = '';
      } else if (explisitTasks && explisitTasks.length === 1 && explisitTasks[0].tasks.length === 1 && pattern === '') {
        // En eneste enkeltdoseutdeling og uten gjentakelse etterpå
        enkeltdoseText =
          (pattern === '' ? '' : getRelativeDay(explisitTasks[0].dateOffset) + ': ') +
          explisitTasks[0].tasks[0].antall.toLocaleString() +
          ' ' +
          explisitTasks[0].tasks[0].unit.toLowerCase() +
          (explisitTasks[0].tasks[0].timeOfDay !== 'Ikke valgt tid'
            ? ' ' + explisitTasks[0].tasks[0].timeOfDay.toLowerCase()
            : '') +
          ' som enkeltdose';
      } else if (explisitTasks && explisitTasks.length === 1 && pattern === '') {
        // En enkeltdosedag, og uten gjentakelse etterpå
        const res: string[] = explisitTasks.map((item) => {
          return getDosageText(item.tasks);
        });
        enkeltdoseText = res.join(';\n ');
      } else {
        // En enkeltdose med påfølgende gjentakelser, eller flere enkeltdosedager
        const res: string[] = explisitTasks.map((item) => {
          return getRelativeDay(item.dateOffset) + ': ' + getDosageText(item.tasks);
        });
        enkeltdoseText = res.join(';\n ');
      }

      if (pattern === '') return enkeltdoseText;

      // " i 3 dager" skal alltid være med, når relevant
      let timeSpanPostlude: string = calculateTimeSpanPostlude(interval, endOption);

      let timeSpanPrelude: string = '';
      if (enkeltdoseText !== '' && pattern !== '') {
        // Både enkeltdoser, og påfølgende gjentakende mønster
        // calculate FirstExplicitDosage Date

        const firstExplDosageDayNo = beginDayNo;
        const dayOff: number | undefined =
          firstExplDosageDayNo !== 0 && startRecurrentDayNo !== 0
            ? startRecurrentDayNo - firstExplDosageDayNo
            : undefined;

        if (dayOff !== undefined) {
          timeSpanPrelude =
            ';\nDeretter fra ' + getRelativeDay(dayOff ?? -1).toLocaleLowerCase() + timeSpanPostlude + ': \n';
          timeSpanPostlude = ''; // Already used
        } else timeSpanPrelude = ';\n';
      }

      if (pattern === 'Dag') {
        if (!dosagetasks || dosagetasks.length === 0) return '';
        // frekvens:
        if (dagrep === 'Hver dag') {
          if (dosagetasks.length === 1) {
            // Spesialbehandling:
            if (dosagetasks[0].timeOfDay === 'Ikke valgt tid')
              return enkeltdoseText + timeSpanPrelude + tasker + ' hver dag' + timeSpanPostlude;
            else if (dosagetasks[0].timeOfDay === 'Midt på dagen')
              return (
                enkeltdoseText +
                timeSpanPrelude +
                dosagetasks[0].antall.toLocaleString() +
                ' ' +
                dosagetasks[0].unit.toLowerCase() +
                ' ' +
                dosagetasks[0].timeOfDay.toLowerCase() +
                ' hver dag' +
                timeSpanPostlude
              );
            else
              return (
                enkeltdoseText +
                timeSpanPrelude +
                dosagetasks[0].antall.toLocaleString() +
                ' ' +
                dosagetasks[0].unit.toLowerCase() +
                ' hver ' +
                dosagetasks[0].timeOfDay.toLowerCase() +
                timeSpanPostlude
              );
          } else result = '';
        } else result = dagrep + ': ';
        return enkeltdoseText + timeSpanPrelude + result + tasker + timeSpanPostlude;
      } else if (pattern === 'Uke') {
        // Antall dager som har doser:
        const txtweeksArr: string[] = [];
        if (noOfWeeks > 1) {
          [...Array(noOfWeeks)].map((i, index) => {
            txtweeksArr[index] = presentWeek(buildWeekUniqueDosages(weeks[index]));
            if (txtweeksArr[index] === '') txtweeksArr[index] = 'Ingen dosering';
          });
          result = txtweeksArr.map((value, index) => 'Uke ' + (index + 1).toString() + ':\n' + value).join(' \n');
          return enkeltdoseText + timeSpanPrelude + result;
        } else {
          // noOfWeeks === 1
          weekDosages = buildWeekUniqueDosages(weeks[0]);
          // Present days:
          result = presentWeek(weekDosages);
          if (result !== '') result = (ukerep === 'Hver uke' ? '' : ukerep + ': \n') + result;
          return enkeltdoseText + timeSpanPrelude + result;
        }
      } else if (pattern === 'Måned') {
        if (!dosagetasks || dosagetasks.length === 0) return '';
        result = 'Tas den ' + monthDay + '. ' + mndrep.toLocaleLowerCase() + ': ' + tasker;
        return enkeltdoseText + timeSpanPrelude + result;
      }
      return '';
    },
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () => {
      setDoseringsvarsel(
        calculateDoseringsvarsel(
          explicitStartDayNo,
          startRecurrentDayNo,
          endDayNo,
          intervalDays,
          endOption,
          valgtPattern,
          dagrepetisjon,
          ukerepetisjon,
          mndrepetisjon,
          noOfWeeks,
          weekArr,
          tasks, //X
          explicitDates
        )
      );
      onUpdateDosageText(
        calculateDosageText(
          explicitStartDayNo,
          startRecurrentDayNo,
          endDayNo,
          intervalDays,
          endOption,
          valgtPattern,
          dagrepetisjon,
          ukerepetisjon,
          mndrepetisjon,
          monthDay,
          noOfWeeks,
          weekArr,
          tasks, //X
          explicitDates
        ),
        convertDoseringsparamsToParam(
          explicitStartDayNo,
          startRecurrentDayNo,
          endDayNo,
          intervalDays,
          endOption,
          valgtPattern,
          dagrepetisjon,
          ukerepetisjon,
          mndrepetisjon,
          monthDay,
          noOfWeeks,
          weekArr,
          tasks, //X
          explicitDates
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      explicitStartDayNo,
      startRecurrentDayNo,
      endDayNo,
      intervalDays,
      endOption,
      valgtPattern,
      dagrepetisjon,
      ukerepetisjon,
      mndrepetisjon,
      monthDay,
      noOfWeeks,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(weekArr),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(tasks), //X
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(explicitDates),
      onUpdateDosageText,
      calculateDoseringsvarsel,
      calculateDosageText,
    ]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getAdjustedDay = (dt: number): number => {
    // Later changed from Date to DayNo
    // Returns anyway delta

    // Returns weekday 0 (Monday) -> 6 (Sunday) ; if undefined, then 0
    //return (!dt) ? 0 :(dt.getDay()===0)?6:dt.getDay()-1; // 0:Monday --> 6: sunday
    // Check comment in CheckBoxArr, corresponds to paramenter firstDispDay
    return 0;
  };

  /*   const getDateControlFormattedDate = (dt: Date | undefined): string => {
    // YYYY-MM-DD
    return dt === undefined
      ? ""
      : dt.getFullYear() +
          "-" +
          (dt.getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          dt.getDate().toString().padStart(2, "0");
  };
 */
  const getSimpleFormattedDate = (dt: Date | undefined): string => {
    // DD.MM.YY
    return dt === undefined
      ? ''
      : dt.getDate().toString().padStart(2, '0') +
          '.' +
          (dt.getMonth() + 1).toString().padStart(2, '0') +
          '.' +
          dt.getFullYear().toString().substring(2, 4);
  };

  /*   const getDateFromDateControlFormattedDate = (
    dtstring: string,
  ): Date | undefined => {
    //        return (dtstring === "")? undefined : new Date(Date.parse(dtstring)); // Had frustrating discrepancy in timezone, maybe due to using Date.parse??
    return dtstring === ""
      ? undefined
      : new Date(
          Number(dtstring.substring(0, 4)),
          Number(dtstring.substring(5, 7)) - 1,
          Number(dtstring.substring(8, 10)),
        );
  };
 */
  type FirstOrLastDosageType = {
    dosageDate: Date | undefined; // Sometimes First, somtimes Last depening on context, returns undefined if there are no matching dosages within given interval
    dosages: string[];
    msg?: string; // Error msg in case something is wrong with the data
  };

  /* ************************************************************************** 
    Common routine (for all dosage patterns) 
    that calculates the frist dosage, given dosage pattern (including beginTime) that is currently configured in the section
    **************************************************************************  */

  const getFirstRecurrentDosage = (
    patternStartDate: Date | undefined,
    patternEndDate: Date | undefined,
    pattern: string,
    dagrep: string,
    ukerep: string,
    mndrep: string,
    monthDay: string,
    noOfWks: number,
    weeks: TaskType[][][],
    dosagetasks: TaskType[]
  ): FirstOrLastDosageType | undefined => {
    // Get first dosage after startDate
    // Return first dosage day and dosage after patternStartDate (including patternStartDate)
    // returns undefined if there are no dosages at all (meaning the dosage pattern is invalid - an error message should appear)
    // returns dosageDate = undefined and an error msg if there are no matching dosages within given interval
    // returns dosageDate = undefined and an error msg if patternStartDate > patternEndDate
    if (patternStartDate === undefined) return undefined;
    const noneWithinInterval: FirstOrLastDosageType = {
      dosageDate: undefined,
      dosages: [],
      msg: 'Det er ingen doser i det gitte intervallet',
    };
    if (patternEndDate && patternStartDate > patternEndDate)
      return {
        dosageDate: undefined,
        dosages: [],
        msg: 'Sluttdato kan ikke være før startdato',
      };

    let returnDate: Date = patternStartDate;
    if (pattern === 'Dag') {
      // "Dag"|"Uke"|"Måned"
      if (dosagetasks.find((i) => i.timeOfDay.length > 0) === undefined)
        // no tasks at all
        return undefined;
      // Returndate is independent of dagrep  // "Hver dag"|"Hver 2.dag"|"Hver 3.dag| ...
      return {
        dosageDate: returnDate,
        dosages: dosagetasks.map((i) => i.timeOfDay),
      };
    } else if (pattern === 'Uke') {
      // Any dosage given?
      //const anydosageday = week1.find((i, index) => (week1[index].find((i, index) => i.timeOfDay.length>0)));
      const anydosageday = weeks.find((i, index1) =>
        weeks[index1].find((i, index2) => weeks[index1][index2].find((i) => i.timeOfDay.length > 0))
      );
      if (anydosageday === undefined) return undefined;
      // Ok, some dosage exists
      // Return date is dependent on ukerep  "Hver uke"|"Hver 2.uke"|"Hver 3.uke"|
      // Get offset from current week:
      let weekdivisor: number;
      if (ukerep === 'Hver uke') weekdivisor = 1;
      else if (ukerep === 'Hver 2.uke') weekdivisor = 2;
      else if (ukerep === 'Hver 3.uke') weekdivisor = 3;
      else if (ukerep === 'Hver 4.uke') weekdivisor = 4;
      else if (ukerep === 'Hver 5.uke') weekdivisor = 5;
      else if (ukerep === 'Hver 6.uke') weekdivisor = 6;
      else if (ukerep === 'Hver 7.uke') weekdivisor = 7;
      else if (ukerep === 'Hver 8.uke') weekdivisor = 8;
      else return undefined; // random internal error

      let foundWeek = 1;
      // Get index of patternStartDate:
      const patternStartDayofweekIx: number = patternStartDate.getDay() === 0 ? 6 : patternStartDate.getDay() - 1; // 0:Monday --> 6: sunday
      // check from this day and until end of week
      let firstdosagedayafter = weeks[0].findIndex(
        (i, index) => index >= patternStartDayofweekIx && weeks[0][index].find((i) => i.timeOfDay.length > 0)
      );
      if (firstdosagedayafter !== -1) {
        returnDate = new Date(
          patternStartDate.getFullYear(),
          patternStartDate.getMonth(),
          patternStartDate.getDate() + firstdosagedayafter - patternStartDayofweekIx
        );
        return patternEndDate && returnDate > patternEndDate
          ? noneWithinInterval
          : {
              dosageDate: returnDate,
              dosages: weeks[0][firstdosagedayafter].map((i) => i.timeOfDay),
            };
      } else {
        // No hit AFTER this until end of week, then check start of "next" week
        // there is now two options:
        // either there is a single week with potentionally a "repeat" factor (weekdivisor) ("hver uke", "hver 2.uke", "hver 3.uke" etc)
        // OR there is several alternating weeks, (with implicitly "hver uke")
        if (noOfWks === 1) {
          // a single week
          firstdosagedayafter = weeks[0].findIndex((i, index) => weeks[0][index].find((i) => i.timeOfDay.length > 0));
          if (firstdosagedayafter !== -1) {
            returnDate = new Date(
              patternStartDate.getFullYear(),
              patternStartDate.getMonth(),
              patternStartDate.getDate() + firstdosagedayafter - patternStartDayofweekIx + weekdivisor * 7
            );
            return patternEndDate && returnDate > patternEndDate
              ? noneWithinInterval
              : {
                  dosageDate: returnDate,
                  dosages: weeks[0][firstdosagedayafter].map((i) => i.timeOfDay),
                };
          } else return undefined; // impossible
        } else {
          // // multiple week definitions exists, go through them "chronologically"

          for (let wkNo = 1; wkNo < noOfWks; wkNo++) {
            firstdosagedayafter = weeks[wkNo].findIndex((i, index) =>
              weeks[wkNo][index].find((i) => i.timeOfDay.length > 0)
            );
            if (firstdosagedayafter !== -1) {
              foundWeek = wkNo;
              break;
            }
          }
          if (firstdosagedayafter !== -1) {
            // a week > 1 is found
            returnDate = new Date(
              patternStartDate.getFullYear(),
              patternStartDate.getMonth(),
              returnDate.getDate() + firstdosagedayafter - patternStartDayofweekIx + foundWeek * 7
            );
            return patternEndDate && returnDate > patternEndDate
              ? noneWithinInterval
              : {
                  dosageDate: returnDate,
                  dosages: weeks[foundWeek][firstdosagedayafter].map((i) => i.timeOfDay),
                };
          } else {
            // No weeks are found, will have to revisit the first week:
            firstdosagedayafter = weeks[0].findIndex((i, index) => weeks[0][index].find((i) => i.timeOfDay.length > 0));
            if (firstdosagedayafter !== -1) {
              returnDate = new Date(
                patternStartDate.getFullYear(),
                patternStartDate.getMonth(),
                returnDate.getDate() + firstdosagedayafter - patternStartDayofweekIx + noOfWks * 7
              );
              return patternEndDate && returnDate > patternEndDate
                ? noneWithinInterval
                : {
                    dosageDate: returnDate,
                    dosages: weeks[0][firstdosagedayafter].map((i) => i.timeOfDay),
                  };
            } else return undefined; // impossible
          }
        }
      }
    } else if (pattern === 'Måned') {
      if (dosagetasks.find((i) => i.timeOfDay.length > 0) === undefined)
        // no tasks at all
        return undefined;
      let targetMonth = patternStartDate.getMonth(); // getMonth starts at 0
      if (patternStartDate.getDate() > Number(monthDay)) {
        targetMonth++;
      }
      const returnDate: Date = new Date(patternStartDate.getFullYear(), targetMonth, Number(monthDay));
      return patternEndDate && returnDate > patternEndDate
        ? noneWithinInterval
        : {
            dosageDate: returnDate,
            dosages: dosagetasks.map((i) => i.timeOfDay),
          };
    } else {
      // TODO
    }

    return undefined;
  };

  /* ************************************************************************** 
    Common routine (for all dosage patterns) 
    that calculates the last dosage, given dosage pattern (including beginTime/endTime) that is currently configured in the section
    **************************************************************************  */

  const getLastDosage = (
    patternStartDate: Date | undefined,
    patternEndDate: Date | undefined,
    pattern: string,
    dagrep: string,
    ukerep: string,
    mndrep: string,
    monthDay: string,
    noOfWks: number,
    weeks: TaskType[][][],
    dosagetasks: TaskType[]
  ): FirstOrLastDosageType | undefined => {
    // Get first dosage after startDate
    // Return last dosage day and dosage before or equal to patternEndDate
    // returns undefined if there are no dosages at all (meaning the dosage pattern is invalid - an error message should appear, or if patternStartDate or patternEndDate= undefined)
    // returns dosageDate = undefined if there are no matching dosages within given interval
    // it is given that patternStartDate <= patternEndDate
    if (patternEndDate === undefined) return undefined;
    if (patternStartDate === undefined) return undefined;
    const noneWithinInterval: FirstOrLastDosageType = {
      dosageDate: undefined,
      dosages: [],
    };

    let returnDate: Date = patternEndDate; // The last day to be included
    if (pattern === 'Dag') {
      // "Dag"|"Uke"|"Måned"
      if (dosagetasks.find((i) => i.timeOfDay.length > 0) === undefined)
        // no tasks at all
        return undefined;
      // Returndate is dependent of dagrep  // "Hver dag"|"Hver 2.dag"|"Hver 3.dag| ...
      let divisor: number;
      if (dagrep === 'Hver dag')
        return {
          dosageDate: returnDate,
          dosages: dosagetasks.map((i) => i.timeOfDay),
        };
      else {
        if (dagrep === 'Hver 2.dag') divisor = 2;
        else if (dagrep === 'Hver 3.dag') divisor = 3;
        else if (dagrep === 'Hver 4.dag') divisor = 4;
        else if (dagrep === 'Hver 5.dag') divisor = 5;
        else if (dagrep === 'Hver 6.dag') divisor = 6;
        else if (dagrep === 'Hver 7.dag') divisor = 7;
        else return undefined; // random internal error

        //number of days between the two dates
        const differenceInTime = patternEndDate.getTime() - patternStartDate.getTime();
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
        const remainder = differenceInDays % divisor;
        const returnDate = new Date(
          patternEndDate.getFullYear(),
          patternEndDate.getMonth(),
          patternEndDate.getDate() - remainder
        );
        return {
          dosageDate: returnDate,
          dosages: dosagetasks.map((i) => i.timeOfDay),
        };
      }
    } else if (pattern === 'Uke') {
      // Any dosage given?
      const anydosageday = weeks.find((i, index1) =>
        weeks[index1].find((i, index2) => weeks[index1][index2].find((i) => i.timeOfDay.length > 0))
      );
      if (anydosageday === undefined) return undefined;
      // Ok, some dosage exists

      // there are two options for the pattern:
      // either there is a single week with potentionally a "repeat" factor (weekdivisor) ("hver uke", "hver 2.uke", "hver 3.uke" etc)
      // OR there is several alternating weeks, (with implicitly "hver uke")
      if (noOfWks === 1) {
        // Potentially the repeat version
        let weekdivisor: number;
        if (ukerep === 'Hver uke') weekdivisor = 1;
        else if (ukerep === 'Hver 2.uke') weekdivisor = 2;
        else if (ukerep === 'Hver 3.uke') weekdivisor = 3;
        else if (ukerep === 'Hver 4.uke') weekdivisor = 4;
        else if (ukerep === 'Hver 5.uke') weekdivisor = 5;
        else if (ukerep === 'Hver 6.uke') weekdivisor = 6;
        else if (ukerep === 'Hver 7.uke') weekdivisor = 7;
        else if (ukerep === 'Hver 8.uke') weekdivisor = 8;
        else return undefined; // random internal error

        // Get monday of the week that contains start date:
        const patternStartDayofweekIx: number = patternStartDate.getDay() === 0 ? 6 : patternStartDate.getDay() - 1; // 0:Monday --> 6: sunday
        const MondayInStartWeek: Date = new Date(
          patternStartDate.getFullYear(),
          patternStartDate.getMonth(),
          patternStartDate.getDate() - patternStartDayofweekIx
        );
        // Get monday of the week that contains end date:
        const patternEndDayofweekIx: number = patternEndDate.getDay() === 0 ? 6 : patternEndDate.getDay() - 1; // 0:Monday --> 6: sunday
        const MondayInEndWeek: Date = new Date(
          patternEndDate.getFullYear(),
          patternEndDate.getMonth(),
          patternEndDate.getDate() - patternEndDayofweekIx
        );

        const differenceInTime = patternEndDate.getTime() - MondayInStartWeek.getTime();
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // Påbegynte dager
        const differenceInWeeks = Math.floor(differenceInDays / 7); // avstand i hele uker fra første mandagen til siste mandagen før sluttdato
        let weekremainder = differenceInWeeks % weekdivisor; // antall uker man må trekke fra for å få siste uke som er med i mønsteret
        let lastdosagedaybefore: number;
        if (weekremainder === 0) {
          // Traff direkte i uken med sluttdato, da må vi sjekke om det er noen doser forut for sluttdatoen i denne uken
          lastdosagedaybefore = findLastIndex(weeks[0], (i, index) =>
            Boolean(index <= patternEndDayofweekIx && weeks[0][index].find((i) => i.timeOfDay.length > 0))
          );
          if (lastdosagedaybefore !== -1) {
            // found something
            returnDate = new Date(
              patternEndDate.getFullYear(),
              patternEndDate.getMonth(),
              patternEndDate.getDate() + lastdosagedaybefore - patternEndDayofweekIx
            );
            return returnDate < patternStartDate
              ? noneWithinInterval
              : {
                  dosageDate: returnDate,
                  dosages: weeks[0][lastdosagedaybefore].map((i) => i.timeOfDay),
                };
          } else weekremainder = weekdivisor;
        }
        // Either weekremainder !==0 or weekremainder == 0 but there were no dosages early enough in that week
        // this means one has to move to previous week according to repeating pattern
        // Find monday in this week:
        const MondayInTargetEndWeek: Date = new Date(
          MondayInEndWeek.getFullYear(),
          MondayInEndWeek.getMonth(),
          MondayInEndWeek.getDate() - 7 * weekremainder
        );
        lastdosagedaybefore = findLastIndex(weeks[0], (i, index) =>
          Boolean(weeks[0][index].find((i) => i.timeOfDay.length > 0))
        );
        if (lastdosagedaybefore !== -1) {
          // found something
          returnDate = new Date(
            MondayInTargetEndWeek.getFullYear(),
            MondayInTargetEndWeek.getMonth(),
            MondayInTargetEndWeek.getDate() + lastdosagedaybefore
          );
          return returnDate < patternStartDate
            ? noneWithinInterval
            : {
                dosageDate: returnDate,
                dosages: weeks[0][lastdosagedaybefore].map((i) => i.timeOfDay),
              };
        } else {
          // internal error, should be at least one
        }
      } else {
        //NoOfWeeks > 1

        let lastdosagedaybefore: number;
        // Get monday of the week that contains start date:
        const patternStartDayofweekIx: number = patternStartDate.getDay() === 0 ? 6 : patternStartDate.getDay() - 1; // 0:Monday --> 6: sunday
        const MondayInStartWeek: Date = new Date(
          patternStartDate.getFullYear(),
          patternStartDate.getMonth(),
          patternStartDate.getDate() - patternStartDayofweekIx
        );
        // Get monday of the week that contains end date:
        const patternEndDayofweekIx: number = patternEndDate.getDay() === 0 ? 6 : patternEndDate.getDay() - 1; // 0:Monday --> 6: sunday

        const differenceInTime = patternEndDate.getTime() - MondayInStartWeek.getTime();
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // Påbegynte dager
        const differenceInWeeks = Math.floor(differenceInDays / 7); // avstand i hele uker fra første mandagen til siste mandagen før sluttdato
        const patternEndDayWeekNo = differenceInWeeks % noOfWeeks;
        lastdosagedaybefore = findLastIndex(weeks[patternEndDayWeekNo], (i, index) =>
          Boolean(
            index <= patternEndDayofweekIx && weeks[patternEndDayWeekNo][index].find((i) => i.timeOfDay.length > 0)
          )
        );
        if (lastdosagedaybefore !== -1) {
          // Found at first try
          returnDate = new Date(
            patternEndDate.getFullYear(),
            patternEndDate.getMonth(),
            patternEndDate.getDate() + lastdosagedaybefore - patternEndDayofweekIx
          );
          return returnDate < patternStartDate
            ? noneWithinInterval
            : {
                dosageDate: returnDate,
                dosages: weeks[patternEndDayWeekNo][lastdosagedaybefore].map((i) => i.timeOfDay),
              };
        } else {
          // Did not find at first , search week by week in reverse direction, maximum noOfWeeks-1 number of weeks[]
          let wkNo = patternEndDayWeekNo - 1;
          if (wkNo < 0)
            // wrap around
            wkNo = wkNo + noOfWeeks;
          let weekdifference = 1;
          let foundWeekNo = -1;
          for (let j = 0; j < noOfWks - 1; j++) {
            lastdosagedaybefore = findLastIndex(weeks[wkNo], (_i, index) =>
              Boolean(weeks[wkNo][index].find((i) => i.timeOfDay.length > 0))
            );
            if (lastdosagedaybefore !== -1) {
              foundWeekNo = wkNo;
              weekdifference++;
              break;
            }
            weekdifference++;
            wkNo--;
            if (wkNo < 0)
              // wrap around
              wkNo = wkNo + noOfWeeks;
          }
          if (foundWeekNo !== -1) {
            // a week is found
            returnDate = new Date(
              patternEndDate.getFullYear(),
              patternEndDate.getMonth(),
              patternEndDate.getDate() + lastdosagedaybefore - patternEndDayofweekIx - 7 * weekdifference
            );
            return returnDate < patternStartDate
              ? noneWithinInterval
              : {
                  dosageDate: returnDate,
                  dosages: weeks[foundWeekNo][lastdosagedaybefore].map((i) => i.timeOfDay),
                };
          } else {
            // No weeks are found, revisit first week searched
            lastdosagedaybefore = findLastIndex(weeks[patternEndDayWeekNo], (i, index) =>
              Boolean(weeks[patternEndDayWeekNo][index].find((i) => i.timeOfDay.length > 0))
            );
            if (lastdosagedaybefore !== -1) {
              // Found at last try
              returnDate = new Date(
                patternEndDate.getFullYear(),
                patternEndDate.getMonth(),
                patternEndDate.getDate() + lastdosagedaybefore - patternEndDayofweekIx - noOfWeeks * 7
              );
              return returnDate < patternStartDate
                ? noneWithinInterval
                : {
                    dosageDate: returnDate,
                    dosages: weeks[patternEndDayWeekNo][lastdosagedaybefore].map((i) => i.timeOfDay),
                  };
            } else return undefined; // should be impossible
          }
        }
      }
    } else if (pattern === 'Måned') {
      if (dosagetasks.find((i) => i.timeOfDay.length > 0) === undefined)
        // no tasks at all
        return undefined;

      let monthdivisor: number;
      if (mndrep === 'Hver måned') monthdivisor = 1;
      else if (mndrep === 'Hver 2.måned') monthdivisor = 2;
      else if (mndrep === 'Hver 3.måned') monthdivisor = 3;
      else if (mndrep === 'Hver 4.måned') monthdivisor = 4;
      else if (mndrep === 'Hver 5.måned') monthdivisor = 5;
      else if (mndrep === 'Hver 6.måned') monthdivisor = 6;
      else if (mndrep === 'Hver 7.måned') monthdivisor = 7;
      else if (mndrep === 'Hver 8.måned') monthdivisor = 8;
      else if (mndrep === 'Hver 9.måned') monthdivisor = 9;
      else if (mndrep === 'Hver 10.måned') monthdivisor = 10;
      else if (mndrep === 'Hver 11.måned') monthdivisor = 11;
      else if (mndrep === 'Hver 12.måned') monthdivisor = 12;
      else return undefined; // random internal error

      let targetStartMonth = patternStartDate.getMonth(); // getMonth starts at 0
      let targetStartYear = patternStartDate.getFullYear();
      if (patternStartDate.getDate() > Number(monthDay)) {
        targetStartMonth++;
        if (targetStartMonth > 11) {
          // month from 0 to 11
          targetStartMonth = 0;
          targetStartYear++;
        }
      }
      let targetEndMonth = patternEndDate.getMonth();
      let targetEndYear = patternEndDate.getFullYear();
      if (patternEndDate.getDate() < Number(monthDay)) {
        targetEndMonth--;
        if (targetEndMonth < 0) {
          targetEndMonth = 11;
          targetEndYear--;
        }
      }
      const totalMonths: number = (targetEndYear - targetStartYear) * 12 + targetEndMonth - targetStartMonth;
      const remainder: number = totalMonths % monthdivisor;
      targetEndMonth -= remainder;
      if (targetEndMonth < 0) {
        targetEndMonth += 12;
        targetEndYear--;
      }
      const returnDate: Date = new Date(targetEndYear, targetEndMonth, Number(monthDay));
      return returnDate < patternStartDate || returnDate > patternEndDate
        ? noneWithinInterval
        : {
            dosageDate: returnDate,
            dosages: dosagetasks.map((i) => i.timeOfDay),
          };
    } else {
      // TODO
    }

    return undefined;
  };

  /* ******************************
    Common for all dosage patterns
    *********************************  */

  const presentFirstDosage = (
    patternStartDayNo: number,
    patternEndDayNo: number,
    pattern: string,
    dagrep: string,
    ukerep: string,
    mndrep: string,
    monthDay: string,
    noOfWks: number,
    weeks: TaskType[][][],
    dosagetasks: TaskType[],
    explDates: NewExplicitTasks[]
  ) => {
    const patternStartDate: Date | undefined = dateFromDayNo(patternStartDayNo);
    const patternEndDate: Date | undefined = dateFromDayNo(patternEndDayNo);

    const firstD = getFirstRecurrentDosage(
      patternStartDate,
      patternEndDate,
      pattern,
      dagrep,
      ukerep,
      mndrep,
      monthDay,
      noOfWks,
      weeks,
      dosagetasks
    );
    const preLude: string =
      (pattern === 'Dag' || pattern === 'Uke' || pattern === 'Måned') && explDates && explDates.length > 0
        ? 'Første gjentakende dose er '
        : 'Første dose er ';
    if (firstD && firstD.dosageDate === undefined)
      return <div className="bg-white text-sm font-medium text-red-600">{firstD.msg}</div>;
    else
      return (
        <div className="bg-white text-sm font-medium text-green-600">
          {firstD && (
            <div className="flex flex-row">
              <div>
                {preLude}
                {firstD.dosageDate?.toLocaleDateString(undefined, {
                  weekday: 'long',
                })}{' '}
                {firstD.dosageDate?.toLocaleDateString(undefined, {
                  dateStyle: 'short',
                })}
              </div>
              <div className="mx-1 text-blue-500 hover:underline">{firstD.dosages[0]}</div>
            </div>
          )}
        </div>
      );
  };

  /* ******************************
    Common for all dosage patterns
    *********************************  */
  const presentLastDosage = (
    patternStartDayNo: number,
    patternEndDayNo: number,
    pattern: string,
    dagrep: string,
    ukerep: string,
    mndrep: string,
    monthDay: string,
    noOfWks: number,
    weeks: TaskType[][][],
    dosagetasks: TaskType[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    explDates: NewExplicitTasks[]
  ) => {
    const patternStartDate: Date | undefined = dateFromDayNo(patternStartDayNo);
    const patternEndDate: Date | undefined = dateFromDayNo(patternEndDayNo);

    const lastD = getLastDosage(
      patternStartDate,
      patternEndDate,
      pattern,
      dagrep,
      ukerep,
      mndrep,
      monthDay,
      noOfWks,
      weeks,
      dosagetasks
    );
    if (lastD && lastD.dosageDate === undefined) return <></>;
    else
      return (
        <div className="bg-white text-sm font-medium text-green-600">
          {lastD && (
            <div className="flex flex-row">
              <div>
                Siste dose er{' '}
                {lastD.dosageDate?.toLocaleDateString(undefined, {
                  weekday: 'long',
                })}{' '}
                {lastD.dosageDate?.toLocaleDateString(undefined, {
                  dateStyle: 'short',
                })}
              </div>
              <div className="mx-1 text-blue-500 hover:underline">{lastD.dosages[lastD.dosages.length - 1]}</div>
            </div>
          )}
        </div>
      );
  };

  /************************************** 
    Common for all recurrent patterns (all except Enkeltdoser)
    ***************************************/
  const getWeekTasksCopy = (weeks: TaskType[][][], curday: number): TaskType[] => {
    if (curday === -1) return [...tasks];
    //X // ZZZ Look into this
    else {
      const WeekNo = Math.floor(curday / 7);
      const DayNo = curday % 7;
      const retval: TaskType[] = [...weeks[WeekNo][DayNo]];
      return retval;
    }
  };

  /************************************** 
    Common for all recurrent patterns (all except Enkeltdoser)
    ***************************************/
  const setDayTasks = (curTasks: TaskType[], curday: number) => {
    if (curday === -1) {
      // Means day-pattern
      setTasks([...curTasks]); //X
      return; // ZZZ Look into this
    } else {
      const WeekNo = Math.floor(curday / 7);
      const DayNo = curday % 7;
      weekArr[WeekNo][DayNo] = [...curTasks]; // ZZZ Neccessary?
      setWeekArr([...weekArr]); // Shallow copy Ok?
    }
  };

  /************************************** 
    Common for all recurrent patterns (all except Enkeltdoser)
    ***************************************/
  const changeCurDay = (dayIncrement: number) => {
    let newWeekDay = curWeekDay + dayIncrement;
    if (newWeekDay < 0) newWeekDay += 7;
    if (curWeekDay < 7) newWeekDay = newWeekDay % 7;
    else if (curWeekDay < 14) newWeekDay = (newWeekDay % 7) + 7;
    else newWeekDay = 0;

    setCurWeekDay(newWeekDay);
  };

  /************************************** 
    Common for all recurrent patterns (all except Enkeltdoser)
    ***************************************/
  const copyToAllDays = (curTasks: TaskType[], curday: number) => {
    if (curday === -1) return;
    // ZZZ Look into this
    else {
      const WeekNo = Math.floor(curday / 7);
      //const DayNo = curday%7;
      for (let i = 0; i < weekArr[WeekNo].length; i++) weekArr[WeekNo][i] = [...curTasks];
      setWeekArr([...weekArr]);
      return; // ZZZ Look into this as well
    }
  };

  /************************************** 
     Support routine presenting single week
    ***************************************/

  const checkBoxArr = (
    setting: TaskType[][],
    arrayId: number,
    curDay: number,
    firstDispDay: number,
    firstDay: number,
    lastDay: number
  ) => {
    // ZZZ ikke spesielt elegant firstDispDay = 0 means monday
    // the idea of firstDispDay was to display first week starting with the first dosage dayofWeek. Got back to starting always with monday, as the idea turned out to end up with a more complicated GUI
    const btnstyle = 'my-px text-sm font-medium leading-6 text-gray-900 bg-gray-200 h-11 w-6 align-top';
    const btnstyleSel = 'my-px text-sm font-medium leading-6 text-blue-500 bg-blue-200 h-11 w-6 align-top';
    const curDayNo: number = curDay % 7; // CurDayNo=0->7, curweekday = 0->n*7-1
    const curArrId: number = Math.floor(curDay / 7);
    const Weekd: string[] = ['Ma', 'Ti', 'On', 'To', 'Fr', 'Lø', 'Sø'];
    //const firstDayStyle = "px-px bg-gradient-to-r from-gray-300 to-gray-100 via-gray-100";
    //const lastDayStyle = "px-px bg-gradient-to-r from-gray-100 to-gray-300 via-gray-100";
    const firstDayStyle = 'px-px flex flex-col items-center';
    const lastDayStyle = firstDayStyle;
    const combinedStyle = 'px-px bg-gradient-to-r from-gray-300 to-gray-300 via-gray-100';
    //const anyDayStyle = "px-px";
    const anyDayStyle = 'px-px flex flex-col items-center';

    const getStyle = (checkDay: number, firstDay: number, lastDay: number): string => {
      return anyDayStyle;
      /*       if (firstDay === lastDay) {
        if (checkDay === firstDay) return combinedStyle;
        else return anyDayStyle;
      } else {
        if (checkDay === firstDay) return firstDayStyle;
        else if (checkDay === lastDay) return lastDayStyle;
        else return anyDayStyle;
      } */
    };

    const presentDayDosageText = (arrayId: number, index: number): ReactNode => {
      // arrayId starting at 0, Index: 0=Mandag, 1=Tirsdag ...
      if (weekArr[arrayId][index].length === 0) return <></>;
      else return checkMark1();
    };

    const getStartSymbol = (arrayId: number, checkDay: number, firstDay: number): JSX.Element => {
      return arrayId === 0 && checkDay === firstDay ? tinyrightarrow2() : nothing1();
    };

    const getEndSymbol = (arrayId: number, checkDay: number, lastDay: number): JSX.Element => {
      const lastDayDayNo = lastDay % 7;
      const lastDayArrId = Math.floor(lastDay / 7);
      return lastDayArrId === arrayId && checkDay === lastDayDayNo ? tinyleftarrow2() : nothing1();
    };

    return (
      <>
        <div className={getStyle(0, firstDay, lastDay)}>
          <div className="self-start">{getStartSymbol(arrayId, 0, firstDay)}</div>
          <button
            className={curDayNo === 0 && curArrId === arrayId ? btnstyleSel : btnstyle}
            onClick={() => setCurWeekDay(arrayId * 7 + 0)}
          >
            {Weekd[0 % 7]}
            <div className="h-5">{presentDayDosageText(arrayId, 0 % 7)}</div>
          </button>
          <div className="self-end">{getEndSymbol(arrayId, 0, lastDay)}</div>
        </div>
        <div className={getStyle(1, firstDay, lastDay)}>
          <div className="self-start">{getStartSymbol(arrayId, 1, firstDay)}</div>
          <button
            className={curDayNo === 1 && curArrId === arrayId ? btnstyleSel : btnstyle}
            onClick={() => setCurWeekDay(arrayId * 7 + 1)}
          >
            {Weekd[1 % 7]}
            <div className="h-5">{presentDayDosageText(arrayId, 1 % 7)}</div>
          </button>
          <div className="self-end">{getEndSymbol(arrayId, 1, lastDay)}</div>
        </div>
        <div className={getStyle(2, firstDay, lastDay)}>
          <div className="self-start">{getStartSymbol(arrayId, 2, firstDay)}</div>
          <button
            className={curDayNo === 2 && curArrId === arrayId ? btnstyleSel : btnstyle}
            onClick={() => setCurWeekDay(arrayId * 7 + 2)}
          >
            {Weekd[2 % 7]}
            <div className="h-5">{presentDayDosageText(arrayId, 2 % 7)}</div>
          </button>
          <div className="self-end">{getEndSymbol(arrayId, 2, lastDay)}</div>
        </div>
        <div className={getStyle(3, firstDay, lastDay)}>
          <div className="self-start">{getStartSymbol(arrayId, 3, firstDay)}</div>
          <button
            className={curDayNo === 3 && curArrId === arrayId ? btnstyleSel : btnstyle}
            onClick={() => setCurWeekDay(arrayId * 7 + 3)}
          >
            {Weekd[3 % 7]}
            <div className="h-5">{presentDayDosageText(arrayId, 3 % 7)}</div>
          </button>
          <div className="self-end">{getEndSymbol(arrayId, 3, lastDay)}</div>
        </div>
        <div className={getStyle(4, firstDay, lastDay)}>
          <div className="self-start">{getStartSymbol(arrayId, 4, firstDay)}</div>
          <button
            className={curDayNo === 4 && curArrId === arrayId ? btnstyleSel : btnstyle}
            onClick={() => setCurWeekDay(arrayId * 7 + 4)}
          >
            {Weekd[4 % 7]}
            <div className="h-5">{presentDayDosageText(arrayId, 4 % 7)}</div>
          </button>
          <div className="self-end">{getEndSymbol(arrayId, 4, lastDay)}</div>
        </div>
        <div className={getStyle(5, firstDay, lastDay)}>
          <div className="self-start">{getStartSymbol(arrayId, 5, firstDay)}</div>
          <button
            className={curDayNo === 5 && curArrId === arrayId ? btnstyleSel : btnstyle}
            onClick={() => setCurWeekDay(arrayId * 7 + 5)}
          >
            {Weekd[5 % 7]}
            <div className="h-5">{presentDayDosageText(arrayId, 5 % 7)}</div>
          </button>
          <div className="self-end">{getEndSymbol(arrayId, 5, lastDay)}</div>
        </div>
        <div className={getStyle(6, firstDay, lastDay)}>
          <div className="self-start">{getStartSymbol(arrayId, 6, firstDay)}</div>
          <button
            className={curDayNo === 6 && curArrId === arrayId ? btnstyleSel : btnstyle}
            onClick={() => setCurWeekDay(arrayId * 7 + 6)}
          >
            {Weekd[6 % 7]}
            <div className="h-5">{presentDayDosageText(arrayId, 6 % 7)}</div>
          </button>
          <div className="self-end">{getEndSymbol(arrayId, 6, lastDay)}</div>
        </div>
      </>
    );
  };

  /************************************** 
     Presenting additional controls in Week pattern
    ***************************************/

  const presentWeeks = (): JSX.Element => {
    const addW = () => {
      setNoOfWeeks(noOfWeeks + 1);
      setWeekArr([...weekArr, [[], [], [], [], [], [], []]]);
      if (ukerepetisjon !== 'Hver uke') setUkeRepetisjon('Hver uke');
    };

    const removeW = () => {
      if (noOfWeeks > 1) {
        if (curWeekDay >= (noOfWeeks - 1) * 7)
          // Within last week
          setCurWeekDay(curWeekDay - 7); // Set to same day the week before
        const weekCopy = [...weekArr];
        weekCopy.pop();
        setNoOfWeeks(noOfWeeks - 1);
        setWeekArr(weekCopy);
        if (ukerepetisjon !== 'Hver uke')
          // Might be initialized to several weeks, not neccessarily calling addW first
          setUkeRepetisjon('Hver uke');
      }
    };

    const calculateFirstDay = (startDayNo: number): number => {
      // Returns dayno (0->6) corresponding to start if start is a date, else -1
      const start = dateFromDayNo(startDayNo);
      return start ? (start.getDay() === 0 ? 6 : start.getDay() - 1) : -1;
    };

    const calculateLastDay = (startDayNo: number, endDayNo: number, weeksInPattern: number): number => {
      // returns dayno (0->weeksInPattern*7-1) corresponding to end, if start and end is a date, and given weeksInPattern, else -1
      const start: Date | undefined = dateFromDayNo(startDayNo);
      const end: Date | undefined = dateFromDayNo(endDayNo);

      if (!end || !start) return -1;
      // Get monday of the week that contains start date:
      const patternStartDayofweekIx: number = start.getDay() === 0 ? 6 : start.getDay() - 1; // 0:Monday --> 6: sunday
      const MondayInStartWeek: Date = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate() - patternStartDayofweekIx
      );
      // Get monday of the week that contains end date:
      const patternEndDayofweekIx: number = end.getDay() === 0 ? 6 : end.getDay() - 1; // 0:Monday --> 6: sunday

      const differenceInTime = end.getTime() - MondayInStartWeek.getTime();
      const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // Påbegynte dager
      const differenceInWeeks = Math.floor(differenceInDays / 7); // avstand i hele uker fra første mandagen til siste mandagen før sluttdato
      const weekremainder = differenceInWeeks % weeksInPattern; // antall uker man må trekke fra for å få siste uke som er med i mønsteret
      return patternEndDayofweekIx + 7 * weekremainder;
    };

    return (
      <div>
        <div>
          {[...Array(noOfWeeks)].map((i, index) => (
            <div key={index}>
              <div className="bg-gray-100">
                <div className="flex flex-row items-center bg-gray-100 text-sm">
                  {noOfWeeks > 1 && <div className="mr-1">Uke{index + 1}</div>}
                  {checkBoxArr(
                    weekArr[index],
                    index,
                    curWeekDay,
                    getAdjustedDay(startRecurrentDayNo),
                    calculateFirstDay(startRecurrentDayNo),
                    calculateLastDay(startRecurrentDayNo, endDayNo, noOfWeeks)
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          <div className="flex flex-row-reverse justify-between text-blue-300 ">
            <button className="hover:text-blue-500 hover:underline" onClick={addW}>
              Legg til uke
            </button>
            {noOfWeeks > 1 && (
              <button className="hover:text-blue-500 hover:underline" onClick={removeW}>
                Fjern siste uke
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  /************************************** 
     Presenting additional controls in Month pattern
    ***************************************/

  const presentMonths = (): JSX.Element => {
    return (
      <div>
        <div className="mt-2 flex flex-row justify-between bg-gray-100 text-sm">
          <label htmlFor="monthday" className={'bg-gray-100 font-medium leading-6 text-gray-900'}>
            Dag
            <select
              className="ml-1 bg-gray-100 align-middle text-blue-500 hover:underline"
              value={monthDay}
              onChange={(e) => setMonthDay(e.target.value)}
              id="monthday"
            >
              {[...Array(31)].map((i, index) => (
                <option key={index + 1}>{(index + 1).toString()}</option>
              ))}
            </select>
          </label>
          <span className="py-1 pl-1">i måneden</span>
        </div>
      </div>
    );
  };

  /************************************** 
     Presenting Explicit dates pattern
  ***************************************/

  const getExplicitDayDosage = (dayNo: number): TaskType[] => {
    if (dayNo === 0) return [];
    const thisdaytasks: NewExplicitTasks | undefined = explicitDates.find((e) => e.dosageDayNo === dayNo);
    return thisdaytasks ? [...thisdaytasks.tasks] : [];
  };

  const getDayReference = (dayNo: number): number => {
    // Returns -1 if not found
    const foundIx: number = explicitDates.findIndex((i) => i.dosageDayNo === dayNo);
    return foundIx;
  };

  const setExplicitTasks = (tasks: TaskType[], dayreference: number) => {
    // number in the explicitDates list, starting with 0
    // set tasks for date = dt[dayreference]

    //const foundIx:number = explicitDates.findIndex(i => i.dosageDate.getTime() === dt.getTime());
    const { dosageDayNo, dateOffset } = explicitDates[dayreference];
    const datesCopy = [...explicitDates];
    datesCopy.splice(dayreference, 1); //& returns the deleted element

    // https://stackoverflow.com/questions/76593892/how-to-use-tosorted-method-in-typescript     <-- ZZZ note this
    // Did not work (is not compiling in Azure, fails by not finding .toSorted method)
    // Using .sort() instead

    const newDates: NewExplicitTasks[] = [...datesCopy, { dosageDayNo, dateOffset, tasks }];
    const sortedDates = [...newDates.sort(sortExplicitTasks)];
    setExplicitDates(sortedDates);

    // ZZZZ If exists already, do nothing (?)
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const copyToAllExplicitdays = (tasks: TaskType[], dayRef: number) => {
    const explDatesCopy = [...explicitDates];
    explDatesCopy?.forEach((i) => (i.tasks = [...tasks]));
    setExplicitDates(explDatesCopy);
  };

  const changeCurDate = (delta: number) => {
    let newIx: number = getDayReference(curExplicitDayNo) + delta;
    if (newIx < 0) newIx = explicitDates.length - 1;
    if (newIx > explicitDates.length - 1) newIx = 0;
    const nextDate: number = explicitDates[newIx].dosageDayNo;
    setCurExplicitDayNo(nextDate);
  };

  // ********************************************************

  const updateDayOffsets = (explTasks: NewExplicitTasks[], daydiff: number): NewExplicitTasks[] => {
    // returns a new array with adjusted dayOffsets
    return explTasks.map((i) => {
      return {
        dosageDayNo: i.dosageDayNo,
        dateOffset: i.dateOffset + daydiff,
        tasks: [...i.tasks],
      };
    });
  };

  const setDatesFromOffsets = (explTasks: NewExplicitTasks[], startDayNo: number): NewExplicitTasks[] => {
    // returns a new array with adjusted dates
    return explTasks.map((i) => {
      return {
        dosageDayNo: startDayNo + i.dateOffset,
        dateOffset: i.dateOffset,
        tasks: [...i.tasks],
      };
    });
  };

  const changeExplicitStartTime = (newDateStr: string, explTasks: NewExplicitTasks[], baselineDayNo: number) => {
    // explicitStartTime is changed,
    // let the effect change all explicit dates, as well as potentially change start recurrence date accordingly
    const newDayNo = getDayNoFromDateControlFormattedDate(newDateStr);
    if (newDayNo === 0) return;
    //const oldStartDate = getStartDate(explTasks, baselineDate);
    const oldStartDayNo = baselineDayNo;
    const diff = oldStartDayNo === 0 ? undefined : newDayNo - oldStartDayNo; //GetNumberofdaysBetweenDates(oldStartDate, newDate);

    setExplicitDates(setDatesFromOffsets(explTasks, newDayNo));
    setExplicitStartDayNo(newDayNo);

    if (curExplicitDayNo && curExplicitDayNo !== 0) setCurExplicitDayNo(diff ? curExplicitDayNo + diff : 0);
    // Now to the recurrent date
    if (valgtPattern !== '') {
      // a recurrence pattern is in place
      if (diff && startRecurrentDayNo !== 0) setStartRecurrentDayNo(startRecurrentDayNo + diff);
    }
  };

  /*   const getDayNoFromOffset = (offset: number, baselineDayNo: number) => {
    return baselineDayNo + offset;
  };
 */
  /*   const getDayNoFromOffsetValue = (
    offset: number | undefined,
    baselineDayNo: number,
  ): number | undefined => {
    if (baselineDayNo === 0 || offset === undefined) return undefined;
    return getDayNoFromOffset(offset, baselineDayNo);
  };

  const getStartDate = (
    explDates: NewExplicitTasks[],
    baselineDate: Date | undefined,
  ): Date | undefined => {
    //return (explDates.length===0)?undefined:explDates.find((i => i.dateOffset===0))?.dosageDate;
    return baselineDate; /// ZZZZ Fix this
  }; */

  /*   const getStartDayNo = (
    explDates: NewExplicitTasks[],
    baselineDayNo: number,
  ): number => {
    //return (explDates.length===0)?undefined:explDates.find((i => i.dateOffset===0))?.dosageDate;
    return baselineDayNo; /// ZZZZ Fix this
  };
 */
  const getLastDayNo = (baseDayNo: number, explDates: NewExplicitTasks[]): number => {
    if (explDates.length === 0) return 0;
    const maxOffset: number = Math.max(...explDates.map((i) => i.dateOffset), -Infinity);
    return baseDayNo + maxOffset;
  };

  const addExplicitDateOffset = (dateOffset: number | undefined, dayNo: number, baselineDayNo: number) => {
    // Add date if it is not there from before
    // ZZZZ If exists already, do nothing (?) other than set cur dat to this date
    if (dayNo === undefined || dayNo === 0) return; // ZZZ what does this represent?
    if (dateOffset === undefined) return; // ZZZ existing values and undefined offset: fails
    if (explicitDates.find((i) => i.dateOffset === dateOffset) === undefined) {
      //
      // a new date is entered, will be stored
      if (explicitDates.length === 0) {
        const newDates = [{ dosageDayNo: dayNo, dateOffset: 0, tasks: [] }];
        setExplicitDates(newDates);
        setExplicitStartDayNo(dayNo);
      } else {
        // mingle with existing values:
        let datesCopy: NewExplicitTasks[] = [...explicitDates];
        if (dateOffset < 0) {
          // new startDate
          datesCopy = updateDayOffsets(datesCopy, -dateOffset); // add them
          datesCopy = [{ dosageDayNo: dayNo, dateOffset: 0, tasks: [] }, ...datesCopy];
          // a new lower date is entered: update StartDate, and Update all the other dateOffsets, and enter the new value
          setExplicitStartDayNo(dateOffset + baselineDayNo);
        } else {
          // just enter the new value
          datesCopy = [{ dosageDayNo: dayNo, dateOffset: dateOffset, tasks: [] }, ...datesCopy];
        }
        const sortedDates = [...datesCopy.sort(sortExplicitTasks)]; // ZZZ is shallow copy good enough here
        setExplicitDates(sortedDates);
      }
    }
    setCurExplicitDayNo(dayNo);
  };

  const removeExplicitDateOffset = (dateOffset: number, baselineDayNo: number) => {
    const foundIx: number = explicitDates.findIndex((i) => i.dateOffset === dateOffset);
    if (foundIx !== -1) {
      // Found
      let datesCopy = [...explicitDates];
      datesCopy.splice(foundIx, 1); // removed it

      if (dateOffset === 0) {
        // representing startDate
        // oldBeginTime is deleted, a new one have to be set, and dayOffsets updated
        const lowOffset = Math.min(...datesCopy.map((i) => i.dateOffset), Infinity);
        let lowBeginDayNo: number;
        if (lowOffset === Infinity) {
          // last one is removed, list is empty
          lowBeginDayNo = 0;
        } else {
          lowBeginDayNo = baselineDayNo === 0 ? 0 : baselineDayNo + lowOffset;
          datesCopy = updateDayOffsets(datesCopy, -lowOffset);
        }
        setExplicitStartDayNo(lowBeginDayNo);
      }
      setExplicitDates(datesCopy);

      // Set selection appropriately:
      if (baselineDayNo !== 0 && baselineDayNo + dateOffset === curExplicitDayNo) {
        // curDate is removed, then keep ix stable as far as possible
        const newIx: number = foundIx > datesCopy.length - 1 ? foundIx - 1 : foundIx;
        setCurExplicitDayNo(datesCopy[newIx]?.dosageDayNo);
      }
    }
  };

  const getNextDayNoSuggestion = (arr: NewExplicitTasks[]) => {
    let maxDayNo: number = -Infinity;
    if (arr && arr.length > 0)
      //maxDateInMS = arr.reduce((a, b) => Math.max(a.dosageDate.getTime(), b.dosageDate.getTime()), -Infinity);
      maxDayNo = Math.max(...arr.map((i) => i.dosageDayNo), -Infinity);
    return maxDayNo === -Infinity ? toDayNo : maxDayNo + 1; // add one day
  };

  const getNextDayOffsetSuggestion = (arr: NewExplicitTasks[], baselineDayNo: number) => {
    const nextDayNo: number = getNextDayNoSuggestion(arr);
    const diff = baselineDayNo === 0 ? 0 : nextDayNo - baselineDayNo;
    return diff;
  };

  const resetExplicitDatesToBeAdded = () => {
    setExplicitDayNoToBeAdded(toDayNo);
    setExplicitNoOfDaysToBeAdded(0);
  };

  const presentExplicitDates = (
    explDates: NewExplicitTasks[],
    baseExplicitDayNo: number,
    curExplDayNo: number | undefined
  ) => {
    const reversecolor = 'text-blue-500 bg-blue-200';
    const beginTimeMessage = 'Du er i ferd med å legge inn ny oppstartsdato. Alle eksisterende datoer beholdes.';

    const newValueEvent = (
      inputTypeDate: boolean,
      dayNoToBeAdded: number,
      numberToBeAdded: number | undefined,
      baselineDayNo: number
    ) => {
      if (inputTypeDate) {
        setExplicitDayNoToBeAdded(dayNoToBeAdded);
        if (baselineDayNo !== 0 && dayNoToBeAdded !== 0) {
          numberToBeAdded = dayNoToBeAdded - baselineDayNo;
          setExplicitNoOfDaysToBeAdded(numberToBeAdded);
        }
      } else {
        // number input
        if (baselineDayNo !== 0 && numberToBeAdded) {
          setExplicitNoOfDaysToBeAdded(numberToBeAdded);
          setExplicitDayNoToBeAdded(baselineDayNo + numberToBeAdded);
        }
      }
      if (numberToBeAdded && numberToBeAdded < 0) setExplicitMsg(beginTimeMessage);
      else setExplicitMsg('');
    };

    const addNewDateDlg = (dayNoToBeAdded: number, numberToBeAdded: number | undefined, baselineDayNo: number) => {
      const narrowNumberInputStyle = { width: '6rem' };
      return (
        <div
          className="absolute left-8 rounded border-2 border-gray-400 bg-white drop-shadow-lg"
          ref={addExplicitDateRef}
        >
          <div className="bg-blue-300 px-2 text-white">
            {baselineDayNo !== 0 ? 'Oppstartsdato ' + getSimpleFormattedDate(dateFromDayNo(baselineDayNo)) : ''}
          </div>
          <div className="mt-2 rounded p-2 ">
            {baselineDayNo !== 0 && dayNoToBeAdded !== 0 && (
              <div className="bg-gray-100 pb-1">
                <input
                  className="bg-gray-100 pl-2 text-blue-600"
                  name="explDateInputMode"
                  checked={explDateInputMode === 'number'}
                  value={explDateInputMode}
                  onChange={() => setExplDateInputMode('number')}
                  type="radio"
                ></input>
                <label className="pl-2 align-bottom ">
                  Dag nummer:
                  <input
                    className={
                      explDateInputMode === 'number'
                        ? 'bg-gray-100 pl-2 text-blue-600'
                        : 'bg-gray-100 pl-2 text-gray-400'
                    }
                    style={narrowNumberInputStyle}
                    value={dayNoToBeAdded - baselineDayNo}
                    onChange={(e) => newValueEvent(false, dayNoToBeAdded, Number(e.target.value), baselineDayNo)}
                    disabled={explDateInputMode === 'date'}
                    type="number"
                  ></input>
                </label>
              </div>
            )}
            <div className="bg-gray-100">
              {baselineDayNo !== 0 && dayNoToBeAdded !== 0 && (
                <input
                  className="bg-gray-100 pl-2 text-blue-600"
                  name="explDateInputMode"
                  checked={explDateInputMode === 'date'}
                  value={explDateInputMode}
                  onChange={() => setExplDateInputMode('date')}
                  type="radio"
                ></input>
              )}
              <label className="pl-2 align-bottom ">
                Ny dato:
                <input
                  className={
                    explDateInputMode === 'date' ? 'bg-gray-100 pl-2 text-blue-600' : 'bg-gray-100 pl-2 text-gray-400'
                  }
                  value={getDateControlFormattedDateFromDbDayNo(dayNoToBeAdded)}
                  // onChange={(e) => setExplicitDateToBeAdded(getDateFromDateControlFormattedDate(e.target.value))}
                  onChange={(e) =>
                    newValueEvent(
                      true,
                      getDayNoFromDateControlFormattedDate(e.target.value),
                      numberToBeAdded,
                      baselineDayNo
                    )
                  }
                  disabled={explDateInputMode === 'number'}
                  type="date"
                ></input>
              </label>
            </div>
            {explicitMsg && (
              <div className="flex w-60 flex-row px-2 ">
                <div className="text-wrap text-red-600 ">{explicitMsg}</div>
              </div>
            )}
            <div className="flex flex-row justify-between border-t-2 border-gray-400 pt-2">
              <button
                onClick={() => {
                  setExplicitDateAddingInProcess(false);
                }}
                className="text-blue-500 hover:underline"
              >
                Lukk
              </button>
              <button
                onClick={() => {
                  if (numberToBeAdded || dayNoToBeAdded !== 0) {
                    addExplicitDateOffset(numberToBeAdded, dayNoToBeAdded, baselineDayNo);
                    setExplicitDateAddingInProcess(false);
                  }
                }}
                className="text-blue-500 hover:underline"
              >
                Legg til dato
              </button>
            </div>
          </div>
        </div>
      );
    };

    const presentSingleDate = (datetask: NewExplicitTasks, baselineDayNo: number) => {
      const curRowSelected = datetask.dosageDayNo === curExplDayNo;
      const styleOuterBtn = 'rounded-md flex flex-row justify-between text-sm font-medium ';
      return (
        <div key={datetask.dateOffset.toString()} className="border-b-[1px] border-gray-300">
          <div className={curRowSelected ? styleOuterBtn + reversecolor : styleOuterBtn + 'bg-gray-100 text-blue-500'}>
            <button onClick={() => removeExplicitDateOffset(datetask.dateOffset, baselineDayNo)}>⛔</button>
            <button
              className="ml-2 flex w-full flex-row justify-between "
              onClick={() => setCurExplicitDayNo(datetask.dosageDayNo)}
            >
              <div className="mx-0.5 flex w-full flex-row justify-between">
                <div className="mr-1">{'dag ' + datetask.dateOffset.toString()}</div>
                <div className="mr-0.5 text-gray-600 ">
                  {dateFromDayNo(datetask.dosageDayNo)?.toLocaleDateString(undefined, {
                    weekday: 'short',
                  }) +
                    ' ' +
                    dateFromDayNo(datetask.dosageDayNo)?.toLocaleDateString(undefined, {
                      dateStyle: 'short',
                    })}
                </div>
                <div className="mx-0.5">
                  {datetask.tasks && datetask.tasks.length > 0 ? (
                    <div>{checkMarkSmaller()}</div>
                  ) : (
                    <div>
                      <Question size={20} />
                    </div>
                  )}
                </div>
              </div>
            </button>
          </div>
        </div>
      );
    };

    return (
      <>
        {(!explicitDates || (explicitDates && explicitDates.length === 0)) && (
          <div className="w-54 my-1 flex flex-row rounded ">
            {/*                 <button
                    className="text-blue-500 hover:underline"
                    onClick={() => {setExplicitDateToBeAdded(getNextDateSuggestion(explicitDates));setExplicitDateAddingInProcess(true); setExplicitMsg("");}}>
                    <span className="no-underline font-bold ml-1.5 pr-4 text-green-600">+</span>Legg til enkeltdoser
                </button>
 */}{' '}
            <button
              className="mt-2 rounded bg-blue-500 px-4 font-bold text-white hover:bg-blue-700"
              onClick={() => {
                setExplicitDayNoToBeAdded(getNextDayNoSuggestion(explicitDates));
                setExplicitNoOfDaysToBeAdded(getNextDayOffsetSuggestion(explicitDates, baseExplicitDayNo));
                setExplicitDateAddingInProcess(true);
                setExplicitMsg('');
              }}
            >
              Legg til enkeltdoser
            </button>
          </div>
        )}

        {/* Enkeltdoser */}
        {explicitDates && explicitDates.length > 0 && (
          <div className="pt-2">
            <div className="w-54 ">
              <div>
                <span className="pr-2">Oppstart</span>
                <input
                  className={'bg-gray-100 pl-4 text-blue-600'}
                  value={getDateControlFormattedDateFromDbDayNo(baseExplicitDayNo)}
                  onChange={(e) => changeExplicitStartTime(e.target.value, explicitDates, baseExplicitDayNo)}
                  type="date"
                ></input>
              </div>
            </div>

            <div className="w-54 flex flex-row rounded bg-gray-100">
              <div className="">
                <div>{explDates?.map((i) => presentSingleDate(i, baseExplicitDayNo))}</div>

                <button
                  onClick={() => {
                    setExplicitDayNoToBeAdded(getNextDayNoSuggestion(explicitDates));
                    setExplicitNoOfDaysToBeAdded(getNextDayOffsetSuggestion(explicitDates, baseExplicitDayNo));
                    setExplicitDateAddingInProcess(true);
                    setExplicitMsg('');
                  }}
                  className="text-blue-500 hover:underline"
                >
                  <span className="ml-1.5 pr-4 font-bold text-green-600 no-underline">+</span>
                  Legg til ny dato
                </button>
              </div>
            </div>
          </div>
        )}
        {explicitDateAddingInProcess &&
          addNewDateDlg(explicitDayNoToBeAdded, explicitNoOfDaysToBeAdded, baseExplicitDayNo)}
      </>
    );
  };

  /************************************** 
     End Explicit dates
    ***************************************/

  // const visibilityborderplus = "border-2 border-gray-200 text-sm";
  const visibilityborderplus = 'text-sm ';
  //const nonfirstborder = visibilityborderplus + "border-t-4 border-gray-300";

  const adjCurWeekDay = (curWD: number, startD: number) => {
    // adjust to correct day within same week, if input == -1 then leace output the same
    //        const adj = getAdjustedDay(startDate);
    if (curWD === -1) return -1;
    const adj = getAdjustedDay(startD);
    //        const slot: number = Math.floor(curWeekDay/7);
    const slot: number = Math.floor(curWD / 7);
    //        const curDay = curWeekDay%7;
    const curDay = curWD % 7;
    return ((curDay + adj) % 7) + 7 * slot;
  };

  // ZZZ temp
  const setStartRecurrentDate = (newDate: Date | undefined) => {
    setStartRecurrentDayNo(dayNoFromDate(newDate));
  };

  const setEndDate = (newDate: Date | undefined) => {
    setEndDayNo(dayNoFromDate(newDate));
  };
  // ZZZ end temp

  const checkAllowedTimeChange = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    startDate: Date | undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    endDate: Date | undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    intervalDays: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    endOption: string
  ): boolean => {
    //true return all ok
    // routine called by timeControl, for checking whether a specific change is allowed
    return true;
  };

  const setEndOptionWithMem = (newEndOption: string) => {
    prevEndOption.current = endOption;
    setEndOption(newEndOption);
  };

  const changePattern = (newPattern: string) => {
    // Set to default values:
    setDagRepetisjon('Hver dag');
    setUkeRepetisjon('Hver uke');
    setMndRepetisjon('Hver måned');
    setWeekArr([[[], [], [], [], [], [], []]]);
    setNoOfWeeks(1);
    setMonthDay(today.getDate().toString());
    if (newPattern === 'Enkeltdoser') setEndOptionWithMem('auto');
    else if (endOption === 'auto')
      // changing from enkeltdoser
      setEndOptionWithMem(
        prevEndOption.current === 'Max' || prevEndOption.current === 'endDate' || prevEndOption.current === 'interval'
          ? prevEndOption.current
          : 'Max'
      ); // ZZZ wise? should rather remember previous choice and revert to that?
    if (newPattern === 'Dag' || newPattern === 'Måned') setCurWeekDay(-1);
    else setCurWeekDay(0);

    if (valgtPattern === '' && (newPattern === 'Dag' || newPattern === 'Uke' || newPattern === 'Måned')) {
      // introducing recurrent pattern, set inital suggested recurrentdate
      if (explicitDates && explicitDates.length > 0) {
        const lastDayNo = getLastDayNo(explicitStartDayNo, explicitDates);
        if (lastDayNo !== 0) setStartRecurrentDayNo(lastDayNo + 1);
      }
    }
    // Set new pattern:
    setValgtPattern(newPattern);
  };

  return (
    <div className={visibilityborderplus}>
      {explicitDates && explicitDates.length > 0 && (
        <div className="mt-2 font-bold text-black">
          Enkeltdoser
          <button
            className="pl-2 font-normal text-blue-300 hover:text-blue-500"
            onClick={() => {
              resetExplicitDatesToBeAdded();
              setExplicitStartDayNo(0);
              setExplicitDates([]);
            }}
          >
            Fjern alle enkeltdoser
          </button>
        </div>
      )}
      <div className="mb-1 flex flex-row justify-between">
        {/* Presenter enkeltdoser, og deretter tilhørende tasker */}
        {presentExplicitDates(explicitDates, explicitStartDayNo, curExplicitDayNo)}
        {explicitDates && explicitDates.length > 0 && (
          <div className="pt-2">
            <PresentTasks
              pattern={valgtPattern}
              dayPresentation={getSimpleFormattedDate(dateFromDayNo(curExplicitDayNo))}
              tasks={getExplicitDayDosage(curExplicitDayNo)}
              setTasks={setExplicitTasks}
              copyToAllDays={copyToAllExplicitdays}
              changeCurDay={changeCurDate}
              dayReference={getDayReference(curExplicitDayNo)}
              administreringsdetaljer={administreringsdetaljer}
            />
          </div>
        )}
      </div>

      {valgtPattern === '' && (
        <div className="my-1 flex w-56 flex-row rounded ">
          <button
            className="mt-2 rounded bg-blue-500 px-4 font-bold text-white hover:bg-blue-700"
            onClick={() => changePattern('Dag')}
          >
            Legg til gjentakende doser
          </button>
        </div>
      )}

      {valgtPattern !== '' && (
        <div>
          <div
            className={
              (explicitDates && explicitDates.length > 0 ? 'border-t ' : '') +
              'mt-2 border-gray-300 font-bold text-black '
            }
          >
            Gjentakende dosering
            <button className="pl-2 font-normal text-blue-300 hover:text-blue-500" onClick={() => changePattern('')}>
              Fjern gjentakende dosering
            </button>
          </div>

          {/* Presenter gjentakende dosering, og deretter tilhørende tasker */}
          <DosageIntervalControl2
            collapsed={false}
            isComplex={false}
            startDate={dateFromDayNo(startRecurrentDayNo)}
            endDate={dateFromDayNo(endDayNo)}
            intervalDays={intervalDays}
            endOption={endOption}
            baseStartDate={explicitDates && explicitDates.length > 0 ? dateFromDayNo(explicitStartDayNo) : undefined}
            startDateOnChange={setStartRecurrentDate}
            endDateOnChange={setEndDate}
            intervalDaysOnChange={setIntervalDays}
            endOptionOnChange={setEndOptionWithMem}
            checkAllowedTimeChange={checkAllowedTimeChange}
            //onDosageTimeChanges={onDosageTimeChanges}
          />

          <div className="mt-2 flex flex-row justify-between text-sm">
            <label htmlFor="frekvens" className={'bg-gray-100 font-medium leading-6 text-gray-900'}>
              Mønster
              <select
                className={'bg-gray-100 text-blue-500 hover:underline'}
                value={valgtPattern}
                onChange={(e) => changePattern(e.target.value)}
                id="frekvens"
              >
                <option>Dag</option>
                <option>Uke</option>
                <option>Måned</option>
              </select>
            </label>

            {valgtPattern === 'Dag' && (
              <div className="w-1/2 bg-gray-100 align-middle text-sm">
                <select
                  className={thisdropdownstyle}
                  value={dagrepetisjon}
                  onChange={(e) => setDagRepetisjon(e.target.value)}
                  id="dagrepetisjon"
                >
                  <option>Hver dag</option>
                  <option>Hver 2.dag</option>
                  <option>Hver 3.dag</option>
                  <option>Hver 4.dag</option>
                  <option>Hver 5.dag</option>
                  <option>Hver 6.dag</option>
                  <option>Hver 7.dag</option>
                </select>
              </div>
            )}

            {valgtPattern === 'Uke' && (
              <div className="w-1/2 bg-gray-100 align-middle text-sm">
                {noOfWeeks === 1 ? (
                  <select
                    className={thisdropdownstyle}
                    value={ukerepetisjon}
                    onChange={(e) => setUkeRepetisjon(e.target.value)}
                    id="ukerepetisjon"
                  >
                    <option>Hver uke</option>
                    <option>Hver 2.uke</option>
                    <option>Hver 3.uke</option>
                    <option>Hver 4.uke</option>
                    <option>Hver 5.uke</option>
                    <option>Hver 6.uke</option>
                    <option>Hver 7.uke</option>
                    <option>Hver 8.uke</option>
                  </select>
                ) : (
                  <div className="ml-1 text-gray-400">Alternerende uker</div>
                )}
              </div>
            )}
            {valgtPattern === 'Måned' && (
              <div className="w-1/2 bg-gray-100 align-middle text-sm">
                <select
                  className={thisdropdownstyle}
                  value={mndrepetisjon}
                  onChange={(e) => setMndRepetisjon(e.target.value)}
                  id="mndrepetisjon"
                >
                  <option>Hver måned</option>
                  <option>Hver 2.måned</option>
                  <option>Hver 3.måned</option>
                  <option>Hver 4.måned</option>
                  <option>Hver 5.måned</option>
                  <option>Hver 6.måned</option>
                  <option>Hver 7.måned</option>
                  <option>Hver 8.måned</option>
                  <option>Hver 9.måned</option>
                  <option>Hver 10.måned</option>
                  <option>Hver 11.måned</option>
                  <option>Hver 12.måned</option>
                </select>
              </div>
            )}
          </div>

          <div className="flex flex-row justify-between rounded">
            <div className="mt-2">
              {valgtPattern === 'Uke' && <div>{presentWeeks()}</div>}
              {valgtPattern === 'Måned' && <div>{presentMonths()}</div>}
            </div>
            <div className="pt-2">
              <PresentTasks
                pattern={valgtPattern}
                dayPresentation={weekdays[(curWeekDay + getAdjustedDay(startRecurrentDayNo)) % 7]}
                tasks={getWeekTasksCopy(weekArr, adjCurWeekDay(curWeekDay, startRecurrentDayNo))}
                setTasks={setDayTasks}
                copyToAllDays={copyToAllDays}
                changeCurDay={changeCurDay}
                dayReference={adjCurWeekDay(curWeekDay, startRecurrentDayNo)}
                administreringsdetaljer={administreringsdetaljer}
              />
            </div>
          </div>
        </div>
      )}
      <div>{doseringsvarsel !== '' && <div className="pt-2 text-sm text-red-500">{doseringsvarsel}</div>}</div>

      <div>
        <div>
          {presentFirstDosage(
            startRecurrentDayNo,
            endOption === 'endDate' ? endDayNo : dayNo3000, // ZZZ ok?
            valgtPattern,
            dagrepetisjon,
            ukerepetisjon,
            mndrepetisjon,
            monthDay,
            noOfWeeks,
            weekArr,
            tasks, //X
            explicitDates
          )}
        </div>
        {(valgtPattern === 'Enkeltdoser' || endOption === 'endDate' || endOption === 'interval') && (
          <div>
            {presentLastDosage(
              startRecurrentDayNo,
              endOption === 'endDate'
                ? endDayNo
                : endOption === 'interval'
                ? startRecurrentDayNo === 0
                  ? dayNo3000
                  : startRecurrentDayNo + intervalDays - 1
                : dayNo3000,
              valgtPattern,
              dagrepetisjon,
              ukerepetisjon,
              mndrepetisjon,
              monthDay,
              noOfWeeks,
              weekArr,
              tasks, //X
              explicitDates
            )}
          </div>
        )}
      </div>
    </div>
  );
};
