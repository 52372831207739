/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { Button } from '../../components/Button';
import { Selector } from '../../components/Selector';
import { Modal, ModalTrigger } from '../../components/Modal';
import PatientClient from '../../network/PatientClient';
import UserClient from '../../network/UserClient';
import formatDate from '../../utils/formatDate';
import { PencilButton } from './PencilButton';
import { minutesFromMidnightToDate, timeToMinutesFromMidnight } from './Profile';
import { useT } from '../../i18n/useT';

export function EditForm({ defaultValue, onSave, label, inputType, children }) {
  const t = useT();
  const [value, setValue] = React.useState(defaultValue);
  const [loading, setLoading] = React.useState(false);

  const save = React.useCallback(
    async (event) => {
      event.preventDefault();

      if (loading) {
        return;
      }

      try {
        await onSave(value);
      } catch (error) {
        setLoading(false);
        throw error;
      }
      setLoading(false);
    },
    [value, onSave, loading]
  );

  return (
    <form onSubmit={save}>
      <label
        css={css`
          display: flex;
          flex-direction: column;
        `}
        htmlFor={label}
      >
        {label}
      </label>
      {inputType && (
        <input
          type={inputType}
          autoFocus
          id={label}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          css={css`
            font-size: 16px;
            padding: 8px 12px;
            border-radius: 4px;
            border: 1px solid #dedede;
            width: 100%;
            margin: 12px 0;
          `}
        />
      )}
      {children || null}
      <Button type="submit" onClick={save} disabled={loading}>
        {t('Save')}
      </Button>
    </form>
  );
}

export function EditProfileField({ modalTitle, defaultValue, onSave, label, inputType, children }) {
  return (
    <ModalTrigger
      button={({ openModal }) => <PencilButton onClick={openModal} />}
      modal={({ closeModal }) => (
        <Modal title={modalTitle} closeModal={closeModal}>
          <EditForm defaultValue={defaultValue} onSave={onSave} label={label} inputType={inputType}>
            {children}
          </EditForm>
        </Modal>
      )}
    />
  );
}

export function EditGivenName({ patient }) {
  const t = useT();

  return (
    <EditProfileField
      modalTitle={t('Change first name')}
      label={t('First name')}
      inputType="text"
      defaultValue={patient.name[0].given && patient.name[0].given.join(' ')}
      onSave={async (value) => {
        const patientCopy = JSON.parse(JSON.stringify(patient));
        patientCopy.name[0].given = value.split(' ');
        const result = await PatientClient.updatePatient(patientCopy.id, patientCopy);

        if (result.success) {
          window.location.reload();
        } else {
          alert(t('Something went wrong, please try again.'));
        }
      }}
    />
  );
}

export function EditFamilyName({ patient }) {
  const t = useT();
  return (
    <EditProfileField
      modalTitle={t('Change last name')}
      label={t('Last name')}
      inputType="text"
      defaultValue={patient.name[0].family}
      onSave={async (value) => {
        const patientCopy = JSON.parse(JSON.stringify(patient));
        patientCopy.name[0].family = value;
        const result = await PatientClient.updatePatient(patientCopy.id, patientCopy);

        if (result.success) {
          window.location.reload();
        } else {
          alert(t('Something went wrong, please try again.'));
        }
      }}
    />
  );
}

export function EditGender({ patient }) {
  const t = useT();
  const [value, setValue] = useState(patient.gender || 'unknown');

  return (
    <EditProfileField
      modalTitle={t('Change gender')}
      onSave={async () => {
        const patientCopy = JSON.parse(JSON.stringify(patient));
        patientCopy.gender = value;
        const result = await PatientClient.updatePatient(patientCopy.id, patientCopy);

        if (result.success) {
          window.location.reload();
        } else {
          alert(t('Something went wrong, please try again.'));
        }
      }}
    >
      <div
        css={css`
          margin-bottom: 16px;
        `}
      >
        <Selector
          title={t('Gender')}
          value={value}
          onValue={(value) => setValue(value)}
          data={[
            { value: 'female', label: t('Female') },
            { value: 'male', label: t('Male') },
            { value: 'other', label: t('Other') },
            { value: 'unknown', label: t('Unknown') },
          ]}
        />
      </div>
    </EditProfileField>
  );
}

export function EditEmail({ patient }) {
  const t = useT();

  const existingEmail = patient.telecom && patient.telecom.find((tele) => tele.system === 'email');
  const defaultValue = existingEmail ? existingEmail.value : '';

  return (
    <EditProfileField
      modalTitle={t('Change e-mail')}
      label={t('E-mail')}
      inputType="email"
      defaultValue={defaultValue}
      onSave={async (value) => {
        const patientCopy = JSON.parse(JSON.stringify(patient));
        if (existingEmail) {
          const index = patientCopy.telecom && patientCopy.telecom.findIndex((tele) => tele.system === 'email');
          patientCopy.telecom[index].value = value;
        } else {
          if (!patientCopy.telecom) {
            patientCopy.telecom = [];
          }

          patientCopy.telecom.push({
            system: 'email',
            use: 'email',
            value: value,
          });
        }

        const result = await PatientClient.updatePatient(patientCopy.id, patientCopy);

        if (result.success) {
          window.location.reload();
        } else {
          alert(t('Something went wrong, please try again.'));
        }
      }}
    />
  );
}

export function EditBirthDate({ patient }) {
  const t = useT();
  return (
    <EditProfileField
      modalTitle={t('Change date of birth')}
      label={t('Date of birth')}
      inputType="date"
      defaultValue={formatDate(new Date(patient.birthDate), 'yyyy-MM-dd')}
      onSave={async (value) => {
        const patientCopy = JSON.parse(JSON.stringify(patient));
        patientCopy.birthDate = value;

        const result = await PatientClient.updatePatient(patientCopy.id, patientCopy);

        if (result.success) {
          window.location.reload();
        } else {
          alert(t('Something went wrong, please try again.'));
        }
      }}
    />
  );
}

export function EditResearchProjectNumber({ patient }) {
  const t = useT();
  const existingResearchNumber =
    (patient.identifier || []).filter((item) => item.use === 'ResearchProjectNumber').map((item) => item.value)[0] ||
    '';

  return (
    <EditProfileField
      modalTitle={t('Change research number')}
      label={t('Research number')}
      inputType="text"
      defaultValue={existingResearchNumber}
      onSave={async (value) => {
        const patientCopy = JSON.parse(JSON.stringify(patient));

        patientCopy.identifier = patientCopy.identifier || [];
        const index = patientCopy.identifier.findIndex((item) => item.use === 'ResearchProjectNumber');

        if (index > -1) {
          patientCopy.identifier[index].value = value;
        } else {
          patientCopy.identifier.push({
            system: 'https://capableapi.azurewebsites.net/ResearchProjectNumber',
            use: 'ResearchProjectNumber',
            value: value,
          });
        }

        const result = await PatientClient.updatePatient(patientCopy.id, patientCopy);

        if (result.success) {
          window.location.reload();
        } else {
          alert(t('Something went wrong, please try again.'));
        }
      }}
    />
  );
}

export function EditSmsAlertOffset({ user }) {
  const t = useT();
  const existingSmsAlertOffset = minutesFromMidnightToDate(
    user.userAttributes.filter((item) => item.key === 'SmsAlertOffset').map((item) => item.value)[0],
    t
  );

  return (
    <EditProfileField
      modalTitle={t('Change SMS reminder for sleep diary registration')}
      label={t('SMS reminder for sleep diary registration')}
      inputType="time"
      defaultValue={existingSmsAlertOffset}
      placeholder={t('Format: 12:00')}
      onSave={async (value) => {
        if (!/^[0-2]{1,1}[0-9]{1,1}:[0-5]{1,1}[0-9]{1,1}$/.test(value)) {
          alert(t('{{value}} is not a valid time format. Correct time format is for example 22:00', value));
          return;
        }
        const userCopy = JSON.parse(JSON.stringify(user));

        userCopy.userAttributes = userCopy.userAttributes || [];
        const index = userCopy.userAttributes.findIndex((item) => item.key === 'SmsAlertOffset');

        if (index > -1) {
          userCopy.userAttributes[index].value = String(timeToMinutesFromMidnight(value) ?? -1);
        } else {
          userCopy.userAttributes.push({
            system: 'https://capableapi.azurewebsites.net/',
            key: 'SmsAlertOffset',
            value: String(timeToMinutesFromMidnight(value) ?? -1),
          });
        }

        const result = await UserClient.updateUser(userCopy);

        if (result.success) {
          window.location.reload();
        } else {
          alert(t('Something went wrong, please try again.'));
        }
      }}
    >
      <div
        css={css`
          margin-bottom: 8px;
        `}
      >
        <Button
          onClick={async (event) => {
            event.preventDefault();

            const userCopy = JSON.parse(JSON.stringify(user));

            userCopy.userAttributes = userCopy.userAttributes || [];
            const index = userCopy.userAttributes.findIndex((item) => item.key === 'SmsAlertOffset');

            if (index > -1) {
              userCopy.userAttributes[index].value = '-1';
            } else {
              userCopy.userAttributes.push({
                system: 'https://capableapi.azurewebsites.net/',
                key: 'SmsAlertOffset',
                value: '-1',
              });
            }

            const result = await UserClient.updateUser(userCopy);

            if (result.success) {
              window.location.reload();
            } else {
              alert(t('Something went wrong, please try again.'));
            }
          }}
        >
          {t('Turn off SMS notification')}
        </Button>
      </div>
    </EditProfileField>
  );
}
