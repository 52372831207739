/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

export const InputLine = ({ v, children, noMargin }) => {
  const hasErrors = v.errors.length > 0;

  return (
    <div style={{ marginBottom: noMargin ? 0 : 32 }}>
      <Wrapper error={hasErrors}>{children}</Wrapper>
      {hasErrors && (
        <ErrorMessage>
          {v.errors.map((error) => (
            <div key={error}>{error}</div>
          ))}
        </ErrorMessage>
      )}
    </div>
  );
};

const Wrapper = styled.div`
  input {
    width: 100%;
  }
  ${(props) =>
    props.error &&
    css`
      * {
        background-color: rgba(255, 0, 0, 0.1);
        border-color: rgba(255, 0, 0, 0.3);
      }
    `}
`;

const ErrorMessage = styled.div`
  margin-top: 5px;
  color: #9e0000;
`;
