import React from 'react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import {
  Cpl_Decision,
  Cpl_Decision_Component,
  Cpl_Decision_RecurrenceParams,
  Cpl_Decision_RecurrentTask,
} from '../../../types/Cpl_Decision';
import {
  dayNoFromDate,
  dbDayFromDateCtrlString,
  getDateControlFormattedDateFromDbDayNo,
  weekDayFromDbDay,
} from './convertDosering';
import { Button } from '../../../components/Button3';

// ZZZ Redo usage of these , collected from dosage4.tsx
const checkMark1 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-5 w-6"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
    </svg>
  );
};

const tinyrightarrow2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 8"
      strokeWidth={1.0}
      stroke="currentColor"
      className="h-2 w-5 text-blue-500"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 0.25 21 4m0 0-3.75 3.75M21 4H2 M0 0.5 0 7.5" />
    </svg>
  );
};

const nothing1 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 8"
      strokeWidth={1.0}
      stroke="currentColor"
      className="h-2 w-5 text-blue-500"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="" />
    </svg>
  );
};

export default function NewDocumentDialog({
  showDlg,
  title,
  onClose,
  onSave,
}: {
  showDlg: boolean;
  title: string;
  onClose: () => void;
  onSave: (newOrder: Cpl_Decision) => void;
  children?: React.ReactNode;
}) {
  const dialogRef = useRef<null | HTMLDialogElement>(null);
  const showDialog = showDlg ? 'y' : '';

  useEffect(() => {
    if (showDialog === 'y') {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [showDialog]);

  const closeDialog = () => {
    dialogRef.current?.close();
    onClose();
  };

  const savingNewOrder = (newOrder: Cpl_Decision) => {
    onSave(newOrder);
    closeDialog();
  };

  const dialog: JSX.Element | null =
    showDialog === 'y' ? (
      /*          dialog ref = {dialogRef} className='min-h-[50%] fixed top-50 left-50 -translate-x-50 -translate-y-50 z-10 rounded-xl backdrop:bg-gray-800/50'
            <dialog ref = {dialogRef} className='min-h-[60%] fixed z-10 rounded-xl backdrop:bg-gray-800/50'>
 */ <dialog
        ref={dialogRef}
        //        className="fixed z-10 min-h-[60%] rounded-xl p-4 backdrop:bg-gray-800/50"
        className="fixed z-10 rounded-xl p-4 backdrop:bg-gray-800/50"
      >
        <div className="flex w-[450px] max-w-full flex-col">
          <div className="mb-4 flex flex-row justify-between bg-blue-400 px-2 pt-2 text-white">
            <h1 className="text-2xl">{title}</h1>
            <button
              onClick={closeDialog}
              className="mb-2 h-8 w-8 cursor-pointer rounded border-none px-2 py-1 font-bold text-white hover:bg-blue-100 hover:text-blue-400"
            >
              X
            </button>
          </div>
          <div className="px-1 pb-2">
            <OrderAssessments onSave={savingNewOrder} onCancel={closeDialog} />
            {/*                     <div className="flex flex-row justify-end mt-2">
                        <button className="bg-green-500 py-1 px-2 rounded border-none">Ok</button>
                    </div>
 */}{' '}
          </div>
        </div>
      </dialog>
    ) : null;

  return dialog;
}

function OrderAssessments({ onSave, onCancel }: { onSave: (newOrder: Cpl_Decision) => void; onCancel: () => void }) {
  const today: Date = new Date();
  const [year, month, day] = [today.getFullYear(), today.getMonth(), today.getDate()];

  const [selectedDocument, setSelectedDocument] = useState<string>('');
  const [frekvens, setFrekvens] = useState<string>('');
  const [startDayNo, setStartDayNo] = useState<number>(dayNoFromDate(new Date(year, month, day)));
  const [selectedWeekDay, setSelectedWeekDay] = useState<number>(0); // 0:Monday --> 6: sunday

  const forms: string[] = ['Crohns sykdom', 'Ulcerøs kolitt', 'IBD-kontroll'];
  const formCategories: string[] = [
    'Cpl_CrohnSelfAssessment',
    'Cpl_UlcColSelfAssessment',
    'Cpl_IBDQuestSelfAssessment',
  ];

  const saveOrder = (newDocument: string) => {
    const recurrenceTag: string = 'RecurrenceParams_Tag#1';
    const DayNo3000 = 401767;

    //const beginDayNo = DbDayFromDate(new Date(year, month, day));
    const docIx = forms.findIndex((i) => i === newDocument);
    if (docIx === -1) return;
    const docCat = formCategories[docIx];

    const recurrenceParam: Cpl_Decision_RecurrenceParams = {
      tag: recurrenceTag,
      recurrencePattern: frekvens, // set
      recurrenceType: '', // not set
      recurrenceCycle: 0, // not set
      recurrenceOffset: 0,
      years: [],
      quarters: [],
      yearDays: [],
      months: [],
      monthDays: [],
      weeks: [],
      weekDays: [],
    };

    const recurrentTask: Cpl_Decision_RecurrentTask = {
      recurrenceTag,
      dayNoOffset: selectedWeekDay,
      daySegment: 'Morning', // ZZZ
      timeOffset: 0,
      alertOffset: 0,
      duration: 5,
      state: '',
      calendarInfo: '',
      autoSavedTasks: [],
    };

    const component: Cpl_Decision_Component = {
      beginDayNo: startDayNo ?? 0,
      endRecurrenceDayNo: DayNo3000,
      recurrenceInterval: 0,
      recurrenceIntervalOption: '',
      recurrenceIntervalOffset: 0,
      savedTasksOffset: 0,
      description: '',
      recurrentTasks: [recurrentTask],
      savedTasks: [],
    };

    const newOrder: Cpl_Decision = {
      patientId: '',
      category: docCat,
      recurrenceParams: [recurrenceParam],
      components: [component],
      computedTasks: [],
      // a lot of crap from base structures:
      mailMessages: [],
      text: { status: '', div: '' }, // Dette her blir for dumt. Den er frivillig i FHIR
      contained: [],
      extension: [],
      modifierExtension: [],
      id: '',
      meta: { versionId: '', profile: [], security: [], tag: [] },
      implicitRules: 'https://capableapi.azurewebsites.net/ImplicitRules', // ZZZ No meaning whatsoever, but follows URI-syntax
      language: '',
    };

    onSave(newOrder);
  };

  const checkDisabledSave = (): boolean => {
    if (selectedDocument === '' || frekvens === '' || startDayNo === 0 || selectedWeekDay < 0) return true;
    else return false;
  };

  const presentWeekCheckboxes = () => {
    const Weekd: string[] = ['Ma', 'Ti', 'On', 'To', 'Fr', 'Lø', 'Sø'];
    const anyDayStyle = 'px-px flex flex-col items-center';
    const btnstyle = 'my-px text-sm font-medium leading-6 text-gray-900 bg-gray-200 h-11 w-6 align-top';

    const firstDay = weekDayFromDbDay(startDayNo ?? 0); // dbDayNo starter på 1900/1/1

    const getStartSymbol = (checkDay: number, firstDay: number | undefined): JSX.Element => {
      return checkDay === firstDay ? tinyrightarrow2() : nothing1();
    };

    const presentDayCheck = (index: number): ReactNode => {
      // arrayId starting at 0, Index: 0=Mandag, 1=Tirsdag ...
      if (selectedWeekDay === index) return checkMark1();
      else return <></>;
    };

    return (
      <>
        <div className="flex flex-col items-start bg-gray-100 text-sm sm:flex-row sm:items-center">
          <div className="mr-1 pr-2">Ukedag for utfylling</div>
          <div className="flex flex-row items-center">
            <div className={anyDayStyle}>
              <div className="self-start">{getStartSymbol(0, firstDay)}</div>
              <button className={btnstyle} onClick={() => setSelectedWeekDay(0)}>
                {Weekd[0 % 7]}
                <div className="h-5">{presentDayCheck(0)}</div>
              </button>
              <div className="self-end">{nothing1()}</div>
            </div>
            <div className={anyDayStyle}>
              <div className="self-start">{getStartSymbol(1, firstDay)}</div>
              <button className={btnstyle} onClick={() => setSelectedWeekDay(1)}>
                {Weekd[1]}
                <div className="h-5">{presentDayCheck(1)}</div>
              </button>
              <div className="self-end">{nothing1()}</div>
            </div>
            <div className={anyDayStyle}>
              <div className="self-start">{getStartSymbol(2, firstDay)}</div>
              <button className={btnstyle} onClick={() => setSelectedWeekDay(2)}>
                {Weekd[2]}
                <div className="h-5">{presentDayCheck(2)}</div>
              </button>
              <div className="self-end">{nothing1()}</div>
            </div>
            <div className={anyDayStyle}>
              <div className="self-start">{getStartSymbol(3, firstDay)}</div>
              <button className={btnstyle} onClick={() => setSelectedWeekDay(3)}>
                {Weekd[3]}
                <div className="h-5">{presentDayCheck(3)}</div>
              </button>
              <div className="self-end">{nothing1()}</div>
            </div>
            <div className={anyDayStyle}>
              <div className="self-start">{getStartSymbol(4, firstDay)}</div>
              <button className={btnstyle} onClick={() => setSelectedWeekDay(4)}>
                {Weekd[4]}
                <div className="h-5">{presentDayCheck(4)}</div>
              </button>
              <div className="self-end">{nothing1()}</div>
            </div>
            <div className={anyDayStyle}>
              <div className="self-start">{getStartSymbol(5, firstDay)}</div>
              <button className={btnstyle} onClick={() => setSelectedWeekDay(5)}>
                {Weekd[5]}
                <div className="h-5">{presentDayCheck(5)}</div>
              </button>
              <div className="self-end">{nothing1()}</div>
            </div>
            <div className={anyDayStyle}>
              <div className="self-start">{getStartSymbol(6, firstDay)}</div>
              <button className={btnstyle} onClick={() => setSelectedWeekDay(6)}>
                {Weekd[6]}
                <div className="h-5">{presentDayCheck(6)}</div>
              </button>
              <div className="self-end">{nothing1()}</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const labelstyle = 'block text-sm font-medium leading-6 text-gray-400';
  /*   const inputtextstyle =
    "block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6";
 */ const inputtextstyle =
    'mx-2 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6';
  return (
    <div className="mx-auto max-w-xl rounded-xl bg-white shadow-lg">
      <div className="m5 p2 text-lg font-medium text-gray-400">Velg egenvurderingsskjema og utfyllingsfrekvens</div>
      <label htmlFor="selecteddocument" className={labelstyle}>
        Dokument
        <select
          className={inputtextstyle}
          value={selectedDocument}
          onChange={(e) => setSelectedDocument(e.target.value)}
          id="selecteddocument"
        >
          <option key="">{''}</option>
          {forms.map((item) => (
            <option key={item}>{item}</option>
          ))}
        </select>
      </label>

      <div className="my-2 bg-gray-100 text-sm font-medium text-gray-900">
        <label>
          Oppstart
          <input
            className="mx-2 mb-2 bg-gray-100 pl-2 text-blue-600 disabled:text-gray-400"
            value={getDateControlFormattedDateFromDbDayNo(startDayNo)}
            onChange={(e) => setStartDayNo(dbDayFromDateCtrlString(e.target.value))}
            type="date"
          ></input>
        </label>
        <div className="flex flex-row items-center">
          <label>
            Utfylling hver uke
            <input
              className="mx-1 translate-y-0.5"
              type="radio"
              onChange={() => setFrekvens('Weeks*1')}
              checked={frekvens === 'Weeks*1'}
              name="prescrtype"
            />
          </label>
          <label className="mx-2">
            Annen hver uke
            <input
              className="mx-1 translate-y-0.5"
              type="radio"
              onChange={() => setFrekvens('Weeks*2')}
              checked={frekvens === 'Weeks*2'}
              name="prescrtype"
            />
          </label>
        </div>
      </div>
      <div>{presentWeekCheckboxes()}</div>
      <div className="flex flex-row justify-end gap-4">
        <Button variant="secondary" onClick={onCancel}>
          Avbryt
        </Button>
        <Button
          className="hover:bg-blue-700 disabled:border-none disabled:bg-gray-400"
          disabled={checkDisabledSave()}
          variant="primary"
          onClick={() => {
            saveOrder(selectedDocument);
          }}
        >
          Lagre avtale
        </Button>
      </div>
    </div>
  );
}
