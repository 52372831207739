import { sortBy } from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { useAddNotification } from '../lib/notifications/NotificationContext';
import { useUserContext } from '../lib/UserContext';
import settings from '../settings';
import { Cpl_User } from '../types/Cpl_User';
import ObjectContainer from '../types/ObjectContainer';
import { User } from '../types/User';
import { RecursivePartial } from '../types/utils';
import { AttachmentInput, MailMessageAddressUser } from './../types/misc';
import { NewUserType } from './../types/User';
import Fetch, { Result } from './fetch';

export const useFetchUsers = () => {
  const { addError } = useAddNotification();

  return useQuery<Result<Cpl_User[]>, { message: string }, Cpl_User[]>(
    ['fetch users'],
    async () => {
      const url = `${settings.apiUrl}/User`;

      const response = await Fetch.get<Cpl_User[]>({ url });

      return {
        ...response,
        data: response.data ?? [],
      };
    },
    {
      select: (data) => sortBy(data.data, (item) => item.displayName),
      onError: () => addError({ message: 'Klarte ikke å hente brukere' }),
    }
  );
};

async function fetchTestUsers() {
  const url = `${settings.apiUrl}/User/TestUsers`;

  const response = await Fetch.get<MailMessageAddressUser[]>({ url });

  return response;
}

async function fetchGrantedUsers(patientId?: string) {
  const url = patientId
    ? `${settings.apiUrl}/User/GrantedUsers?patientId=${patientId}`
    : `${settings.apiUrl}/User/TestUsers`;

  const response = await Fetch.get<MailMessageAddressUser[]>({ url });

  return response;
}

async function fetchUsersForAttachment(attachment: AttachmentInput) {
  let url = `${settings.apiUrl}/User/AttachmentUsers?refResourceClass=${attachment.resourceReference.refResourceClass}&refLogicalId=${attachment.resourceReference.refLogicalId}`;
  if (attachment.resourceReference.refResourceParamA) {
    url += `&refResourceParamA=${attachment.resourceReference.refResourceParamA}`;
  }
  if (attachment.resourceReference.refResourceParamB) {
    url += `&refResourceParamB=${attachment.resourceReference.refResourceParamB}`;
  }

  const response = await Fetch.get<MailMessageAddressUser[]>({ url });

  return response;
}

async function fetchUser(id: string) {
  const url = `${settings.apiUrl}/User/${id}?MetaInfo=true`;

  const response = await Fetch.get<ObjectContainer>({ url });

  return {
    ...response,
    data: {
      user: response.data?.users?.[0],
      patient: response.data?.patients?.[0],
    },
  };
}

async function updateUser(user: any) {
  const url = `${settings.apiUrl}/User?id=${user.id}&MetaInfo=true`;

  const response = await Fetch.put<ObjectContainer>({ url, body: user });

  return {
    ...response,
    data: response.data?.users?.[0],
  };
}

export const useUpdateUser = () => {
  const { loggedInUser } = useUserContext();

  return useMutation(async (user: RecursivePartial<Cpl_User>) => {
    const url = `${settings.apiUrl}/User?id=${user?.id ?? loggedInUser?.user.id}&MetaInfo=true`;

    const response = await Fetch.put<ObjectContainer>({
      url,
      body: {
        ...loggedInUser?.user,
        ...user,
      },
    });

    return {
      ...response,
      data: response.data?.users?.[0],
    };
  });
};

async function setUserAttribute(userId: string, key: string, value: string | boolean | number) {
  const url = `${settings.apiUrl}/User/set-userAttribute?userId=${userId}&key=${key}&value=${value}`;

  const response = await Fetch.put<ObjectContainer>({ url });

  return {
    ...response,
    data: response.data?.users?.[0],
  };
}

type UserAttribute = {
  key: string;
  value: string | boolean | number;
};

async function setUserAttributes(userId: string, attributes: UserAttribute[]) {
  const url = `${settings.apiUrl}/User/set-userAttributes${userId ? `?userId=${userId}` : ''}`;

  const response = await Fetch.put<ObjectContainer>({ url, body: attributes });

  return {
    ...response,
    data: response.data?.users?.[0],
  };
}

export const useSetUserAttributes = () => {
  return useMutation(async (attributes: UserAttribute[], userId?: string) => {
    const url = `${settings.apiUrl}/User/set-userAttributes${userId ? `?userId=${userId}` : ''}`;

    const response = await Fetch.put<ObjectContainer>({ url, body: attributes });

    return {
      ...response,
      data: response.data?.users?.[0],
    };
  });
};

async function createUser(user: NewUserType) {
  const url = `${settings.apiUrl}/User/DtoPatientUser`;

  return Fetch.post<User>({ url, body: user });
}

const functions = {
  fetchTestUsers,
  fetchGrantedUsers,
  fetchUsersForAttachment,
  fetchUser,
  updateUser,
  createUser,
  setUserAttribute,
  setUserAttributes,
};

export default functions;
