import settings from '../settings';
import ObjectContainer from '../types/ObjectContainer';
import { _DtoContainer } from '../types/_DtoContainer';
import { Cpl_MedicationRequestCreateParams, Cpl_MedicationRequestEditParams } from './../types/Cpl_FEST';
import { MedicationRequest } from './../types/MedicationRequest';
import Fetch from './fetch';

async function fetchMedications(patientId: string) {
  const url = `${settings.apiUrl}/MedicationRequest/Patient/${patientId}?MetaInfo=true`;
  const response = await Fetch.get<_DtoContainer>({ url });

  return {
    ...response,
    data: response.data?.medicationRequests ?? [],
  };
}

async function fetchMedication(id: string) {
  const url = `${settings.apiUrl}/MedicationRequest/${id}?MetaInfo=true`;
  const response = await Fetch.get<ObjectContainer>({ url });

  return {
    ...response,
    data: response.data?.medicationRequests?.[0],
  };
}

// type CreateMedicationRequestParams = {
//   patientId: string;
//   legemiddelpakning: Cpl_Legemiddelpakning;
//   bruksomrade?: Cpl_Bruksomrade;
//   dosering?: Cpl_Dosering;
//   forholdsregel?: Cpl_Forholdsregel;
//   administrasjonsvei?: Cpl_Administrasjonsvei;
//   administreringsdetaljer?: Cpl_Administreringsdetaljer;
// };

// const createMedicationRequestBody = ({
//   patientId,
//   legemiddelpakning,
//   bruksomrade,
//   dosering,
//   forholdsregel,
//   administreringsdetaljer,
//   administrasjonsvei,
// }: CreateMedicationRequestParams): RecursivePartial<Fhir_MedicationRequest> => {
//   return {
//     id: '',
//     status: 'active',
//     medication: {
//       extension: [
//         {
//           uri: 'urn:oid:2.16.578.1.12.4.1.1.7421',
//           code: legemiddelpakning.reseptgruppe_V,
//           valueCode: legemiddelpakning.reseptgruppe_V,
//         },
//       ],
//       identifier: [
//         {
//           type: {
//             text: 'legemiddelpakning.varenr',
//           },
//           system: 'https://medapi.capable.healthcare/api/Legemiddelpakning',
//           value: legemiddelpakning.varenr,
//         },
//       ],
//       code: {
//         coding: [
//           {
//             system: 'http://ehelse.no/fhir/CodeSystem/FEST',
//             version: '',
//             code: legemiddelpakning.legemiddelpakningsId,
//             display: legemiddelpakning.navnFormStyrke,
//             userSelected: true,
//           },
//           {
//             system: 'http://www.whocc.no/atc',
//             version: '',
//             code: legemiddelpakning.atC_V,
//             display: legemiddelpakning.atC_DN,
//             userSelected: true,
//           },
//         ],
//         text: legemiddelpakning.navnFormStyrke,
//       },
//       manufacturer: {
//         display: legemiddelpakning.produsent,
//       },
//       form: {
//         coding: [
//           {
//             system: 'urn:oid:2.16.578.1.12.4.1.1.7448',
//             version: '',
//             code: legemiddelpakning.legemiddelformKort_V,
//             display: legemiddelpakning.legemiddelformKort,
//             userSelected: true,
//           },
//         ],
//         text: legemiddelpakning.legemiddelformKort, // aner ikke om dette er riktig
//       },
//     },
//     subject: {
//       reference: `Patient/${patientId}`,
//     },
//     reasonCode: [
//       {
//         coding: [
//           {
//             system: bruksomrade?.dictionary,
//             version: '',
//             code: bruksomrade?.id,
//             display: bruksomrade?.bruksomrade,
//             userSelected: true,
//           },
//         ],
//         text: bruksomrade?.bruksomrade,
//       },
//     ],
//     dosageInstruction: [
//       {
//         text: dosering?.doseringLang,
//         timing: {
//           code: {
//             text: dosering?.doseringLang,
//             coding: [
//               {
//                 system: dosering?.doseDictionary,
//                 version: '',
//                 code: dosering?.code7478_Dosering,
//                 display: dosering?.doseringLang,
//                 userSelected: true,
//               },
//             ],
//           },
//         },
//         additionalInstruction: [
//           {
//             text: forholdsregel?.forholdsregel,
//             coding: [
//               {
//                 system: forholdsregel?.dictionary,
//                 version: '',
//                 code: forholdsregel?.id,
//                 display: forholdsregel?.forholdsregel,
//                 userSelected: true,
//               },
//             ],
//           },
//         ],
//         route: {
//           text: administrasjonsvei?.administrasjonsvei,
//           coding: [
//             {
//               system: administrasjonsvei?.dictionary,
//               version: '',
//               code: administrasjonsvei?.id,
//               display: administrasjonsvei?.administrasjonsvei,
//               userSelected: true,
//             },
//           ],
//         },
//       },
//     ],
//     dispenseRequest: {
//       validityPeriod: {
//         start: new Date().toISOString(),
//         end: addYears(new Date(), 1).toISOString(),
//       },
//       numberOfRepeatsAllowed: 3,
//       quantity: {
//         value: stringToInt(legemiddelpakning.pakningsstr),
//         unit: legemiddelpakning.enhetPakning_V,
//         system: legemiddelpakning.dictionaryLegemiddelformKort,
//         code: legemiddelpakning.legemiddelformKort_V,
//       },
//     },
//   };
// };

async function createMedicationRequest(patientId: string, params: Cpl_MedicationRequestCreateParams) {
  const url = `${settings.apiUrl}/MedicationRequest/${patientId}/CreateParams`;
  const response = await Fetch.post<MedicationRequest>({ url, body: params });

  return response;
}

async function updateMedicationRequest(medicationRequestId: string, params: Cpl_MedicationRequestEditParams) {
  console.log(`params`, params);
  const url = `${settings.apiUrl}/MedicationRequest/${medicationRequestId}/EditParams`;
  const response = await Fetch.put<MedicationRequest>({
    url,
    body: params,
  });

  return response;
}

export default {
  fetchMedications,
  fetchMedication,
  createMedicationRequest,
  updateMedicationRequest,
};
