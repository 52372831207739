// https://stackoverflow.com/questions/105034/how-to-create-guid-uuid

const uuidv4_crypto = () => {
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) => {
    const c2 = parseInt(c, 10);
    return (c2 ^ (window.crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c2 / 4)))).toString(16);
  });
};

const uuidv4_math_random = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const uuid = () => {
  if (window.crypto) {
    return uuidv4_crypto();
  } else {
    return uuidv4_math_random();
  }
};

export default uuid;
