import { MailMessageAttachment } from '../../types/Messages';

export const createAttachmentUrl = (attachment: MailMessageAttachment) => {
  switch (attachment.resourceReference.refResourceClass) {
    case 'Fhir_Patient':
      return `/${attachment.resourceReference.refLogicalId}/hjem`;
    case 'Cpl_SleepObservationWeekSummary':
      return `/${attachment.resourceReference.refLogicalId}/sovn-oversikt/${attachment.resourceReference.refResourceParamA}`;
    default:
      return '';
  }
};
