import React from 'react';
import AuthClient from '../network/authClient';
import UserClient from '../network/UserClient';
import { Cpl_User } from '../types/Cpl_User';
import { Fhir_Patient } from '../types/Fhir_Patient';
import { createSessionCache, TTL } from './sessionCache';
import { hasRole, Role } from './userRoles';

type UserContextState = {
  loading: boolean;
  isLoggedIn: boolean;
  loggedInUser?: {
    user: Cpl_User;
    patient: Fhir_Patient;
    isAdmin: boolean;
  };
};

type UserContextType = UserContextState & {
  checkIfLoggedIn: () => Promise<void>;
  clearLoggedIn: () => void;
  hasRole: (role: Role) => boolean;
};

export const UserContext = React.createContext<UserContextType>({
  loading: true,
  isLoggedIn: false,
  loggedInUser: undefined,
  checkIfLoggedIn: () => Promise.resolve(),
  clearLoggedIn: () => {},
  hasRole: () => false,
});

export const useUserContext = () => React.useContext(UserContext);

const UserContextSessionStorage = createSessionCache<UserContextState>('UserContextState_v4', TTL.TEN_MINUTES);

export const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [userContext, setUserContext] = React.useState<UserContextState>(() => {
    return (
      UserContextSessionStorage.get('loggedInUser') ?? {
        loading: true,
        isLoggedIn: false,
      }
    );
  });

  const checkIfLoggedIn = React.useCallback(async () => {
    try {
      const authResult = await AuthClient.isLoggedIn();

      if (authResult.success) {
        const userResult = await UserClient.fetchUser(authResult.data!.id);

        const userContextState = {
          loading: false,
          isLoggedIn: true,
          loggedInUser: {
            user: userResult.data.user!,
            patient: userResult.data.patient!,
            isAdmin: hasRole(userResult.data.user?.userAttributes ?? [], 'SystemAdmin'),
          },
        };

        UserContextSessionStorage.store('loggedInUser', userContextState);

        setUserContext(userContextState);
      } else {
        setUserContext({ loading: false, isLoggedIn: false });
        return Promise.reject('Not logged in');
      }
    } catch (error) {
      console.error(error);
      setUserContext({ loading: false, isLoggedIn: false });
      throw error;
    }
  }, []);

  const clearLoggedIn = React.useCallback(() => {
    setUserContext({ loading: false, isLoggedIn: false });
    UserContextSessionStorage.clear('loggedInUser');
  }, []);

  const userHasRole = React.useCallback(
    (role: Role) => {
      if (userContext.loggedInUser) {
        return hasRole(userContext.loggedInUser.user.userAttributes, role);
      }
      return false;
    },
    [userContext]
  );

  React.useEffect(() => {
    checkIfLoggedIn();
  }, [checkIfLoggedIn]);

  const providedValue = React.useMemo(() => {
    return {
      ...userContext,
      checkIfLoggedIn,
      clearLoggedIn,
      hasRole: userHasRole,
    };
  }, [userContext, checkIfLoggedIn, clearLoggedIn, userHasRole]);

  return <UserContext.Provider value={providedValue}>{children}</UserContext.Provider>;
};
