import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Cpl_Decision, Cpl_Decision_Component_Medication } from '../../../types/Cpl_Decision';
import { Fhir_Coding } from '../../../types/Fhir_Element';
import { GH_Legemiddeldose, GH_PrescriptionNoFestDetails } from '../../../types/gh-types';
import AutofillDrugDose from './autofilldrugdose';
import { convertFreeTextPrescrToBackend, convertParamToBackend } from './convertDosering';
import { sortStyrke } from './medutility';
import PrescriptionDetails from './prescribedetailsbymerkevare';
import { Cpl_ProblemDecisionRelation } from '../../../types/Cpl_Decision';

type PrescribeNewDrugProps = {
  onSave: ({
    newPrescription,
    relations,
  }: {
    newPrescription: Cpl_Decision;
    relations: Cpl_ProblemDecisionRelation[];
  }) => void;
};

/* type PrescNewDrugProps = {
  onSave: (newPrescription: GH_Prescription) => void;
}; */

const PrescribeDetailDrugDose = () => {
  const saveFunc = () => {};

  return <PrescribeNewDrug2 onSave={saveFunc} />;
};

/* const convertLMDoseToPrescr = (
  lmiddose: GH_Legemiddeldose,
  details: GH_PrescriptionNoFestDetails,
): GH_Prescription => {
  const prescr: GH_Prescription = {
    legemiddelpakningsId: "",
    varenr: "",
    ean: "",
    legemiddelformKort: lmiddose?.legemiddelformKort_DN ?? "",
    dictionaryLegemiddelformKort: lmiddose?.legemiddelformKort_Kodeverk ?? "",
    varenavn: "",
    smak_DN: "",
    atC_V: lmiddose?.atc_V ?? "",
    atC_DN: lmiddose?.atc_DN ?? "",
    styrke_SAMM: "styrkesamm",
    virkestoffMedStyrke_Styrke_V: lmiddose?.styrke.toString() ?? "",
    virkestoffMedStyrke_Styrke_U: lmiddose?.styrkeEnhet ?? "",
    virkestoffMedStyrke_StyrkeNevner_V: lmiddose?.styrkeNevner.toString() ?? "",
    virkestoffMedStyrke_StyrkeNevner_U: lmiddose?.styrkeEnhetNevner ?? "",
    pakningsstr: "",
    enhetPakning_V: "",
    ddD_V: 0,
    ddD_U: "",
    statistikkfaktor: 0,
    packs: 0,

    ...lmiddose,
    ...details,

    id: -1, // overwrites same name attribute in lmiddose
    navnFormStyrke: lmiddose?.navnFormStyrke ?? "",
    refLegemiddelMerkevare: lmiddose?.refLegemiddelMerkevare ?? "",
  };

  return prescr;
}; */

export const PrescribeNewDrug2 = ({ onSave }: PrescribeNewDrugProps) => {
  const getCurDoses = (lmdoser: GH_Legemiddeldose[], curform: string, curstyrke: string): GH_Legemiddeldose[] => {
    return lmdoser.filter((item) => item.legemiddelformKort_DN === curform && styrke_pres(item) === curstyrke);
  };

  const legemiddelforms_Get = (lmdoser: GH_Legemiddeldose[]): string[] => {
    if (!lmdoser) return [];
    const doser: string[] = [...new Set(lmdoser.map((x) => x.legemiddelformKort_DN))];
    if (doser.length > 1) doser.unshift('');
    return doser;
  };

  const styrke_pres = useCallback((lmdose: GH_Legemiddeldose): string => {
    // UseCallback removed a "problem" ??
    if (!lmdose) return '';
    else return styrke_pres_int(lmdose.styrke, lmdose.styrkeEnhet, lmdose.styrkeNevner, lmdose.styrkeEnhetNevner);
  }, []);

  const legemiddelstyrke_Get = useCallback(
    (lmform: string, lmdoser: GH_Legemiddeldose[]): string[] => {
      if (!lmdoser) return [];
      const styrke: string[] = [
        ...new Set(
          lmdoser
            .filter((x) => x.legemiddelformKort_DN === lmform)
            .sort(sortStyrke)
            .map((x) => styrke_pres(x) ?? 'null')
        ),
      ];
      if (styrke.length > 1) styrke.unshift('');
      return styrke;
    },
    [styrke_pres]
  );

  const isSingleDoseStyrke = (lmform: string, lmdoser: GH_Legemiddeldose[]): boolean => {
    return legemiddelforms_Get(lmdoser).length === 1 && legemiddelstyrke_Get(lmform, lmdoser).length === 1;
  };

  const styrke_pres_int = (
    styrke: number,
    styrkeEnhet: string,
    styrkeNevner: number,
    styrkeEnhetNevner: string
  ): string => {
    const nevner: string = styrkeNevner === 0 ? '' : '/' + (styrkeNevner === 1 ? '' : styrkeNevner) + styrkeEnhetNevner;
    return styrke === 0 ? '' : styrke + styrkeEnhet + nevner;
  };

  const legemiddeldose_Get = (
    lmform: string,
    lmstyrke: string,
    lmdoser: GH_Legemiddeldose[]
  ): GH_Legemiddeldose | undefined => {
    return lmdoser ? lmdoser.find((x) => x.legemiddelformKort_DN === lmform && styrke_pres(x) === lmstyrke) : undefined;
  };

  const legemiddeldoser_count = (lmform: string, lmstyrke: string, lmdoser: GH_Legemiddeldose[]): number => {
    return lmdoser
      ? lmdoser.filter((x) => x.legemiddelformKort_DN === lmform && styrke_pres(x) === lmstyrke).length
      : 0;
  };

  const [legemiddeldoser, setLegemiddeldoser] = useState<GH_Legemiddeldose[]>([]);
  const [legemiddelform, setLegemiddelform] = useState<string>('');
  const [legemiddelstyrke, setLegemiddelstyrke] = useState<string>('');
  const [merkevarenavn, setMerkevarenavn] = useState<string>('');
  const [merkevareproducer, setMerkevareproducer] = useState<string>('');
  const [filledState, setFilledState] = useState<string>('initial'); // initial, styrke, form, finished (finished means styrke + close edit version)
  const [searchMode, setSearchMode] = useState<boolean>(true);
  const [initialSearchText, setInitialSearchText] = useState<string>('');
  const [debug, setDebug] = useState<boolean>(false);

  const onSelectedMed = (drugName: string, drugProducer: string, searchString: string) => {
    if (merkevarenavn !== '' && merkevarenavn === drugName && merkevareproducer === drugProducer) {
      // same as previous
      const curform: string = legemiddelforms_Get(legemiddeldoser)[0];
      const lmstyrkeList: string[] = legemiddelstyrke_Get(curform, legemiddeldoser);
      setLegemiddelform(curform);
      setLegemiddelstyrke(lmstyrkeList[0]);
      setFilledState(calculateFilledState(curform, lmstyrkeList));
    }
    setMerkevarenavn(drugName);
    setMerkevareproducer(drugProducer);
    setSearchMode(false);
    setInitialSearchText(searchString);
  };

  const onChangeForm = (drugForm: string) => {
    setLegemiddelform(drugForm);
    const lmsList: string[] = legemiddelstyrke_Get(drugForm, legemiddeldoser);
    setLegemiddelstyrke(lmsList[0]);
    setFilledState(calculateFilledState(drugForm, lmsList));
  };

  const onChangeStyrke = (styrke: string) => {
    setLegemiddelstyrke(styrke);
    setFilledState(styrke && styrke !== '' ? 'finished' : 'form');
  };

  const calculateFilledState = (lmform: string, lmstyrkeList: string[]): string => {
    return lmstyrkeList.length === 1 ? 'finished' : lmform && lmform.length === 1 ? 'form' : 'initial';
  };

  /*   const onPrescriptionDetailsReady = (
    prescrDetails: GH_PrescriptionNoFestDetails,
  ) => {
    const dos: GH_Legemiddeldose | undefined = legemiddeldose_Get(
      legemiddelform,
      legemiddelstyrke,
      legemiddeldoser,
    );
    if (dos) {
      const newPrescription: GH_Prescription = convertLMDoseToPrescr(
        dos,
        prescrDetails,
      );
      //onSave(newPrescription);
    }
  }; */

  const onPrescriptionDetailsReady2 = ({
    prescriptionDetails,
    relations,
  }: {
    prescriptionDetails: GH_PrescriptionNoFestDetails;
    relations: Cpl_ProblemDecisionRelation[];
  }) => {
    const dos: GH_Legemiddeldose | undefined = legemiddeldose_Get(legemiddelform, legemiddelstyrke, legemiddeldoser);
    if (dos) {
      // a drug is selected
      // create Cpl_Decision structure:
      if (prescriptionDetails) {
        let newDecision: Cpl_Decision | undefined = undefined;
        if (prescriptionDetails.doseringParams) newDecision = convertParamToBackend(prescriptionDetails.doseringParams);
        else if (prescriptionDetails.freeTextPrescrTimeParams)
          newDecision = convertFreeTextPrescrToBackend(prescriptionDetails.freeTextPrescrTimeParams);
        if (newDecision && newDecision.components && newDecision.components[0]?.componentMedication) {
          const newBruksomrade: Fhir_Coding | undefined = prescriptionDetails.bruksomrade
            ? {
                system: prescriptionDetails.bruksomradeIx ? 'http://ehelse.no/volven/7488' : undefined,
                version: undefined,
                code: prescriptionDetails.bruksomradeIx,
                display: prescriptionDetails.bruksomrade,
                userSelected: prescriptionDetails.bruksomradeIx ? true : false,
              }
            : undefined;
          const newForholdsregler: Fhir_Coding | undefined = prescriptionDetails.forholdsregler
            ? {
                system: prescriptionDetails.forholdsreglerIx ? 'http://ehelse.no/volven/7479' : undefined,
                version: undefined,
                code: prescriptionDetails.forholdsreglerIx,
                display: prescriptionDetails.forholdsregler,
                userSelected: prescriptionDetails.forholdsreglerIx ? true : false,
              }
            : undefined;
          const newAdministrasjonsvei: Fhir_Coding | undefined = prescriptionDetails.administrasjonsvei
            ? {
                system: prescriptionDetails.administrasjonsveiIx ? 'http://ehelse.no/volven/7477' : undefined,
                version: undefined,
                code: prescriptionDetails.administrasjonsveiIx,
                display: prescriptionDetails.administrasjonsvei,
                userSelected: prescriptionDetails.administrasjonsveiIx ? true : false,
              }
            : undefined;
          const newCourseOfTherapy: Fhir_Coding = {
            system: prescriptionDetails.status
              ? 'http://capable/CourseOfTherapy' // ZZZZ
              : undefined,
            version: undefined,
            code: prescriptionDetails.status,
            display: prescriptionDetails.status,
            userSelected: prescriptionDetails.status ? true : false,
          };
          const newComponentMedication: Cpl_Decision_Component_Medication = {
            state: '',
            medicationDisplayValue: dos.navnFormStyrke,
            legemiddeldose: dos,
            doseringsTekst: prescriptionDetails.dosering,
            bruksomrade: newBruksomrade,
            forholdsregler: newForholdsregler,
            administrasjonsvei: newAdministrasjonsvei,
            courseOfTherapy: newCourseOfTherapy,
            legemiddelpakningKilde: '',
            legemiddelpakningId: dos.refPakning,
          };

          // ZZZ Replace null values with blank:
          if (dos && newComponentMedication.legemiddeldose?.pakningstype_DN === null)
            newComponentMedication.legemiddeldose.pakningstype_DN = '';
          if (dos && newComponentMedication.legemiddeldose?.enhetOrdinering_DN === null)
            newComponentMedication.legemiddeldose.enhetOrdinering_DN = '';
          if (dos && newComponentMedication.legemiddeldose?.enhetOrdinering_DN_Plural === null)
            newComponentMedication.legemiddeldose.enhetOrdinering_DN_Plural = '';
          if (dos && newComponentMedication.legemiddeldose?.styrkeEnhetNevner === null)
            newComponentMedication.legemiddeldose.styrkeEnhetNevner = '';

          newDecision.components[0].componentMedication = newComponentMedication;

          onSave({ newPrescription: newDecision, relations });
        }
      }
    }
  };

  const onPrescriptionDetailsCancel = () => {
    setSearchMode(true);
  };

  useEffect(() => {
    const postUri =
      merkevareproducer && merkevareproducer !== '' ? '&produsent=' + encodeURIComponent(merkevareproducer) : '';
    if (merkevarenavn)
      fetch(
        'https://capablemedapi.azurewebsites.net/api/LegemiddelDose/FetchByMerkevareName?merkevareNavn=' +
          encodeURIComponent(merkevarenavn) +
          postUri
      )
        //fetch('api/LegemiddelDose/FetchByName/'+ searchText)
        .then((res) => res.json())
        .then((data: GH_Legemiddeldose[]) => {
          setLegemiddeldoser(data);
          const curform: string = legemiddelforms_Get(data)[0];
          const lmstyrkeList: string[] = legemiddelstyrke_Get(curform, data);
          setLegemiddelform(curform);
          setLegemiddelstyrke(lmstyrkeList[0]);
          setFilledState(calculateFilledState(curform, lmstyrkeList));
        });
    else setLegemiddeldoser([]);
  }, [legemiddelstyrke_Get, merkevarenavn, merkevareproducer]); // ZZZ Fikk feilmelding når legemiddelstyrke_Get m,anglet i dependency-listen. Skjønner ikke det

  const presentLegemiddelDoser = (meds: GH_Legemiddeldose[] | undefined) => {
    return (
      <div>
        {meds?.map((med) => {
          return presentLegemiddelDose(med);
        })}
      </div>
    );
  };

  const presentLegemiddelDose = (med: GH_Legemiddeldose) => {
    return (
      <table key={med?.lmrLopenr} className="my-5 rounded-xl bg-gray-100 px-2 shadow-lg ">
        <tbody>
          <tr>
            <td>Id = </td>
            <td>{med?.doseId}</td>
          </tr>
          <tr>
            <td>LMR = </td>
            <td>{med?.lmrLopenr}</td>
          </tr>
          <tr className="bg-gray-200">
            <td>NavnFormStyrke = </td>
            <td>{med?.navnFormStyrke}</td>
          </tr>
          <tr>
            <td>Produsent = </td>
            <td>{med?.produsent}</td>
          </tr>
          <tr className="">
            <td>Legemiddelform_Lang = </td>
            <td>{med?.legemiddelformLang}</td>
          </tr>
          <tr>
            <td>LegemiddelformKort_Beskrivelse = </td>
            <td>{med?.legemiddelformKort_Beskrivelse}</td>
          </tr>
          <tr>
            <td>LegemiddelformKort_DN = </td>
            <td>{med?.legemiddelformKort_DN}</td>
          </tr>
          <tr>
            <td>LegemiddelformKort_V = </td>
            <td>{med?.legemiddelformKort_V}</td>
          </tr>
          <tr>
            <td>Preparattype_DN = </td>
            <td>{med?.preparattype_DN}</td>
          </tr>
          <tr>
            <td>SvartTrekant_DN = </td>
            <td>{med?.svartTrekant_DN}</td>
          </tr>
          <tr>
            <td>Varseltrekant = </td>
            <td>{med?.varseltrekant === true ? 'true' : med?.varseltrekant === false ? 'false' : 'null'}</td>
          </tr>
          <tr>
            <td>ATC_V = </td>
            <td>{med?.atc_V}</td>
          </tr>
          <tr>
            <td>ATC_DN = </td>
            <td>{med?.atc_DN}</td>
          </tr>
          <tr className="bg-gray-200">
            <td>Mengde = </td>
            <td>
              {med?.mengde_V}
              {med?.mengde_U}
            </td>
          </tr>
          <tr>
            <td>mengde_U = </td>
            <td>{med?.mengde_U}</td>
          </tr>
          <tr>
            <td>mengde_V = </td>
            <td>{med?.mengde_V}</td>
          </tr>
          <tr>
            <td>pakningstype_DN = </td>
            <td>{med?.pakningstype_DN}</td>
          </tr>
          <tr>
            <td>rowsInEnhetOrdinering = </td>
            <td>{med?.rowsInEnhetOrdinering}</td>
          </tr>
          <tr>
            <td>enhetOrdinering_V = </td>
            <td>{med?.enhetOrdinering_V}</td>
          </tr>
          <tr>
            <td>enhetOrdinering_DN = </td>
            <td>{med?.enhetOrdinering_DN}</td>
          </tr>
          <tr>
            <td>enhetOrdinering_Kodeverk = </td>
            <td>{med?.enhetOrdinering_Kodeverk}</td>
          </tr>
          <tr>
            <td>merkevarenavn = </td>
            <td>{med?.merkevarenavn}</td>
          </tr>
          <tr>
            <td>virkestoffNavn1 = </td>
            <td>{med?.virkestoffNavn1}</td>
          </tr>
          <tr>
            <td>virkestoffNavn1Engelsk = </td>
            <td>{med?.virkestoffNavn1Engelsk}</td>
          </tr>
          <tr>
            <td>noOfVirkestoff = </td>
            <td>{med?.noOfVirkestoff}</td>
          </tr>
          <tr className="bg-gray-200">
            <td>Styrke = </td>
            <td>
              {styrke_pres_int(med?.styrke ?? 0, med?.styrkeEnhet, med?.styrkeNevner ?? 0, med?.styrkeEnhetNevner)}
            </td>
          </tr>
          <tr>
            <td>styrke = </td>
            <td>{med?.styrke}</td>
          </tr>
          <tr>
            <td>styrkeEnhet = </td>
            <td>{med?.styrkeEnhet}</td>
          </tr>
          <tr>
            <td>styrkeNevner = </td>
            <td>{med?.styrkeNevner}</td>
          </tr>
          <tr>
            <td>styrkeEnhetNevner = </td>
            <td>{med?.styrkeEnhetNevner}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const labelstyle = 'block text-sm font-medium leading-6 text-gray-400';
  const inputtextstyle =
    'block w-full rounded-md border-0 py-1.5 pl-1 pr-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6';

  return (
    <>
      {searchMode && (
        <div className="mx-auto max-w-xl rounded-xl bg-white shadow-lg">
          <div className="m5 p2 text-sm font-medium text-gray-400">Velg legemiddel</div>
          <AutofillDrugDose onSelection={onSelectedMed} prefill={initialSearchText}></AutofillDrugDose>
        </div>
      )}

      {!searchMode && filledState !== 'finished' && (
        <div className="mx-auto max-w-xl rounded-xl bg-white shadow-lg">
          <label htmlFor="merkevarenavn" className={labelstyle}>
            Legemiddel
            <select
              className={inputtextstyle}
              value={merkevarenavn}
              onChange={(e) => setMerkevarenavn(e.target.value)}
              id="merkevarenavn"
            >
              <option>{merkevarenavn}</option>
            </select>
          </label>

          <label htmlFor="legemiddelform" className={labelstyle}>
            Form
            <select
              className={inputtextstyle}
              value={legemiddelform}
              onChange={(e) => {
                onChangeForm(e.target.value);
              }}
              id="legemiddelform"
            >
              {legemiddelforms_Get(legemiddeldoser).map((x) => (
                <option key={x}>{x}</option>
              ))}
            </select>
          </label>

          <label htmlFor="styrke" className={labelstyle}>
            Styrke
            <select
              className={inputtextstyle}
              value={legemiddelstyrke}
              onChange={(e) => onChangeStyrke(e.target.value)}
              id="styrke"
            >
              {legemiddelstyrke_Get(legemiddelform, legemiddeldoser).map((x) => (
                <option key={x}>{x}</option>
              ))}
            </select>
          </label>

          {filledState !== 'styrke' && (
            <div className="flex flex-row justify-end">
              <button
                className="my-2 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
                onClick={() => {
                  setSearchMode(true);
                  setFilledState('initial');
                }}
              >
                Avbryt, søk videre
              </button>
            </div>
          )}
        </div>
      )}

      {!searchMode && filledState === 'finished' && (
        <div className="mx-auto max-w-xl rounded-xl bg-white shadow-lg">
          <span>
            <div className={labelstyle + ' mr-6 inline'}>Legemiddel</div>
            {!isSingleDoseStyrke(legemiddelform, legemiddeldoser) && (
              <button className={labelstyle + ' inline hover:underline'} onClick={() => setFilledState('styrke')}>
                Endre form/styrke
              </button>
            )}
          </span>
          <div className={inputtextstyle}>
            {merkevarenavn} {legemiddelform} {legemiddelstyrke}
          </div>
        </div>
      )}

      <div>
        {filledState === 'styrke' && (
          <div>Antall doser = {legemiddeldoser_count(legemiddelform, legemiddelstyrke, legemiddeldoser)}</div>
        )}

        {!searchMode && filledState === 'finished' && (
          <div className="bg-gray-100 text-sm font-medium text-gray-900">
            <label className="align-bottom">
              Debug dose
              <input
                className="mx-1"
                type="checkbox"
                onChange={() => setDebug(!debug)}
                checked={debug === true}
                name="debug"
              />
            </label>
            {debug && (
              <div className="mb-2">
                {presentLegemiddelDoser(getCurDoses(legemiddeldoser, legemiddelform, legemiddelstyrke))}
              </div>
            )}
          </div>
        )}

        {/*         {(filledState === "styrke") && PresentLegemiddel(legemiddeldoser_Get(legemiddelform, legemiddelstyrke, legemiddeldoser))}
         */}
        {!searchMode && filledState === 'finished' && (
          <PrescriptionDetails
            merkevareId={
              legemiddeldose_Get(legemiddelform, legemiddelstyrke, legemiddeldoser)?.refLegemiddelMerkevare ?? ''
            }
            onSubmit={onPrescriptionDetailsReady2}
            onCancel={onPrescriptionDetailsCancel}
          />
        )}
      </div>
    </>
  );
};

export default PrescribeDetailDrugDose;
