import React from 'react';
import ignoreException from './ignoreException';

function useSessionStorageState<T>(uniqueKey: string, initialValue: T): [T, (value: T) => void] {
  const storageKey = `SessionStorageState:${uniqueKey}`;

  const [value, setValueReal] = React.useState(() => {
    const storedValue = ignoreException(() => window.sessionStorage.getItem(storageKey), null);
    return storedValue ? (JSON.parse(storedValue) as T) : initialValue;
  });

  const setValue = React.useCallback(
    (value: T) => {
      setValueReal(value);
      ignoreException(() => window.sessionStorage.setItem(storageKey, JSON.stringify(value)), null);
    },
    [storageKey]
  );

  return [value, setValue];
}

export default useSessionStorageState;
