/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import React, { FC, useCallback, useMemo, useState } from 'react';
import Block from '../../components/Block';
import Button from '../../components/Button2';
import { Datalist } from '../../components/Datalist';
import { Column, Row } from '../../components/Flex';
import Grid from '../../components/Grid';
import Heading from '../../components/Heading';
import Loader from '../../components/loader/Loader';
import { Modal, ModalTrigger } from '../../components/Modal';
import AuthedPageLayout from '../../components/pagelayout/AuthedPageLayout';
import Text from '../../components/Text';
import TextInput from '../../components/TextInput';
import { useT } from '../../i18n/useT';
import { useAddNotification } from '../../lib/notifications/NotificationContext';
import useSelectedPatientId from '../../lib/useSelectedPatientId';
import MedicationClient from '../../network/MedicationClient';
import {
  Cpl_Administrasjonsvei,
  Cpl_Administreringsdetaljer,
  Cpl_Bruksomrade,
  Cpl_CourseOfTherapy,
  Cpl_Dosering,
  Cpl_Forholdsregel,
  Cpl_Legemiddelpakning,
} from '../../types/Cpl_FEST';
import { buttonReset } from '../../utils/styles';
import theme from '../../utils/theme';
import { CourseOfTherapySelector } from './CourseOfTherapySelector';
import { PackageLeafletModal } from './PackageLeafletModal';
import { useFetchDosering, useSearchMedicationApi } from './useSearchMedicationApi';

const PageContent = () => {
  const t = useT();
  const [query, setQuery] = useState('');
  const { data, isLoading, isSuccess } = useSearchMedicationApi(query);
  const [selectedLegemiddelpakningsId, setSelectedLegemiddelpakningsId] = useState<string | undefined>(undefined);
  const selctedLegemiddelpakning = data.find((item) => item.legemiddelpakningsId === selectedLegemiddelpakningsId);

  return (
    <Column>
      <Heading level={2} marginBottom={20}>
        {t('Add medication')}
      </Heading>
      <label style={{ marginBottom: 16 }}>
        <Column>
          <Text strong marginBottom={8}>
            {t('Search for medications')}
          </Text>
          <TextInput value={query} onValue={setQuery} placeholder={t('Search by name, article number or EAN-number')} />
        </Column>
      </label>
      {isLoading && <Loader />}
      {query.length === 0 && (
        <Row horizontal="center">
          <Text>{t('Start typing to search...')}</Text>
        </Row>
      )}
      {isSuccess && (
        <Row
          css={css`
            margin-bottom: 16px;
          `}
        >
          {data.length === 0 ? (
            <Text>{t('No results')}</Text>
          ) : (
            <Text>
              {/* @ts-ignore */}
              {data.length === 0 ? t('Showing one result') : t('Showing {{value}} results', { value: data.length })}
            </Text>
          )}
        </Row>
      )}

      <Grid
        css={css`
          grid-gap: 16px;
        `}
      >
        {data.map((result) => {
          return (
            <MedicationItem
              key={result.legemiddelpakningsId}
              medication={result}
              onLegemiddelClick={() => {
                setSelectedLegemiddelpakningsId(result.legemiddelpakningsId);
              }}
            />
          );
        })}
      </Grid>
      {selctedLegemiddelpakning && (
        <SelectedLegemiddelPakningModal
          selctedLegemiddelpakning={selctedLegemiddelpakning}
          onDismiss={() => setSelectedLegemiddelpakningsId(undefined)}
        />
      )}
    </Column>
  );
};

type FormProps = {
  selctedLegemiddelpakning: Cpl_Legemiddelpakning;
  data: {
    dosering: Cpl_Dosering[];
    bruksomrader: Cpl_Bruksomrade[];
    forholdsregler: Cpl_Forholdsregel[];
    administrasjonsveier: Cpl_Administrasjonsvei[];
    administrasjonsdetaljer: Cpl_Administreringsdetaljer[];
  };
};
const Form = ({ selctedLegemiddelpakning, data }: FormProps) => {
  const t = useT();
  const patientId = useSelectedPatientId();
  const { addError } = useAddNotification();
  const navigate = useNavigate();

  const [doseringString, setDoseringString] = useState<string>(
    data.dosering.length === 1 ? data.dosering[0].doseringLang : ''
  );
  const dosering = useMemo(
    () =>
      data.dosering.find((dosering) => dosering.doseringLang === doseringString) ?? {
        code7478_Dosering: '' as const,
        doseringLang: doseringString,
      },
    [data.dosering, doseringString]
  );

  const [bruksomraderString, setBruksomraderString] = useState<string>(
    data.bruksomrader.length === 1 ? data.bruksomrader[0].bruksomrade : ''
  );
  const bruksomrade = useMemo(
    () =>
      data.bruksomrader.find((bruksomrade) => bruksomrade.bruksomrade === bruksomraderString) ?? {
        id: '' as const,
        bruksomrade: bruksomraderString,
      },
    [bruksomraderString, data.bruksomrader]
  );

  const [forholdsreglerString, setForholdsreglerString] = useState<string>(
    data.forholdsregler.length === 1 ? data.forholdsregler[0].forholdsregel : ''
  );
  const forholdsregel = useMemo(
    () =>
      data.forholdsregler.find((forholdsregel) => forholdsregel.forholdsregel === forholdsreglerString) ?? {
        id: '' as const,
        forholdsregel: forholdsreglerString,
      },
    [data.forholdsregler, forholdsreglerString]
  );

  const [administrasjonsveiString, setAdministrasjonsveiString] = useState<string>(
    data.administrasjonsveier.length === 1 ? data.administrasjonsveier[0].administrasjonsvei : ''
  );
  const administrasjonsvei = useMemo(
    () =>
      data.administrasjonsveier.find(
        (administrasjonsvei) => administrasjonsvei.administrasjonsvei === administrasjonsveiString
      ) ?? { id: '' as const, administrasjonsvei: administrasjonsveiString },
    [administrasjonsveiString, data.administrasjonsveier]
  );

  const [courseOfTherapyType, setCourseOfTherapyType] =
    useState<Cpl_CourseOfTherapy['courseOfTherapyType']>('Faste medisiner');

  const onSubmit = useCallback(async () => {
    try {
      const result = await MedicationClient.createMedicationRequest(patientId!, {
        legemiddelpakning: selctedLegemiddelpakning,
        dosering,
        bruksomrade,
        forholdsregel,
        administrasjonsvei,
        courseOfTherapy: {
          courseOfTherapyType: courseOfTherapyType,
        },
      });

      if (result.success) {
        navigate(`/${patientId}/medisiner`);
      } else {
        addError({ message: (result.error as any)?.resultMessage ?? 'Ukjent feil' });
      }
    } catch (error) {
      addError({ message: t('Something went wrong, please try again.') });
    }
  }, [
    patientId,
    selctedLegemiddelpakning,
    dosering,
    bruksomrade,
    forholdsregel,
    administrasjonsvei,
    courseOfTherapyType,
    addError,
    navigate,
    t,
  ]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
    >
      <Column>
        <Text>{selctedLegemiddelpakning.navnFormStyrke}</Text>
        {selctedLegemiddelpakning.produsent && <Text>{selctedLegemiddelpakning.produsent}</Text>}
        <Text>{selctedLegemiddelpakning.atC_DN}</Text>
        <Datalist
          label={t('Dosage')}
          value={doseringString}
          onValue={(val) => setDoseringString(val)}
          options={data.dosering.map((d) => d.doseringLang)}
        />
        <Datalist
          label={t('Reason for use')}
          value={bruksomraderString}
          onValue={(val) => setBruksomraderString(val)}
          options={data.bruksomrader.map((d) => d.bruksomrade)}
        />
        <Datalist
          label={t('Caution')}
          value={forholdsreglerString}
          onValue={(val) => setForholdsreglerString(val)}
          options={data.forholdsregler.map((d) => d.forholdsregel)}
        />
        <Datalist
          label={t('Route of administration')}
          value={administrasjonsveiString}
          onValue={(val) => setAdministrasjonsveiString(val)}
          options={data.administrasjonsveier.map((d) => d.administrasjonsvei)}
        />
        <CourseOfTherapySelector value={courseOfTherapyType} onValue={(val) => setCourseOfTherapyType(val)} />
      </Column>

      <Row
        horizontal="flex-end"
        css={css`
          margin-top: 20px;
        `}
      >
        <Button type="submit">{t('Save')}</Button>
      </Row>
    </form>
  );
};

const SelectedLegemiddelPakningModal: FC<{
  selctedLegemiddelpakning: Cpl_Legemiddelpakning;
  onDismiss: () => void;
}> = ({ selctedLegemiddelpakning, onDismiss }) => {
  const { data, isLoading, isSuccess } = useFetchDosering(selctedLegemiddelpakning.legemiddelpakningsId);
  const t = useT();

  return (
    <Modal title={selctedLegemiddelpakning.varenavn} closeModal={onDismiss}>
      {isLoading ? (
        <Loader />
      ) : isSuccess ? (
        <Form selctedLegemiddelpakning={selctedLegemiddelpakning} data={data} />
      ) : (
        <div>{t('Something went wrong, please try again.')}</div>
      )}
    </Modal>
  );
};

const MedicationItem: FC<{ medication: Cpl_Legemiddelpakning; onLegemiddelClick: () => void }> = ({
  medication,
  onLegemiddelClick,
}) => {
  const selectedPatientId = useSelectedPatientId();
  const t = useT();

  return (
    <Block
      css={css`
        padding: 12px 16px;
        @media (max-width: 599px) {
          padding: 8px 12px;
        }
      `}
    >
      <Row vertical="center">
        <Column>
          <ModalTrigger
            button={({ openModal }) => (
              <Row>
                <button
                  onClick={openModal}
                  css={[
                    buttonReset,
                    css`
                      background-color: ${theme.colors.brightBlue};
                      border-radius: 4px;
                      padding: 2px 8px;

                      &:hover {
                        background-color: ${theme.colors.blue};
                        span {
                          color: white;
                        }
                      }
                    `,
                  ]}
                >
                  <Row vertical="center">
                    <Text>{medication.navnFormStyrke}</Text>
                  </Row>
                </button>
              </Row>
            )}
            modal={({ closeModal }) => (
              <PackageLeafletModal
                closeModal={closeModal}
                medicationName={medication.navnFormStyrke}
                itemNumber={medication.varenr}
                patientId={selectedPatientId}
              />
            )}
          />
          {/* TODO: i18n */}
          <Text size="small" marginTop={4}>
            Pakningstørrelse: {medication.pakningsstr} {medication.enhetPakning_V}
          </Text>
          <Text size="small">Virkestoff: {medication.atC_DN ?? '-'}</Text>
          <Text size="small">Produsent: {medication.produsent ?? '-'}</Text>
        </Column>

        <Row
          css={css`
            margin-left: auto;
          `}
        >
          <Button marginLeft={16} onClick={onLegemiddelClick}>
            {t('Choose')}
          </Button>
        </Row>
      </Row>
    </Block>
  );
};

export const NewMedicationPage: FC<RouteComponentProps> = () => {
  return (
    <AuthedPageLayout width="narrow">
      <PageContent />
    </AuthedPageLayout>
  );
};
