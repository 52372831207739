import { Covid19Result } from '../pages/covid19/types';
import settings from '../settings';
import Fetch from './fetch';

async function fetchCovid19(patientId: string) {
  const url = `${settings.apiUrl}/Covid19/Patient/${patientId}/Covid19`;

  return Fetch.get<Covid19Result[]>({ url });
}

export default {
  fetchCovid19,
};
