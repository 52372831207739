/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Redirect, RouteComponentProps, useLocation, useNavigate } from '@reach/router';
import React, { FC } from 'react';
import { FilledButton } from '../../components/Button2';
import { Column } from '../../components/Flex';
import Heading from '../../components/Heading';
import Loader from '../../components/loader/Loader';
import OpenPageLayout from '../../components/pagelayout/OpenPageLayout';
import Text, { getTextStyle } from '../../components/Text';
import TextInput from '../../components/TextInput';
import { useT } from '../../i18n/useT';
import { useAddNotification } from '../../lib/notifications/NotificationContext';
import { useUserContext } from '../../lib/UserContext';
import AuthClient from '../../network/authClient';
import { buttonReset } from '../../utils/styles';
import theme from '../../utils/theme';

function getReturnToPath(searchQuery: string) {
  const urlParams = new URLSearchParams(searchQuery);
  const returnTo = urlParams.get('returnto');
  if (returnTo) {
    return decodeURIComponent(returnTo);
  } else {
    return '/hjem';
  }
}

const PageContent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const t = useT();
  const { addError, addInfo } = useAddNotification();

  const { checkIfLoggedIn } = useUserContext();

  const [status, setStatus] = React.useState<'init' | 'loading' | 'success' | 'error'>('init');

  const [mobilePhone, setMobilePhone] = React.useState('');
  const [smsCode, setSmsCode] = React.useState('');
  const [password, setPassword] = React.useState('');

  const [loginType, setLoginType] = React.useState<'password' | 'smsCode'>('smsCode');
  const [requestedSmsCode, setRequestedSmsCode] = React.useState(false);

  const showLoginButton = loginType === 'password' || (loginType === 'smsCode' && requestedSmsCode);

  const submit = React.useCallback(async () => {
    if (status === 'loading') {
      return;
    }
    setStatus('loading');

    const result = await AuthClient.logIn(
      mobilePhone,
      loginType === 'password' ? password : '',
      loginType === 'smsCode' ? smsCode : ''
    );

    if (result.success) {
      try {
        await checkIfLoggedIn();
        setStatus('success');
        addInfo({ message: t('You have been successfully logged in') });
        navigate(getReturnToPath(location.search ?? ''));
      } catch (error) {
        console.log(error);
        setStatus('error');
        addError({ message: (result.error as any)?.resultMessage ?? 'Ukjent feil' });
      }
    } else {
      setStatus('error');
    }
  }, [
    status,
    mobilePhone,
    loginType,
    password,
    smsCode,
    checkIfLoggedIn,
    addInfo,
    t,
    navigate,
    location.search,
    addError,
  ]);

  const requestSmsCode = React.useCallback(async () => {
    if (status === 'loading') {
      return;
    }
    setStatus('loading');

    const result = await AuthClient.requestSmsCode(mobilePhone);

    if (result.success) {
      setStatus('init');
      setRequestedSmsCode(true);
    } else {
      setStatus('error');
      addError({ message: (result.error as any)?.resultMessage ?? 'Ukjent feil' });
    }
  }, [status, mobilePhone, addError]);

  return (
    <Column horizontal="center">
      <Column
        width={300}
        css={css`
          margin-top: 40px;
        `}
      >
        <Heading level={2}>{t('Log in header')}</Heading>

        <form
          css={css`
            width: 100%;
          `}
          onSubmit={(event) => {
            event.preventDefault();

            if (showLoginButton) {
              submit();
            } else {
              requestSmsCode();
            }
          }}
        >
          <Column>
            <TextInput
              value={mobilePhone}
              onValue={setMobilePhone}
              type="tel"
              placeholder={t('Mobile number')}
              autoComplete="phone"
              autoFocus
              disabled={status === 'loading'}
              marginBottom={12}
            />
            {loginType === 'password' && (
              <TextInput
                value={password}
                onValue={setPassword}
                type="password"
                placeholder={t('Password')}
                autoComplete="password"
                disabled={status === 'loading'}
                marginBottom={12}
              />
            )}
            {loginType === 'smsCode' && requestedSmsCode && (
              <TextInput
                value={smsCode}
                onValue={setSmsCode}
                placeholder={t('SMS code')}
                autoComplete="none"
                autoFocus
                disabled={status === 'loading'}
                marginBottom={12}
              />
            )}

            {showLoginButton && (
              <FilledButton type="submit" onClick={submit} loading={status === 'loading'}>
                {t('Log in')}
              </FilledButton>
            )}

            {loginType === 'smsCode' && (
              <FilledButton
                type={requestedSmsCode ? 'button' : 'submit'}
                onClick={requestSmsCode}
                loading={status === 'loading'}
                marginTop={requestedSmsCode ? 12 : 0}
              >
                {requestedSmsCode ? t('Re-send SMS code') : t('Send SMS code')}
              </FilledButton>
            )}

            {status === 'loading' ? (
              <Loader />
            ) : status === 'error' ? (
              <Text
                marginTop={12}
                size="small"
                color={theme.colors.errorRed}
                css={css`
                  text-align: center;
                `}
              >
                {t('Wrong username or password')}
              </Text>
            ) : null}
            {loginType === 'smsCode' && (
              <Text
                css={css`
                  margin-top: 16px;
                `}
              >
                {t(
                  'Type your mobile number above and click the button to receive the sign in code on SMS. Click "Log in using password" to use your mobile number and password.'
                )}
              </Text>
            )}
            <button
              type="button"
              css={[
                buttonReset,
                getTextStyle('normal'),
                css`
                  width: 100%;
                  text-decoration: underline;
                  margin-top: 20px;
                `,
              ]}
              onClick={() => {
                setLoginType(loginType === 'password' ? 'smsCode' : 'password');
                setStatus('init');
              }}
            >
              {loginType === 'password' ? t('Log in using SMS code') : t('Log in using password')}
            </button>
          </Column>
        </form>
      </Column>
    </Column>
  );
};

export const LoginPage: FC<RouteComponentProps> = () => {
  const userContext = useUserContext();
  const location = useLocation();

  if (userContext.isLoggedIn) {
    return <Redirect to={getReturnToPath(location.search ?? '')} noThrow />;
  }

  return (
    <OpenPageLayout documentTitle="Capable - Logg inn">
      <PageContent />
    </OpenPageLayout>
  );
};
