import React from 'react';
import { Result } from './fetch';

function useClientFetch<TResponse>(
  fetchFunction: (...args: any) => Promise<Result<TResponse>>,
  options: {
    args: any[];
    skip?: boolean;
  }
) {
  const [result, setResult] = React.useState<{
    data: TResponse | undefined;
    error: boolean;
    errorMessage?: string;
    loading: boolean;
  }>({ data: undefined, loading: true, error: false });

  React.useEffect(() => {
    if (options.skip) {
      return;
    }

    let cancelled = false;
    setResult({ data: undefined, loading: true, error: false });

    fetchFunction(...options.args)
      .then((response) => {
        if (!cancelled) {
          if (response.success) {
            setResult({
              data: response.data,
              loading: false,
              error: false,
            });
          } else {
            console.log(response.error);
            setResult({
              data: undefined,
              loading: false,
              error: true,
              errorMessage: (response?.error as any)?.message,
            });
          }
        }
      })
      .catch(() => {
        if (!cancelled) {
          setResult({ data: undefined, loading: false, error: true });
        }
      });

    return () => {
      cancelled = true;
    };
  }, [fetchFunction, options.skip, JSON.stringify(options.args)]); // eslint-disable-line

  return result;
}

export default useClientFetch;
