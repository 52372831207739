/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import readShortProps, { ShortProps } from './helpers/readShortProps';
import { getTextStyle } from './Text';
import theme from '../utils/theme';
import { Label } from '../pages/messages/ExternalMessages';

const TextInput = ({
  value,
  onValue,
  type = 'text',
  className,
  placeholder,
  ...restBeforeShortProps
}: { value: string; onValue: (value: string) => void; placeholder: string } & ShortProps &
  React.ComponentPropsWithoutRef<'input'>) => {
  const [shortPropClass, rest] = readShortProps(restBeforeShortProps);

  return (
    <Label label={placeholder}>
      <input
        value={value}
        onChange={(event) => onValue(event.target.value)}
        type={type}
        css={[
          getTextStyle('normal'),
          css`
            appearance: none;
            border: 1px solid ${theme.colors.gray};
            padding: 8px;
            border-radius: 4px;

            &:disabled {
              background-color: ${theme.colors.grayBackground};
              color: ${theme.colors.gray};
            }

            &:focus {
              color: initial;
              border: 1px solid ${theme.colors.blue};
            }
          `,
          shortPropClass,
        ]}
        className={className}
        placeholder={placeholder || ''}
        {...rest}
      />
    </Label>
  );
};

export default TextInput;
