import React from 'react';
import { useEffect, useRef } from 'react';
import { Cpl_Decision, Cpl_ProblemDecisionRelation } from '../../../types/Cpl_Decision';
import { PrescribeNewDrug2 } from './prescribedrugdose';

type Props = {
  showDlg: boolean;
  title: string;
  onClose: () => void;
  onSave: ({ newDrug, relations }: { newDrug: Cpl_Decision; relations: Cpl_ProblemDecisionRelation[] }) => void;
  children?: React.ReactNode;
};

export default function NewDrugDialog2({ showDlg, title, onClose, onSave }: Props) {
  const dialogRef = useRef<null | HTMLDialogElement>(null);
  const showDialog = showDlg ? 'y' : '';

  useEffect(() => {
    if (showDialog === 'y') {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [showDialog]);

  const closeDialog = () => {
    dialogRef.current?.close();
    onClose();
  };

  const savingNewDrug = ({
    newPrescription,
    relations,
  }: {
    newPrescription: Cpl_Decision;
    relations: Cpl_ProblemDecisionRelation[];
  }) => {
    onSave({ newDrug: newPrescription, relations });
    closeDialog();
  };

  const dialog: JSX.Element | null =
    showDialog === 'y' ? (
      /*          dialog ref = {dialogRef} className='min-h-[50%] fixed top-50 left-50 -translate-x-50 -translate-y-50 z-10 rounded-xl backdrop:bg-gray-800/50'
            <dialog ref = {dialogRef} className='min-h-[60%] fixed z-10 rounded-xl backdrop:bg-gray-800/50'>
 */ <dialog
        ref={dialogRef}
        // @ts-ignore
        onCancel={closeDialog}
        className="fixed z-10 min-h-[60%] rounded-xl p-4 backdrop:bg-gray-800/50"
      >
        <div className="flex w-[500px] max-w-full flex-col">
          <div className="mb-4 flex flex-row justify-between bg-blue-400 px-2 pt-2 text-white">
            <h1 className="text-2xl">{title}</h1>
            <button
              onClick={closeDialog}
              className="mb-2 h-8 w-8 cursor-pointer rounded border-none px-2 py-1 font-bold text-white hover:bg-blue-100 hover:text-blue-400"
            >
              X
            </button>
          </div>
          <div className="px-1 pb-2">
            <PrescribeNewDrug2 onSave={savingNewDrug} />
            {/*                     <div className="flex flex-row justify-end mt-2">
                        <button className="bg-green-500 py-1 px-2 rounded border-none">Ok</button>
                    </div>
 */}{' '}
          </div>
        </div>
      </dialog>
    ) : null;

  return dialog;
}
