/** @jsxImportSource @emotion/react */
import { Link, RouteComponentProps } from '@reach/router';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loader from '../../components/loader/Loader';
import { Modal, ModalTrigger } from '../../components/Modal';
import AuthedPageLayout from '../../components/pagelayout/AuthedPageLayout';
import Text from '../../components/Text';
import { useT } from '../../i18n/useT';
import useSelectedPatientId from '../../lib/useSelectedPatientId';
import { Fhir_Condition } from '../../types/Fhir_Condition';
import { api, toastAndThrowError } from '../../network/api';
import { Cpl_Decision, Cpl_ProblemDecisionRelation } from '../../types/Cpl_Decision';
import { ObjectContainer } from '../../types/ObjectContainer';
import { Cpl_KeyStringValue } from '../../types/Cpl_Element';
import { NotTranslated, RecursivePartial } from '../../types/utils';
import {
  getDateControlFormattedDate,
  getDateFromDateControlFormattedDate,
} from '../medications/prescriptions/convertDosering';
import { Select } from '../../components/Select2';
import { uniqBy } from 'lodash';
import { TextInput } from '../../components/TextInput2';
import { Button } from '../../components/Button3';
import Heading from '../../components/Heading';
import { fromServerDate, toServerDate } from '../../utils/serverDateConverter';
import problemDecisionRelationClient from '../../network/ProblemDecisionClient';

const CreateMedicalConditionModal = ({ closeModal }: { closeModal: () => void }) => {
  const { i18n } = useTranslation();
  const t = useT();
  const selectedPatientId = useSelectedPatientId()!;
  const [selectedGroupCode, setSelectedGroupCode] = useState<string | undefined>(undefined);
  const [selectedConditionKey, setSelectedConditionKey] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [source, setSource] = useState<string>('');
  const [addedDecisions, setAddedDecisions] = useState<string[]>([]);
  const [selectedDecision, setSelectedDecision] = useState<Cpl_Decision | undefined>(undefined);

  const groupCodesQuery = useQuery({
    queryKey: ['/Condition/GroupCodes', i18n.language],
    queryFn: () => api.get<{ diagnoses: Cpl_KeyStringValue[] }>('/Condition/GetGroupCodes'),
  });

  const conditionsByGroupCodeQuery = useQuery({
    queryKey: ['/Condition/GetByGroupCode', selectedGroupCode, i18n.language],
    queryFn: () =>
      api.get<{ diagnoses: Cpl_KeyStringValue[] }>('/Condition/GetByGroupCode', {
        params: { groupCode: selectedGroupCode },
      }),
    enabled: !!selectedGroupCode,
  });

  const medicationDecisionsQuery = useQuery({
    queryKey: ['/Decision/GetByPatient', selectedPatientId],
    queryFn: () => api.get<{ decisions: Cpl_Decision[] }>(`/Decision/GetByPatient/${selectedPatientId}?metaInfo=true`),
  });

  const decisions = medicationDecisionsQuery.data?.data.decisions ?? [];
  const medicalprescriptions = decisions.filter((i) => i.category === '' || i.category === 'Cpl_Decision_Medication'); // ZZZ To leave out assessment documents.  Explicit test when that is in place

  const selectedCondition = conditionsByGroupCodeQuery.data?.data.diagnoses.find(
    (item) => item.key === selectedConditionKey
  );

  const problemRelations = (): Cpl_ProblemDecisionRelation[] => {
    return addedDecisions.map((item) => ({
      patientId: selectedPatientId,
      id: '',
      problemType: '',
      problemId: '<NotFilledIn>',
      decisionId: item,
      relation: '',
      mailMessages: [],
      text: { status: '', div: '' }, // Dette her blir for dumt. Den er frivillig i FHIR,
      contained: [],
      extension: [],
      modifierExtension: [],
      meta: { versionId: '', profile: [], security: [], tag: [] },
      implicitRules: 'https://capableapi.azurewebsites.net/ImplicitRules', // ZZZ No meaning whatsoever, but follows URI-syntax
      language: '',
    }));
  };

  const queryClient = useQueryClient();
  const createConditionMutation = useMutation({
    mutationFn: ({
      conditionData,
      relationData,
    }: {
      conditionData: RecursivePartial<Fhir_Condition>;
      relationData: Cpl_ProblemDecisionRelation[];
    }) =>
      api
        .post<Fhir_Condition>('/Condition/Post', conditionData)
        .then(async (res) => {
          const allRelations = await Promise.all(
            relationData.map(async (i) => {
              i.problemId = res.data.id;
              var ret = problemDecisionRelationClient.createProblemDecisionRelation(i);
              return ret;
            })
          );
          return allRelations;

          /*           relationData.forEach((i) => {
            i.problemId = res.data.id;
            problemDecisionRelationClient.createProblemDecisionRelation(i);
          })
 */
        })
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: ['/api/Condition/GetAll/Patient', selectedPatientId],
          });
          queryClient.invalidateQueries({
            queryKey: ['/api/ProblemDecisionRelation/GetByPatient/', selectedPatientId],
          });
          closeModal();
        })
        .catch(toastAndThrowError),
  });

  const onSubmit = async () => {
    if (!selectedCondition) {
      return;
    }

    createConditionMutation.mutate({
      conditionData: {
        subject: { reference: `Patient/${selectedPatientId}` },
        clinicalStatus: {
          coding: [
            {
              system: 'http://terminology.hl7.org/CodeSystem/condition-clinical',
              code: 'active',
            },
          ],
        },
        code: {
          coding: [
            {
              system: 'http://capable.healthcare/HelseNorgeDiagnose',
              version: '',
              code: selectedCondition.key,
              display: selectedCondition.displayValue,
            },
          ],
          text: selectedCondition.displayValue,
        },
        onSetDateTime: startDate ? toServerDate(startDate) : '1900-01-01T00:00:00.000',
        abatementDateTime: endDate ? toServerDate(endDate) : '3000-01-01T00:00:00.000',
      },
      relationData: problemRelations(),
    });
  };

  const onStartDateChange = (dt: string) => {
    const input: Date | undefined = getDateFromDateControlFormattedDate(dt);
    setStartDate(input);
  };

  const onEndDateChange = (dt: string) => {
    const input: Date | undefined = getDateFromDateControlFormattedDate(dt);
    setEndDate(input);
  };

  const onAddDecisionToList = (id: string) => {
    setAddedDecisions([...addedDecisions, id]);
    const selectedDec = medicalprescriptions.find((i) => i.id === id);
    setSelectedDecision(selectedDec);
  };

  const onRemoveDecision = (id: string): void => {
    const ix = addedDecisions.findIndex((i) => i === id);
    addedDecisions.splice(ix, 1);
    if (ix > -1) setAddedDecisions([...addedDecisions]);
  };

  return (
    <Modal title={t('New medical condition')} closeModal={closeModal}>
      <div className="flex flex-col gap-4">
        <Select
          label="Kategori"
          value={selectedGroupCode}
          onChange={(event) => {
            if (event.target.value) {
              setSelectedGroupCode(event.target.value);
              setSelectedConditionKey(undefined);
            } else {
              setSelectedGroupCode(undefined);
              setSelectedConditionKey(undefined);
            }
          }}
        >
          {!selectedGroupCode && <option value="">{t('Select')}</option>}
          {groupCodesQuery.data?.data.diagnoses.map((item) => {
            return (
              <option key={item.key} value={item.key}>
                {item.displayValue}
              </option>
            );
          })}
        </Select>

        {selectedGroupCode && (
          <>
            {conditionsByGroupCodeQuery.isLoading ? (
              <Loader />
            ) : conditionsByGroupCodeQuery.data ? (
              <>
                <Select
                  label={t('Diagnose')}
                  value={selectedConditionKey}
                  onChange={(event) => {
                    if (event.target.value) {
                      setSelectedConditionKey(event.target.value);
                    } else {
                      setSelectedConditionKey(undefined);
                    }
                  }}
                >
                  {!selectedConditionKey && <option value="">{t('Select')}</option>}
                  {uniqBy(conditionsByGroupCodeQuery.data.data.diagnoses, (item) => item.key).map((item) => {
                    return (
                      <option key={item.key} value={item.key}>
                        {item.displayValue}
                      </option>
                    );
                  })}
                </Select>
                {selectedConditionKey && (
                  <>
                    <div className="flex flex-row justify-start">
                      <label className="break-normal pr-2 text-sm font-semibold">
                        {t('Onset')}
                        <input
                          className="w-full rounded-md border border-gray-300 p-2 text-base font-normal"
                          value={getDateControlFormattedDate(startDate)}
                          onChange={(e) => onStartDateChange(e.target.value)}
                          type="date"
                        ></input>
                      </label>
                      <label className="break-normal text-sm font-semibold">
                        {t('End date')}
                        <input
                          className="w-full rounded-md border border-gray-300 p-2 text-base font-normal"
                          value={getDateControlFormattedDate(endDate)}
                          onChange={(e) => onEndDateChange(e.target.value)}
                          type="date"
                        ></input>
                      </label>
                    </div>
                    <TextInput label={t('Source')} value={source} onChange={(e) => setSource(e.target.value)} />
                    <div className="break-normal pr-2 text-sm font-semibold">
                      {t('Drug treatment')}

                      <div className="text-base font-normal">
                        {medicalprescriptions
                          ?.filter((item) => addedDecisions.includes(item.id!))
                          .map((i) => (
                            <div key={i.id} className="flex flex-row justify-start">
                              <button className="" onClick={() => onRemoveDecision(i.id ?? '0')}>
                                ⛔
                              </button>
                              <div>{i.components?.[0]?.componentMedication?.legemiddeldose?.navnFormStyrke}</div>
                            </div>
                          ))}
                      </div>
                      <Select
                        label=""
                        value={selectedDecision?.decisionMedication?.medicationDisplayValue}
                        onChange={(e) => onAddDecisionToList(e.target.value)}
                      >
                        <option key="" value="">
                          Select
                        </option>
                        {medicalprescriptions
                          .filter((item) => !addedDecisions.includes(item.id!))
                          .map((item) => (
                            <option key={item.id ?? '0'} value={item.id ?? '0'}>
                              {item.components?.[0]?.componentMedication?.legemiddeldose?.navnFormStyrke}
                            </option>
                          ))}
                      </Select>
                    </div>
                  </>
                )}
              </>
            ) : null}
          </>
        )}

        <div className="flex items-start justify-end gap-4">
          <Button onClick={closeModal} variant="secondary">
            {t('Cancel')}
          </Button>
          <Button onClick={onSubmit} disabled={!selectedCondition} loading={createConditionMutation.isLoading}>
            {t('Add')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const MedicalConditions = () => {
  const t = useT();
  const selectedPatientId = useSelectedPatientId()!;
  const queryClient = useQueryClient();

  const conditionQuery = useQuery({
    queryKey: ['/api/Condition/GetAll/Patient', selectedPatientId],
    queryFn: () => api.get<Fhir_Condition[]>(`/Condition/GetAll/Patient/${selectedPatientId}`),
  });

  const relationQuery = useQuery({
    queryKey: ['/api/ProblemDecisionRelation/GetByPatient/', selectedPatientId],
    queryFn: () =>
      api.get<ObjectContainer>(`/ProblemDecisionRelation/GetByPatient/${selectedPatientId}`, {
        params: { metaInfo: true },
      }),
  });

  const decisionQuery = useQuery({
    queryKey: ['/api/Decision/GetByPatient', selectedPatientId],
    queryFn: () => api.get<Cpl_Decision[]>(`/Decision/GetByPatient/${selectedPatientId}`),
  });

  const conditions = conditionQuery.data?.data ?? [];
  const now = new Date();

  const presentConditions = conditions.filter(
    (item) => fromServerDate(item?.abatementDateTime ?? '3000-01-01T00:00:00.000') > now
  );
  const formerConditions = conditions.filter(
    (item) => item?.abatementDateTime && fromServerDate(item.abatementDateTime!).getTime() <= now.getTime()
  );

  const relations: Cpl_ProblemDecisionRelation[] = relationQuery.data?.data?.problemDecisionRelations ?? [];
  const decisions = decisionQuery.data?.data ?? [];

  const presentDecision = (decisionId: string) => {
    return (
      <div key={decisionId}>
        {decisions.find((i) => i.id === decisionId)?.components?.[0]?.componentMedication?.legemiddeldose
          ?.navnFormStyrke ?? decisionId}
      </div>
    );
  };

  // const presentableRelations:PresentableRelation[] = relations.filter(i => conditions.includes((j as Fhir_Condition) => j.indentifier.) )

  const deleteCondition = async (condId: string) => {
    await api.delete('/Condition/Delete/' + condId.toString());
    queryClient.invalidateQueries({
      queryKey: ['/api/Condition/GetAll/Patient', selectedPatientId],
    });
  };

  const presentOnsetTime = (onsetDate: string | undefined): string => {
    if (onsetDate === undefined) return '';
    if (onsetDate === '1900-01-01T00:00:00:000' || onsetDate === '3000-01-01T00:00:00:000') return '';
    const onsetD: Date = fromServerDate(onsetDate);
    if (onsetD.getFullYear() === 1900 || onsetD.getFullYear() === 3000) return '';

    return onsetD.getFullYear().toString();
  };

  const presentConditionList = (header: string, cond: Fhir_Condition[]) => {
    return (
      <>
        {cond.length > 0 && (
          <div>
            <div className="text-xl">{header}</div>
          </div>
        )}
        {cond.map((condition) => {
          const links = relations?.filter((item) => item.problemId === condition.id);
          // ?.map((i) => presentDecision(i.decisionId))

          return (
            <div key={condition.id} className="flex flex-row items-center gap-2 hover:bg-blue-100 ">
              <div className="grow basis-28">
                <Text className="">
                  {presentOnsetTime(condition?.onSetDateTime) + '  ' + condition?.code?.coding?.[0]?.display}
                </Text>
                {links.length > 0 && (
                  <div className="text-gray-400 text-sm">{links?.map((i) => presentDecision(i.decisionId))}</div>
                )}
              </div>
              <button
                onClick={() => {
                  deleteCondition(condition.id!);
                }}
                className=" p-2 basis-16 bg-blue-200 hover:bg-blue-400 hover:text-white rounded"
              >
                {t('Delete')}
              </button>
              <Link
                className="basis-30 m1 p-4 max-h-5 rounded flex justify-center items-center bg-blue-200 hover:bg-blue-400 hover:text-white"
                to={`/${selectedPatientId}/medical-condition/${condition.id!}`}
              >
                {t('View details')}
              </Link>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <Heading>{t('Current conditions and treatment')}</Heading>

      {conditionQuery.isLoading && <Loader />}

      {!conditionQuery.isLoading && conditions.length === 0 && (
        <Text>{t("You haven't added any medical conditions yet.")}</Text>
      )}

      {presentConditionList(t('Current conditions'), presentConditions)}
      <br></br>
      {presentConditionList(t('Previous conditions'), formerConditions)}

      <ModalTrigger
        button={({ openModal }) => (
          <div>
            <Button onClick={openModal}>{t('Add new medical condition')}</Button>
          </div>
        )}
        modal={({ closeModal }) => <CreateMedicalConditionModal closeModal={closeModal} />}
      />
    </div>
  );
};

export const MedicalConditionsPage: FC<RouteComponentProps> = () => {
  const t = useT();

  return (
    <AuthedPageLayout width="narrow" documentTitle={t('Capable - Medical conditions')}>
      <MedicalConditions />
    </AuthedPageLayout>
  );
};
