import React from 'react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import {
  Cpl_Decision,
  Cpl_Decision_Component,
  Cpl_Decision_RecurrenceParams,
  Cpl_Decision_RecurrentTask,
} from '../../../types/Cpl_Decision';
import {
  dayNoFromDate,
  dbDayFromDateCtrlString,
  getDateControlFormattedDateFromDbDayNo,
  weekDayFromDbDay,
} from './convertDosering';
import { Button } from '../../../components/Button3';
import { fromServerDate } from '../../../utils/serverDateConverter';
import { useT } from '../../../i18n/useT';

// ZZZ Redo usage of these , collected from dosage4.tsx
const checkMark1 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-5 w-6"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
    </svg>
  );
};

const tinyrightarrow2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 8"
      strokeWidth={1.0}
      stroke="currentColor"
      className="h-2 w-5 text-blue-500"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 0.25 21 4m0 0-3.75 3.75M21 4H2 M0 0.5 0 7.5" />
    </svg>
  );
};

const nothing1 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 8"
      strokeWidth={1.0}
      stroke="currentColor"
      className="h-2 w-5 text-blue-500"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="" />
    </svg>
  );
};

export default function DiscontinueDrugDialog({
  showDlg,
  title,
  curDecision,
  onClose,
  onSave,
}: {
  showDlg: boolean;
  title: string;
  curDecision: Cpl_Decision | undefined;
  onClose: () => void;
  onSave: ({
    decision,
    terminationDay,
    terminationOffset,
    createIntoleranceEntry,
  }: {
    decision: Cpl_Decision;
    terminationDay: number;
    terminationOffset: number;
    createIntoleranceEntry: boolean;
  }) => void;
  children?: React.ReactNode;
}) {
  const dialogRef = useRef<null | HTMLDialogElement>(null);
  const showDialog = showDlg ? 'y' : '';

  useEffect(() => {
    if (showDialog === 'y') {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [showDialog]);

  const closeDialog = () => {
    dialogRef.current?.close();
    onClose();
  };

  const savingNewOrder = ({
    decision,
    terminationDay,
    terminationOffset,
    createIntoleranceEntry,
  }: {
    decision: Cpl_Decision;
    terminationDay: number;
    terminationOffset: number;
    createIntoleranceEntry: boolean;
  }) => {
    onSave({ decision, terminationDay, terminationOffset, createIntoleranceEntry });
    closeDialog();
  };

  const dialog: JSX.Element | null =
    showDialog === 'y' ? (
      <dialog ref={dialogRef} className="fixed z-10 rounded-xl p-4 backdrop:bg-gray-800/50">
        <div className="flex w-[450px] max-w-full flex-col">
          <div className="mb-4 flex flex-row justify-between bg-blue-400 px-2 pt-2 text-white">
            <h1 className="text-2xl">{title}</h1>
            <button
              onClick={closeDialog}
              className="mb-2 h-8 w-8 cursor-pointer rounded border-none px-2 py-1 font-bold text-white hover:bg-blue-100 hover:text-blue-400"
            >
              X
            </button>
          </div>
          <div className="px-1 pb-2">
            <DiscontinueDrug curDecision={curDecision} onSave={savingNewOrder} onCancel={closeDialog} />
            {/*                     <div className="flex flex-row justify-end mt-2">
                        <button className="bg-green-500 py-1 px-2 rounded border-none">Ok</button>
                    </div>
 */}{' '}
          </div>
        </div>
      </dialog>
    ) : null;

  return dialog;
}

function DiscontinueDrug({
  curDecision,
  onSave,
  onCancel,
}: {
  curDecision: Cpl_Decision | undefined;
  onSave: ({
    decision,
    terminationDay,
    terminationOffset,
    createIntoleranceEntry,
  }: {
    decision: Cpl_Decision;
    terminationDay: number;
    terminationOffset: number;
    createIntoleranceEntry: boolean;
  }) => void;
  onCancel: () => void;
}) {
  const today: Date = new Date();
  const [year, month, day] = [today.getFullYear(), today.getMonth(), today.getDate()];
  const [discontinuationDayNo, setDiscontinuationDayNo] = useState<number>(dayNoFromDate(new Date(year, month, day)));

  const [discontinueReason, setDiscontinueReason] = useState<string>('');
  const [registerIntolerance, setRegisterIntolerance] = useState<boolean>(false);
  const t = useT();

  const saveDiscontinuation = (prescr: Cpl_Decision | undefined) => {
    onSave({
      decision: prescr!,
      terminationDay: discontinuationDayNo,
      terminationOffset: 0,
      createIntoleranceEntry: registerIntolerance,
    });
  };

  const checkDisabledSave = (): boolean => {
    if (discontinuationDayNo === 0) return true;
    else if (discontinuationDayNo >= dayNoFromDate(fromServerDate(curDecision?.computedEndTime!))) return true;

    return false;
  };

  const labelstyle = 'block text-sm font-medium leading-6 text-gray-400';
  /*   const inputtextstyle =
    "block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6";
 */ const inputtextstyle =
    'mx-2 rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6';

  return (
    <div className="mx-auto max-w-xl rounded-xl bg-white ">
      <div className="m5 p2 text-lg font-medium text-gray-400">
        {t('Discontinue ') + curDecision?.components?.[0]?.componentMedication?.legemiddeldose?.navnFormStyrke ?? ''}
      </div>
      <label htmlFor="stopdate" className={labelstyle + ' py-2 '}>
        {t('From and including')}
        <input
          className="mx-2 mb-2 bg-gray-100 pl-2 text-blue-600 disabled:text-gray-400"
          value={getDateControlFormattedDateFromDbDayNo(discontinuationDayNo)}
          onChange={(e) => setDiscontinuationDayNo(dbDayFromDateCtrlString(e.target.value))}
          type="date"
          id="stopdate"
        ></input>
      </label>

      <label htmlFor="intolerance" className="align-bottom">
        {t('Register intolerance to this drug')}
        <input
          className="mx-2"
          type="checkbox"
          onChange={() => setRegisterIntolerance(!registerIntolerance)}
          checked={registerIntolerance}
          name="intolerance"
        />
      </label>
      <div hidden={!registerIntolerance} className="text-red-800 py-2">
        {t('Intolerance to the medicine will automatically be registered')}
      </div>

      <div className="flex flex-row justify-end gap-4 pt-6">
        <Button variant="secondary" onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button
          className="hover:bg-blue-700 disabled:border-none disabled:bg-gray-400"
          disabled={checkDisabledSave()}
          variant="primary"
          onClick={() => {
            saveDiscontinuation(curDecision);
          }}
        >
          {t('Ok')}
        </Button>
      </div>
    </div>
  );
}
