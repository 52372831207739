/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { buttonReset } from '../../utils/styles';

export function PencilButton({ onClick, disabled }) {
  return (
    <button
      aria-label="Rediger"
      onClick={onClick}
      disabled={disabled}
      css={[
        buttonReset,
        css`
          width: 16px;
          height: 16px;
          cursor: pointer;

          svg path {
            fill: black;
          }

          &:hover {
            svg path {
              fill: rgba(53, 90, 183);
            }
          }
        `,
        disabled &&
          css`
            opacity: 0.4;
            cursor: no-drop;
          `,
      ]}
    >
      <svg
        viewBox="0 0 528.899 528.899"
        css={css`
          width: 100%;
          height: 100%;
        `}
      >
        <path d="M328.883 89.125l107.59 107.589-272.34 272.34L56.604 361.465l272.279-272.34zm189.23-25.948l-47.981-47.981c-18.543-18.543-48.653-18.543-67.259 0l-45.961 45.961 107.59 107.59 53.611-53.611c14.382-14.383 14.382-37.577 0-51.959zM.3 512.69c-1.958 8.812 5.998 16.708 14.811 14.565l119.891-29.069L27.473 390.597.3 512.69z" />
      </svg>
    </button>
  );
}
