/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { FC } from 'react';
import { Row } from '../../components/Flex';
import Table from '../../components/Table';
import { useT } from '../../i18n/useT';
import { useAddNotification } from '../../lib/notifications/NotificationContext';
import { useUserContext } from '../../lib/UserContext';
import UserClient from '../../network/UserClient';
import { Cpl_User } from '../../types/Cpl_User';
import theme from '../../utils/theme';

export const InvitedUserList: FC = () => {
  const t = useT();
  const { addWarn } = useAddNotification();
  const { loggedInUser, checkIfLoggedIn } = useUserContext();

  const deleteInvite = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    metaId: number,
    mobileNumber: string
  ) => {
    event.preventDefault();

    // make a copy
    let user: Cpl_User = JSON.parse(JSON.stringify(loggedInUser!.user));

    user.invitedUsers.forEach((invitedUser) => {
      if (invitedUser.metaAttributes?.id === metaId) {
        invitedUser.metaAttributes.deleted = true;
      }
    });

    try {
      await UserClient.updateUser(user);
      await checkIfLoggedIn();
      addWarn({
        message: t('Removed access for {{mobileNumber}}', { mobileNumber: mobileNumber }),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const invitedUsers = (loggedInUser?.user.invitedUsers ?? []).filter((user) => !user.metaAttributes?.deleted);

  return (
    <Row
      horizontal="center"
      vertical="center"
      css={css`
        margin: 20px;
      `}
    >
      <Table>
        <thead>
          <tr>
            <td colSpan={2}>
              <h2>{t('Invited people')}</h2>
            </td>
          </tr>
        </thead>
        <tbody
          css={css`
            tr:last-child {
              td:last-child {
                button {
                  border-bottom-right-radius: 8px;
                  border: 0;
                }
              }
            }
          `}
        >
          {invitedUsers.map((user) => (
            <tr key={user.metaAttributes?.id}>
              <td>{user.displayName + (user.mobilePhone && ' (' + user.mobilePhone + ')')}</td>
              <td
                css={css`
                  padding: 0px !important;
                  position: relative;
                  width: 150px;
                `}
              >
                <button
                  css={css`
                    background-color: ${theme.colors.lightBlue};
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    border-color: white;
                    border-top: 0;
                    font-size: 14px;
                    color: rgba(255, 255, 255, 1);
                    border: 0;
                    cursor: pointer;
                  `}
                  onClick={(event) => deleteInvite(event, user.metaAttributes!.id, user.mobilePhone)}
                >
                  {t('Remove access')}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Row>
  );
};
