import axios from 'axios';
import settings from '../settings';

export const api = (() => {
  const instance = axios.create({
    baseURL: settings.apiUrl,
    withCredentials: true,
  });

  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers['x-capable-session-id'] = localStorage.getItem('sessionId') || '';
    }

    return config;
  });

  return instance;
})();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getApiErrorMessage = (error: any): string | undefined => {
  return error ? error.response?.data?.resultMessage ?? error.message ?? 'Ukjent feil' : undefined;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toastAndThrowError = (error: any) => {
  const message = getApiErrorMessage(error);
  if (message) {
    // toast.error(message);
  }
  throw error;
};
