import { RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import Heading from '../../components/Heading';
import OpenPageLayout from '../../components/pagelayout/OpenPageLayout';
import Text from '../../components/Text';
import { useT } from '../../i18n/useT';

const Content = () => {
  const t = useT();

  return (
    <div>
      <Heading level={2}>{t('Feedback')}</Heading>

      <Text>TODO: Create a feedback form</Text>
    </div>
  );
};

export const FeedbackPage: FC<RouteComponentProps> = () => {
  const t = useT();

  return (
    <OpenPageLayout documentTitle={t('Capable - Feedback')}>
      <Content />
    </OpenPageLayout>
  );
};
